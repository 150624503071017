@import 'bootstrap/scss/bootstrap';
@import 'bootstrap/scss/mixins';
@import '../../../Assets/Styles/typography';
@import '../../../Assets/Styles/colors';
@import '../../../Assets/Styles/mixins';

.DeletePopup {
  z-index: 9999999 !important;

  .modal-dialog {
    max-width: 405px;
    width: 100%;

    @include media-breakpoint-down(md) {
      max-width: 100% !important;
    }
  }

  .modal-header {
    border-bottom: none;
    padding-bottom: 0;
  }

  .modal-body {
    text-align: center;
    padding: 0 25px;

    i {
      font-size: 36px;
      color: $success;
      margin-bottom: 30px;

      &.media {
        color: #fc394d;
      }
    }
  }

  .modal-footer {
    text-align: center;
    border-top: none;
    justify-content: center;
    padding-bottom: 23px;

    button {
      @include button-success;

      width: 80px;
      height: 40px;

      &.mediabutton {
        background-color: $white;
        border: 1px solid #fc394d;
        color: #fc394d;

        &:hover,
        &:focus {
          background-color: #fc394d !important;
          border: 1px solid #fc394d;
          color: $white;
        }
      }
    }
  }
}

body:has(.DeletePopup.show) .modal-backdrop {
  z-index: 9999999 !important;
}
