@import '~bootstrap/scss/bootstrap';
@import '~bootstrap/scss/mixins';
@import '../../../Assets/Styles/typography';
@import '../../../Assets/Styles/colors';
@import '../../../Assets/Styles/mixins';

.legacy-modal {
  @include media-breakpoint-down(xl) {
    z-index: 9999999999 !important;
  }

  @include media-breakpoint-down(md) {
    padding: 0 !important;
  }

  .logo {
    width: 244px;
    height: 96px;
  }

  .modal-dialog {
    @include media-breakpoint-down(md) {
      margin: 0 !important;
    }
  }

  .header {
    font-size: 24px;
    font-family: Poppins, serif;
    font-weight: 600;
    line-height: 31px;
    color: #2c3e50;
    margin-top: 33px;
    margin-bottom: 13px;
  }

  .modal-content {
    width: 586px;

    @include media-breakpoint-down(md) {
      width: 100% !important;
      max-width: 100% !important;
      border: none;
    }
  }

  .modal-body {
    padding: 48px;
    font-family: Poppins, serif;
  }

  .body {
    font-size: 16px;
    line-height: 24px;
    color: #333;
    margin-bottom: 13px;
  }

  .shareContainer {
    width: 100%;
    justify-content: center;
    display: flex;
    margin-top: 13px;
    margin-bottom: 13px;
  }

  .shareButton > img {
    width: 40px;
    height: 40px;
    border-radius: 100px;
  }

  .shareButton {
    border: 1px solid black;
    border-radius: 20px;
    margin-left: 4px;
    margin-right: 4px;
  }

  .publishContainer {
    width: 100%;
    justify-content: center;
    display: flex;
    margin-top: 23px;
  }

  .publishButton {
    // flex-grow: 0;
    // display: flex;
    // flex-direction: row;
    // justify-content: center;
    // align-items: center;
    // gap: 10px;
    // padding: 13px 26px;
    // border-radius: 50px;
    // border-color: #24b183;
    // background-color: #24b183;
    // box-shadow: none;
    @include button-success;

    width: 166px;
    height: 50px;
  }

  .Icons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 287px;

    //margin: 28px auto 48px;
    margin: 28px auto;
    pointer-events: none;

    i {
      color: #24b183;
      cursor: pointer;

      &:hover {
        color: #2c3e50;
      }

      &.fa-butterflylink {
        font-size: 19px;
      }

      &.fa-butterflyemail2 {
        font-size: 19px;
      }

      &.fa-butterflymessage {
        font-size: 22px;
      }

      &.fa-butterflyfacebook {
        font-size: 19px;
      }

      &.fa-twitter {
        font-size: 17px;
      }

      &.fa-butterflylinkedin {
        font-size: 18px;
      }
    }

    span.iconWrapper {
      width: 42px;
      height: 42px;
      border: 2px solid #e4e5e6;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;

      a {
        line-height: 1;
      }
    }
  }

  .overlayActive {
    z-index: 1;
  }

  .overlay {
    background: rgb(0 0 0 / 50%);
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    z-index: 1;
  }
}
