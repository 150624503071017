@import 'bootstrap/scss/bootstrap';
@import 'bootstrap/scss/mixins';
@import '../../Assets/Styles/typography';
@import '../../Assets/Styles/colors';
@import '../../Assets/Styles/mixins';

.panel-wrap {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  transform: translateX(100%);

  //min-height: 100vh;
  transition-delay: 0.1s;
  transition-property: background;

  @include media-breakpoint-up(xl) {
    height: 100vh;
  }

  @include media-breakpoint-down(md) {
    display: none;
  }

  .panel {
    background: $white;
    overflow: auto;
    padding: 0 24px 15px;
    width: 100%;
    height: 100%;
    right: 0;
    position: absolute;

    @include media-breakpoint-down(md) {
      padding-bottom: 90px;
      position: fixed;
    }

    &.EditPage {
      max-width: 730px;
      width: 100%;
    }

    &.Fundraiser {
      max-width: 730px;
      width: 100%;
    }

    &.Gallery {
      max-width: 942px;
      width: 100%;

      @include media-breakpoint-down(md) {
        padding-bottom: 100px;
      }

      max-height: 100vh;
      overflow: 'scroll';
    }

    &.Members {
      max-width: 500px;
      width: 100%;
    }

    &.MessageBoard {
      padding: 0 15px 15px;
    }

    &.SearchPages {
      max-width: 620px;
    }

    &.SettingsPages {
      max-width: 730px;
    }

    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid $grey-1;
      margin-bottom: 17px;
      flex-wrap: wrap;
      padding-right: 25px;

      @include media-breakpoint-down(md) {
        position: fixed;
        width: 100%;
        left: 0;
        background: $white;
        z-index: 99;
      }

      h4 {
        margin-bottom: 0;
        padding: 18px 8px;
        padding-right: 15px;
        color: $success;
        font: {
          family: $font-family-base;
          weight: $font-weight-semibold;
          size: 20px;
        }

        @include media-breakpoint-down(md) {
          padding-left: 0;
        }

        word-wrap: break-word;
        word-break: break-all;
      }

      h5 {
        word-wrap: break-word;
        cursor: pointer;
        margin-bottom: 0;
        padding: 15px 8px 17px 0;
        color: $success;
        display: flex;
        align-items: center;
        word-break: break-all;
        padding-right: 15px;
        font: {
          family: $font-family-base;
          size: 16px;
        }

        i.fa-arrow-left {
          font-size: 12px;
          padding-left: 12px;
          transition: ease-out;
          padding-right: 4px;
        }

        &:hover {
          i.fa-arrow-left {
            font-size: 12px;
            padding-left: 4px;
            padding-right: 12px;
            transition: ease-in;
          }
        }

        .darkcolor {
          color: $title;
        }
      }

      button.close-btn {
        background-color: transparent;
        border: none;
        display: block !important;
        padding: 0;
        position: absolute;
        right: 10px;
        width: 30px;
        height: 30px;
        top: 15px;

        i {
          color: #777;
          font-size: 30px;
          font-weight: lighter;
        }

        &:hover {
          i {
            color: $hover-button;
          }

          color: $hover-button;
        }
      }

      span {
        color: $success;
        cursor: pointer;
        font: {
          size: 14px;
          family: $font-family-dada-grotesk;
          weight: $font-weight-medium;
        }

        &:nth-child(1) {
          margin-right: 20px;
        }

        @include media-breakpoint-down(md) {
          padding-bottom: 10px;
          display: inline-block;
        }

        &:hover {
          color: $hover-button;
        }
      }
    }

    &.AudioMessage {
      @include media-breakpoint-down(md) {
        .EmptyText {
          text-align: center;
          position: absolute;
          top: 50%;
          width: calc(100% - 50px);
        }
      }

      .AudioRecordingPopup {
        .page-owner-img {
          text-align: center;
          width: 100%;

          img {
            display: block;
            width: 70px;
            height: 70px;
            border-radius: 50%;
            margin: auto;
          }

          span {
            &.Alpha {
              text-transform: capitalize;
              height: 65px;
              width: 65px;
              background: #f3f0fe;
              border-radius: 50%;
              color: #9b7bf6;
              font-size: 36px;
              font-weight: $font-weight-semibold;
              display: flex;
              align-items: center;
              margin: auto;
              justify-content: center;
            }
          }

          p {
            display: block;
            max-width: 320px;
            margin: auto;
            margin-top: 8px;
            font: {
              size: 16px;
              family: $font-family-base;
              weight: $font-weight-medium;
            }
          }
        }

        .body {
          margin: 20px 0;

          .Audio {
            .AudioPost {
              .UploadSec {
                border-radius: 10px;
                border: 1px dashed $grey-1;
                padding: 28px;
                text-align: center;
                min-height: 176px;
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                justify-content: center;

                .row {
                  align-items: center;
                  width: 100%;

                  .AudioWaves {
                    flex: 0 1 calc(100% - 60px);
                  }
                }

                .delete i {
                  font-size: 24px;
                  color: $title;
                  cursor: pointer;
                }

                .ControllerIcon {
                  width: 58px;
                  height: 58px;
                  flex: 0 1 58px;
                  padding: 0;
                  cursor: pointer;

                  i {
                    font-size: 58px;
                    color: $success;
                  }
                }

                .RecSec {
                  cursor: pointer;
                  width: 100%;

                  span {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;

                    i {
                      padding-right: 5px;

                      &.fa-stoprecording {
                        color: #fc394d;
                      }
                    }

                    &:hover {
                      color: #fc394d;
                    }
                  }

                  button {
                    @include button-success;

                    height: 40px;

                    i {
                      padding-right: 5px;
                      font-size: 20px;
                    }
                  }
                }

                .Ripple {
                  position: relative;
                  margin: 0 auto 40px;
                  width: 250px;
                  height: 100px;
                }

                .dot::before {
                  content: ' ';
                  position: absolute;
                  z-index: 2;
                  left: 50%;
                  top: 50%;
                  transform: translate(-50%, -50%);
                  width: 20px;
                  height: 20px;
                  background-color: #1d817b;
                  border-radius: 50%;
                }

                .dot::after {
                  content: ' ';
                  position: absolute;
                  z-index: 1;
                  width: 20px;
                  height: 20px;
                  left: 50%;
                  top: 50%;
                  transform: translate(-50%, -50%);
                  background-color: #1d817b;
                  border-radius: 50%;
                  box-shadow: 0 0 20px rgb(0 0 0 / 30%) inset;
                  animation-name: 'ripple';
                  animation-duration: 1s;
                  animation-timing-function: ease;
                  animation-delay: 0s;
                  animation-iteration-count: infinite;
                  animation-direction: normal;
                }

                @keyframes ripple {
                  0% {
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                    opcity: 75;
                    width: 0;
                    height: 0;
                  }

                  100% {
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                    opacity: 0;
                    width: 100px;
                    height: 100px;
                  }
                }
              }
            }
          }
        }

        .footer {
          button {
            @include button-success;

            height: 40px;
            width: 100px;
          }
        }
      }
    }
  }

  &.active {
    transform: translateX(0%);
    z-index: 999;
    background: rgb(0 0 0 / 60%);
    transition-delay: 0s;
    transition-property: background;

    @include media-breakpoint-down(md) {
      position: fixed;
      display: block;
    }

    &:has(.CreateEvent),
    &:has(.EventDetails),
    &:has(.EventEdit),
    &:has(.fundraiser-popup) {
      @include media-breakpoint-down(md) {
        //z-index: 999;
        z-index: 9999999;

        .Fundraiser {
          padding-bottom: 0 !important;
        }

        .MessageBoard {
          padding-bottom: 0 !important;

          .outside {
            box-shadow: 0 -1px 0 rgb(0 0 0 / 10%) !important;
            padding-bottom: 0 !important;
          }
        }
      }
    }
  }
}

body {
  &.message-board,
  &.audio-message,
  &.search-page,
  &.notification-page,
  &.blocked-member-screen,
  &.personal-message,
  &.settings-page {
    .panel-wrap {
      &.active {
        @include media-breakpoint-down(md) {
          //z-index: 999;
          z-index: 9999999;

          .MessageBoard {
            padding-bottom: 0 !important;

            .outside {
              box-shadow: 0 -1px 0 rgb(0 0 0 / 10%) !important;
              padding-bottom: 0 !important;
            }
          }
        }
      }
    }
  }

  &.edit-page {
    .panel-wrap {
      &.active {
        @include media-breakpoint-down(md) {
          z-index: 9999999;
        }
      }
    }
  }

  &.event-page,
  &.gallery-add-page {
    .panel-wrap {
      &.active {
        @include media-breakpoint-down(md) {
          bottom: 0;
        }
      }
    }
  }

  &.notification-page,
  &.search-page,
  &.settings-page,
  &.question-page,
  &.event-page,
  &.gallery-add-page,
  &.members-page,
  &.personal-message,
  &.message-board,
  &.message-board,
  &.audio-message,
  &.fundraiser,
  &.edit-page {
    .panel-wrap .panel {
      @include media-breakpoint-up(md) {
        &.Notification,
        &.SettingsPages {
          .header {
            position: fixed;
            background-color: $white;
            width: 520px;
            z-index: 99;
            padding-right: 0;

            button.close-btn {
              right: 0;
            }
          }

          .NotificationWrapper,
          .search-text,
          .SettingTab {
            margin-top: 70px;
          }
        }

        &.SettingsPages {
          .header {
            width: 679px;
          }
        }

        &.SearchPages {
          overflow: hidden;

          .header {
            position: fixed;
            background-color: $white;
            width: 570px;
            z-index: 99;
            padding-right: 0;

            button.close-btn {
              right: 0;
            }
          }

          .NotificationWrapper,
          .search-text {
            margin-top: 70px;
          }
        }
      }

      @include media-breakpoint-down(md) {
        .header {
          position: fixed;
          background: $white;
          width: 100%;
          padding-right: 0;
          z-index: 99;
          padding: 0 24px;
          left: 0;
          margin: 0;
        }

        .NotificationWrapper,
        .fundraiser-popup {
          padding-top: 65px;
        }

        .CreateEvent {
          padding-top: 85px !important;
        }

        .search-text,
				.EventList,
				.EventDetails,
				
				.EventEdit,
				//.MemberHeader,
				.AudioViewMobile,
				.AudioRecordingPopup,
				.fundraiserSliderWrapper {
          padding-top: 105px !important;
        }

        .EditTab {
          padding-top: 105px !important;
        }

        .AudioViewMobile {
          .header {
            position: inherit !important;
          }
        }

        .selected-album-name {
          margin-top: 140px;
        }

        .SettingTab,
        #MessageBoard {
          margin-top: 75px;
        }
      }
    }
  }
}

body.gallery-add-page .panel-wrap.active:has(.GalleryTab.hide) {
  z-index: 9999999 !important;
}

body.gallery-add-page.modal-open {
  &:has(.PostPopup) {
    .modal-backdrop {
      z-index: 9999999;
    }
  }

  .modal.show {
    z-index: 999999999 !important;
  }
}
