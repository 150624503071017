@import 'bootstrap/scss/bootstrap';
@import 'bootstrap/scss/mixins';
@import '../../Assets/Styles/typography';
@import '../../Assets/Styles/colors';
@import '../../Assets/Styles/mixins';

.panel.SettingsPages {
  .SettingTab {
    &.nav.nav-tabs {
      padding: 0 10px;
      border: none;
      overflow-y: hidden;
      display: flex;
      flex-flow: row nowrap;
      scrollbar-width: none;

      li {
        &.nav-item {
          button {
            cursor: pointer;

            &.nav-link {
              font: {
                size: 16px;
                family: $font-family-dada-grotesk;
                weight: $font-weight-medium;
              }

              color: #8c8c8c;
              border: none;
              padding: 5px;

              &.active {
                border-bottom: 4px solid $hover-button;
                color: $hover-button;
                font-weight: $font-weight-medium;
              }
               &:hover {
                color: $hover-button;
                font-weight: $font-weight-medium;
              }
            }
          }
        }

        margin-right: 35px;
      }

      &::-webkit-scrollbar {
        height: 0;
        background-color: transparent;
      }
    }
  }

  .tab-content {
    width: 100%;
    height: calc(100% - 132px);
    padding: 0 10px;

    .tab-pane {
      width: 100%;

      @include media-breakpoint-down(md) {
        padding-bottom: 90px;
        height: fit-content;
      }

      .OwnerDetails {
        padding: 25px 50px 20px;

        @include media-breakpoint-down(md) {
          padding: 0 0 20px;
        }

        .mt-4.mb-4.row {
          @include media-breakpoint-down(md) {
            margin-top: 0 !important;
          }
        }

        .ImgCroper {
          width: 143px;
          height: 143px;
          border-radius: 50%;
          border: 1px dashed #777;
          text-align: center;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          position: relative;

          &:has(img) {
            border: none;
          }

          span {
            i {
              color: $success;
              display: block;
              font-size: 34px;
              margin-bottom: 10px;
            }

            color: $title;
            display: block;
            font: {
              size: 12px;
              family: $font-family-dada-grotesk;
              weight: $font-weight-regular;
            }

            &.cropImg {
              border: 1px solid $bg;
              padding: 8px;
              border-radius: 50%;
              width: 40px;
              height: 40px;
              position: absolute;
              background-color: $white;
              bottom: 0;
              right: 0;

              i {
                color: $success;
                font-size: 20px;
              }
            }
          }

          .ImgWrapper {
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            position: relative;
            width: 143px;
            height: 141px;
            border-radius: 50%;
            overflow: hidden;
            background-color: #1d817b2b;
            color: #1d817b;

            span {
              color: #1d817b;
              font: {
                size: 60px;
                family: $font-family-dada-grotesk;
                weight: $font-weight-medium;
              }
            }

            img {
              height: 143px;
            }
          }

          &:hover {
            span i {
              &.noImg {
                color: $hover-button;
              }

              color: $success;
            }

            span.cropImg {
              i {
                color: $title;
              }
            }
          }

          p.text.text-danger.errorMessage {
            top: 42%;
            font-size: 12px;
            width: 150px;
            right: 0;
            left: 143px;
            position: absolute;
          }
        }

        label {
          font: {
            size: 14px;
            family: $font-family-dada-grotesk;
            weight: $font-weight-medium;
          }

          color: $title;
          margin-bottom: 5px;
        }

        input {
          height: 40px;
          padding: 0 0 0 10px;
          border: 1px solid $grey-1;
          border-radius: 10px;
          background-color: $white;
          background-size: cover;
          font: {
            size: 14px;
            family: $font-family-dada-grotesk;
            weight: $font-weight-regular;
          }

          color: $title;
          outline: none;

          &:focus {
            outline: none;
            box-shadow: none;
          }

          &:disabled {
            background-color: $bg;
          }
        }

        textarea {
          height: 80px;
          outline: none;
          padding: 10px 5px 5px 10px;
          border: 1px solid $grey-1;
          border-radius: 10px;
          background-color: $white;
          background-size: cover;
          resize: none;
          font: {
            size: 14px;
            family: $font-family-dada-grotesk;
            weight: $font-weight-regular;
          }

          color: $title;
          text-decoration: none solid rgb(44 62 80);

          &:focus,
          &:hover {
            box-shadow: none;
            outline: none;
          }
        }

        span.textcount {
          color: #777;
          font: {
            size: 12px;
            family: $font-family-dada-grotesk;
            weight: $font-weight-medium;
          }
        }

        @include media-breakpoint-down(md) {
          .mobile-alignment {
            text-align: center !important;
            width: 100%;
            justify-content: center;
            margin: 0;
          }
        }

        button {
          @include button-success;

          min-width: 120px;
          width: auto;

          &.prev {
            border: 1px solid $grey-1;
            border-radius: 50px;
            background-color: $white;
            background-size: cover;
            font-family: $font-family-dada-grotesk;
            font-size: 16px;
            color: #777;
            text-decoration: none solid rgb(119 119 119);
            text-align: center;

            &:hover,
            &:focus,
            &:active {
              background-color: $hover-button !important;
              color: $white !important;
            }
          }
        }

        .passowrd {
          margin-top: 32px;

          h6 {
            font: {
              size: $h6-font-size - 4px;
              family: $font-family-dada-grotesk;
              weight: $font-weight-medium;
            }

            margin-bottom: 15px;
          }

          label {
            font: {
              size: 14;
              family: $font-family-dada-grotesk;
              weight: $font-weight-medium;
            }
          }

          .form-group {
            max-width: 540px;
            margin-bottom: 24px;

            input {
              height: 40px;
              padding: 0 0 0 10px;
              border: 1px solid $grey-1;
              border-radius: 10px;
              background-color: $white;
              background-size: cover;
              outline: none;
              font: {
                size: 14px;
                family: $font-family-dada-grotesk;
                weight: $font-weight-regular;
              }

              color: $title;

              &:focus {
                outline: none;
                box-shadow: none;
              }

              &::placeholder {
                color: $gray-400;
              }
            }

            &.passwordToggle {
              position: relative;

              .icon {
                display: block;
                position: absolute;
                right: 10px;
                top: 40px;
                font-size: 20px;
                cursor: pointer;

                @media screen and (width >= 1920px) {
                  top: 45px;
                }
              }
            }

            &.passwordToggleConfirm {
              position: relative;
              margin-bottom: 10px;

              .icon {
                display: block;
                position: absolute;
                right: 10px;
                top: 40px;
                font-size: 20px;
                cursor: pointer;

                @media screen and (width >= 1920px) {
                  top: 40px;
                }
              }
            }

            &.note {
              font-size: 12px;
            }
          }

          button {
            &.submitCta {
              @include button-success;
            }

            margin-bottom: 16px;
            width: 147px;
            height: 55px;

            @media (width >= 1910px) {
              width: 160px;
            }

            &.changePassword {
              @include button-success;

              width: 178px;

              @media (width >= 1910px) {
                width: 194px;
              }

              margin-top: 20px;
            }
          }
        }
      }

      .switch input {
        display: none;
      }

      .switch {
        display: inline-block;
        width: 75px; /* =w */
        height: 40px; /* =h */
        margin: 4px;

        //transform: translateY(50%);
        position: relative;

        &.default-set {
          input:disabled:checked + .slider::before {
            box-shadow:
              0 0 0 1px $grey-1,
              0 0 0 $grey-1;
            background-color: $grey-1;
          }

          .slider {
            box-shadow:
              0 0 0 1px $grey-1,
              0 0 0 $grey-1;
          }

          &.switch200 input:checked + .slider {
            box-shadow:
              0 0 0 1px $grey-1,
              0 0 0 $grey-1;
          }
        }
      }

      .slider {
        position: absolute;
        inset: 0;
        border-radius: 30px;
        box-shadow:
          0 0 0 1px $grey-1,
          0 0 0 $grey-1;
        cursor: pointer;
        border: 4px solid transparent;
        overflow: hidden;
        transition: 0.2s;
      }

      .slider::before {
        position: absolute;
        content: '';
        width: 100%;
        height: 100%;
        background-color: $grey-1;
        border-radius: 30px;
        transform: translateX(-30px); /* translateX(-(w-h)) */
        transition: 0.2s;
      }

      input:checked + .slider::before {
        transform: translateX(35px); /* translateX(w-h) */
        background-color: $success;
      }

      input:checked + .slider {
        box-shadow:
          0 0 0 2px $success,
          0 0 8px $success;
      }

      .switch200 .slider::before {
        width: 200%;
        transform: translateX(-102px); /* translateX(-(w-h)) */
      }

      .switch200 input:checked + .slider::before {
        background-color: $success;
      }

      .switch200 input:checked + .slider {
        box-shadow:
          0 0 0 1px #6f7072,
          0 0 0 #6f7072;
      }

      &.NotificationSettings {
        .privacy {
          padding: 25px 50px 0;

          @include media-breakpoint-down(md) {
            padding: 36px 0 0;
          }

          h3 {
            font: {
              size: 20px;
              family: $font-family-base;
              weight: $font-weight-semibold;
            }

            color: $title;
          }

          .card {
            flex-wrap: nowrap;
            width: 100%;
            border: none;
            margin-bottom: 46px;

            .card-body {
              align-items: center;

              @include media-breakpoint-down(md) {
                align-items: flex-start;
                margin-bottom: 15px;
              }

              .lhs {
                flex: 0 1 calc(100% - 75px);
                max-width: calc(100% - 75px);
                padding-right: 10px;

                .subTitle {
                  color: $title;
                  font: {
                    size: 16px;
                    family: $font-family-base;
                    weight: $font-weight-regular;
                  }

                  margin-bottom: 0;
                  text-align: left;
                  word-wrap: break-word;
                }
              }

              .rhs {
                flex: 0 1 75px;
                max-width: 75px;
              }
            }
          }
        }
      }

      &.BlockedMembers {
        padding: 0 100px;

        @include media-breakpoint-down(lg) {
          padding: 0;
        }

        .Members {
          .MemberHeader {
            display: flex;
            flex-wrap: nowrap;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            padding: 36px 0 43px;

            @include media-breakpoint-down(md) {
              flex-wrap: wrap;
              padding: 20px 0 30px !important;
            }

            .MembersCount {
              font: {
                family: $font-family-base;
                weight: $font-weight-semibold;
                size: 16px;
              }

              color: $title;

              @include media-breakpoint-down(md) {
                margin-bottom: 15px;
              }
            }

            .MembersSearch {
              position: relative;
              color: #aaa;
              font-size: 16px;
              display: inline-block;
              width: 100%;

              input {
                width: 100%;
                height: 40px;
                padding: 0 40px 0 8px;
                border: 1px solid $grey-1;
                border-radius: 50px;
                background-color: $white;
                background-size: cover;
                font: {
                  family: $font-family-base;
                  weight: $font-weight-regular;
                  size: 14px;
                }

                color: #121212;
                text-decoration: none solid rgb(18 18 18);

                &::placeholder {
                  font: {
                    family: $font-family-base;
                    weight: $font-weight-regular;
                    size: 14px;
                  }
                }
              }

              i {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                left: auto;
                right: 15px;
                color: $success;
                font-weight: bolder;
              }
            }
          }

          .MemberBody {
            width: 100%;

            @include media-breakpoint-down(md) {
              max-height: unset !important;
            }

            ul {
              padding: 0;

              li {
                list-style: none;
                padding: 10px;

                &.EachMember {
                  display: flex;
                  flex-wrap: nowrap;
                  justify-content: space-between;
                  align-items: center;
                  width: 100%;
                  border-bottom: 1px solid $bg;

                  .MemberDetail {
                    .owner {
                      @include ProfilePic;

                      .ownerPic {
                        max-width: 60px;

                        img,
                        .ownerAlpha {
                          width: 60px;
                          height: 60px;
                          font-size: 25px;
                        }
                      }

                      .ownerName {
                        max-width: calc(100% - 60px);
                        flex: calc(100% - 60px);
                        color: #333;
                        font: {
                          family: $font-family-base;
                          weight: $font-weight-medium;
                          size: 16px;
                        }

                        padding-left: 10px;
                        line-height: 1.2;

                        span {
                          display: block;
                          font: {
                            family: $font-family-base;
                            weight: $font-weight-regular;
                            size: 12px;
                          }

                          color: #777;
                        }
                      }
                    }
                  }

                  .IconsList {
                    display: inline-flex;
                    align-items: center;
                    color: $title;
                    cursor: pointer;

                    span {
                      padding-left: 5px;
                      font: {
                        family: $font-family-base;
                        weight: $font-weight-medium;
                        size: 14px;
                      }

                      color: $title;
                    }

                    i {
                      color: $success;
                      margin-left: 16px;

                      &:hover {
                        color: #777;
                      }

                      &.active {
                        color: $success;
                      }
                    }

                    .checkbox {
                      display: inline-block;

                      .majesticCheckbox {
                        $boxSize: 16px * 1.25;
                        $checkThickness: 2px;

                        cursor: pointer;
                        display: flex;
                        min-height: $boxSize;
                        align-items: center;
                        line-height: 1;
                        user-select: none;
                        color: $success;
                        font-weight: normal;

                        input {
                          display: none;
                        }

                        span {
                          position: relative;
                          display: flex;
                          width: 25px;
                          height: 25px;
                          border: 1px solid $success;
                          box-sizing: border-box;
                          overflow: hidden;
                          margin-right: 16px / 2;
                          border-radius: 8px;
                          box-shadow: inset 0 1px 2px rgb(0 0 0 / 20%);

                          i {
                            position: relative;
                            width: 20px;
                            height: 20px;
                            margin-left: 0;
                            top: 50%;
                            left: 50%;
                            transform: rotate(-45deg) translateX(0%) translateY(-60%) scale(0.8);
                            transform-origin: center left;

                            &::before,
                            &::after {
                              content: '';
                              position: absolute;
                              background-color: $white;
                              border-radius: $boxSize;
                            }

                            &::before {
                              height: 0%;
                              top: 50%;
                              left: 0;
                              width: $checkThickness;
                            }

                            &::after {
                              width: 0%;
                              bottom: 0;
                              left: 0;
                              height: $checkThickness;
                            }
                          }
                        } // Box
                      }
                    }
                  }
                }
              }
            }

            .no-record {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              width: 100%;
              text-align: center;
              font-size: 16px;
              font-family: Poppins, sans-serif;
              font-weight: 400;
              color: $title;
            }
          }
        }
      }

      &.Payment {
        padding: 20px 100px;

        @include media-breakpoint-down(lg) {
          padding: 20px 0;
        }

        .paymentCard {
          width: 100%;
          background-color: $white;

          h4 {
            color: $title;
            font: {
              size: 24px;
              family: $font-family-base;
              weight: $font-weight-semibold;
            }
          }

          p.subHeading {
            font: {
              size: 16px;
              family: $font-family-base;
              weight: $font-weight-regular;
            }

            margin-bottom: 20px;
            color: #777;

            span {
              font-weight: $font-weight-semibold;
              color: $success;
            }
          }

          .ccicon {
            height: 38px;
            position: absolute;
            right: 20px;
            top: 40px;
            width: 60px;

            @include media-breakpoint-down(xl) {
              top: 36px;
              right: 14px;
            }
          }

          h5 {
            font: {
              size: 16px;
              family: $font-family-base;
              weight: $font-weight-semibold;
            }

            margin: 24px 0;
          }

          .discliamer {
            font: {
              size: 11px;
              family: $font-family-base;
              weight: $font-weight-regular;
            }

            color: #777;
            letter-spacing: 0.4px;
            padding-bottom: 20px;
            margin-top: 20px;
          }

          form {
            margin-bottom: 32px;

            @include media-breakpoint-down(xl) {
              margin-bottom: 100px;
            }

            .form-group {
              margin-bottom: 24px;

              input[type='text'],
              input[type='number'],
              input[type='email'],
              input[type='password'] {
                height: 40px;
                width: 100%;
                border-radius: 8px;
              }

              label {
                font-family: $font-family-base;
                font-size: 14px;
                font-weight: 400;
                line-height: 24px;
                color: $title;
                margin-left: 5px;
                margin-bottom: 0;
              }

              input {
                background: rgb(255 255 255 / 30%) none repeat scroll 0 0;

                //margin-top: 10px;
                font-family: $font-family-base;
                font-size: 16px;
                font-weight: 400;
                color: $text-primary;
                padding: 9px 17px;
                width: 100%;

                // box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 8px rgba(126, 239, 104, 0.6);
                &::placeholder {
                  color: $gray-400;
                }
              }

              span {
                font-family: $font-family-base;
                font-weight: 400;
                font-size: 14px;
                color: $gray-600;
                margin-top: 8px;
                display: block;

                &.text-danger {
                  margin-top: 6px;
                  transition: all 0.6s ease-in-out;
                }

                &.forgotPassword {
                  position: relative;
                  float: right;
                  color: $gray-600;
                  top: -2px;
                  text-decoration: underline;
                  cursor: pointer;

                  a {
                    font-family: $font-family-base;
                    font-weight: 400;
                    font-size: 14px;
                    color: $gray-600;
                  }
                }
              }
            }

            .paymentCta {
              align-items: center;
              justify-content: center;

              button {
                @include button-success;

                max-width: 230px;
                font-size: 16px;
              }
            }

            .stripeLogo {
              img {
                max-width: 100%;
              }
            }

            .separator {
              display: flex;
              align-items: center;

              //margin-top: 30px;
              .line {
                height: 1px;
                flex: 1;

                //background-color: #c1c1c1;
                border-top: 1px dashed #c1c1c1;
              }
            }

            .text-danger {
              font: {
                size: $body-copy-secondary;
                family: $font-family-base;
                weight: $font-weight-regular;
              }

              color: $error !important;
              padding-top: 5px;
            }

            .card-payment-btn {
              height: 55px;

              @include button-success;
            }

            .promoinput {
              margin-bottom: 0;

              @include media-breakpoint-down(md) {
                width: 100%;
              }

              input {
                height: 50px !important;
              }

              .text-danger {
                margin-top: 0 !important;
              }
            }

            .promobtn {
              @include media-breakpoint-down(md) {
                width: 100%;
              }

              button {
                @include button-success;
                font: {
                  size: 16px;
                  family: $font-family-base;
                  weight: $font-weight-medium;
                }
              }
            }
          }

          .stripeLogo {
            img {
              width: 75%;
            }

            margin-bottom: 25px;
          }

          stripe-payment-btn {
            display: inline-block;
            text-align: center;
          }

          .react-datepicker__header.react-datepicker-year-header {
            padding: 12px 0;
          }

          .react-datepicker__month {
            margin: 0.4rem;
            text-align: center;
            width: 200px;
          }

          .react-datepicker__month .react-datepicker__month-text,
          .react-datepicker__month .react-datepicker__quarter-text {
            display: inline-block;
            width: 5rem;
            margin: 2px;
            font-size: 12px;
          }

          @include media-breakpoint-down(md) {
            .m-50 {
              width: 50%;
            }
          }
        }
      }
    }
  }
}
