@import 'bootstrap/scss/bootstrap';
@import 'bootstrap/scss/mixins';
@import '../../Assets/Styles/typography';
@import '../../Assets/Styles/colors';
@import '../../Assets/Styles/mixins';

.myTooltipClass {
  transform: translate(0, 100px);
  width: 300px;

  .introjs-skipbutton {
    display: none;
  }

  .introjs-arrow {
    height: 100%;
    border: none;

    &::before {
      background-image: url('../../Assets/Images/arrow.svg');
      width: 100px;
      height: 100px;
      background-size: contain;
      background-repeat: no-repeat;
      content: '';
      display: block;
      position: absolute;
      top: 15px;
      left: 0;
      transform: translate(-100px, -50px) rotateZ(55deg);
    }
  }

  &.introjs-left .introjs-arrow {
    top: 0;
    left: 0;

    &::before {
      transform: translate(250px, -70px) rotateY(-175deg) rotateZ(45deg);
    }
  }

  .introjs-tooltip-header {
    h1.introjs-tooltip-title {
      font: {
        size: 17px;
        family: $font-family-base;
        weight: $font-weight-semibold;
      }
    }
  }

  .introjs-tooltiptext {
    p {
      font: {
        size: 14px;
        family: $font-family-base;
        weight: $font-weight-regular;
      }

      color: #777;
      margin-bottom: 10px;
    }

    padding-top: 5px;

    span {
      i {
        font-family: Hmmngbrd !important;
        color: #25b183;
        font-size: 20px;
      }

      color: #777;
      display: inline-flex;
      font: {
        size: 12px;
        family: $font-family-base;
        weight: $font-weight-regular;
      }
    }
  }

  .introjs-tooltipbuttons {
    border-top: 0;
    text-align: center;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 15px;
    padding-top: 0;

    a {
      &.introjs-nextbutton {
        @include button-success;

        height: 40px !important;
        text-shadow: none;
        padding: 0 18px;

        @include media-breakpoint-down(xl) {
          padding: 0 18px;
        }
      }

      &.butterfly-custum-skip-now {
        @include button-outline;

        height: 40px !important;
        text-shadow: none;
        padding: 0 18px;
        margin-right: 10px;

        @include media-breakpoint-down(xl) {
          padding: 0 18px;
        }
      }
    }
  }

  .introjs-tooltipbuttons a.butterfly-custum-skip-now,
  .introjs-tooltipbuttons a.introjs-nextbutton,
  .introjs-tooltipbuttons a.introjs-prevbutton {
    @include media-breakpoint-down(lg) {
      font-size: 14px !important;
      height: 36px;
    }
  }

  .introjs-prevbutton {
    display: none;
  }

  &.UserGuideCreatePost {
    left: 120px !important;
    top: 0 !important;

    @include media-breakpoint-down(md) {
      left: 50% !important;
      margin-top: -283px !important;
      transform: translateX(-50%) !important;
      bottom: unset !important;

      .introjs-arrow {
        display: block !important;

        &::before {
          top: unset !important;
          bottom: 0 !important;
          left: 50% !important;
          transform: translate(0, -50px) rotateZ(55deg);
        }
      }

      .introjs-arrow.bottom-middle {
        bottom: -150px;
        left: -8%;
        margin-left: -5px;
      }
    }

    &.tabTooltipClass {
      @media (width >= 768px) and (width <= 1199.98px) and (orientation: landscape) {
        transform: translateY(70px) !important;

        .introjs-arrow {
          &::before {
            transform: translate(-100px, -20px) rotate(55deg) !important;
          }
        }
      }
    }
  }

  &.UserGuidePinPost {
    // opacity: 1 !important;
    // display: block !important;
    left: -310px !important;
    top: -50px !important;
    height: max-content;

    .introjs-arrow {
      &::before {
        top: -65px !important;
        left: 160px !important;
        transform: translate(45px, -10px) rotateZ(157deg) !important;
      }
    }

    &.tabTooltipClass {
      .introjs-arrow {
        &.bottom-middle,
        &.bottom,
        &.top {
          @media (width >= 768px) and (width <= 1199.98px) and (orientation: landscape) {
            left: 50% !important;
          }
        }

        &::before {
          top: -100px !important;
          left: 160px !important;
          transform: translate(45px, -10px) rotateZ(157deg) !important;

          @include media-breakpoint-only(md) {
            top: -70px !important;
          }
        }
      }

      @media (width >= 768px) and (width <= 1199.98px) and (orientation: landscape) {
        height: max-content;
        left: -350px !important;
        top: -174px !important;

        .introjs-arrow {
          &::before {
            left: 0 !important;
            transform: translate(146px, 190px) rotate(317deg) !important;
            top: -80px !important;
          }
        }
      }
    }

    @include media-breakpoint-down(md) {
      left: -234px !important;
      top: -355px !important;

      .introjs-arrow {
        top: 108px !important;
        left: 66px !important;

        &::before {
          transform: translate(-83px, 172px) rotateZ(210deg) rotateX(-170deg) !important;
        }
      }
    }
  }

  &.UserGuideQuestion {
    // opacity: 1 !important;
    // display: block !important;
    left: 320px !important;
    top: -310px !important;
    height: fit-content;

    .introjs-arrow {
      &::before {
        top: 0 !important;
        left: 0 !important;
        transform: translate(-27px, 198px) rotateZ(-35deg) !important;
      }
    }

    @include media-breakpoint-down(md) {
      left: -30px !important;
      top: -375px !important;
      transform: translateY(0);

      .introjs-arrow {
        top: 10px !important;
        left: 0 !important;

        &::before {
          transform: translate(-37px, 266px) rotate(105deg) !important;
        }
      }

      &.introjs-top-left-aligned .introjs-arrow.bottom::before {
        transform: translate(5px, 79px) rotateX(180deg) rotate(74deg) !important;
        left: 0 !important;
        top: unset !important;
        bottom: 0 !important;
      }
    }

    &.introjs-top-left-aligned {
      &.tabTooltipClass {
        left: -60px !important;
        top: -373px !important;
        transform: translate(0, 0);

        .introjs-arrow::before {
          top: 0 !important;
          left: 0 !important;
          transform: translate(50px, 265px) rotateZ(-255deg) !important;
        }
      }

      .introjs-arrow::before {
        transform: translate(-109px, 108px) rotateX(180deg) rotateZ(45deg) !important;
      }
    }
  }

  &.UserGuideEvents {
    // opacity: 1 !important;
    // display: block !important;
    left: 323px !important;
    top: -253px !important;
    height: fit-content;

    .introjs-arrow {
      &.top {
        &::before {
          top: 75px;
          left: -109px;
          transform: translate(0, 0) rotateZ(-45deg) rotateX(180deg);
        }
      }

      &.bottom {
        bottom: -212px !important;

        &::before {
          bottom: -212px !important;
          left: -18px !important;
          transform: translate(-92px, -164px) rotateX(-161deg) rotateZ(40deg) !important;
        }
      }
    }

    @include media-breakpoint-down(md) {
      left: -80px !important;
      top: -383px !important;
      transform: translateY(50px);

      .introjs-arrow {
        transform: translate(-90px, 253px) rotateZ(40deg);

        &::before {
          top: 0;
          transform: translate(-94px, -20px) rotate(55deg);
        }

        &.bottom {
          bottom: -212px !important;
        }
      }
    }

    &.tabTooltipClass {
      left: -70px !important;
      top: -433px !important;

      .introjs-arrow.bottom::before {
        bottom: -212px !important;
        left: -18px !important;
        transform: translate(70px, 7px) rotateZ(-255deg) !important;
      }
    }
  }

  &.UserGuidePublishPage,
  &.UserGuideSharePage {
    // opacity: 1 !important;
    // display: block !important;
    h4 {
      font-size: 18px;
      font-weight: $font-weight-semibold;
      font-family: $font-family-base;
    }

    @include media-breakpoint-up(xl) {
      left: 118px !important;
      top: -56px !important;

      .introjs-arrow {
        &::before {
          top: 7px !important;
          left: -18px !important;
          transform: translate(-83px, -15px) rotateZ(55deg) !important;
        }
      }
    }

    &.tabTooltipClass {
      transform: translate(0, 0);
      bottom: 90px !important;
      left: -105px !important;
      height: max-content;

      .introjs-arrow {
        &.top,
        &.top-middle {
          @media (width >= 768px) and (width <= 1199.98px) and (orientation: landscape) {
            left: 50% !important;
          }
        }

        &::before {
          transform: translateY(-90px) rotate(155deg);

          @media (width >= 768px) and (width <= 1199.98px) and (orientation: landscape) {
            // transform: translateY(-90px) translateX(-130px) rotate(155deg) !important;
            transform: translate(0, 0) rotate(155deg) !important;
            top: -80px !important;
            left: unset !important;
            right: 40px !important;
          }
        }
      }
    }

    @include media-breakpoint-down(md) {
      transform: translate(-30px, 20px);

      &.introjs-top-middle-aligned {
        bottom: 80px !important;

        .introjs-arrow {
          display: block !important;

          &::before {
            transform: translateY(170px) translateX(-1px) rotate(39deg) !important;
          }
        }
      }

      .introjs-arrow {
        display: block !important;

        &::before {
          transform: translate(0, -100px) rotateZ(135deg) !important;
        }
      }
    }
  }

  &.UserGuideSharePage {
    .introjs-tooltip-header {
      display: none;
    }

    img {
      margin: 10px 0;
    }

    &.introjs-top-middle-aligned {
      .introjs-arrow {
        &.bottom-middle {
          &::before {
            transform: translateY(230px) translateX(-1px) rotate(39deg) !important;
          }
        }
      }
    }

    &.introjs-bottom-left-aligned {
      top: 0 !important;
      transform: translate(0, 0);

      .introjs-arrow::before {
        top: 37px !important;
        left: -18px !important;
        transform: translate(-83px, 5px) rotateZ(55deg) !important;
      }

      &.tabTooltipClass {
        transform: translate(0, 0);
        bottom: 90px !important;
        left: -105px !important;
        height: max-content;
        top: 70px !important;

        .introjs-arrow::before {
          transform: translate(20px, -115px) rotate(155deg) !important;

          @media (width >= 768px) and (width <= 1199.98px) and (orientation: landscape) {
            // transform: translateY(-90px) translateX(-130px) rotate(155deg) !important;
            transform: translate(0, 0) rotate(155deg);
            top: -80px;
            left: unset !important;
            right: 60px !important;
          }
        }
      }
    }
  }

  &.UserGuideSubscribe {
    left: 118px !important;
    top: -56px !important;

    h4 {
      font-size: 18px;
      font-family: $font-family-base;
      font-weight: $font-weight-semibold;
    }

    .introjs-arrow {
      &::before {
        top: 7px !important;
        left: -18px !important;
        transform: translate(-83px, -15px) rotateZ(55deg) !important;
      }
    }

    @include media-breakpoint-down(md) {
      left: 30px !important;
      top: 4px !important;
      min-height: 190px !important;

      .introjs-arrow {
        display: block !important;

        &::before {
          left: -58px !important;
          top: 177px !important;
          transform: translate(0, -259px) rotateZ(83deg) !important;
        }
      }
    }
  }

  &.UserGuideEditPost {
    // opacity: 1 !important;
    // display: block !important;
    left: -310px !important;
    top: -10px !important;
    height: fit-content;

    .introjs-tooltipbuttons a.butterfly-custum-skip-now,
    .introjs-tooltipbuttons a.introjs-nextbutton,
    .introjs-tooltipbuttons a.introjs-prevbutton {
      @include media-breakpoint-down(lg) {
        font-size: 14px !important;
        height: 36px;
      }
    }

    @media (width >= 768px) and (width <= 1199.98px) and (orientation: landscape) {
      &.introjs-bottom-left-aligned {
        transform: translate(0, 0) !important;

        .introjs-arrow {
          &::before {
            top: 60px !important;
            left: 280px !important;
            transform: translate(0, 0) rotateZ(-55deg) rotateX(0deg) !important;
          }
        }
      }
    }

    .introjs-arrow {
      &.top {
        &::before {
          top: -90px;
          left: 200px;
          transform: translate(0, 0) rotateZ(-45deg) rotateX(180deg);
        }
      }

      &.bottom {
        bottom: -212px !important;

        &::before {
          bottom: -212px !important;
          left: -18px !important;
          transform: translate(-92px, -164px) rotateX(-161deg) rotateZ(40deg) !important;
        }
      }
    }

    @include media-breakpoint-down(md) {
      left: unset !important;
      right: 0 !important;
      top: -363px !important;
      transform: translateY(50px);

      .introjs-arrow {
        right: 0;

        &::before {
          top: unset;
          transform: translate(-64px, -20px) rotate(265deg);
          bottom: -110px;
        }

        &.bottom {
          bottom: -212px !important;
        }

        &.bottom-middle {
          left: unset !important;
        }

        &.top-middle {
          left: unset !important;

          &::before {
            top: unset;
            transform: translate(-64px, 0) rotate(265deg);
            bottom: -110px;
          }
        }

        &.introjs-top-middle-aligned {
        }
      }
    }

    &.tabTooltipClass {
      @media (width >= 768px) and (width <= 1199.98px) and (orientation: landscape) {
        left: -320px !important;
        top: unset !important;
        transform: translate(0, 0);

        &.introjs-top-right-aligned {
          left: -320px !important;
          top: unset !important;
          transform: translate(0, 0);

          .introjs-arrow.bottom-right {
            &::before {
              transform: translate(-70px, 30px) rotate(35deg);
              top: 175px;
            }
          }
        }

        &.introjs-bottom-right-aligned {
          .introjs-arrow top-right {
            &::before {
              transform: translate(0, 40px) rotate(-45deg);
            }
          }
        }

        &.introjs-top-middle-aligned {
          .introjs-arrow.bottom-middle {
            &::before {
              transform: translate(70px, 30px) rotate(35deg);
              top: 175px;
            }
          }
        }
      }

      .introjs-arrow.bottom::before {
        bottom: -212px !important;
        left: -18px !important;
        transform: translate(70px, 7px) rotateZ(-255deg) !important;
      }
    }
  }

  &.UserGuideAddQuestion {
    transform: translate(0, 0);

    .introjs-tooltipbuttons a.butterfly-custum-skip-now,
    .introjs-tooltipbuttons a.introjs-nextbutton,
    .introjs-tooltipbuttons a.introjs-prevbutton {
      @include media-breakpoint-down(lg) {
        font-size: 14px !important;
        height: 36px;
      }
    }

    .introjs-arrow {
      border: none;
      left: 100%;

      &::before {
        transform: translate(-150px, 216px) rotateZ(-25deg);
      }
    }

    &.introjs-bottom-left-aligned {
      .introjs-arrow {
        &::before {
          transform: translate(-130px, -94px) rotate(-145deg);
        }
      }
    }
  }
}

body:has(.UserGuideAddQuestion) {
  .introjs-disableInteraction.introjs-fixedTooltip {
    @include media-breakpoint-up(xl) {
      height: 45px !important;
    }
  }
}

body.edit-page.userguideopened {
  .PageCard .card-footer button.shareButton {
    color: white !important;
  }
}
