@import 'bootstrap/scss/bootstrap';
@import 'bootstrap/scss/mixins';
@import '../../Assets/Styles/typography';
@import '../../Assets/Styles/colors';
@import '../../Assets/Styles/mixins';

.App {
  font-family: sans-serif;
  text-align: center;
}

div .css-jqlxwl {
  width: 550px;
  height: auto;
  background: $white;
  border-radius: 5px;
}

.css-1l8u3yl {
  position: absolute;
  top: calc(50% + 270px) !important;
}

.your_question_title {
  display: flex;
  justify-content: space-between;
  margin: 15px;
  align-items: center;
  padding-bottom: 10px;
  border-bottom: 0.2px solid $bg;
}

.your_question_title h4 {
  height: 33px;
  font: {
    size: 20px;
    family: $font-family-base;
    weight: $font-weight-semibold;
  }

  color: $success;
  text-decoration: none solid rgb(36 177 131);
  line-height: 27px;
  margin: 0;
}

.your_question_title i {
  font-size: 24px;
  cursor: pointer;

  &:hover {
    color: $success;
  }
}

.question_hint {
  text-align: center;
  padding: 83px;

  @include media-breakpoint-down(md) {
    padding: 50px 0;
  }

  i {
    font-size: 24px;
    cursor: pointer;
    position: absolute;
    right: 10px;
    top: 10px;
  }

  div {
    text-align: center;
    max-width: 300px;
    margin: auto;

    h4 {
      font: {
        size: 24px;
        family: $font-family-base;
        weight: $font-weight-semibold;
      }

      color: $title;
    }

    p {
      font: {
        size: 15px;
        family: $font-family-base;
        weight: $font-weight-regular;
      }

      color: #777;
    }
  }

  button {
    &.Cancel {
      background-color: transparent;
      border: 1px solid $success;
      color: $success;
      border-radius: 50px;
      padding: 12px 18px;
      opacity: 1;
      outline: none;
      box-shadow: none;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      min-width: 90px;
      height: 40px;
      margin-top: 0;
      font-size: 12px;
      font-family: Poppins, sans-serif;
      font-weight: 500;

      &:hover {
        background-color: $success !important;
        border: 1px solid $success;
        color: $white;
      }
    }

    &.Yes {
      @include button-success;

      height: 40px;
      margin-left: 10px;
    }
  }
}

.your_question_memory h5 {
  font-family: Poppins;
  font-size: 18px;
  color: $text-primary;
  text-decoration: none solid rgb(83 85 95);
  line-height: 27px;
  margin-left: 15px;
  margin: 15px 15px 12px;
  text-align: initial;
  min-height: 54px;
}

.your_question_addinput {
  margin: 15px 15px 12px;
  position: relative;

  i.fa-butterflysmiley {
    position: absolute;
    left: 0;
    bottom: -10px;
    padding: 5px;
    cursor: pointer;

    &:hover {
      color: $success;
    }
  }

  .reaction-emoji-msgbord {
    position: absolute;
    bottom: 290px;
    width: 100%;

    & > p {
      position: absolute;
      z-index: 9;
      right: -7px;
      cursor: pointer;
      width: 20px;
      text-align: center;
      top: 5px;
      font-size: 20px;
    }

    aside {
      max-width: 100% !important;
      width: 100% !important;
      background-color: $white !important;
      max-height: 350px;

      &.EmojiPickerReact {
        .epr-header {
          width: 100% !important;

          .epr-header-overlay {
            padding-bottom: 0 !important;
          }
        }

        .epr-body {
          width: 100%;
          padding: 0 10px;

          .epr-emoji-list {
            li {
              &.epr-emoji-category > .epr-emoji-category-label {
                font: {
                  size: 14px;
                  family: $font-family-base;
                  weight: $font-weight-semibold;
                }
              }
            }
          }
        }
      }
    }
  }
}

.your_question_addinput textarea {
  width: 100%;
  height: 78px;
  padding: 0 0 8px;
  border-style: initial;
  border-color: $grey-1;
  background-color: $white;
  background-size: cover;
  font-family: Poppins;
  font-size: 16px;
  color: #121212;
  resize: none;
  outline: none;
}

.your_question_addinput textarea:focus {
  outline: none;
}

.your_question_icons {
  border-top: 1px solid $grey-1;
  margin: 0 15px;
  padding: 13px 0 0;
}

.your_question_icons .IconsList i {
  font-size: 20px;
  margin-right: 13px;
  cursor: pointer;
}

.your_question_icons .IconsList i:hover {
  color: $success;
}

.your_question_add_post {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 15px;
}

.your_question_add_post .your_question_Add_Delete {
  cursor: pointer;
}

.your_question_add_post .your_question_Add_Delete:hover {
  color: $success;
}

.your_question_add_post .your_question_Add_Delete a {
  display: flex;
  align-items: center;
  font-family: Poppins;
  font-size: 14px;
  color: $title;
  text-decoration: none solid rgb(44 62 80);
  text-align: center;
  cursor: pointer;
}

.your_question_add_post .your_question_Add_Delete span i {
  font-size: 18px;
  color: $success;
  margin-right: 5px;
}

.your_question_post_button button {
  @include button-success;

  height: 40px;
}
