@import '~slick-carousel/slick/slick.css';
@import '~slick-carousel/slick/slick-theme.css';
@import 'bootstrap/scss/bootstrap';
@import 'bootstrap/scss/mixins';
@import '../../Assets/Styles/typography';
@import '../../Assets/Styles/colors';
@import '../../Assets/Styles/mixins';

.QuestionSlider {
  padding-bottom: 100px;

  @include media-breakpoint-down(md) {
    padding-top: 75px;
  }

  .slick-slider {
    .slick-list {
      .slick-track {
        .slick-slide {
          padding: 0 5px;

          h3 {
            font: {
              size: 18px;
              family: $font-family-base;
              weight: $font-weight-medium;
            }

            color: $text-primary;
            line-height: 1.5;
            word-break: break-word;
          }

          .textfield {
            position: relative;

            textarea {
              border: 1px solid $grey-1;
              border-radius: 10px;
              resize: none;
              color: $title;
              width: 100%;
              padding: 10px;
              margin: 0;
              outline: none;

              &:focus,
              &:hover {
                box-shadow: none;
                outline: none;
              }
            }

            i {
              position: absolute;
              bottom: 15px;
              left: 10px;
              font-size: 20px;
            }
          }

          .IconHolder {
            display: flex;
            justify-content: flex-end;
            padding: 8px 0;
            margin-bottom: 5px;

            .IconsList {
              flex: 0 1 102px;
              max-width: 102px;

              i {
                color: $success;
                margin-right: 13px;
                font-size: 20px;
                cursor: pointer;

                &.active {
                  color: $hover-button;
                }
              }
            }
          }

          .ButtonWrapper {
            text-align: center;
            margin-bottom: 40px;

            button {
              @include button-success;

              width: 90px;
              font: {
                size: 16px;
                family: $font-family-base;
                weight: $font-weight-medium;
              }
            }
          }
        }
      }
    }
  }

  .controls {
    text-align: center;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 100%;

    .button {
      border: 0;
      background-color: transparent;
    }

    i {
      font-size: 40px;
      color: $title;

      &:focus {
        color: #25b082;
      }
    }

    span {
      color: $title;
      padding: 0 10px;
      font: {
        size: 18px;
        family: $font-family-base;
        weight: $font-weight-medium;
      }
    }
  }

  .AddDeleteCtaWrapper {
    text-align: center;

    .AddDeleteCta {
      width: 225px;
      height: 40px;
      padding: 0 17px;
      border: 1px solid $grey-1;
      border-radius: 50px;
      background-color: $white;
      background-size: cover;
      margin: 40px auto;
      font: {
        size: 14px;
        family: $font-family-base;
        weight: $font-weight-medium;
      }

      color: #25b082;
      text-decoration: none solid rgb(37 176 130);
      text-align: center;

      &:focus {
        color: $white;
        background-color: #25b082;
        border: 1px solid #25b082;
      }

      i {
        margin-right: 5px;
        font-size: 18px;
      }
    }
  }
}
