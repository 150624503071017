@import 'bootstrap/scss/bootstrap';
@import 'bootstrap/scss/mixins';
@import '../../Assets/Styles/typography';
@import '../../Assets/Styles/colors';
@import '../../Assets/Styles/mixins';

.lottie-loader-404 {
  background-color: $white;
  margin: auto;
  width: 100%;
  height: 100%;
  text-align: center;

  .Wrapper404 {
    width: 700px;
    margin: auto;
    margin-top: 100px;
    text-align: left;
    padding-left: 90px;

    button {
      @include button-success;

      height: 40px;
      margin-left: 8px;
    }

    @include media-breakpoint-down(md) {
      width: 100%;
    }
  }

  #Footer {
    text-align: center;
    padding-top: 100px;

    p {
      font: {
        size: 12px;
        family: $font-family-base;
        weight: $font-weight-regular;
      }

      color: #777;
    }

    a {
      cursor: pointer;
      color: #777;
      text-decoration: underline;
    }
  }
}

body:has(.lottie-loader-404) .MobileFeatureNav {
  display: none;
}
