@import 'bootstrap/scss/bootstrap';
@import 'bootstrap/scss/mixins';
@import '../../Assets/Styles/typography';
@import '../../Assets/Styles/colors';
@import '../../Assets/Styles/mixins';

.logoWrap {
  .logo {
    max-width: 215px;
    margin-bottom: 30px;
  }
}

h1 {
  font: {
    size: $h1-font-size;
    family: $font-family-base;
    weight: $font-weight-semibold;
  }

  color: $text-primary;
  margin-bottom: 40px;
  line-height: 1.5;
}

h2 {
  font: {
    size: $h2-font-size;
    family: $font-family-base;
    weight: $font-weight-semibold;
  }

  color: $text-primary;
  margin-bottom: 10px;
  line-height: 1.5;
}

p {
  font: {
    size: $body-copy-primary;
    family: $font-family-base;
    weight: $font-weight-regular;
  }

  color: $text-primary;
}

.legal-page {
  padding: 40px 225px;
  overflow-y: overlay;
  overflow-x: hidden;

  //max-height: 98vh;
  word-wrap: break-word;
  background: $white;
  height: 100%;

  @include media-breakpoint-down(md) {
    padding: 100px 15px;
    max-height: 100vh;
  }

  @include media-breakpoint-between(md, xl) {
    padding: 50px;
  }

  .container {
    background-color: transparent;
  }

  h1 {
    line-height: 1.2;
    color: $title;
    margin-bottom: 16px;
    font: {
      size: 32px;
      family: $font-family-base;
      weight: $font-weight-bold;
    }
  }

  p {
    font-family: $font-family-base;
    font-size: 16px;
    line-height: 1.5;
    margin-bottom: 20px;
    font-weight: 400;
  }

  .title {
    line-height: 1.5;
    margin-bottom: 20px;
    margin-top: 48px;
    color: $title;
    font: {
      size: 32px;
      family: $font-family-base;
      weight: $font-weight-bold;
    }
  }

  .sub-title {
    line-height: 1.5;
    margin-bottom: 10px;
    margin-top: 30px;
    color: $title;

    font: {
      size: 24px;
      family: $font-family-base;
      weight: $font-weight-semibold;
    }
  }

  .subsub-title {
    color: $title;
    padding-bottom: 10px;
    font: {
      size: 18px;
      family: $font-family-base;
      weight: $font-weight-semibold;
    }
  }

  .top-title-spacing {
    padding-top: 10px;
  }

  ul {
    margin: 0 0 30px 26px;
    padding: 0;

    li {
      margin-bottom: 15px;
      color: $text-primary;

      &::marker {
        color: #734aca;
      }
      font: {
        size: 16px;
        family: $font-family-base;
        weight: $font-weight-regular;
      }

      p {
        color: $text-primary;
        margin-bottom: 10px;
        font: {
          size: 16px;
          family: $font-family-base;
          weight: $font-weight-regular;
        }

        strong {
          font: {
            size: 16px;
            family: $font-family-base;
            weight: $font-weight-bold;
          }
        }
      }

      strong {
        font: {
          size: 16px;
          family: $font-family-base;
          weight: $font-weight-bold;
        }
      }
    }

    ul {
      font-family: $font-family-base;
      font-size: 16px;
      line-height: 1.5;
      margin-bottom: 30px;
      font-weight: 400;
      margin-top: 20px;
    }
  }

  a {
    color: $success;
  }

  @include media-breakpoint-down(lg) {
    h1 {
      font-size: 36px;
      line-height: 1.3;
    }

    .title {
      font-size: 30px;
      line-height: 1.3;
    }
  }

  .wrapper {
    width: 900px;
    margin: auto;
  }
}

/* ------------------------------------------------------------
  NUMBER OF COLUMNS: Adjust #primaryNav li to set the number
  of columns required in your site map. The default is 
  4 columns (25%). 5 columns would be 20%, 6 columns would 
  be 16.6%, etc. 
------------------------------------------------------------ */

#primaryNav li {
  width: 25%;
}

#primaryNav li ul li {
  width: 100% !important;
}

#primaryNav.col1 li {
  width: 99.9%;
}

#primaryNav.col2 li {
  width: 50%;
}

#primaryNav.col3 li {
  width: 33.3%;
}

#primaryNav.col4 li {
  width: 25%;
}

#primaryNav.col5 li {
  width: 20%;
}

#primaryNav.col6 li {
  width: 16.6%;
}

#primaryNav.col7 li {
  width: 14.2%;
}

#primaryNav.col8 li {
  width: 12.5%;
}

#primaryNav.col9 li {
  width: 11.1%;
}

#primaryNav.col10 li {
  width: 10%;
}
