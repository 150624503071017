@import 'bootstrap/scss/bootstrap';
@import 'bootstrap/scss/mixins';
@import '../../Assets/Styles/typography';
@import '../../Assets/Styles/colors';
@import '../../Assets/Styles/mixins';

.PinedFundraiser {
  @include media-breakpoint-down(md) {
    display: none;
  }

  position: relative;

  .count {
    position: absolute;
    right: 20px;
    top: 23px;
    transform: translateY(-50%);
    line-height: 1;
    z-index: 9;
    font-size: 14px;
    display: none;
  }

  .eachFundraiser {
    border-radius: 5px;
    margin-bottom: 12px;
    background-color: $white;

    @include media-breakpoint-down(md) {
      margin-bottom: 16px;
      box-shadow: 0 0 30px rgb(0 0 0 / 20%);
      background: $white;
      margin-top: 0;
    }

    .card-header {
      padding: 12px;
      position: relative;

      .owner {
        @include ProfilePic;

        .ownerPic {
          width: 24px;
          height: 24px;
          display: flex;
          max-width: 24px;
          border-radius: 50%;
          vertical-align: middle;
          flex: 0 0 24px;
          align-items: center;

          img,
          .ownerAlpha {
            width: 24px;
            height: 24px;
          }

          .ownerAlpha {
            font-size: 14px;
          }
        }

        .ownerName {
          max-width: calc(100% - 70px);
          flex: 0 0 calc(100% - 70px);
          font: {
            size: 14px;
            family: $font-family-base;
            weight: $font-weight-medium;
          }

          color: $title;

          .created_at {
            font: {
              size: 10px;
              family: $font-family-base;
              weight: $font-weight-regular;
            }

            margin-top: 0;
            color: $text-primary;
          }
        }
      }
    }

    .ImageWrapper {
      position: relative;
      min-height: 130px;
      background-color: $white;
      margin: 0 12px;
      border-radius: 5px;
      max-height: 130px;
      overflow: hidden;

      &::before {
        background: linear-gradient(0.86deg, rgb(0 0 0 / 90%) 0.76%, rgb(0 0 0 / 0%) 99.29%);
        content: '';
        width: 100%;
        height: 88px;
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
      }

      img {
        width: 100%;
        height: auto;
        border-radius: 5px;
      }

      .caption {
        position: absolute;
        bottom: 12px;
        padding: 0 12px;
        word-break: break-word;
        font: {
          family: $font-family-dada-grotesk;
          weight: $font-weight-medium;
          size: $body-copy-secondary;
        }

        line-height: 18px;
        color: $white;
      }

      .no-fundraiser-image {
        width: 100%;
        height: 100%;
        background: url('https://butterflyv2-production-assets.nyc3.cdn.digitaloceanspaces.com/image/white_mark.png'),
          #1d817b;
        background-repeat: no-repeat;
        background-position: center;
        position: absolute;
        overflow: hidden;
        display: flex;
        background-size: 20%;
        justify-content: space-between;
        top: 0;
      }
    }

    .card-body {
      padding: 0 13px;

      .title {
        font: {
          family: $font-family-base;
          weight: $font-weight-semibold;
          size: 20px;
        }

        line-height: 30px;
        color: $title;
        margin-bottom: 4px;
      }

      p {
        font: {
          family: $font-family-base;
          weight: $font-weight-medium;
          size: 14px;
        }

        line-height: 21px;
        color: $text-primary;
        margin-bottom: 10px;
      }

      .progress-bar {
        margin-bottom: 8px;
        height: 7px;
      }

      .raised-fund {
        color: $title;
        line-height: 21px;
        margin-top: 4px;
        font: {
          family: $font-family-base;
          weight: 600;
          size: 14px;
        }
      }

      .donate-info {
        padding: 8px 0 12px;
        display: flex;
        flex-flow: row wrap;
        gap: 12px;
        align-items: center;
        justify-content: space-between;

        .donater {
          width: calc(100% - 200px);

          .img {
            display: flex;

            .each-img {
              width: 24px;
              height: 24px;
              border: 0.5px solid $white;
              border-radius: 47px;
              margin-left: -7px;

              &:nth-child(1) {
                margin-left: 0;
              }

              img {
                width: 100%;
                height: auto;
                border-radius: 47px;
              }

              .ownerAlpha {
                background-color: #1d817b2b;
                color: #1d817b;
                border-radius: 50%;
                flex: 0 1 24px;
                height: 24px;
                max-width: 24px;
                overflow: hidden;
                position: relative;
                width: 24px;

                span {
                  font-family: 'Work Sans', sans-serif;
                  font-size: 14px;
                  font-weight: 600;
                  left: 50%;
                  position: absolute;
                  top: 50%;
                  transform: translate(-50%, -50%);
                }
              }
            }
          }

          .text {
            font: {
              family: $font-family-dada-grotesk;
              weight: 400;
              size: 11px;
            }

            line-height: 16px;
            color: $text-primary;
            margin-top: 4px;
          }
        }

        .edit {
          color: $text-primary;
          font: {
            family: $font-family-dada-grotesk;
            weight: $font-weight-medium;
            size: 12px;
          }

          line-height: 18px;
          padding: 13px 15px;
          border: 1px solid $grey-1;
          border-radius: 50px;
          width: 70px;
          height: 44px;
          transition: all 0.5s;
          background: none;

          &:hover {
            background: $hover-button;
            color: $white;
            transition: all 0.5s;
          }
        }

        .donatenow {
          color: $white;
          font: {
            family: $font-family-dada-grotesk;
            weight: $font-weight-medium;
            size: 12px;
          }

          line-height: 18px;
          padding: 13px 15px;
          border: 0;
          border-radius: 50px;
          height: 44px;
          background-color: $success;
          transition: all 0.5s;

          &:hover {
            background-color: $hover-button;
            box-shadow: 0 2px 8px rgb(0 0 0 / 15%);
            color: $white;
            outline: none;
          }
        }
      }
    }

    .card-footer {
      display: flex;
      justify-content: space-between;
      flex-wrap: nowrap;
      padding: 12px 0 16px;
      align-items: center;
      border-top: 1px solid $bg;
      margin-right: 13px;
      margin-left: 13px;

      .date-wrap {
        color: $text-primary;
        font: {
          family: $font-family-dada-grotesk;
          weight: 400;
          size: 12px;
        }

        line-height: 18px;
      }

      .ike-comment-warp {
        display: flex;
        align-items: center;
      }

      span {
        color: #777;
        display: inline-flex;
        align-items: center;
        line-height: 1;
        justify-content: flex-end;
        font: {
          size: 14px;
          family: $font-family-base;
          weight: $font-weight-regular;
        }

        i {
          margin-left: 2px;
          cursor: pointer;
        }

        &:nth-of-type(1) {
          margin-right: 5px;

          i {
            font-size: 16px;
            color: $success;
            margin-left: 6px;
          }
        }

        &:nth-of-type(2) {
          i {
            color: #fc394d;
            font-size: 15px;
            line-height: 16px;

            &.disabled {
              pointer-events: none;
            }
          }

          width: 35px;
        }
      }
    }
  }

  .slick-slider {
    .slick-arrow {
      top: 20px;

      &.slick-next {
        right: 10px;
        z-index: 9;

        &::before {
          font-family: Hmmngbrd;
          font-size: 15px;
          line-height: 1;
          opacity: 1;
          color: #8c8a8a;
          content: '\f0b6';
        }

        &:hover {
          &::before {
            color: $success;
          }
        }
      }

      &.slick-prev {
        right: 40px;
        z-index: 9;
        left: unset;

        &::before {
          font-family: Hmmngbrd;
          font-size: 15px;
          line-height: 1;
          color: #8c8a8a;
          content: '\f0b5';
          opacity: 1;
        }

        &:hover {
          &::before {
            color: $success;
          }
        }

        .slick-disabled::before {
          opacity: 0.25;
        }
      }
    }
  }
}
