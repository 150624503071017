// Import local fonts using @font-face
@font-face {
  font-family: 'Ivar Display';
  src: url('../fonts/IvarDisplay-Medium.woff') format('woff');
  font-weight: 500; // Medium
  font-style: normal;
}

@font-face {
  font-family: 'Ivar Display';
  src: url('../fonts/IvarDisplay-Regular.woff') format('woff');
  font-weight: 400; // Regular
  font-style: normal;
}

@font-face {
  font-family: 'Dada Grotesk';
  src: url('../fonts/DadaGroteskBook-Regular.woff') format('woff');
  font-weight: 400; // Regular
  font-style: normal;
}

@font-face {
  font-family: 'Dada Grotesk';
  src: url('../fonts/DadaGrotesk-Medium.woff') format('woff');
  font-weight: 500; // Medium
  font-style: normal;
}

@font-face {
  font-family: 'Dada Grotesk';
  src: url('../fonts/DadaGrotesk-Book.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}


//font family
$font-family-base: 'Ivar Display', sans-serif;
$font-family-dada-grotesk: 'Dada Grotesk', sans-serif;
// $font-family-base: 'Poppins', sans-serif;
$font-family-work-san: 'Work Sans', sans-serif;
$font-family-inter: 'Inter', sans-serif;
$font-weight-regular: 400; // Regular 400
$font-weight-medium: 500;
$font-weight-semibold: 600;
$font-weight-bold: 700;
$font-weight-extrabold: 800;
$heading-font-family: $font-family-base;
$body-font-family: $font-family-base;
$font-size-base: 16px;
$font-base-size: 16px;

//font size
$h1-font-size: 90px;
$h2-font-size: 70px;
$h3-font-size: 60px;
$h4-font-size: 24px;
$h5-font-size: 24px;
$h6-font-size: 24px;
$titles: 20px;
$subtext: 16px;
$subtitle: 18px;
$tabs: 16px;
$body-copy-primary: 16px;
$body-copy-secondary: 14px;
$button-primary: 16px;
$button-secondary: 14px;
$lp-cta: $body-copy-secondary;

body {
  -webkit-overflow-scrolling: touch;
  overflow: auto;

  * {
    scrollbar-width: auto;
    scrollbar-color: rgb(127 127 127 / 50%);

    //overflow: auto;
  }

  *::-webkit-scrollbar {
    width: 3px;
    background-color: transparent;
  }

  *::-webkit-scrollbar-track {
    background: transparent;
  }

  *::-webkit-scrollbar-thumb {
    background-color: rgb(127 127 127 / 50%);
    border-radius: 10px;
    border: none;
    opacity: 0;
  }

  .pac-container {
    z-index: 99999999;
  }
}
