@import 'bootstrap/scss/bootstrap';
@import 'bootstrap/scss/mixins';
@import '../../Assets/Styles/typography';
@import '../../Assets/Styles/colors';
@import '../../Assets/Styles/mixins';

#MessageBoard {
  background-color: $white;
  border-radius: 5px;

  //height: calc(100vh - 235px) !important;
  scroll-behavior: smooth;
  width: 100%;
  position: relative;
  flex: 3;
  display: flex;
  flex-direction: column;
  transition: 0.2s;

  @include media-breakpoint-up(xl) {
    height: calc(100vh - 235px) !important;
  }

  &.withFundraiser {
    @include media-breakpoint-up(xl) {
      height: calc(100vh - 235px) !important;
      max-height: 350px;
    }

    @include media-breakpoint-between(md, xl) {
      max-height: 500px;
    }
  }

  @include media-breakpoint-down(md) {
    //height: calc(100vh - 95px) !important;
  }

  &-header {
    position: relative;
    top: 0;
    padding: 12px 0 9px;
    margin: 0 14px;

    @include media-breakpoint-down(md) {
      display: none;
    }

    display: inline-flex;
    justify-content: space-between;
    letter-spacing: 0.5px;
    font: {
      size: 16px;
      family: $font-family-base;
      weight: $font-weight-medium;
    }

    div {
      cursor: pointer;
      padding-bottom: 8px;
      border-bottom: 3px solid $grey-1;

      &.active {
        border-bottom: 3px solid $success;
        color: $title;
      }

      &.default {
        color: $title;
      }

      color: #8c8c8c;

      &:first-of-type {
        max-width: 145px;
        flex: 0 1 145px;
        padding-right: 5px;
      }

      &:last-of-type {
        padding-left: 8px;
        max-width: 176px;
        flex: 0 1 176px;
        position: relative;

        &::before {
          content: '';
          position: absolute;
          width: 1.5px;
          height: 22px;
          display: block;
          background-color: $grey-1;
          left: -1px;
        }
      }

      &:only-of-type {
        width: 100%;
        max-width: 100%;
        flex: 100%;
        border-bottom: 1px solid $grey-1;

        &::before {
          content: none;
        }
      }
    }

    span {
      font: {
        size: 14px;
        family: $font-family-dada-grotesk;
        weight: $font-weight-regular;
      }
    }
  }

  .chat-wrapper {
    overflow-y: overlay;
    overflow-x: hidden;
    padding: 10px 0 10px 10px;
    position: relative;
    height: calc(100% - 50px);
    scroll-behavior: smooth;

    .empty-message-board {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      text-align: center;
      font-size: 16px;
      font-family: $font-family-dada-grotesk;
      font-weight: 400;
      color: #777;
      padding: 15px;

      span {
        color: #333;
        font-size: 20px;
        font-family: $font-family-dada-grotesk;
        font-weight: 500;
        display: block;
      }

      i {
        font-size: 25px;
        color: #000;
      }
    }

    &.phoneMessage {
      padding: 10px;
    }
  }

  .outside {
    word-wrap: break-word;
    background-color: $white;
    padding: 10px;
    text-align: center;
    box-shadow: 0 -1px 2px rgb(0 0 0 / 10%);

    font: {
      size: 13px;
      weight: $font-weight-regular;
      family: $font-family-base;
    }

    span {
      display: inline;

      font: {
        size: 15px;
        weight: $font-weight-semibold;
        family: $font-family-base;
      }
    }
  }

  .chat-input {
    border: none;
    outline: none;
    box-sizing: border-box;
    min-height: 30px;
    max-height: 92px;
    flex: 0 1 calc(100% - 80px);
    margin-right: 4px;
    font-size: 16px;
    max-width: calc(100% - 80px);
    resize: none;
    overflow-x: auto;

    &::placeholder {
      font-size: 14px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 10px !important;
      background: rgb(127 127 127 / 50%) !important;
      box-shadow: inset 0 0 6px rgb(127 127 127 / 50%) !important;
      opacity: 1 !important;
    }

    &-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: auto;
      padding: 5px 12px;
      background-color: var(--chat-input);
      border-top: 1px solid $grey-1;

      & > div:nth-child(2) {
        position: absolute;
        bottom: 50px;
        left: 0;
        width: 100%;

        & > p {
          position: absolute;
          z-index: 9;
          right: 10px;
          cursor: pointer;
          width: 20px;
          text-align: center;
          top: 10px;
          font-size: 20px;
        }

        aside {
          max-width: 100%;
          width: 100% !important;
          background-color: $white;
          max-height: 350px;
          position: relative;

          @include media-breakpoint-down(md) {
            display: flex;
          }

          &.EmojiPickerReact {
            .epr-header {
              width: 100%;

              .epr-header-overlay {
                padding-bottom: 0;
              }
            }

            .epr-body {
              width: 100%;
              padding: 0 10px;

              .epr-emoji-list {
                li {
                  &.epr-emoji-category > .epr-emoji-category-label {
                    font: {
                      size: 14px;
                      family: $font-family-base;
                      weight: $font-weight-semibold;
                    }
                  }
                }
              }
            }
          }
        }
      }

      @include media-breakpoint-down(md) {
        position: absolute;
        width: 100%;
        bottom: 0;
        padding: 8px 0 0;
      }
    }
  }

  .input-wrapper {
    border: none;
    border-radius: 4px;
    font-size: 12px;
    display: flex;
    flex: 1;
    overflow: hidden;
    justify-content: space-between;
    background-color: $white;
    align-items: center;
    position: relative;

    .input {
      font: {
        size: 16px;
        family: $font-family-base;
        weight: $font-weight-medium;
      }
    }

    .message-cant-send {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      cursor: pointer;
    }
  }

  .emoji-btn {
    border: none;
    background-color: transparent;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    color: $grey-1;
    width: 40px;
    height: 40px;

    i {
      font-size: 18px;
      color: $text-primary;
    }

    &:hover {
      i {
        color: orange;
      }
    }
  }

  .chat-send-btn {
    color: $success;
    border: none;
    background-color: transparent;
    padding: 0;
    width: 40px;
    height: 40px;

    i {
      font-size: 18px;
    }

    &:disabled {
      color: $grey-1;
    }

    &:focus {
      i {
        color: $hover-button;
      }
    }
  }

  .chat-attachment-btn {
    border: none;
    padding: 0;
    background-color: transparent;
    color: var(--text-light);
    display: flex;
    align-items: center;
    opacity: 0.7;
  }

  .message-wrapper {
    display: flex;
    align-items: flex-start;
    padding-bottom: 10px;
    flex-wrap: wrap;
    width: 100%;

    &.reverse {
      justify-content: flex-end;

      .message-wrapper-reply {
        margin: 10px 42px 0 0;
      }

      .message-box {
        color: $text-primary;
        word-wrap: break-word;

        &-wrapper {
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          background-color: #f2f4f5;
          border-radius: 5px;
          margin: 0 12px 0 0;

          .footer {
            display: flex;
            align-items: center;
            padding-top: 5px;

            .reply {
              margin: 0 0 0 10px;
            }

            .addreaction {
              margin: 0 0 0 10px;
            }
          }
        }
      }

      .message-pp {
        order: 2;
      }
    }

    .message-pp {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      object-fit: cover;
      flex-shrink: 0;
      flex: 0 0 30px;
      max-width: 30px;
    }

    .ownerAlpha {
      background-color: #1d817b2b;
      color: #1d817b;
      width: 30px;
      height: 30px;
      line-height: 1;
      padding: 7px;
      position: relative;
      border-radius: 50%;

      font: {
        size: 18px;
        family: $font-family-dada-grotesk;
        weight: $font-weight-medium;
      }

      span {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }

    .message-box {
      border-radius: 5px;
      width: auto;
      max-width: 100%;
      word-wrap: break-word;
      max-height: 100px;
      overflow: auto;

      font: {
        size: 14px;
        family: $font-family-dada-grotesk;
        weight: $font-weight-regular;
      }

      line-height: 1.4;
      color: $text-primary;

      &::-webkit-scrollbar {
        width: 2px;
      }

      &-wrapper {
        margin: 0 0 0 12px;
        padding: 10px;
        background-color: #f2f4f5;
        border-radius: 5px;
        flex: 0 0 calc(100% - 50px);
        max-width: calc(100% - 50px);

        .title-box {
          display: flex;
          width: 100%;
          justify-content: space-between;
          margin-bottom: 4px;

          // max-width: calc(100% - 70px); //Commented due to space issue in message board time stamp
          max-width: 100%;

          .name {
            font: {
              size: 12px;
              family: $font-family-dada-grotesk;
              weight: $font-weight-medium;
            }

            color: $text-primary;
          }

          .time {
            font: {
              size: 11px;
              family: $font-family-dada-grotesk;
              weight: $font-weight-regular;
            }

            color: $text-primary;
          }
        }

        .footer {
          display: flex;
          align-items: center;
          padding-top: 5px;
          justify-content: space-between;

          .ctas {
            span {
              color: #777;
              display: inline-flex;
              align-items: center;
              line-height: 1;
              border-radius: 30px;
              border: 1px solid #e8e8e8;
              padding: 5px 8px;
              background-color: $white;
              cursor: pointer;

              font: {
                size: 13px;
                family: $font-family-dada-grotesk;
                weight: $font-weight-regular;
              }

              img {
                margin-right: 5px;
              }

              em.reaction-wrapper {
                font-style: normal;
                font-size: 16px;
                letter-spacing: 6px;
              }
            }
          }

          .reply {
            font: {
              size: 11px;
              family: $font-family-dada-grotesk;
              weight: $font-weight-medium;
            }

            color: $text-primary;
            margin-right: 10px;
          }

          .addreaction {
            //background-color: $white;
            //	padding: 3px 9px;
            //border-radius: 20px;
            margin-right: 10px;
            cursor: pointer;

            i {
              font-size: 17px;
              color: $text-primary;
            }

            &:hover {
              i {
                color: orange;
              }
            }
          }

          .reply {
            cursor: pointer;

            &:hover {
              color: $success;
            }
          }
        }
      }
    }

    &-reply {
      display: flex;
      align-items: flex-start;

      //padding-bottom: 20px;
      flex-wrap: wrap;
      width: calc(100% - 42px);
      margin: 10px 0 0 42px;

      .message-box-wrapper {
        background-color: transparent !important;
        border: 1px solid #ebebeb;

        .footer {
          .addreaction {
            background-color: $white;
            border: 1px solid $bg;
            padding: 3px 9px;
            border-radius: 20px;
            margin-right: 10px;
            cursor: pointer;

            i {
              font-size: 17px;
            }
          }

          .ctas {
            span {
            }
          }
        }
      }
    }

    .reaction-emoji-msgbord {
      aside {
        max-width: 100%;
        width: 100% !important;
        background-color: $white;
        margin-top: 10px;

        &.EmojiPickerReact {
          .epr-header {
            width: 100%;

            .epr-header-overlay {
              padding-bottom: 0;
            }
          }

          .epr-body {
            width: 100%;
            padding: 0 10px;

            .epr-emoji-list {
              li {
                &.epr-emoji-category > .epr-emoji-category-label {
                  font: {
                    size: 14px;
                    family: $font-family-dada-grotesk;
                    weight: $font-weight-semibold;
                  }
                }
              }
            }
          }
        }
      }
    }

    &.phoneMessagewraapper {
      .message-box-wrapper {
        flex: 0 1 100%;
        max-width: 100%;
        margin-left: 0;

        .audioMessageWrapper {
          margin-top: 16px;
          padding-bottom: 5px;

          .row {
            align-items: center;

            .AudioWaves {
              flex: 0 1 calc(100% - 40px);
              width: 290px;

              .audioWrapper {
                width: 100%;

                .waves {
                  width: 100%;

                  wave {
                    height: 30px !important;
                  }
                }
              }
            }

            .ControllerIcon {
              width: 30px;
              height: 30px;
              flex: 0 1 30px;
              padding: 0;
              cursor: pointer;

              i {
                font-size: 30px;
                color: $success;
              }
            }
          }
        }

        .ImgWrapper {
          max-width: 100%;
          max-height: 200px;
          display: flex;
          justify-content: center;
          height: 200px;
          margin-top: 10px;
          background-color: #dadada;

          img {
            width: auto;
            height: 100%;
            max-height: 100%;
            object-fit: contain;
            transition: transform 400ms ease-out;
            max-width: 100%;
          }
        }
      }
    }
  }

  .reply-box {
    position: absolute;
    height: calc(100% - 102px);
    background: rgb(255 255 255);
    background: linear-gradient(180deg, rgb(255 255 255 / 0%) 5%, rgb(0 0 0 / 49.2%) 100%);

    //border-bottom: 1px solid $grey-1;
    width: 100%;
    bottom: 65px;

    @include media-breakpoint-down(md) {
      bottom: 48px;
    }

    .reply-wrapper {
      padding: 10px;
      background-color: $white;
      width: 100%;
      bottom: 0;
      position: absolute;

      @include media-breakpoint-down(md) {
        padding: 10px 0;
      }

      .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: none;
        padding-right: 0;

        .title {
          font: {
            size: 11px;
            family: $font-family-base;
            weight: $font-weight-medium;
          }

          color: $success;
        }

        .close-btn {
          cursor: pointer;
          padding-bottom: 0;
          line-height: 1;

          @include media-breakpoint-down(md) {
            padding-right: 15px;
          }

          i {
            font-size: 18px;
            color: #777;
          }
        }
      }

      .message {
        font: {
          size: 12px;
          family: $font-family-dada-grotesk;
          weight: $font-weight-regular;
        }

        color: #333;
        max-height: 58px;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  .skeleton:empty {
    position: relative;
    height: 400px;
    background-color: #fff;
    border-radius: 0;
    background-image: linear-gradient(#f5f7f9 6px, transparent 0), linear-gradient(#f5f7f9 6px, transparent 0),
      linear-gradient(#f5f7f9 6px, transparent 0), linear-gradient(#f5f7f9 6px, transparent 0),
      linear-gradient(#f5f7f9 6px, transparent 0), radial-gradient(circle 20px at 20px 20px, #f5f7f9 99%, transparent 0);
    background-repeat: repeat-y;
    background-size:
      43% 133px,
      90% 133px,
      74% 133px,
      52px 133px,
      88px 133px,
      40px 133px;
    background-position:
      left 12px top 111px,
      left 12px top 92px,
      left 12px top 73px,
      left 59px top 39px,
      left 59px top 21px,
      left 12px top 12px;
  }

  .skeleton:empty::before {
    content: ' ';
    position: absolute;
    z-index: 1000;
    width: 100%;
    height: 400px;
    mask-image: linear-gradient(100deg, rgb(255 255 255 / 0%), rgb(255 255 255 / 50%) 50%, rgb(255 255 255 / 0%) 80%);
    mask-repeat: repeat-y;
    mask-size: 50px 400px;
    mask-position: -20% 0;
    background-image: linear-gradient(rgb(102 102 102 / 100%) 6px, transparent 0),
      linear-gradient(rgb(102 102 102 / 100%) 6px, transparent 0),
      linear-gradient(rgb(102 102 102 / 100%) 6px, transparent 0),
      linear-gradient(rgb(102 102 102 / 100%) 6px, transparent 0),
      linear-gradient(rgb(102 102 102 / 100%) 6px, transparent 0),
      radial-gradient(circle 20px at 20px 20px, rgb(102 102 102 / 100%) 99%, transparent 0);
    background-repeat: repeat-y;
    background-size:
      43% 133px,
      90% 133px,
      74% 133px,
      52px 133px,
      88px 133px,
      40px 133px;
    background-position:
      left 12px top 111px,
      left 12px top 92px,
      left 12px top 73px,
      left 59px top 39px,
      left 59px top 21px,
      left 12px top 12px;
    animation: shineForSkeleton-1rdh8brp10k 2s infinite;
    left: 0;
  }

  @keyframes shineForSkeleton-1rdh8brp10k {
    to {
      mask-position: 120% 0;
    }
  }
}
