@import 'bootstrap/scss/bootstrap';
@import 'bootstrap/scss/mixins';
@import '../../Assets/Styles/typography';
@import '../../Assets/Styles/colors';
@import '../../Assets/Styles/mixins';

.panel.Gallery {
  .MoveTop {
    box-shadow: 0 0 10px silver;
    position: fixed;
    right: 10px;
    width: 40px;
    height: 40px;
    background-color: $success;
    border-radius: 50%;
    border: none;
    bottom: 10px;
    z-index: 99;
    cursor: pointer;

    i {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: $white;
    }
  }

  .selected-album-name {
    padding: 0 10px;
    margin-bottom: 40px;
    color: $success;
    font-size: 24px;
    font-weight: 600;

    @include media-breakpoint-down(md) {
      margin-bottom: 20px;
      margin-top: 100px;
    }
  }

  .header {
    h5 {
      padding-left: 0 !important;

      span {
        padding-bottom: 0;
      }
    }
  }

  .action {
    position: absolute;
    right: 25px;

    select {
      border: 1px solid $grey-1;
      border-radius: 25px;
      color: $success;
      padding: 10px;
      outline: none;
      margin-left: 27px;
      width: 123px;
      background: url("data:image/svg+xml,<svg height='10px' width='10px' viewBox='0 0 16 16' fill='%23000000' xmlns='http://www.w3.org/2000/svg'><path d='M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z'/></svg>")
        no-repeat;
      appearance: none;
      background-position-x: calc(100% - 10px);
      background-position-y: 50%;
      cursor: pointer;

      &:focus {
        outline: none;
      }
      font: {
        size: 12px;
        family: $font-family-dada-grotesk;
        weight: $font-weight-regular;
      }

      option {
        color: $success;

        @include media-breakpoint-down(xl) {
          font: {
            size: 16px;
            family: $font-family-base;
            weight: $font-weight-regular;
          }
        }
      }
    }

    i {
      font-size: 24px;
      margin-left: 17px;
      cursor: pointer;

      &.active,
      &:hover {
        color: $hover-button;
      }
    }

    @include media-breakpoint-down(md) {
      top: 85px;
      display: flex;
      align-items: center;
      width: calc(100% - 15px);

      select {
        order: 1;
        width: calc(100% - 85px);
        height: 40px;
        background-color: $white;
      }

      .fa-thumbnail {
        order: 2;
      }

      .fa-calendar {
        order: 3;
      }
    }
  }

  .GalleryTab {
    &.hide {
      display: none;
    }

    &.nav.nav-tabs {
      padding: 0 10px;
      border: none;
      margin-bottom: 47px;
      padding-left: 0;

      @include media-breakpoint-down(md) {
        margin-bottom: 30px;
        margin-top: 145px;
      }

      li {
        &.nav-item {
          button {
            cursor: pointer;

            &.nav-link {
              font: {
                size: 16px;
                family: $font-family-dada-grotesk;
                weight: $font-weight-medium;
              }

              color: #8c8c8c;
              border: none;
              padding: 5px;

              &.active {
                border-bottom: 4px solid $hover-button;
                color: $hover-button;
                font-weight: $font-weight-medium;
              }

              &:hover {
                color: $hover-button;
              }
            }
          }
        }

        margin-right: 35px;
      }
    }
  }

  .tab-content {
    .tab-pane {
      .media,
      .album {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(170px, 170px));
        grid-template-rows: repeat(auto-fit, minmax(170px, 170px));
        gap: 10px;

        @include media-breakpoint-down(md) {
          grid-template-columns: repeat(auto-fit, minmax(114px, 1fr));
          grid-template-rows: repeat(auto-fit, minmax(114px, 1fr));
          gap: 5px;
        }

        .eachTilesWrapper {
          margin-bottom: 10px;

          &:first-child {
            @include media-breakpoint-down(md) {
              // display: none;
            }
          }

          .NoImg {
            // pointer-events: none;
            cursor: default;
           background: url('https://butterflyv2-production-assets.nyc3.cdn.digitaloceanspaces.com/image/white_mark.png'),
          #1d817b;
            background-repeat: no-repeat;
            background-position: center;
          }
        }

        .albumDetails {
          margin-top: 8px;
          padding: 0 2px;

          .albumName {
            font: {
              family: $font-family-base;
              weight: $font-weight-medium;
              size: 14px;
            }

            color: $text-primary;
            line-height: 1;
          }

          .count {
            font: {
              family: $font-family-base;
              weight: $font-weight-regular;
              size: 12px;
            }

            color: $text-primary;
          }
        }

        .eachTiles {
          cursor: pointer;
          border-radius: 5px;
          overflow: hidden;
          position: relative;
          background-color: #e8e8e8;
          display: flex;
          align-items: center;
          justify-content: center;

          @include media-breakpoint-up(lg) {
            min-height: 170px;
          }

          &.firstTiles {
            border: 1px dashed #8c8c8c;
            text-align: center;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            background-color: $white;
            width: 100%;
            height: 170px;
            font-family: $font-family-dada-grotesk;

            @include media-breakpoint-down(md) {
              display: none;
            }

            button {
              background-color: transparent;
              border: none;
              color: $success;
              font: {
                size: 12px;
                family: $font-family-dada-grotesk;
                weight: $font-weight-regular;
              }

              i {
                font-size: 44px;
                color: $title;
              }

              span {
                display: block;
                padding-top: 10px;
                word-break: break-word;
              }
            }
          }

          img {
            max-width: 100%;
            height: 100%;
            width: 100%;
            object-fit: contain;
          }

          .metalballs {
            position: absolute;
            z-index: 9;
            top: 7px;
            right: 8px;
            width: 28px;
            height: 28px;
            border-radius: 50%;
            background: rgb(0 0 0 / 30%);
            display: inline-flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;

            &:hover {
              background-color: black;

              i {
                color: $success;
              }
            }

            i {
              font-size: 4px;
              color: $white;
            }
          }

          .overlay {
            position: absolute;
            z-index: 9;
            top: 0;
            right: 0;
            width: 100%;
            height: 100%;
            background: rgb(0 0 0 / 50%);
            display: inline-flex;
            align-items: center;
            justify-content: center;

            .close {
              position: absolute;
              z-index: 9;
              top: 7px;
              right: 8px;
              width: 28px;
              height: 28px;
              border-radius: 50%;
              background: $black;
              display: inline-flex;
              align-items: center;
              justify-content: center;
              cursor: pointer;
              color: $white;

              &:hover {
                background-color: black;

                i {
                  color: $success;
                }
              }

              i {
                font-size: 18px;
                color: $white;
              }
            }
            font: {
              size: 12px;
              family: $font-family-base;
              weight: $font-weight-regular;
            }

            color: $white;

            i {
              &:hover {
                color: $success;
              }
            }

            div {
              display: inline-flex;

              span {
                text-align: center;
                display: block;
                padding: 0 16px;
                line-height: 1;
                cursor: pointer;

                i {
                  font-size: 30px;
                  display: block;
                  padding-bottom: 7px;
                }

                &:nth-child(1) {
                  border-right: 1px dashed $white;
                }
              }

              &.report-image {
                span {
                  border: none;
                }
              }
            }

            &.isAlbumMedia {
              div {
                span {
                  border-right: none;
                }
              }
            }
          }

          @include media-breakpoint-down(md) {
            .overlay {
              font-size: 16px;

              div {
                display: block;

                span {
                  padding: 10px 0;

                  i {
                    font-size: 24px;
                  }

                  &:nth-child(1) {
                    border-right: none;
                    border-bottom: 1px solid $white;
                  }
                }
              }
            }
          }

          .audio-media,
          .video-media {
            background-color: #1d817b;
            width: 100%;
            height: 100%;
            font-size: 60px;
            align-items: center;
            color: $white;
            display: flex;
            justify-content: center;
          }

          .video-media {
            font-size: 50px;
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
          }

          &.NoImg {
            cursor: default;

            background-color: #1d817b;
            .no-image-component {
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              cursor: pointer;
              background: url('https://butterflyv2-production-assets.nyc3.cdn.digitaloceanspaces.com/image/white_mark.png'),
                #1d817b;
              background-repeat: no-repeat;
              background-position: center;
            }
          }
        }

        &.timelineView {
          display: block;

          .eachTiles {
            max-width: 170px;
          }

          .date {
            font: {
              family: $font-family-base;
              weight: $font-weight-medium;
              size: 14px;
            }

            border-bottom: 1px dashed $grey-1;
            line-height: 2.3;
            margin-bottom: 18px;
            margin-top: 33px;
          }

          .eachrow {
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(170px, 170px));
            grid-template-rows: repeat(auto-fit, minmax(170px, 170px));
            gap: 10px;

            @include media-breakpoint-down(md) {
              grid-template-columns: repeat(auto-fit, minmax(114px, 1fr));
              grid-template-rows: repeat(auto-fit, minmax(114px, 1fr));
              gap: 5px;
            }
          }
        }
      }

      .CreateMedia {
        position: fixed;
        left: 50%;
        transform: translateX(-50%);
        bottom: 100px;
        width: 160px;
        height: 50px;
        border: 2px solid $white;
        border-radius: 50px;
        background-color: $success;
        background-size: cover;
        font-family: $font-family-base;
        font-weight: $font-weight-medium;
        font-size: 16px;
        color: $white;
        text-decoration: none solid rgb(255 255 255);
        text-align: center;
        box-shadow: 0 1px 20px rgb(0 0 0 / 20%);
        z-index: 9;

        @include media-breakpoint-up(md) {
          display: none;
        }

        &.createAlbum {
          width: 180px;
        }

        &.createAlbum {
          width: 180px;
        }

        i {
          margin-right: 3px;
        }
      }
    }
  }
}
