@import 'bootstrap/scss/bootstrap';
@import 'bootstrap/scss/mixins';
@import '../../Assets/Styles/typography';
@import '../../Assets/Styles/colors';
@import '../../Assets/Styles/mixins';
@import '~slick-carousel/slick/slick.css';
@import '~slick-carousel/slick/slick-theme.css';

.panel-wrap {
  .panel.Fundraiser {
    padding-left: 0;
    padding-right: 0;
    overflow-x: hidden;

    .header {
      margin-left: 24px;
      margin-right: 24px;
      margin-bottom: 0;

      @include media-breakpoint-down(md) {
        border-bottom: 0;
      }

      h4 {
        padding-left: 0;
      }
    }

    .CreateFundraiserEmpty {
      p {
        max-width: 300px;
        text-align: center;

        font: {
          size: 15px;
          family: $font-family-base;
          weight: $font-weight-regular;
        }

        color: #777;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        margin: 0;

        span {
          color: #333;

          font: {
            size: 20px;
            family: $font-family-base;
            weight: $font-weight-medium;
          }
        }

        @include media-breakpoint-down(md) {
          width: 100%;
        }
      }
    }

    .eachFundraiser {
      border-radius: 5px;
      box-shadow: 0 0 20px rgb(0 0 0 / 20%);
      flex: 0 1 calc(50% - 15px);
      max-width: calc(50% - 15px);
      margin-top: 20px;
      margin-bottom: 20px;
      justify-content: space-between;

      @include media-breakpoint-down(md) {
        margin: 0 24px 16px;
        box-shadow: 0 0 30px rgb(0 0 0 / 20%);
        background: $white;
        flex: 0 1 100%;
        max-width: 100%;
      }

      .ImageWrapper {
        position: relative;
        min-height: 216px;
        background-color: #dadada;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;

        @include media-breakpoint-down(md) {
          min-height: unset;

          &.noImgWrapper {
            min-height: 216px;
          }
        }

        &::before {
          background: linear-gradient(0.86deg, rgb(0 0 0 / 90%) 0.76%, rgb(0 0 0 / 0%) 99.29%);
          content: '';
          width: 100%;
          height: 88px;
          display: block;
          position: absolute;
          bottom: 0;
          left: 0;
        }

        img {
          width: 100%;
          height: auto;
          border-top-left-radius: 5px;
          border-top-right-radius: 5px;
        }

        .no-fundraiser-image {
          width: 100%;
          height: 100%;
          background: url('https://butterflyv2-production-assets.nyc3.cdn.digitaloceanspaces.com/image/white_mark.png'),
            #1d817b;
          background-repeat: no-repeat;
          background-position: center;
          position: absolute;
          overflow: hidden;
          display: flex;
          background-size: 40%;
          justify-content: space-between;
          top: 0;
          border-top-left-radius: 5px;
          border-top-right-radius: 5px;
        }

        .caption {
          position: absolute;
          bottom: 12px;
          padding: 0 12px;
          word-break: break-word;

          font: {
            family: $font-family-base;
            weight: $font-weight-semibold;
            size: 16px;
          }

          line-height: 20px;
          color: $white;
        }
      }

      .card-body {
        padding: 12px 13px 0;

        .title {
          font: {
            family: $font-family-base;
            weight: $font-weight-semibold;
            size: 20px;
          }

          line-height: 1.2;
          color: $title;
          margin-bottom: 4px;
          min-height: 48px;
        }

        p {
          font: {
            family: $font-family-dada-grotesk;
            weight: $font-weight-medium;
            size: 14px;
          }

          line-height: 21px;
          color: $text-primary;
          margin-bottom: 10px;
          min-height: 83px;
          max-height: 83px;
          text-overflow: ellipsis;
          overflow: hidden;
          word-break: break-word;

          .read-or-hide {
            cursor: pointer;
            color: rgb(25 135 84);
          }
        }

        .progress-bar {
          margin-bottom: 8px;
          height: 7px;
        }

        .raised-fund {
          color: $title;
          line-height: 21px;
          margin-top: 4px;

          font: {
            family: $font-family-dada-grotesk;
            weight: 600;
            size: 14px;
          }
        }

        .donate-info {
          padding: 22px 0 12px;
          display: flex;
          flex-flow: row wrap;
          gap: 12px;
          align-items: center;
          justify-content: space-between;

          .donater {
            width: calc(100% - 200px);

            .img {
              display: flex;

              .each-img {
                width: 24px;
                height: 24px;
                border: 0.5px solid $white;
                border-radius: 47px;
                margin-left: -7px;

                &:nth-child(1) {
                  margin-left: 0;
                }

                img {
                  width: 100%;
                  height: auto;
                  border-radius: 47px;
                }

                .ownerAlpha {
                  background-color: rgba(29, 129, 123, 0.169);
                  border-radius: 50%;
                  color: #1d817b;
                  flex: 0 1 24px;
                  height: 24px;
                  max-width: 24px;
                  overflow: hidden;
                  position: relative;
                  width: 24px;

                  span {
                    font-family: $font-family-dada-grotesk;
                    font-size: 14px;
                    font-weight: 600;
                    left: 50%;
                    position: absolute;
                    top: 50%;
                    transform: translate(-50%, -50%);
                  }
                }
              }
            }

            .text {
              font: {
                family: $font-family-dada-grotesk;
                weight: 400;
                size: 11px;
              }

              line-height: 16px;
              color: $text-primary;
              margin-top: 4px;
            }
          }

          .edit {
            color: $text-primary;

            font: {
              family: $font-family-dada-grotesk;
              weight: 500;
              size: 12px;
            }

            line-height: 18px;
            padding: 13px 15px;
            border: 1px solid $grey-1;
            border-radius: 50px;
            width: 70px;
            height: 44px;
            transition: all 0.5s;
            background: none;

            &:hover {
              background: $hover-button;
              color: $white;
              transition: all 0.5s;
            }
          }

          .donatenow {
            color: $white;

            font: {
              family: $font-family-dada-grotesk;
              weight: 500;
              size: 12px;
            }

            line-height: 18px;
            padding: 13px 15px;
            border: 0;
            border-radius: 50px;
            height: 44px;
            background-color: $success;
            transition: all 0.5s;

            &:hover {
              background-color: $hover-button;
              box-shadow: 0 2px 8px rgb(0 0 0 / 15%);
              color: $white;
              outline: none;
            }
          }
        }
      }

      .card-footer {
        display: flex;
        justify-content: space-between;
        flex-wrap: nowrap;
        padding: 12px 0 16px;
        align-items: center;
        border-top: 1px solid $bg;
        margin-right: 13px;
        margin-left: 13px;

        .date-wrap {
          color: $text-primary;

          font: {
            family: $font-family-dada-grotesk;
            weight: 400;
            size: 12px;
          }

          line-height: 18px;
        }

        .ike-comment-warp {
          display: flex;
          align-items: center;
        }

        span {
          color: #777;
          display: inline-flex;
          align-items: center;
          line-height: 1;
          justify-content: flex-end;

          font: {
            size: 14px;
            family: $font-family-dada-grotesk;
            weight: $font-weight-regular;
          }

          i {
            margin-left: 2px;
            cursor: pointer;
          }

          &:nth-of-type(1) {
            margin-right: 5px;

            i {
              font-size: 16px;
              color: $success;
              margin-left: 6px;
            }
          }

          &:nth-of-type(2) {
            i {
              color: #fc394d;
              font-size: 15px;
              line-height: 16px;

              &.disabled {
                pointer-events: none;
              }
            }

            width: 35px;
          }
        }
      }
    }
  }
}

.fundraiserSliderWrapper {
  padding: 0 24px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-bottom: 100px;

  @include media-breakpoint-down(md) {
    padding: 16px 0 100px;
  }

  .slick-arrow {
    display: none !important;
  }

  .slick-dots {
    position: static;
  }

  .slick-dots li,
  .slick-dots li button {
    padding: 0 !important;
    margin: 0 !important;
  }

  .slick-dots li button::before {
    font-size: 12px !important;
    color: $bg !important;
    opacity: 1 !important;
  }

  .slick-dots li.slick-active button::before,
  .slick-dots li.slick-active button:hover::before {
    color: $title !important;
    opacity: 1 !important;
  }
}

.fundraiserSlider {
  padding-left: 0;
  margin-left: 16px;

  .slick-slide {
    padding-left: 20px;
  }
}

progress[value] {
  --color: $success;

  height: 7px;

  --background: $bg;

  appearance: none;
  border: none;
  width: 100%;
  border-radius: 17px;
  background: var(--background);
}

progress[value]::-webkit-progress-bar {
  border-radius: 17px;
  background: var(--background);
}

progress[value]::-webkit-progress-value {
  border-radius: 17px;
  background: var(--color);
}

progress[value]::-moz-progress-bar {
  border-radius: 17px;
  background: var(--color);
}

.btn-wrap {
  bottom: 30px;
  margin-top: 64px;
  position: fixed;
  text-align: center;
  width: 730px;

  .create-fund {
    align-items: center;
    background-color: $success;
    border-radius: 50px;
    padding: 13px 26px;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: $white;
    height: 50px;
    border: 0;
    display: inline-flex;
    justify-content: center;

    @include media-breakpoint-down(md) {
      border: 2px solid $white;
      bottom: 100px;
      box-shadow: 0 1px 20px rgb(0 0 0 / 20%);
      height: 50px;
      left: 50%;
      position: fixed;
      transform: translateX(-50%);
      width: max-content;
    }

    i {
      color: $white;
      font-size: 20px;
      margin-right: 8px;
    }

    &:hover {
      background-color: $hover-button;
      box-shadow: 0 2px 8px rgb(0 0 0 / 15%);
      color: $white;
      outline: none;
    }
  }

  @media (width <= 767.98px) {
    margin-top: 0;
  }
}

.isMobile {
  display: none;

  @media (width <= 767.98px) {
    display: block;
  }
}

.isNotMobile {
  @media (width <= 767.98px) {
    display: none;
  }
}
