@import 'bootstrap/scss/bootstrap';
@import 'bootstrap/scss/mixins';
@import '../../Assets/Styles/typography';
@import '../../Assets/Styles/colors';
@import '../../Assets/Styles/mixins';

.RightSection {
  flex: 0 1 375px;
  max-width: 375px;
  padding: 9px 13px;
  max-height: 100%;
  overflow: auto;

  @include media-breakpoint-between(md, lg) {
    flex: 0 1 40%;
    max-width: 40%;
    overflow: auto;
    max-height: 100%;
  }

  @include media-breakpoint-down(md) {
    display: none;
  }

  .SectionWrapper {
    min-height: calc(100vh - 65px);
  }
}
