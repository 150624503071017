@import 'bootstrap/scss/mixins';

@mixin button-success {
  font-family: $font-family-dada-grotesk;
  font-weight: $font-weight-medium;
  font-size: 16px;
  background-color: $success-button;
  border: none;
  height: 50px;
  color: $white;
  border-radius: 50px;
  padding: 16px 18px;
  opacity: 1;
  outline: none;
  box-shadow: none;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  min-width: 90px;

  &:disabled {
    background-color: $disabled-button;
    border: none;
    outline: none;
    cursor: none;
    pointer-events: none;
    color: #333;

    &:hover,
    &:focus {
      background-color: $disabled-button;
      border: none;
      outline: none;
      box-shadow: none;
    }
  }

  &:hover {
    box-shadow: 0 2px 8px rgb(0 0 0 / 15%);
    background-color: $hover-button;
    outline: none;
    color: $white;
  }

  &:focus {
    outline: 0;
    box-shadow: none;
    background-color: $success-button !important;
  }

  &:active {
    background-color: $cta-focus !important;
    outline: 0;
    box-shadow: none;
  }

  &:focus-visible {
    outline: none;
    border: none;
  }

  @include media-breakpoint-down(lg) {
    font-size: 16px;
  }

  @include media-breakpoint-down(sm) {
    padding: 10px;
  }
}

@mixin button-outline($color: $success, $hoverColor: $cta-focus) {
  font-family: $font-family-dada-grotesk;
  font-weight: $font-weight-medium;
  font-size: 16px;
  background-color: transparent;
  border: 1px solid $color;
  color: $color;
  border-radius: 50px;
  padding: 12px 18px;
  opacity: 1;
  outline: none;
  box-shadow: none;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  min-width: 90px;

  &:hover {
    box-shadow: none;
    outline: none;
    background-color: $success !important;
    color: $white;
    border-color: $color;

    i {
      color: $white;
    }
  }

  &:focus {
    outline: 0;
    box-shadow: none;
    background-color: transparent !important;
    color: $color;
    border-color: $color;
  }

  &:active {
    background-color: transparent;
    outline: none;
    box-shadow: none;
    border-color: $hoverColor;
    color: $hoverColor;
  }

  &:disabled {
    background-color: $disabled-button;
    border: none;
    outline: none;
    color: $text-primary !important;

    &:hover,
    &:focus,
    &:active {
      background-color: $disabled-button !important;
      border: none;
      outline: none;
      box-shadow: none;
      color: $white;
    }
  }

  &:focus-visible {
    outline: none;
    border: none;
  }

  @include media-breakpoint-down(lg) {
    font-size: 16px;
  }

  @include media-breakpoint-down(sm) {
    padding: 10px;
  }
}

.btnOutline {
  @include button-outline;

  &.grayed {
    color: $gray-800;
    background-color: transparent;
    border: 1px solid #c1c1c1;

    i {
      color: $gray-500;
    }

    &:hover {
      box-shadow: 0 2px 8px rgb(0 0 0 / 15%);
      outline: none;
      background-color: transparent;
      border-color: $disabled-button;
      color: $gray-800;
    }

    &:focus {
      outline: 0;
      box-shadow: none;
      background-color: transparent;
      border-color: $disabled-button;
      color: $gray-800;
    }

    &:active {
      background-color: $gray-200;
      outline: none;
      box-shadow: none;
      border-color: $disabled-button;
      color: $white;
    }
  }
}

@mixin ProfilePic {
  display: flex;
  line-height: 1;
  align-items: center;

  .ownerPic {
    display: flex;
    max-width: 40px;
    border-radius: 50%;
    vertical-align: middle;
    flex: 0 0 30px;
    align-items: center;

    img {
      border-radius: 50%;
      width: 40px;
      height: 40px;
      border: 1px solid #c1c1c1;
    }

    .ownerAlpha {
      background-color: #1d817b2b;
      color: #1d817b;
      width: 40px;
      height: 40px;
      line-height: 1;
      padding: 7px;
      position: relative;
      border-radius: 50%;
      font: {
        size: 20px;
        family: $font-family-dada-grotesk;
        weight: $font-weight-medium;
      }

      span {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-transform: capitalize;
      }
    }
  }

  .ownerName {
    max-width: calc(100% - 40px);
    flex: 0 0 calc(100% - 40px);
    line-height: 1.2;
    word-break: break-word;
    font: {
      size: $font-size-base;
      family: $font-family-dada-grotesk;
      weight: $font-weight-medium;
    }

    color: #333;
    padding-left: 8px;

    .created_at {
      display: block;
      color: $text-primary;
      margin-top: 4px;
      font: {
        size: $font-size-base - 4;
        family: $font-family-dada-grotesk;
        weight: $font-weight-regular;
      }
    }
  }
}

body {
  .picker-dialog-bg {
    z-index: 9999999;
  }

  .picker.picker-dialog {
    z-index: 99999999999;
  }

  .modal.show .modal-dialog {
    @include media-breakpoint-down(md) {
      width: auto !important;
    }
  }
}

@mixin SelectDropdown {
  .butterfly-dropdown-select {
    margin-left: 5px;

    .butterfly {
      &__control {
        width: 200px;
        border: 1px solid $grey-1;
        border-radius: 25px;
        color: $success;
        outline: none;
        height: 40px;
        background-color: $white;
        box-shadow: none;

        &:focus {
          outline: none;
          box-shadow: none;
        }
        font: {
          size: 12px;
          family: $font-family-dada-grotesk;
          weight: $font-weight-regular;
        }

        @include media-breakpoint-down(md) {
          width: unset;
        }
      }

      &__menu {
        width: 200px;
        height: 132px;
        border-radius: 5px;
        box-shadow: 0 0 4px rgb(0 0 0 / 10%);
        border: none;
        top: 39px;
        margin-top: 1px;
      }

      &__menu-list {
        font: {
          size: 12px;
          family: $font-family-dada-grotesk;
          weight: $font-weight-medium;
        }
      }

      &__option {
        background-color: transparent;
        color: $text-primary;
        cursor: pointer;
        padding: 10px 24px;
      }

      &__single-value {
        font: {
          size: 12px;
          family: $font-family-dada-grotesk;
          weight: $font-weight-medium;
        }

        color: $success;
      }

      &__option--is-selected {
        color: $success;
      }

      &__indicator-separator {
        display: none;
      }
    }
  }

  .css-t3ipsp-control,
  .react-dropdown-select .css-13cymwt-control {
    .react-dropdown-select-dropdown,
    .css-1u9des2-indicatorSeparator {
      display: none;
    }

    .css-1hb7zxy-IndicatorsContainer {
      cursor: pointer;
    }
  }

  .css-1nmdiq5-menu {
    width: 200px;
    height: 132px;
    border-radius: 5px;
    box-shadow: 0 0 4px rgb(0 0 0 / 10%);
    border: none;
    top: 39px;

    .react-dropdown-select-item {
    }
  }
}

@for $i from 1 through 6 {
  $zIndexBackdrop: #{1000 + (5 * $i)};
  $zIndexContent: #{1000 + (5 * $i) + 2};
  .modal-backdrop.show:nth-of-type(#{$i}) {
    z-index: $zIndexBackdrop;
  }
  div[role='dialog'][aria-modal='true']:nth-of-type(#{$i}) {
    z-index: $zIndexContent;
  }
}

// emoji-picker-react Picker

.epr-skin-tones.epr-horizontal {
  opacity: 0;
  pointer-events: none;
}

.reaction-emoji-msgbord {
  position: relative;
}

.reaction-emoji-msgbord > p {
  position: absolute;
  z-index: 9;
  right: 10px;
  cursor: pointer;
  width: 20px;
  text-align: center;
  top: 23px;
  font-size: 20px;
}

.blur {
  filter: blur(5px);
  pointer-events: none;
  cursor: none;
}
