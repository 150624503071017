/* eslint-disable */
@import 'bootstrap/scss/bootstrap';
@import 'bootstrap/scss/mixins';
@import '../../Assets/Styles/typography';
@import '../../Assets/Styles/colors';
@import '../../Assets/Styles/mixins';

body {
  #HPLandingpage {
    background-color: transparent;

    .container {
      min-height: inherit;
      height: 100%;
      max-height: inherit;

      --bs-gutter-x: 1.5rem;
      --bs-gutter-y: 0;

      width: 100%;
      padding-right: calc(var(--bs-gutter-x) * 0.5);
      padding-left: calc(var(--bs-gutter-x) * 0.5);
      margin-right: auto;
      margin-left: auto;
      background-color: transparent;
    }

    @media (width >= 576px) {
      .container-sm,
      .container {
        max-width: 540px;
      }
    }

    @media (width >= 768px) {
      .container-md,
      .container-sm,
      .container {
        max-width: 720px;
      }
    }

    @media (width >= 992px) {
      .container-lg,
      .container-md,
      .container-sm,
      .container {
        max-width: 960px;
      }
    }

    @media (width >= 1200px) {
      .container-xl,
      .container-lg,
      .container-md,
      .container-sm,
      .container {
        max-width: 1140px;
      }
    }

    @media (width >= 1400px) {
      .container-xxl,
      .container-xl,
      .container-lg,
      .container-md,
      .container-sm,
      .container {
        max-width: 1320px;
      }
    }
  }
}

#HPLandingpage {
  .Header {
    background-color: transparent;
    transition: all 0.5s ease-out;
    z-index: 9999;
    position: fixed;
    width: 100%;

    // position: absolute;
    // width: 100%;
    // top: 0;
    // height: 95px;
    // z-index: 999;

    @include media-breakpoint-down(md) {
      padding-bottom: 10px;
    }
    &.scrolled-header {
      background: #014542 !important;
      transition: all 0.5s ease-out;
    }

    .navigationContainer {
      display: flex;
      justify-content: space-between;
      align-content: flex-end;
      padding: 23px 0;
      flex-wrap: wrap;

      .logo {
        align-self: center;
        font-size: 1.5em;
        font-weight: 900;
        text-transform: uppercase;
        flex: 33.33%;
        max-width: 33.33%;

        @include media-breakpoint-down(md) {
          max-width: fit-content;
        }

        img {
          @include media-breakpoint-down(md) {
            width: 50px;
          }

          @include media-breakpoint-between(md, xl) {
            width: 183px;
          }

          @include media-breakpoint-up(xl) {
            width: 350px;
          }
        }
      }

      .signCta {
        margin: 0;
        padding: 0;
        display: flex;
        flex-wrap: wrap;
        gap: 20px;

        li {
          margin: 0;
          padding: 0;
          list-style-type: none;

          .signIn {
            width: 105px;
            height: 50px;
            border: 1px solid $success;
            border-radius: 50px;
            font-family: $font-family-dada-grotesk;
            font-size: 16px;
            color: $white;
            text-align: center;
            box-shadow: none;
            background-color: transparent;
            transition: all 0.5s;
            font-weight: $font-weight-medium;

            &:hover {
              background-color: $hover-button;
              transition: all 0.5s;
              color: $white;
              outline: none;
              border: 1px solid $hover-button;
            }
          }

          .signUp {
            width: 105px;
            height: 50px;
            border-radius: 50px;
            background-color: $success;
            font-family: $font-family-dada-grotesk;
            font-size: 16px;
            color: $white;
            text-align: center;
            box-shadow: none;
            border: 1px solid $success;
            transition: all 0.5s;
            font-weight: $font-weight-medium;

            &:hover {
              background-color: $hover-button;
              box-shadow: 0 2px 8px rgb(0 0 0 / 15%);
              color: $white;
              outline: none;
              border: 1px solid $hover-button;
            }
          }
        }
      }
    }

    /* Media Queries */
    @media only screen and (width >= 768px) {
      .navigation-container {
        flex-direction: row;
        justify-content: flex-start;
      }
    }
  }

  #Banner {
    position: relative;
    z-index: 999;
    padding-top: 150px;

    &::before {
      content: '';
      background: #014542;
      width: 100%;
      height: calc(100% - 200px);
      display: block;
      position: absolute;
      top: 0;

      @include media-breakpoint-down(md) {
        height: calc(100% - 150px);
      }
    }

    &::after {
      content: '';
      background: $bg;
      width: 100%;
      height: 200px;
      display: block;
      position: absolute;
      bottom: 0;
      z-index: -1;

      @include media-breakpoint-only(md) {
        bottom: -3px;
      }
    }

    .banner-overlay {
      position: relative;

      &::before {
        // content: '';
        // background: url('../../Assets/Images/leaf.png') no-repeat;
        // width: 263px;
        // height: 308px;
        // transform: rotate(180deg);
        // position: absolute;
        // top: -220px;
        // left: -90px;
        @include media-breakpoint-down(md) {
          display: none;
        }
      }

      &::after {
        // content: '';
        // background: url('../../Assets/Images/leaf.png') no-repeat;
        // width: 292px;
        // height: 343px;
        // position: absolute;
        // bottom: 290px;
        // display: block;
        // right: -146px;
        // transform: rotate(-8deg);

        //   @include media-breakpoint-down(md) {
        //     height: 50px;
        //     bottom: 126px;
        //   }
      }
    }

    .banner-content {
      width: 900px;
      text-align: center;
      margin: 0 auto;
      padding-top: 20px;
      padding-bottom: 47px;
      position: relative;

      @include media-breakpoint-down(md) {
        padding-top: 0;
        padding-left: 27px;
        padding-right: 27px;
      }

      @include media-breakpoint-down(md) {
        width: 100%;
      }

      h1 {
        color: $white;
        font-family: $font-family-base;
        font-size: 70px;
        font-weight: 700;
        margin: 0 0 10px;
        letter-spacing: 2px;

        @include media-breakpoint-down(md) {
          font-size: 36px;
        }
      }

      p {
        color: $white;
        font-family: $font-family-dada-grotesk;
        font-size: 20px;
        font-weight: 500;
        margin: 0 0 30px;
        line-height: 24px;

        @include media-breakpoint-down(md) {
          font-size: 16px;
        }
      }

      .getStarted {
        width: 180px;
        height: 50px;
        border-radius: 50px;
        background-color: $success;
        font-family: $font-family-dada-grotesk;
        font-size: 16px;
        color: $white;
        text-align: center;
        box-shadow: none;
        border: 1px solid $success;
        transition: all 0.5s;
        font-weight: $font-weight-medium;

        &:hover {
          background-color: $hover-button;
          box-shadow: 0 2px 8px rgb(0 0 0 / 15%);
          color: $white;
          outline: none;
          border: 1px solid $hover-button;
        }
      }
    }

    .bannerSliderWrapper {
      width: 850px;
      margin: 0 auto;
      position: relative;
      z-index: 99;

      @include media-breakpoint-down(md) {
        width: calc(100% - 40px);
        padding-right: 0;
        padding-left: 0;
      }

      @include media-breakpoint-only(md) {
        width: 640px;
      }

      .each-slider {
        border-radius: 10px;
        padding: 20px;

        img {
          width: 100%;
          height: auto;
          border-radius: 10px;
          box-shadow: 0 0 17px rgb(0 0 0 / 50%);
        }
      }

      .slick-arrow {
        position: absolute;
        top: 45%;
        transform: translateY(-50%);
        display: block !important;
        width: 50px;
        height: 50px;
        // background: rgb(44 62 80 / 70%);
        border: 2px solid $success;
        border-radius: 50%;
        transition: all 0.5s;

        @include media-breakpoint-down(md) {
          width: 32px;
          height: 32px;
        }

        &:hover {
           background-color: $hover-button;
             border: 1px solid $hover-button;
          transition: all 0.5s;
        }

        &.slick-prev {
          left: -80px;

          @include media-breakpoint-down(md) {
            left: 5px;
            z-index: 99;
          }

          @include media-breakpoint-only(md) {
            left: -13px;
            z-index: 99;
          }

          &::before {
            font-family: Hmmngbrd;
            content: '\f0b5';
            color: $white;
            font-size: 20px;
            position: absolute;
            top: 50%;
            transform: translate(-50%, -50%);
            left: 50%;
            opacity: 1;
            transition: all 0.5s;

            @include media-breakpoint-down(md) {
              font-size: 13px;
            }
          }
        }

        &.slick-next {
          right: -80px;

          @include media-breakpoint-down(md) {
            right: 5px;
            z-index: 99;
          }

          @include media-breakpoint-only(md) {
            right: -13px;
            z-index: 99;
          }

          &::before {
            font-family: Hmmngbrd;
            content: '\f0b6';
            color: $white;
            font-size: 20px;
            position: absolute;
            top: 50%;
            transform: translate(-50%, -50%);
            left: 50%;
            opacity: 1;

            @include media-breakpoint-down(md) {
              font-size: 13px;
            }
          }
        }
      }

      .slick-dots {
        position: static;
        padding-top: 20px;

        li {
          width: auto;
          height: auto;
          margin: 0 5px !important;

          &.slick-active,
          &:hover {
            button {
              height: 8px;
              width: 24px;
              border-radius: 4px;
              transition: width 0.5s;
              background-color: $success !important;
              opacity: 1;

              &::before {
                content: none;
              }
            }
          }

          button {
            height: 8px;
            width: 8px;
            border-radius: 4px;
            transition: width 0.5s;
            background-color: #a1a1a1 !important;
            opacity: 1;

            &::before {
              content: none;
            }
          }
        }
      }
    }
  }

  #GetStartedIntro {
    background-color: $bg;
    padding: 112px 0 130px;

    @include media-breakpoint-down(md) {
      padding: 60px 0;
    }

    @include media-breakpoint-down(md) {
      padding: 80px 0;
    }

    .gSinner-holder {
      padding: 0 150px 130px;
      display: flex;
      flex-flow: row wrap;
      justify-content: center;
      align-items: flex-start;
      align-content: center;
      gap: 60px;

      @include media-breakpoint-down(md) {
        padding-left: 12px;
        padding-right: 12px;
        padding-bottom: 60px;
      }

      @include media-breakpoint-only(md) {
        padding: 0 60px 60px;
      }

      @include media-breakpoint-only(lg) {
        padding-left: 40px;
        padding-right: 40px;
      }

      .lhs-section {
        width: 400px;
        position: relative;
        top: 90px;

        @include media-breakpoint-down(md) {
          width: 100%;
          top: 0;
        }

        @include media-breakpoint-only(md) {
          width: 100%;
          top: 0;
        }

        &::before {
          content: '';
          background: url('https://butterflyv2-production-assets.nyc3.digitaloceanspaces.com/image/bdf80a6b-70de-4a18-8cc7-4ea831af7989-logo-bg.svg')
            no-repeat 0 0 / cover;
          position: absolute;
          width: 150px;
          height: 150px;
          opacity: 0.03;
          top: -100px;
          left: -77px;

          @include media-breakpoint-down(md) {
            display: none;
          }

          @include media-breakpoint-only(md) {
            display: none;
          }
        }

        h2 {
          color: $title;
          font-size: 48px;
          letter-spacing: 2px;
          font-family: $font-family-base;
          font-weight: $font-weight-medium;
          margin: 0 0 16px;
          line-height: 1.2;

          @include media-breakpoint-down(md) {
            font-size: 30px;
          }

          @include media-breakpoint-only(md) {
            font-size: 30px;
          }
        }

        p {
          color: $title;
          font-size: 20px;
          font-family: $font-family-dada-grotesk;
          font-weight: 400;
          margin: 0 0 24px;
          line-height: 24px;
        }

        .getStarted {
          width: 180px;
          height: 50px;
          border-radius: 50px;
          background-color: $success;
          font-family: $font-family-dada-grotesk;
          font-size: 16px;
          color: $white;
          text-align: center;
          box-shadow: none;
          border: 1px solid $success;
          transition: all 0.5s;
          font-weight: $font-weight-medium;

          &:hover {
            background-color: $hover-button;
            border: 1px solid $hover-button;
            box-shadow: 0 2px 8px rgb(0 0 0 / 15%);
            color: $white;
            outline: none;
          }
        }
      }

      .rhs-section {
        width: calc(100% - 400px - 60px);

        @include media-breakpoint-down(md) {
          width: 100%;
        }

        @include media-breakpoint-only(md) {
          width: 100%;
        }

        .inner-wrapper {
          .each-card {
            box-shadow: 0 0 6px rgb(0 0 0 / 10%);
            border-radius: 5px;
            background: rgb(255 255 255 / 40%);
            padding: 18px 30px;
            display: flex;
            justify-content: center;
            align-items: center;
            align-content: center;
            gap: 36px;
            margin-bottom: 16px;

            .icon {
              i {
                color: $success;
                font-size: 30px;
              }
            }

            h3 {
              color: $title;
              font-family: $font-family-base;
              font-size: 24px;
              font-weight: $font-weight-semibold;
              margin: 0 0 5px;
              letter-spacing: 2px;

              @include media-breakpoint-down(md) {
                font-size: 20px;
              }
            }

            p {
              color: $title;
              font-family: $font-family-dada-grotesk;
              font-size: 16px;
              font-weight: 400;
              margin: 0;

              @include media-breakpoint-down(md) {
                font-size: 14px;
              }
            }
          }
        }
      }
    }

    .pAlbum-holder {
      padding: 0 150px;
      display: flex;
      flex-flow: row wrap;
      justify-content: center;
      align-items: center;
      align-content: center;
      gap: 60px;

      @include media-breakpoint-down(md) {
        padding-right: 27px;
        padding-left: 27px;
      }

      @include media-breakpoint-only(md) {
        padding: 0 60px;
      }

      @include media-breakpoint-only(lg) {
        padding-left: 40px;
        padding-right: 40px;
      }

      .lhs-section {
        width: calc(100% - 300px - 60px);

        @include media-breakpoint-down(md) {
          width: 100%;
          order: 2;
        }

        @include media-breakpoint-only(md) {
          width: 100%;
          order: 2;
        }

        img {
          width: 100%;
          height: auto;
        }
      }

      .rhs-section {
        width: 300px;
        position: relative;

        @include media-breakpoint-down(md) {
          width: 100%;
          order: 1;
        }

        @include media-breakpoint-only(md) {
          width: 100%;
          order: 1;
        }

        &::before {
          content: '';
          background: url('https://butterflyv2-production-assets.nyc3.digitaloceanspaces.com/image/bdf80a6b-70de-4a18-8cc7-4ea831af7989-logo-bg.svg')
            no-repeat 0 0 / cover;
          position: absolute;
          width: 150px;
          height: 150px;
          opacity: 0.03;
          top: -100px;
          right: -58px;

          @include media-breakpoint-down(md) {
            display: none;
          }

          @include media-breakpoint-only(md) {
            display: none;
          }
        }

        h3 {
          color: $title;
          font-family: $font-family-base;
          font-size: 48px;
          font-weight: $font-weight-medium;
          margin: 0 0 5px;

          @include media-breakpoint-down(md) {
            font-size: 30px;
          }
        }

        p {
          color: $title;
          font-family: $font-family-dada-grotesk;
          font-size: 20px;
          font-weight: 400;
          margin: 0;

          @include media-breakpoint-down(md) {
            font-size: 16px;
          }
        }
      }
    }
  }

  #PreFooter {
    background-color: $bg;

    .content {
      width: 700px;
      text-align: center;
      margin: 0 auto;
      padding-top: 20px;
      padding-bottom: 47px;
      position: relative;

      @include media-breakpoint-down(md) {
        padding-top: 0;
        padding-left: 27px;
        padding-right: 27px;
      }

      @include media-breakpoint-down(md) {
        width: 100%;
      }

      h1 {
        color: $white;
        font-family: $font-family-base;
        font-size: 44px;
        font-weight: 700;
        margin: 0 0 10px;

        @include media-breakpoint-down(md) {
          font-size: 36px;
        }
      }

      h3 {
        color: $title;
        font-family: $font-family-base;
        font-size: 48px;
        font-weight: $font-weight-medium;
        margin: 0 0 40px;

        @include media-breakpoint-down(md) {
          font-size: 30px;
        }
      }

      p {
        color: $white;
        font-family: $font-family-base;
        font-size: 18px;
        font-weight: 500;
        margin: 0 0 30px;
        line-height: 24px;

        @include media-breakpoint-down(md) {
          font-size: 16px;
        }
      }

      .getStarted {
        width: 180px;
        height: 50px;
        border-radius: 50px;
        background-color: $success;
        font-family: $font-family-dada-grotesk;
        font-size: 16px;
        color: $white;
        text-align: center;
        box-shadow: none;
        border: 1px solid $success;
        transition: all 0.5s;
        font-weight: $font-weight-medium;

        &:hover {
          background-color: $hover-button;
          border: 1px solid $hover-button;
          box-shadow: 0 2px 8px rgb(0 0 0 / 15%);
          color: $white;
          outline: none;
        }
      }
    }
  }
  #ContantUs {
    background-color: $white;
    padding: 101px 0;
    text-align: center;
    position: relative;

    .contactus-message {
      font-size: 18px;
      min-height: 292px;

      @include media-breakpoint-down(md) {
        min-height: auto;
      }

      @include media-breakpoint-only(md) {
        min-height: auto;
      }

      @include media-breakpoint-only(lg) {
        min-height: auto;
      }
    }

    @include media-breakpoint-down(md) {
      padding-right: 13px;
      padding-left: 13px;
    }

    &::before {
      content: '';
      background: url('https://butterflyv2-production-assets.nyc3.digitaloceanspaces.com/image/bdf80a6b-70de-4a18-8cc7-4ea831af7989-logo-bg.svg')
        no-repeat center center / cover;
      width: 530px;
      height: 530px;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      opacity: 0.03;
      top: 40px;

      @include media-breakpoint-down(md) {
        width: 280px;
        height: 280px;
      }

      @include media-breakpoint-only(md) {
        width: 320px;
        height: 320px;
      }
    }

    h3 {
      color: $title;
      font-family: $font-family-base;
      font-size: 36px;
      font-weight: $font-weight-semibold;
      margin: 0 0 40px;

      @include media-breakpoint-down(md) {
        font-size: 30px;
      }
    }

    p {
      color: $title;
      font-size: 18px;
      font-family: 400;
      margin: 0 0 48px;

      @include media-breakpoint-down(md) {
        font-size: 16px;
      }
    }

    form {
      width: 500px;
      margin: 0 auto;
      text-align: left;

      @include media-breakpoint-down(md) {
        width: 100%;
        padding-right: 13px;
        padding-left: 13px;
      }

      label {
        color: $title;
        font-family: $font-family-base;
        font-size: 14px;
        font-weight: 400;
        margin-bottom: 4px;
        text-align: left;
      }

      input {
        color: $title;
        border: 1px solid $grey-1;
        padding: 5px 10px;
        font-family: $font-family-base;
        border-radius: 6px;
        font-size: 14px;
        text-align: left;
        width: 100%;
      }

      textarea {
        color: $title;
        border: 1px solid $grey-1;
        padding: 5px 10px;
        font-family: $font-family-base;
        border-radius: 6px;
        font-size: 14px;
        text-align: left;
        width: 100%;

        &:focus,
        &:hover {
          box-shadow: none;
          outline: none;
        }
      }

      .sendMessage {
        width: 180px;
        height: 50px;
        border-radius: 50px;
        background-color: $success;
        font-family: $font-family-base;
        font-size: 16px;
        color: $white;
        text-align: center;
        box-shadow: none;
        transition: all 0.5s;
        font-weight: 500;
        border: 0;
        margin: 52px auto 0;
        display: block;

        &:hover {
          background-color: $title;
          box-shadow: 0 2px 8px rgb(0 0 0 / 15%);
          color: $white;
          outline: none;
          border: 1px solid $success;
        }

        &:disabled {
          background-color: $disabled-button;
          border: none;
          outline: none;
          cursor: none;
          pointer-events: none;
          color: $title;

          &:hover,
          &:focus {
            background-color: $disabled-button;
            border: none;
            outline: none;
            box-shadow: none;
          }

          i {
            color: $title;
          }
        }
      }
    }
  }

  .Footer {
    background: $title;
    padding: 40px 0;

    ul {
      text-align: center;
      margin: 0;
      padding: 0;

      li {
        margin: 0;
        padding: 0;
        display: inline-block;
        color: $white;
        font: {
          size: 12px;
          family: $font-family-dada-grotesk;
          weight: $font-weight-regular;
        }
        position: relative;
        margin-right: 30px;

        &::after {
          content: '|';
          position: absolute;
          right: -16px;
        }

        @include media-breakpoint-down(md) {
          &:nth-child(2) {
            margin-right: 0;

            &::after {
              content: none;
            }
          }
        }

        &:last-child {
          margin-right: 0;

          &::after {
            content: none;
          }

          @include media-breakpoint-down(md) {
            display: block;
            margin-top: 10px;
          }
        }

        a {
          color: $white;
          text-decoration: underline;

          &:hover {
            color: $success;
          }
        }
      }
    }
  }
  .hidden-link {
    font-family: $font-family-base;
    font-size: 8px;
    color: rgb(44 62 80 / 70%);
    // background-color: rgb(44 62 80 / 70%);
  }
}
