@import 'bootstrap/scss/bootstrap';
@import 'bootstrap/scss/mixins';
@import '../../Assets/Styles/typography';
@import '../../Assets/Styles/colors';
@import '../../Assets/Styles/mixins';

.panel.EditPage {
  max-width: 730px;

  .EditTab {
    &.nav.nav-tabs {
      padding: 0 10px;
      border: none;
      overflow-y: hidden;
      display: flex;
      flex-flow: row nowrap;
      scrollbar-width: none;

      li {
        &.nav-item {
          button {
            cursor: pointer;

            &.nav-link {
              font: {
                size: 16px;
                family: $font-family-dada-grotesk;
                weight: $font-weight-medium;
              }

              color: #8c8c8c;
              border: none;
              padding: 5px;

              &.active {
                border-bottom: 4px solid $hover-button;
                color: $hover-button;
                font-weight: $font-weight-medium;
              }

              &:hover {
                color: $hover-button;
              }
            }
          }
        }

        margin-right: 35px;
      }

      &::-webkit-scrollbar {
        height: 0;
        background-color: transparent;
      }
    }
  }

  .tab-content {
    display: flex;
    width: 100%;
    max-height: 644px;
    height: 100%;

    @include media-breakpoint-down(md) {
      min-height: unset;
    }

    .tab-pane {
      width: 100%;

      @include media-breakpoint-down(md) {
        padding-bottom: 90px;
        height: fit-content;
      }

      .m-logo {
        margin: 43px auto 0;
        text-align: center;
        display: none;

        img {
          max-width: 152px;
        }

        .title {
          font: {
            size: 20px;
            family: $font-family-base;
            weight: $font-weight-semibold;
          }
        }
      }

      .formHolder {
        width: 100%;

        .steps {
          label {
            font: {
              size: 14px;
              family: $font-family-base;
              weight: $font-weight-regular;
            }

            color: $title;
            margin-bottom: 5px;
          }

          input {
            height: 40px;
            padding: 0 0 0 10px;
            border: 1px solid $grey-1;
            border-radius: 10px;
            background-color: $white;
            background-size: cover;
            outline: none;
            font: {
              size: 16px;
              family: $font-family-base;
              weight: $font-weight-regular;
            }

            color: $title;
            text-decoration: none solid rgb(44 62 80);
          }

          textarea {
            height: 87px;
            padding: 7px;
            border: 1px solid $grey-1;
            border-radius: 10px;
            background-color: $white;
            background-size: cover;
            resize: none;
            outline: none;
            font: {
              size: 16px;
              family: $font-family-base;
              weight: $font-weight-regular;
            }

            color: $title;
            text-decoration: none solid rgb(44 62 80);

            &:focus,
            &:focus-visible {
              outline: none;
              box-shadow: none;
            }
          }

          span.textcount {
            color: #777;
            font: {
              size: 12px;
              family: $font-family-base;
              weight: $font-weight-medium;
            }
          }

          @include media-breakpoint-down(md) {
            .mobile-alignment {
              text-align: center !important;
              width: 100%;
              justify-content: center;
              margin: 0;
            }
          }

          &.stepOne {
            padding: 35px 30px 20px;

            @include media-breakpoint-down(md) {
              padding: 30px 0 20px;
            }

            .ImgCroper {
              width: fit-content;
              border: 1px dashed #777;
              text-align: center;
              display: flex;
              align-items: center;
              justify-content: center;
              cursor: pointer;
              position: relative;

              &:has(img) {
                border: none;
              }

              span {
                i {
                  color: $success;
                  display: block;
                  font-size: 34px;
                  margin-bottom: 10px;
                }

                color: $title;
                display: block;
                font: {
                  size: 12px;
                  family: $font-family-base;
                  weight: $font-weight-regular;
                }

                &.NoImgWrap {
                  padding: 40px 57px;
                }

                &.cropImg {
                  border: 1px solid $bg;
                  padding: 8px;
                  border-radius: 50%;
                  width: 40px;
                  height: 40px;
                  position: absolute;
                  background-color: $white;
                  bottom: 5px;
                  right: 5px;

                  i {
                    color: $success;
                    font-size: 20px;
                  }
                }
              }

              .ImgWrapper {
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                position: relative;
                border-radius: 5px;
                overflow: hidden;

                img {
                  height: 143px;
                }
              }

              &:hover {
                span i {
                  &.noImg {
                    color: $title;
                  }

                  color: $success;
                }

                span.cropImg {
                  i {
                    color: $title;
                  }
                }
              }

              @include media-breakpoint-down(md) {
                p.text.text-danger.errorMessage {
                  position: absolute;
                  bottom: -25px;
                  font-size: 14px;
                  width: 320px;
                  left: -4px;
                }
              }
            }

            form {
              label {
                font: {
                  size: 14px;
                  family: $font-family-base;
                  weight: $font-weight-regular;
                }

                color: $title;
                margin-bottom: 5px;
              }

              input {
                height: 40px;
                padding: 0 0 0 10px;
                border: 1px solid $grey-1;
                border-radius: 10px;
                background-color: $white;
                background-size: cover;
                font: {
                  size: 14px;
                  family: $font-family-base;
                  weight: $font-weight-regular;
                }

                color: $title;
                text-decoration: none solid rgb(44 62 80);
              }

              textarea {
                height: 80px;
                padding: 10px 5px 5px 10px;
                border: 1px solid $grey-1;
                border-radius: 10px;
                background-color: $white;
                background-size: cover;
                resize: none;
                font: {
                  size: 14px;
                  family: $font-family-base;
                  weight: $font-weight-regular;
                }

                color: $title;
                text-decoration: none solid rgb(44 62 80);

                &:focus,
                &:hover {
                  box-shadow: none;
                  outline: none;
                }
              }

              span.textcount {
                color: #777;
                font: {
                  size: 12px;
                  family: $font-family-base;
                  weight: $font-weight-medium;
                }
              }

              @include media-breakpoint-down(md) {
                .mobile-alignment {
                  text-align: center !important;
                  width: 100%;
                  justify-content: center;
                  margin: 0;
                }
              }
            }
          }

          &.stepUrl {
            padding: 35px 30px 20px;

            @include media-breakpoint-down(md) {
              padding: 30px 0 20px;
            }

            h3 {
              color: $title;
              font: {
                size: 24px;
                family: $font-family-base;
                weight: $font-weight-semibold;
              }

              @include media-breakpoint-down(md) {
                text-align: center;
              }
            }

            p {
              color: $title;
              word-wrap: break-word;
              font: {
                size: 16px;
                family: $font-family-base;
                weight: $font-weight-regular;
              }

              margin-bottom: 10px;
            }

            span.url {
              color: $success;
              margin-bottom: 30px;
              display: block;
              word-wrap: break-word;

              @include media-breakpoint-down(md) {
                text-align: center;
              }
              font: {
                size: 16px;
                family: $font-family-base;
                weight: $font-weight-regular;
              }
            }

            input {
              border: none;
              border-bottom: 1px solid #000;
              padding-bottom: 10px;
              color: #121212;
              outline: none;
              border-radius: 0;
              padding-left: 0;

              &::placeholder {
                color: #121212;
              }

              @include media-breakpoint-down(md) {
                text-align: left;
                padding-left: 0;
                margin: auto;
                display: block;
              }
            }

            .mobile-alignment {
              margin-top: 60px;
            }
          }

          &.stepTwo {
            padding: 35px 30px 20px;

            @include media-breakpoint-down(md) {
              padding: 30px 0 20px;
            }

            .privacy {
              padding: 0;

              @include media-breakpoint-down(md) {
                padding: 36px 0 0;
              }

              .card {
                flex-wrap: nowrap;
                width: 100%;
                border: none;
                margin-bottom: 46px;

                .card-body {
                  align-items: center;

                  @include media-breakpoint-down(md) {
                    align-items: flex-start;
                  }

                  .lhs {
                    flex: 0 1 calc(100% - 75px);
                    max-width: calc(100% - 75px);
                    padding-right: 10px;

                    .title {
                      font: {
                        size: 16px;
                        family: $font-family-base;
                        weight: $font-weight-medium;
                      }

                      color: $title;
                      padding-bottom: 3px;
                      display: flex;
                      align-items: center;

                      i {
                        color: $success;
                        font-size: 24px;
                        font-weight: 100;
                        padding-right: 8px;
                      }

                      @include media-breakpoint-down(md) {
                        align-items: flex-start;
                      }
                    }

                    .subTitle {
                      color: #777;
                      font: {
                        size: 15px;
                        family: $font-family-base;
                        weight: $font-weight-regular;
                      }

                      margin-bottom: 0;
                      text-align: left;
                    }
                  }

                  .rhs {
                    flex: 0 1 75px;
                    max-width: 75px;
                  }
                }
              }
            }

            .justify-content-between {
              @include media-breakpoint-down(md) {
                justify-content: center !important;
              }
            }
          }

          &.stepThree {
            .justify-content-between {
              @include media-breakpoint-down(md) {
                justify-content: center !important;
              }
            }

            .privacy {
              margin-bottom: 125px;

              @include media-breakpoint-down(md) {
                margin-bottom: 60px;
              }
            }
          }

          h2 {
            font: {
              size: 24px;
              family: $font-family-base;
              weight: $font-weight-semibold;
            }

            color: $title;
            margin-bottom: 0;

            @include media-breakpoint-down(md) {
              text-align: center;
              font-size: 20px;
            }

            word-wrap: break-word;
          }

          p {
            font: {
              size: 16px;
              family: $font-family-base;
              weight: $font-weight-regular;
            }

            word-wrap: break-word;
            line-height: 1.6;
            color: $title;
            margin-bottom: 47px;

            span {
              color: $success;
              display: block;
            }

            @include media-breakpoint-down(md) {
              text-align: center;
            }

            &.errorMessage {
              margin-bottom: 0;
              position: absolute;
              right: -190px;
              width: 180px;
            }
          }

          button {
            @include button-success;

            min-width: 120px;
            width: auto;

            .next {
            }

            &.prev {
              border: 1px solid $grey-1;
              border-radius: 50px;
              background-color: $white;
              background-size: cover;
              font-family: Poppins;
              font-size: 16px;
              color: #777;
              text-decoration: none solid rgb(119 119 119);
              text-align: center;

              &:hover,
              &:focus,
              &:active {
                background-color: $title !important;
                color: $white !important;
              }
            }
          }

          .switch input {
            display: none;
          }

          .switch {
            display: inline-block;
            width: 75px; /* =w */
            height: 40px; /* =h */
            margin: 4px;
            position: relative;
          }

          .slider {
            position: absolute;
            inset: 0;
            border-radius: 30px;
            box-shadow:
              0 0 0 1px $grey-1,
              0 0 0 $grey-1;
            cursor: pointer;
            border: 4px solid transparent;
            overflow: hidden;
            transition: 0.2s;
          }

          .slider::before {
            position: absolute;
            content: '';
            width: 100%;
            height: 100%;
            background-color: $grey-1;
            border-radius: 30px;
            transform: translateX(-30px); /* translateX(-(w-h)) */
            transition: 0.2s;
          }

          input:checked + .slider::before {
            transform: translateX(35px); /* translateX(w-h) */
            background-color: $success;
          }

          input:checked + .slider {
            box-shadow:
              0 0 0 2px $success,
              0 0 8px $success;
          }

          .switch200 .slider::before {
            width: 200%;
            transform: translateX(-102px); /* translateX(-(w-h)) */
          }

          .switch200 input:checked + .slider::before {
            background-color: $success;
          }

          .switch200 input:checked + .slider {
            box-shadow:
              0 0 0 1px #6f7072,
              0 0 0 #6f7072;
          }
        }

        button {
          // &:disabled{
          //     display: none;
          // }
        }

        .RSPBprogressBar {
          height: 0;
          line-height: 1;
          border: 1px dashed rgb(211 211 211 / 60%);
          border-radius: 10px;
          position: relative;
          background-color: transparent;
          width: 100px;

          @include media-breakpoint-down(md) {
            margin: auto;
            margin-top: 30px;
          }

          .RSPBprogression {
            border: none;
            background: transparent;
          }

          .RSPBstep {
            .steps {
              width: 12px;
              height: 12px;
              background-color: $bg;
              border-radius: 50%;

              &.completed {
                background-color: $success;
              }
            }
          }
        }

        .CopyRight {
          font: {
            size: 14px;
            family: $font-family-base;
            weight: $font-weight-regular;
          }

          color: #777;
          margin-top: 66px;
        }
      }
    }
  }
}
