@import 'bootstrap/scss/bootstrap';
@import 'bootstrap/scss/mixins';
@import '../../Assets/Styles/typography';
@import '../../Assets/Styles/colors';
@import '../../Assets/Styles/mixins';

.QrCode {
  display: none;

  @include media-breakpoint-down(xl) {
    display: flex;
    text-align: center;
    vertical-align: middle;
    width: 100%;
    max-width: 320px;
    background-color: $white;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    padding: 15px;
    height: 100%;
    margin: auto;

    .upload {
      text-align: center;

      .logo {
        text-align: center;

        h4 {
          width: 100%;
          font: {
            size: 20px;
            family: $font-family-base;
            weight: $font-weight-semibold;
          }

          color: $title;
        }

        img {
          width: 152px;
          display: block;
          text-align: center;
          margin: auto;
        }
      }

      .ImgHolder {
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        max-height: 300px;
        overflow: auto;

        .EachImage {
          max-width: calc(50% - 10px);
          flex: 0 1 calc(50% - 10px);
          border-radius: 8px;
          position: relative;
          margin: 5px;
          height: calc(80vw / 3);
          max-height: 131px;
          overflow: hidden;
          border: 1px solid silver;

          .delete {
            position: absolute;
            top: 3px;
            right: 3px;
            background-color: $title;
            border-radius: 50%;
            width: 24px;
            height: 24px;

            i {
              color: $white;
              font-size: 14px;
            }
          }

          img {
            max-width: 100%;
            border-radius: 5px;
            width: auto;
            height: 100%;
            object-fit: contain;
          }

          .video-media {
            background-position: 50%;
            background-repeat: no-repeat;
            background-size: cover;
            font-size: 26px;
            align-items: center;
            background-color: #1d817b;
            color: $white;
            display: flex;
            height: 100%;
            justify-content: center;
            width: 100%;
          }
        }
      }

      .textfield {
        border: 1px dashed #8c8c8c;
        border-radius: 10px;
        padding: 10px;
        margin-top: 40px;

        label {
          cursor: pointer;

          input {
            display: none;
          }

          span {
            color: $success;
            display: block;
            padding-top: 15px;
            font: {
              size: 12px;
              family: $font-family-base;
              weight: $font-weight-regular;
            }

            i {
              font-size: 44px;
              color: $title;
              display: block;
              margin-bottom: 10px;
            }
          }
        }
      }
    }
  }

  .uploadBtn {
    @include button-success;

    height: 40px;
    margin-top: 1em;
  }
}
