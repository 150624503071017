@import 'bootstrap/scss/bootstrap';
@import 'bootstrap/scss/mixins';
@import '../../Assets/Styles/typography';
@import '../../Assets/Styles/colors';
@import '../../Assets/Styles/mixins';

.CenterSection {
  flex: 0 1 calc(100% - 701px);
  max-width: calc(100% - 701px);
  padding: 9px 13px;
  border-right: 1px solid rgb(119 119 119 / 30%);
  border-left: 1px solid rgb(119 119 119 / 30%);
  position: relative;

  @include media-breakpoint-between(lg, xl) {
    flex: 0 1 calc(100% - 325px);
    max-width: calc(100% - 325px);
  }

  @include media-breakpoint-between(md, xl) {
    flex: 0 1 60%;
    max-width: 60%;
    overflow-y: scroll;
    max-height: 100vh;
    -webkit-overflow-scrolling: touch;
  }

  .EmptyDiv {
    display: none;
  }

  @include media-breakpoint-down(xl) {
    .EmptyDiv {
      display: block;
      height: 120px;
    }
  }

  @include media-breakpoint-down(md) {
    flex: 0 1 100%;
    max-width: 100%;
    padding: 80px 15px 0;
    max-height: unset;
    border: none;
  }

  .CreatePostBtn {
    height: 52px;
    width: 100%;
    padding: 6px;
    background-color: $white;
    border-radius: 5px;
    border: none;
    text-align: left;
    margin-bottom: 15px;
    cursor: pointer;

    .form-group {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .ProfilePic {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        overflow: hidden;

        img {
          width: 100%;
          border: 1px solid #c1c1c1;
        }

        flex: 0 1 40px;
        max-width: 40px;
        position: relative;
        background-color: #1d817b2b;
      color: #1d817b;

        span {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          font: {
            size: 20px;
            family: $font-family-dada-grotesk;
            weight: $font-weight-medium;
          }
        }
      }

      textarea,
      input {
        border: none;
        padding: 8px 10px;
        height: 40px;
        flex: 0 1 calc(100% - 140px);
        max-width: calc(100% - 140px);
        resize: none;
        cursor: pointer;

        &:focus,
        &:focus-visible {
          border: none;
          outline: none;
        }
      }

      .IconsList {
        flex: 0 1 100px;
        max-width: 100px;

        i {
          color: $success;
          margin-right: 13px;
          font-size: 20px;
          cursor: pointer;

          &:hover {
            color: $hover-button;
          }
        }
      }
    }

    &.PageMemberOnboarding-highlight {
      position: relative;
      z-index: 99999;
      background: white;
      pointer-events: none;

      &:hover {
        border: 1px solid $hover-button;
        background-color: $hover-button;
        color: $white;
      }
    }
  }

  .EmptyPosts {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 264px;
    text-align: center;
    font: {
      size: 14px;
      family: $font-family-dada-grotesk;
      weight: $font-weight-regular;
    }

    color: #777;

    span {
      color: #333;
      font: {
        size: 20px;
        family: $font-family-dada-grotesk;
        weight: $font-weight-medium;
      }

      display: block;
    }

    @include media-breakpoint-between(md, xl) {
      position: unset;
      transform: unset;
      max-width: 264px;
      text-align: center;
      font-size: 14px;
      font-family: Poppins, sans-serif;
      font-weight: 400;
      color: #777;
      margin: auto;
      padding-top: 100px;
    }

    @include media-breakpoint-down(md) {
      position: unset;
      transform: unset;
      margin: auto;
      height: 250px;
      padding-bottom: 50px;
      padding-top: 50px;
    }

    i {
      font-size: 30px;
      color: #000;
    }
  }

  .CreatePostFixed {
    position: fixed;
    z-index: 9;
    left: 50%;
    transform: translateX(-50%);
    bottom: 100px;
    width: 160px;
    height: 50px;
    border: 2px solid $white;
    border-radius: 50px;
    background-color: $success;
    background-size: cover;
    font-family: $font-family-base;
    font-weight: $font-weight-medium;
    font-size: 16px;
    color: $white;
    text-decoration: none solid rgb(255 255 255);
    text-align: center;
    box-shadow: 0 1px 20px rgb(0 0 0 / 20%);

    @include media-breakpoint-up(md) {
      display: none;
    }

    i {
      margin-right: 3px;
    }
  }

  .PageCard {
    margin-bottom: 15px;

    @include media-breakpoint-up(xl) {
      display: none;
    }
  }

  .post-inline-loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 50px;
    text-align: center;
  }
}
