@import 'bootstrap/scss/bootstrap';
@import 'bootstrap/scss/mixins';
@import '../../../Assets/Styles/typography';
@import '../../../Assets/Styles/colors';
@import '../../../Assets/Styles/mixins';

.CreatePost {
  -webkit-overflow-scrolling: touch;
  overflow: auto;

  @include media-breakpoint-down(xl) {
    z-index: 999999 !important;
    position: fixed !important;
    top: 0 !important;
    bottom: 0;
  }

  .modal-dialog {
    max-width: 550px;
    width: 100%;

    @include media-breakpoint-down(md) {
      margin: 0;
      align-items: flex-start !important;
    }

    .modal-content {
      padding: 9px 13px;
      border: none;

      @include media-breakpoint-down(md) {
        border-radius: 0;
        max-height: 100vh;
        height: 100vh;
        overflow: auto;
        padding-bottom: 75px;
      }

      .modal-header {
        padding: 9px 0;
        border-bottom: none;

        @include media-breakpoint-down(md) {
          border-bottom: none;
          width: calc(100% - 30px);
        }

        .modal-title {
          align-items: center;
          font: {
            size: 18px;
            family: $font-family-base;
            weight: $font-weight-semibold;
          }

          color: $title;
          word-break: break-word;

          .owner {
            margin-right: 6px;

            @include ProfilePic;
          }
        }

        button.btn-close {
          color: $text-primary;
          opacity: 1;
          padding: 0;
          background-size: 14px;
          padding-right: 8px;
          background: none;
          font-family: Hmmngbrd;
          display: inline-block;
          font-size: 24px;
          font-weight: normal;
          font-style: normal;
          line-height: 1;
          text-rendering: auto;

          &::before {
            content: '\f06c';
          }

          &:focus,
          &:focus-visible {
            outline: none;
            border: none;
            box-shadow: none;
          }

          @include media-breakpoint-down(md) {
            position: absolute;
            right: 9px;
            top: 20px;
          }

          &:hover {
            color: $success;
          }
        }
      }

      .modal-body {
        padding: 10px 0 0;
        position: relative;

        .MessagePost {
          textarea {
            border: none;
            padding: 0;
            resize: none;
            width: 100%;
            height: 220px;

            @include media-breakpoint-down(md) {
              border: 1px solid $grey-1;
              border-radius: 5px;
              padding: 10px;
            }

            &:focus,
            &:focus-visible {
              border: none;
              outline: none;
            }

            color: $title;
            font: {
              size: 16px;
              family: $font-family-dada-grotesk;
              weight: $font-weight-regular;
            }
          }

          .Emojis {
            position: absolute;
            bottom: 0;
            left: 30px;

            @include media-breakpoint-down(md) {
              left: 0;
              right: unset;
              bottom: unset;
              top: 0;
            }

            p {
              position: absolute;
              z-index: 9;
              right: 10px;
              cursor: pointer;
              margin-bottom: 0;
              top: 5px;
            }

            aside {
              height: 400px !important;
            }
          }
        }

        .ImagePost,
        .VideoPost,
        .AudioPost {
          i.fa-butterflysmiley {
            top: 10px;
            position: absolute;
            cursor: pointer;

            @include media-breakpoint-down(md) {
              display: none;
            }
          }

          textarea {
            width: calc(100% - 20px);
            resize: none;
            border: none;
            height: 70px;
            color: $title;
            padding-right: 20px;

            @include media-breakpoint-down(md) {
              border: 1px solid $grey-1;
              border-radius: 5px;
              padding: 10px;
              width: 100%;
              height: 100px;
              margin-bottom: 10px;
            }

            &:focus {
              border: none;
              box-shadow: none;
              outline: none;
            }

            &:placeholder {
              color: #777;
            }
          }

          .UploadSec {
            border-radius: 10px;
            border: 1px dashed $grey-1;
            padding: 28px;
            text-align: center;

            @include media-breakpoint-down(md) {
              padding: 28px 0;
            }

            span {
              font: {
                size: 14px;
                family: $font-family-base;
                weight: $font-weight-regular;
              }

              color: #777;
              margin-bottom: 15px;
              display: block;
            }

            .IconHolder {
              display: flex;
              justify-content: center;
              flex-wrap: nowrap;

              .EachIconHolder {
                flex: 95px;
                max-width: 95px;
                margin: 0 9px;

                &:last-child {
                  @include media-breakpoint-down(md) {
                    display: none;
                  }
                }

                i {
                  color: $success;
                  font-size: 30px;
                  cursor: pointer;
                }

                img {
                  width: 50px;
                  cursor: pointer;
                }

                span {
                  display: block;
                  margin-top: 8px;
                  font: {
                    size: 12px;
                    family: $font-family-dada-grotesk;
                    weight: $font-weight-regular;
                  }

                  color: $title;

                  sup {
                    font-size: 7px;
                  }
                }
              }
            }

            .RecSec {
              color: $title;
              font: {
                size: 14px;
                family: $font-family-base;
                weight: $font-weight-medium;
              }

              i {
                color: $success;
                font-size: 24px;
              }

              img {
                width: 25px;
                height: 25px;
              }
            }

            video {
              max-width: 100%;
              margin-bottom: 15px;
            }
          }

          .Emojis {
            position: absolute;
            bottom: 0;
            right: 0;

            @include media-breakpoint-down(md) {
              left: 0;
              right: unset;
              bottom: unset;
              top: 0;
            }

            p {
              position: absolute;
              z-index: 99;
              right: 10px;
              cursor: pointer;
              margin-bottom: 0;
              top: 5px;
            }

            aside {
              height: 400px !important;
              z-index: 9;
            }
          }

          .addCapt {
            padding: 10px 10px 10px 0;
            display: inline-flex;
            align-items: center;
            cursor: pointer;

            i {
              color: $success;
              font-size: 18px;
              padding-right: 5px;
            }
            font: {
              size: 14px;
              family: $font-family-base;
              weight: $font-weight-medium;
            }

            color: $title;

            .folder {
              i {
                font-size: 24px;
                padding-right: 5px;
              }

              display: inline-flex;
              align-items: center;
              margin-left: 15px;
            }

            .phone {
              i {
                font-size: 24px;
                padding-right: 5px;
              }

              display: inline-flex;
              align-items: center;
              margin-left: 5px;
            }

            .g-drive,
            .dropbox {
              i {
                font-size: 22px;
                padding-right: 5px;
              }

              display: inline-flex;
              align-items: center;
              margin-left: 15px;
            }

            span {
              img {
                width: 30px;
              }
            }
          }

          .SelectedImages {
            display: flex;
            flex-wrap: wrap;
            width: 100%;
            justify-content: flex-start;
            max-height: 312px;
            overflow-y: overlay;

            @include media-breakpoint-down(md) {
              max-height: unset;
            }

            .EachImageHolder {
              width: 150px;
              height: 150px;
              margin: 3px;
              position: relative;
              text-align: center;
              background: silver;
              border-radius: 10px;
              display: flex;
              align-items: center;
              justify-content: center;
              overflow: hidden;

              @include media-breakpoint-down(md) {
                width: calc(50% - 10px);
              }

              img {
                max-width: 100%;
                max-height: 100%;
                width: auto;
              }

              span {
                position: absolute;
                top: 10px;
                right: 10px;
                width: 28px;
                height: 28px;
                background-color: rgb(0 0 0 / 50%);
                text-align: center;
                display: inline-flex;
                align-items: center;
                justify-content: center;
                border-radius: 50%;
                cursor: pointer;

                i {
                  color: $white;
                }
              }

              .audio-media {
                background-color: #9381cd;
                width: 100%;
                height: 100%;
                font-size: 50px;
                align-items: center;
                color: $white;
                display: flex;
                justify-content: center;
              }

              .video-media {
                font-size: 40px;
                background-size: cover;
                background-position: center;
                background-repeat: no-repeat;
                background-color: rgb(0 0 0 / 10%) !important;
                width: 100%;
                height: 100%;
                align-items: center;
                color: $white;
                display: flex;
                justify-content: center;
              }
            }
          }

          .Caption {
            max-height: 312px;
            overflow-y: overlay;

            .Eachcaption {
              margin-bottom: 10px;
              display: flex;
              flex-wrap: wrap;
              width: 100%;
              justify-content: flex-start;

              .Images {
                flex: 0 1 100px;
                max-width: 100px;
                margin-right: 10px;
                width: 100px;
                height: 100px;
                text-align: center;
                background-color: silver;
                border-radius: 10px;
                overflow: hidden;

                img {
                  max-width: 100%;
                  height: 100%;
                  width: auto;
                  object-fit: contain;
                }

                .audio-media,
                .video-media {
                  background-color: #9381cd;
                  width: 100%;
                  height: 100%;
                  font-size: 30px;
                  align-items: center;
                  color: $white;
                  display: flex;
                  justify-content: center;
                }

                .video-media {
                  font-size: 40px;
                }
              }

              .TextField {
                flex: 0 1 calc(100% - 115px);
                max-width: calc(100% - 115px);
                border: 1px solid $bg;
                color: #121212;
                outline: none;
                border-radius: 10px;
                padding: 5px;

                textarea {
                  border: none;
                  outline: none;
                  height: 60px;

                  &:focus,
                  &:hover {
                    box-shadow: none;
                    outline: none;
                  }
                }
                font: {
                  size: 15px;
                  family: $font-family-base;
                  weight: $font-weight-regular;
                }

                &:focus {
                  outline: none;
                }

                &::placeholder {
                  color: $bg;
                }

                .captCount {
                  color: #b7b7b7;
                }
              }
            }
          }
        }

        .VideoPost {
          .UploadSec {
            &.recstarted {
              padding: 0;
            }

            .videoSec,
            .RecSec {
              display: inline-flex;
              align-items: center;
              cursor: pointer;
              transition: ease-out 0.2s;
              padding-bottom: 15px;
              font: {
                size: 14px;
                family: $font-family-base;
                weight: $font-weight-regular;
              }

              @include media-breakpoint-down(md) {
                display: none;
              }

              .starticon {
                background-image: url('https://butterflyv2-production-assets.nyc3.digitaloceanspaces.com/image/fa8c5a75-6843-4c7f-a213-6193ac95be1a-Record_Video.svg');
                width: 25px;
                height: 25px;
                background-size: cover;
                margin: 0;
                margin-right: 8px;
                transition: ease-out 0.2s;
              }

              .stopicon {
                background-image: url('https://butterflyv2-production-assets.nyc3.digitaloceanspaces.com/image/ed2eddb8-6c73-4dc6-98d9-c6927efa6cc8-Stop_Recording.svg');
                width: 25px;
                height: 25px;
                background-size: cover;
                margin: 0;
                margin-right: 8px;
                transition: ease-out 0.2s;
              }

              &:hover {
                color: $success;
                transition: ease-out 0.2s;
              }
            }

            .delete {
              i {
                font-size: 24px;
                padding-top: 15px;
              }

              cursor: pointer;
            }
          }
        }

        .AudioPost {
          .UploadSec {
            & > .row {
              padding: 0 15px;
            }

            .row {
              align-items: center;

              .AudioWaves {
                flex: 0 1 calc(100% - 60px);
              }

              .row {
                padding: 0 15px;
              }
            }

            .delete i {
              font-size: 24px;
              color: $title;
              cursor: pointer;
            }

            .ControllerIcon {
              width: 58px;
              height: 58px;
              flex: 0 1 58px;
              padding: 0;
              cursor: pointer;

              i {
                font-size: 58px;
                color: $success;
              }
            }

            .RecSec {
              cursor: pointer;

              span {
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;

                i {
                  padding-right: 5px;

                  &.fa-stoprecording {
                    color: #fc394d;
                  }
                }

                &:hover {
                  color: #fc394d;
                }

                &.audioSec {
                  color: $title;

                  &:hover {
                    i {
                      color: $title;
                    }

                    color: $success;
                  }
                }
              }
            }

            .Ripple {
              position: relative;
              margin: 0 auto 40px;
              width: 250px;
              height: 100px;
            }
          }
        }

        .QrCodeWrapper {
          border-radius: 10px;
          border: 1px dashed $grey-1;

          .Qrcode {
            text-align: center;
            width: 100%;
            padding: 12px;
            position: relative;

            img {
              max-width: 150px;
              width: 150px;
              height: 150px;
            }

            span {
              display: block;
              font: {
                size: 14px;
                family: $font-family-dada-grotesk;
                weight: $font-weight-regular;
              }
            }

            .closebtn {
              position: absolute;
              top: 10px;
              right: 10px;
              font-size: 18px;
              color: $success;
              cursor: pointer;
            }
          }

          .Cta {
            border-top: 1px dashed $grey-1;
            padding: 22px 32px;
            display: flex;
            align-items: center;
            justify-content: space-between;

            i {
              padding-right: 5px;
              color: $success;
              font-size: 18px;
              cursor: pointer;

              &.fa-butterflycopylink {
                font-size: 24px;
              }

              &.fa-butterflyemail {
                font-size: 18px;
              }

              &.fa-sms {
                font-size: 23px;
              }
            }
            font: {
              size: 14px;
              family: $font-family-dada-grotesk;
              weight: $font-weight-medium;
            }

            color: $success;

            span {
              cursor: pointer;
              display: flex;
              align-items: center;

              &:hover {
                color: $hover-button;

                i {
                  color: $hover-button;
                }
              }
            }
          }
        }

        .ShareLink {
          color: $title;
          text-align: center;
          padding: 28px 0;

          p {
            font: {
              size: 14px;
              family: $font-family-base;
              weight: $font-weight-regular;
            }

            span {
              display: block;
              color: $success;
              font: {
                size: 16px;
                family: $font-family-base;
                weight: $font-weight-semibold;
              }
            }

            margin-bottom: 28px;
          }

          .InputHolder {
            select {
              border: 1px solid $grey-1;
              height: 30px;
              width: 55px;

              &:focus,
              &:hover {
                outline: none;
                border: 1px solid $grey-1;
              }

              color: #121212;
              border-radius: 40px;
              font: {
                size: 14px;
                family: $font-family-base;
                weight: $font-weight-medium;
              }

              margin-right: 5px;
            }

            input {
              border: 1px solid $grey-1;
              width: 197px;
              height: 30px;
              border-radius: 40px;
              color: #121212;
              padding: 0 15px;

              &:focus,
              &:hover {
                outline: none;
                border: 1px solid $grey-1;
              }
              font: {
                size: 14px;
                family: $font-family-base;
                weight: $font-weight-medium;
              }
            }
          }
        }

        .IconHolder {
          display: flex;
          justify-content: space-between;
          padding: 8px 0;

          .lhs {
            i {
              font-size: 20px;
              color: $success;
              cursor: pointer;

              &.fa-butterflysmiley {
                @include media-breakpoint-down(xl) {
                  display: none;
                }

                &:hover {
                  color: $hover-button;
                }
              }
            }
          }

          .IconsList {
            flex: 0 1 132px;
            max-width: 132px;

            @include media-breakpoint-down(md) {
              flex: 0 1 137px;
              max-width: 137px;
            }

            i {
              color: $success;
              margin-left: 13px;
              font-size: 20px;
              cursor: pointer;

              &.active,
              &:hover {
               color: $hover-button;
              }
            }
          }
        }

        .mobileCTA {
          display: none;
          margin-top: 38px;

          @include media-breakpoint-down(md) {
            display: block;
            text-align: center;
          }

          button {
            @include button-success;

            width: 80px;
            height: 40px;
            border-radius: 50px;
          }
        }
      }

      .modal-footer {
        justify-content: center;
        padding-bottom: 0;
        border-top: none;

        button {
          @include button-success;

          width: 80px;
          height: 40px;
          border-radius: 50px;

          @include media-breakpoint-down(md) {
            display: none;
          }
        }
      }
    }
  }

  .phoneBtn {
    @include button-success;

    height: 40px;
    margin-top: 1em;

    &.cancelCta {
      margin-left: 15px;
      background-color: $white;
      border: 1px solid $success;
      color: $success;
    }
  }

  .text.text-center.text-success {
    margin-bottom: 10px;

    span {
      color: $white;
      font-size: 12px;
      padding: 3px 10px;
      background-color: #9b7bf6;
      border-radius: 25px;
    }
  }

  .video-qrmedia {
    background-color: initial;
  }
}

body:has(.CreatePost.modal.show) .MobileFeatureNav {
  @include media-breakpoint-down(md) {
    z-index: 999999 !important;
  }
}

body:has(.CreatePost.modal.show) {
  z-index: 9999;
}
