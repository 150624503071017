@import 'bootstrap/scss/bootstrap';
@import 'bootstrap/scss/mixins';
@import '../../../Assets/Styles/typography';
@import '../../../Assets/Styles/colors';
@import '../../../Assets/Styles/mixins';

.AudioRecordingPopup {
  .modal-dialog {
    .modal-content {
      .modal-header {
        border-bottom: 0;
        padding-bottom: 0;

        button {
          position: absolute;
          right: 15px;
          top: 20px;
        }

        div {
          text-align: center;
          width: 100%;

          img {
            display: block;
            width: 70px;
            height: 70px;
            border-radius: 50%;
            margin: auto;
          }

          span {
            &.Alpha {
              text-transform: capitalize;
              height: 65px;
              width: 65px;
              background: #f3f0fe;
              border-radius: 50%;
              color: #9b7bf6;
              font-size: 36px;
              font-weight: $font-weight-semibold;
              display: flex;
              align-items: center;
              margin: auto;
              justify-content: center;
            }
          }

          p {
            display: block;
            max-width: 320px;
            margin: auto;
            margin-top: 8px;
            font: {
              size: 16px;
              family: $font-family-base;
              weight: $font-weight-medium;
            }
          }
        }
      }

      .Audio {
        .AudioPost {
          .UploadSec {
            border-radius: 10px;
            border: 1px dashed $grey-1;
            padding: 28px;
            text-align: center;
            min-height: 176px;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: center;

            .row {
              align-items: center;
              width: 100%;

              .AudioWaves {
                flex: 0 1 calc(100% - 60px);
              }
            }

            .delete i {
              font-size: 24px;
              color: $title;
              cursor: pointer;
            }

            .ControllerIcon {
              width: 58px;
              height: 58px;
              flex: 0 1 58px;
              padding: 0;
              cursor: pointer;

              i {
                font-size: 58px;
                color: $success;
              }
            }

            .RecSec {
              cursor: pointer;
              width: 100%;

              span {
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;

                i {
                  padding-right: 5px;

                  &.fa-stoprecording {
                    color: #fc394d;
                  }
                }

                &:hover {
                  color: #fc394d;
                }
              }

              button {
                @include button-success;

                height: 40px;

                i {
                  padding-right: 5px;
                  font-size: 20px;
                }
              }
            }

            .Ripple {
              position: relative;
              margin: 0 auto 40px;
              width: 250px;
              height: 100px;
            }
          }
        }
      }

      .modal-footer {
        border-top: none;
        justify-content: center;
        padding-top: 0;

        button {
          @include button-success;

          height: 40px;
        }
      }
    }
  }
}
