@import 'bootstrap/scss/bootstrap';
@import 'bootstrap/scss/mixins';
@import '../../../Assets/Styles/typography';
@import '../../../Assets/Styles/colors';
@import '../../../Assets/Styles/mixins';

@include media-breakpoint-down(md) {
  .fundraiser-popup[role='dialog'][aria-modal='true']:nth-of-type(4) {
    z-index: 999999999;
    padding: 0;
    margin: 0;
  }
}

@media (width >= 576px) and (width <= 767.98px) {
  .fundraiser-popup[role='dialog'][aria-modal='true']:nth-of-type(4) {
    z-index: 9999;
  }
}

.fundraiser-popup {
  input::focus-visisble {
    box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 25%);
    outline: 0;
  }

  input:focus {
    box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 25%);
    outline: 0;
  }

  .stepOne,
  .stepTwo,
  .stepThree {
    border-radius: 5px;
  }

  .modal-dialog {
    @include media-breakpoint-down(md) {
      margin: 0 !important;
      padding: 0 !important;
      width: 100% !important;
    }
  }

  .modal-content {
    width: 630px;
    border-radius: 5px;
    margin: 0 auto;
    position: relative;
    border: 0;
    height: auto;

    @include media-breakpoint-down(md) {
      width: 100% !important;
      height: 100vh;
      border-radius: 0;
      overflow: auto;
      padding-bottom: 100px;
    }

    @include media-breakpoint-only(md) {
      height: 850px;
    }

    @include media-breakpoint-only(lg) {
      height: 800px;
    }
  }

  .modal-header {
    border-bottom: 0;
    position: absolute;
    right: 32px;
    top: 31px;
    padding: 0;

    button {
      padding-left: 0;
      padding-right: 0;
      color: $text-primary;
      opacity: 1;
      font-size: 14px !important;

      &.btn-close {
        top: -9px;
        position: relative;
      }

      &:hover,
      &:focus,
      &:focus-visible {
        outline: none;
        border: none;
        box-shadow: none;
      }
    }

    @include media-breakpoint-down(md) {
      top: 31px;
      right: 19px;
    }
  }

  .header {
    color: $success;
    font: {
      family: $font-family-base;
      weight: 600;
      size: 20px;
    }

    line-height: 30px;
    padding-bottom: 12px;
    padding-top: 13px;
    border-bottom: 1px solid $bg;
    padding-left: 0;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    margin-left: 20px;
    margin-right: 20px;

    @include media-breakpoint-down(md) {
      margin-left: 15px;
      margin-right: 15px;
      padding-left: 0;
    }
  }

  .createFundraiser {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 24px 20px;

    @include media-breakpoint-down(md) {
      flex-direction: column;
      padding-left: 15px;
      padding-right: 15px;
    }

    .ProfilePic {
      flex: 0 1 170px;
      max-width: 170px;

      @include media-breakpoint-only(md) {
        flex: 0 1 120px;
        max-width: 120px;
      }

      .ImgCroper {
        width: 143px;
        height: 143px;
        border-radius: 50%;
        border: 1px dashed #777;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        position: relative;

        .ImgWrapper {
          width: 143px;
          height: 143px;
          border-radius: 50%;
          overflow: hidden;
          display: flex;
          justify-content: center;

          .empty-event-image {
            width: 100%;
            height: 100%;
            background: url('https://butterflyv2-production-assets.nyc3.cdn.digitaloceanspaces.com/image/white_mark.png'),
              #1d817b;
            background-repeat: no-repeat;
            background-position: center;
            position: absolute;
            overflow: hidden;
            display: flex;
            background-size: 40%;
            justify-content: space-between;
            top: 0;
            border-radius: 50%;
          }

          .errorMessage {
            font: {
              size: 14px;
              family: $font-family-dada-grotesk;
              weight: $font-weight-regular;
            }
          }
        }

        span {
          i {
            color: $success;
            display: block;
            font-size: 34px;
            margin-bottom: 10px;
          }

          color: $title;
          display: block;
          font: {
            size: 12px;
            family: $font-family-dada-grotesk;
            weight: $font-weight-regular;
          }

          &.cropImg {
            border: 1px solid $bg;
            padding: 8px;
            border-radius: 50%;
            width: 40px;
            height: 40px;
            position: absolute;
            background-color: $white;
            bottom: 0;
            right: 0;

            i {
              color: $success;
              font-size: 20px;
            }
          }
        }

        img {
          width: auto;
          height: 143px;
        }

        &:hover {
          span i {
            &.noImg {
              color: $hover-button
            }

            color: $success;
          }

          span.cropImg {
            i {
              color: $title;
            }
          }
        }

        p {
          &.text.text-danger.errorMessage {
            position: absolute;
            bottom: -60px;
            font: {
              size: 14px;
              family: $font-family-dada-grotesk;
              weight: $font-weight-regular;
            }

            width: 140px !important;
            height: 40px !important;

            @include media-breakpoint-down(md) {
              font-size: 12px;
              line-height: 1.1;
              bottom: 20px;
              right: -100%;
            }
          }
        }
      }
    }

    .FormSection {
      flex: 0 1 calc(100% - 170px);
      max-width: calc(100% - 170px);
      padding-right: 20px;

      @include media-breakpoint-down(md) {
        width: 100%;
        max-width: 100%;
        padding-right: 0;
        flex-basis: 100%;
      }

      @include media-breakpoint-only(md) {
        flex-basis: calc(100% - 120px);
        max-width: calc(100% - 120px);
        padding-right: 0;
      }

      .each-group {
        margin-bottom: 20px;
        max-width: 100%;

        span.text-count {
          font-size: 12px;
          margin-top: 5px;
          padding-left: 8px;
          text-align: right;
          display: block;
        }

        label {
          color: $title;
          font: {
            family: $font-family-dada-grotesk;
            weight: 500;
            size: 14px;
          }

          line-height: 21px;
          margin-bottom: 5px;
          width: 100%;

          sup {
            top: -4px;
            left: 1px;
          }
        }

        .form-select {
          padding: 8px 21px;
          border: 1px solid $grey-1;
          border-radius: 15px;
          color: $title;
          font-family: $font-family-dada-grotesk;
          font-weight: 400;
          font-size: 16px;
          line-height: 24px; /* Firefox */ /* Safari and Chrome */
          appearance: none;
          cursor: pointer;
          height: 40px;
          background-image: none;

          option:not(:first-of-type) {
            color: $grey-1;
          }

          &::input-placeholder {
            /* Edge */
            color: $grey-1;
          }

          &:input-placeholder {
            /* Internet Explorer 10-11 */
            color: $grey-1;
          }

          &::placeholder {
            color: $grey-1;
          }
        }

        .select-wrap {
          position: relative;

          .form-select {
            /* Firefox */ /* Safari and Chrome */
            appearance: none !important;
          }

          .arrow-down {
            width: 0;
            height: 0;
            border-left: 4px solid transparent;
            border-right: 4px solid transparent;
            border-top: 4px solid $text-primary;
            position: absolute;
            right: 14px;
            top: 50%;
            transform: translateY(-50%);
          }

          input#search,
          input.outline-none {
            padding: 8px 30px 8px 9px !important;
          }

          button.absolute {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            z-index: 1 !important;
            right: 10px;
            top: 0;
            bottom: 0;
            position: absolute;
            background-color: transparent;
            background-image: none;
            color: inherit;
            margin: 0;
            padding: 0;
            border: none;
            font-size: 30px;
          }

          #search-listbox {
            background-color: $white;
            border: 1px solid $grey-1;
            max-height: 200px;
            overflow-y: scroll;

            div.flex {
              padding: 10px !important;
            }
          }

          .flex {
            display: flex;
          }
        }

        input {
          color: $title;
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          font-family: $font-family-dada-grotesk;
          padding: 8px 9px;
          border: 1px solid $grey-1;
          border-radius: 15px;
          height: 40px;
          width: 100%;

          &::input-placeholder {
            /* Edge */
            color: $grey-1;
          }

          &:input-placeholder {
            /* Internet Explorer 10-11 */
            color: $grey-1;
          }

          &::placeholder {
            color: $grey-1;
          }

          &:focus,
          &:hover {
            box-shadow: none;
            outline: none;
          }

          &:read-only.readOnly {
            background-color: #ececec;
          }
        }

        textarea {
          color: $title;
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          font-family: $font-family-dada-grotesk;
          padding: 8px 9px;
          border: 1px solid $grey-1;
          border-radius: 15px;
          resize: none;

          &::input-placeholder {
            /* Edge */
            color: $grey-1;
          }

          &:input-placeholder {
            /* Internet Explorer 10-11 */
            color: $grey-1;
          }

          &::placeholder {
            color: $grey-1;
          }

          &:focus,
          &:hover {
            box-shadow: none;
            outline: none;
          }
        }

        .two-column {
          margin-bottom: 20px;
          position: relative;

          input {
            width: 167px;
            display: inline-block;
            vertical-align: top;
            margin-right: 20px;

            @include media-breakpoint-down(md) {
              width: 141px;
            }

            &.mr-0 {
              margin-right: 0 !important;
            }
          }

          span.error-message {
            font-size: 12px;
            display: block;
            color: red;
            position: absolute;
            bottom: -17px;
          }

          .select-wrap {
            width: 113px;
            display: inline-block;
            vertical-align: top;

            .form-select {
              width: 113px;

              @include media-breakpoint-down(md) {
                width: 141px;
              }
            }

            @include media-breakpoint-down(md) {
              width: 141px;
            }
          }

          span.input-group-text {
            border-top-left-radius: 15px;
            border-bottom-left-radius: 15px;
          }

          .inner-holder {
            width: calc(50% - 10px);
            display: inline-block;
            vertical-align: top;
            margin-right: 20px;

            &:last-child {
              margin-right: 0;
            }

            input {
              width: 100%;
            }

            .react-datepicker-wrapper {
              @include media-breakpoint-down(md) {
                padding-right: 0 !important;

                .mb-4.row {
                  padding-right: 0 !important;
                }
              }

              input {
                &.form-control {
                  height: 40px;
                  border-radius: 15px;
                  border: 1px solid $grey-1;
                  color: #121212;
                  font: {
                    size: 16px;
                    family: $font-family-dada-grotesk;
                    weight: $font-weight-regular;
                  }
                }
              }
            }

            .react-datepicker {
              button {
                padding: unset;
                background-color: transparent;
                height: unset;
                margin-top: 8px;

                &:hover,
                &:active {
                  box-shadow: none;
                }
              }
            }

            .react-datepicker__header {
              background-color: $success;
            }

            .react-datepicker__day--selected,
            .react-datepicker__day--in-selecting-range.react-datepicker__day--selecting-range-end {
              background-color: $success;
              color: $white;
            }

            .react-datepicker__time-container
              .react-datepicker__time
              .react-datepicker__time-box
              ul.react-datepicker__time-list {
              overflow-y: overlay;
            }

            .react-datepicker__time-container
              .react-datepicker__time
              .react-datepicker__time-box
              ul.react-datepicker__time-list
              li.react-datepicker__time-list-item--selected {
              background-color: $success;
              color: $white;
            }

            .react-datepicker__header {
              background-color: $success;
              color: $white;
            }

            .react-datepicker__day-name,
            .react-datepicker__current-month,
            .react-datepicker-time__header,
            .react-datepicker-year-header {
              color: $white;
            }

            .react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle::before,
            .react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle::after {
              border-bottom-color: $success;
            }

            .react-datepicker__day--in-selecting-range {
              background-color: #216ba5;
              color: $white;
            }
          }
        }
      }

      .ActionCta {
        margin-top: 50px;

        @include media-breakpoint-down(md) {
          flex-wrap: wrap;
          justify-content: center;
        }

        button {
          @include media-breakpoint-down(md) {
            margin: 0 30px 20px;
          }

          &.create-fund {
            align-items: center;
            background-color: $success;
            border-radius: 50px;
            padding: 13px 26px;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            color: $white;
            height: 50px;
            border: 0;
            display: inline-flex;
            justify-content: center;
            transition: all 0.5s;

            @include media-breakpoint-down(md) {
              display: flex;
            }

            i {
              color: $white;
              font-size: 20px;
              margin-right: 8px;
            }

            &:hover,
            &:focus,
            &.focus-visible {
              background-color: $hover-button;
              box-shadow: 0 2px 8px rgb(0 0 0 / 15%);
              color: $white;
              outline: none;
              transition: all 0.5s;
            }

            &:disabled {
              background-color: $disabled-button;
              border: none;
              outline: none;
              cursor: none;
              pointer-events: none;
              color: $title;

              &:hover,
              &:focus {
                background-color: $disabled-button;
                border: none;
                outline: none;
                box-shadow: none;
              }

              i {
                color: $title;
              }
            }
          }

          &.cancelCta {
            align-items: center;
            background-color: $success;
            border-radius: 50px;
            padding: 13px 26px;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            color: $white;
            height: 50px;
            border: 0;
            display: inline-flex;
            justify-content: center;
            transition: all 0.5s;

            @include media-breakpoint-down(md) {
              display: flex;
            }

            @include media-breakpoint-up(md) {
              margin-right: 30px;
            }

            i {
              color: $white;
              font-size: 20px;
              margin-right: 8px;
            }

            &:hover,
            &:focus,
            &.focus-visible {
              background-color: $hover-button;
              box-shadow: 0 2px 8px rgb(0 0 0 / 15%);
              color: $white;
              outline: none;
              transition: all 0.5s;
            }

            &.cancelCta {
              background-color: $white;
              border: 1px solid $success;
              color: $success;

              &:focus,
              &:hover {
                color: $white;
                background-color: $hover-button;
                border: 1px solid $hover-button;
              }

              width: 100px;
            }
          }
        }
      }
    }
  }

  .intro-text {
    padding-bottom: 12px;
    padding-top: 12px;
    border-bottom: 1px solid $bg;
    padding-left: 20px;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
  }

  .stepTwo {
    .header {
      p {
        color: $text-primary;
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        margin-bottom: 2px;

        @include media-breakpoint-down(md) {
          margin-bottom: 0;
          font-size: 16px;
        }

        a {
          color: $success;
          text-decoration: underline;
        }
      }

      h4 {
        font-family: $font-family-base;
        color: $success;
        font-weight: 600;
        font-size: 18px;
        line-height: 27px;
        margin-bottom: 0;
        word-break: break-word;
        padding-right: 27px;

        @include media-breakpoint-down(md) {
          font-size: 20px;
        }
      }
    }

    .innerwrap {
      padding: 20px;

      @include media-breakpoint-down(md) {
        padding-left: 13px;
        padding-right: 13px;
      }

      .description {
        margin-bottom: 20px;

        p {
          font-family: Poppins, sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 21px;
          color: $text-primary;
          margin: 0;
        }
      }

      .inner-width-section {
        width: 100%;
        margin: 0 auto;

        @include media-breakpoint-down(md) {
          width: 100%;
        }

        .price {
          text-align: center;
          border: 1px solid $bg;
          border-radius: 24px;
          padding: 16px;

          h3 {
            color: $title;
            font-weight: 600;
            font-size: 20px;
            line-height: 30px;
            font-family: Poppins, sans-serif;
            margin-bottom: 20px;

            @include media-breakpoint-down(md) {
              font-size: 18px;
            }
          }

          .pricelist {
            display: flex;
            flex-wrap: wrap;
            gap: 10px;

            @include media-breakpoint-down(md) {
              gap: 5px;
            }

            button {
              color: $title;
              font-family: Poppins, sans-serif;
              font-weight: 500;
              font-size: 14px;
              line-height: 21px;
              padding: 4px 13px;
              border: 1px solid $bg;
              border-radius: 16px;
              height: 29px;
              background: transparent;

              &:hover {
                background: $success;
                color: $white;
                transition: all 0.5s;
              }
            }
          }
        }

        .thanks-section {
          text-align: center;
          border: 1px solid $bg;
          border-radius: 24px;
          padding: 16px;

          .amount {
            margin-bottom: 0;
          }

          p {
            color: $text-primary;
            font-weight: 400;
            font-size: 14px;
            line-height: 21px;
            font-family: Poppins, sans-serif;
            margin: 0 0 24px;
            text-align: left;
          }
        }

        .cta-wrapper {
          display: flex;
          flex-wrap: wrap;
          gap: 8px;
          padding: 20px 0;
          justify-content: center;

          .onetime {
            align-items: center;
            background-color: $success;
            border-radius: 50px;
            padding: 6px 26px;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            color: $white;
            height: 36px;
            border: 0;
            display: inline-flex;
            justify-content: center;
            transition: all 0.5s;
            width: calc(100% / 2 - 4px);
            cursor: pointer;

            @include media-breakpoint-down(md) {
              width: 131px;
            }

            &:hover {
              background-color: $title;
              box-shadow: 0 2px 8px rgb(0 0 0 / 15%);
              color: $white;
              outline: none;
              transition: all 0.5s;
            }
          }

          .monthly {
            align-items: center;
            background-color: $white;
            border-radius: 50px;
            padding: 6px 26px;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            color: $text-primary;
            height: 36px;
            display: inline-flex;
            justify-content: center;
            transition: all 0.5s;
            border: 1px solid $grey-1;
            width: calc(100% / 2 - 4px);
            cursor: pointer;

            @include media-breakpoint-down(md) {
              width: 131px;
            }

            &:hover {
              border-color: $success;
              background: $success;
              color: $white;
              outline: none;
              transition: all 0.5s;
            }
          }
        }

        .amount {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          padding: 0;
          gap: 4px;
          margin-bottom: 20px;

          .form-select {
            appearance: none !important; /* Firefox */ /* Safari and Chrome */
            border: 0 !important;
            color: $title !important;
          }

          .input-wrapper {
            span.dollar {
              color: $title;
              font-weight: 400;
              font-size: 32px;
              line-height: 48px;
              font-family: $font-family-base;
              margin-right: 4px;

              @include media-breakpoint-down(md) {
                font-size: 20px;
                line-height: 30px;
              }
            }

            span.pipeline {
              color: #abb2b9;
              font-weight: 100;
              font-size: 32px;
              line-height: 48px;
              font-family: $font-family-base;

              @include media-breakpoint-down(md) {
                font-size: 20px;
                line-height: 30px;
              }
            }

            input {
              color: $title;
              font-weight: 700;
              font-size: 32px;
              line-height: 48px;
              font-family: $font-family-base;
              width: 24px;
              border: 0;
              padding: 0;

              @include media-breakpoint-down(md) {
                font-size: 20px;
                line-height: 30px;
              }

              &::input-placeholder {
                /* Edge */
                color: #abb2b9;
              }

              &:input-placeholder {
                /* Internet Explorer 10-11 */
                color: #abb2b9;
              }

              &::placeholder {
                color: #abb2b9;
              }
            }

            .form-select {
              appearance: none !important; /* Firefox */ /* Safari and Chrome */
              border: 0 !important;
              color: $title !important;

              @include media-breakpoint-down(md) {
                font-size: 20px;
                line-height: 30px;
              }
            }
          }
        }
      }

      .privacy-button-wrap {
        width: 498px;
        margin: 20px auto 0;

        @include media-breakpoint-down(md) {
          width: 100%;
        }

        .checkbox-wrap {
          display: flex;
          gap: 12px;
          justify-content: space-between;

          input {
            display: none;
          }

          label {
            color: $text-primary;
            font-weight: 500;
            font-size: 14px;
            line-height: 21px;
            font-family: $font-family-base;
            margin: 0;
            position: relative;
            display: flex;

            p {
              padding-right: 40px;
              display: inline-block;
              color: $text-primary;
              font-weight: 500;
              font-size: 14px;
              line-height: 21px;
              font-family: $font-family-base;
              margin: 0;
              vertical-align: middle;
              padding: 0;
            }

            &::before {
              content: '';
              width: 23px;
              height: 20px;
              background: #f2f2f2;
              border: 1px solid rgb(75 101 132 / 30%);
              display: inline-block;
              margin-right: 8px;
              cursor: pointer;

              @include media-breakpoint-down(md) {
                width: 31px;
              }
            }
          }

          input[type='checkbox']:checked ~ label::before {
            background: $success no-repeat center;
            background-size: 12px 12px;
            cursor: pointer;
            background-image: url('data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTYuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4PSIwcHgiIHk9IjBweCIgd2lkdGg9IjE2cHgiIGhlaWdodD0iMTZweCIgdmlld0JveD0iMCAwIDQ1LjcwMSA0NS43IiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCA0NS43MDEgNDUuNzsiIHhtbDpzcGFjZT0icHJlc2VydmUiPgo8Zz4KCTxnPgoJCTxwYXRoIGQ9Ik0yMC42ODcsMzguMzMyYy0yLjA3MiwyLjA3Mi01LjQzNCwyLjA3Mi03LjUwNSwwTDEuNTU0LDI2LjcwNGMtMi4wNzItMi4wNzEtMi4wNzItNS40MzMsMC03LjUwNCAgICBjMi4wNzEtMi4wNzIsNS40MzMtMi4wNzIsNy41MDUsMGw2LjkyOCw2LjkyN2MwLjUyMywwLjUyMiwxLjM3MiwwLjUyMiwxLjg5NiwwTDM2LjY0Miw3LjM2OGMyLjA3MS0yLjA3Miw1LjQzMy0yLjA3Miw3LjUwNSwwICAgIGMwLjk5NSwwLjk5NSwxLjU1NCwyLjM0NSwxLjU1NCwzLjc1MmMwLDEuNDA3LTAuNTU5LDIuNzU3LTEuNTU0LDMuNzUyTDIwLjY4NywzOC4zMzJ6IiBmaWxsPSIjRkZGRkZGIi8+Cgk8L2c+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPC9zdmc+Cg==');
          }

          input[type='checkbox']:focus ~ label {
            color: $success $success $success;
          }
        }

        .prev-next-btn {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          margin-top: 20px;

          @include media-breakpoint-down(md) {
            border-top: 1px solid $bg;
            padding-top: 20px;
          }

          .back {
            margin-left: 16px;
            color: $text-primary;
            font: {
              family: $font-family-base;
              weight: 500;
              size: 16px;
            }

            line-height: 16px;
            padding: 13px 15px;
            border: 1px solid $grey-1;
            border-radius: 50px;
            width: 119px;
            height: 44px;
            transition: all 0.5s;
            background: none;

            @include media-breakpoint-down(md) {
              margin-left: 0;
            }

            &:hover {
              background: $success;
              color: $white;
              transition: all 0.5s;
            }
          }

          .proceed {
            margin-right: 16px;
            width: 119px;
            color: $white;
            font: {
              family: $font-family-base;
              weight: 500;
              size: 16px;
            }

            line-height: 18px;
            padding: 13px 15px;
            border: 0;
            border-radius: 50px;
            height: 44px;
            background-color: $success;
            transition: all 0.5s;

            @include media-breakpoint-down(md) {
              margin-right: 0;
            }

            &:hover {
              background-color: $title;
              box-shadow: 0 2px 8px rgb(0 0 0 / 15%);
              color: $white;
              outline: none;
            }
          }
        }
      }
    }
  }

  .stepThree {
    .header {
      p {
        color: $text-primary;
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        margin-bottom: 0;

        a {
          color: $success;
          text-decoration: underline;
        }
      }

      h4 {
        font-family: $font-family-base;
        color: $success;
        font-weight: 600;
        font-size: 18px;
        line-height: 27px;
        margin-bottom: 0;
      }
    }

    .carddetails {
      width: 350px;
      margin: 0 auto;
      padding-top: 16px;

      .title {
        color: $title;
        font-family: $font-family-base;
        font-weight: 600;
        font-size: 20px;
        line-height: 30px;
        margin-bottom: 24px;
      }

      .form-group {
        margin-bottom: 17px;

        input {
          font-family: $font-family-base;
          font-weight: 500;
          font-size: 14px;
          padding: 9px 12px;
          height: 40px;
          display: block;
          border: 1px solid $bg;
          border-radius: 15px;
          width: 100%;
          line-height: 21px;

          &::input-placeholder {
            /* Edge */
            color: $grey-1;
            line-height: 21px;
          }

          &:input-placeholder {
            /* Internet Explorer 10-11 */
            color: $grey-1;
            line-height: 21px;
          }

          &::placeholder {
            color: $grey-1;
            line-height: 21px;
          }
        }

        label {
          color: $title;
          font-family: $font-family-base;
          font-weight: 500;
          font-size: 14px;
          line-height: 21px;
          margin-bottom: 3px;
          display: block;
        }

        &.name-section {
          display: flex;
          gap: 20px;
          justify-content: space-between;
        }

        &.card-section {
          display: flex;
          gap: 30px;
          justify-content: space-between;
        }

        &.checkbox-wrap {
          display: flex;
          gap: 12px;
          justify-content: space-between;

          input {
            display: none;
          }

          label {
            color: $text-primary;
            font-weight: 500;
            font-size: 14px;
            line-height: 21px;
            font-family: $font-family-base;
            margin: 0;
            position: relative;
            display: flex;

            p {
              padding-right: 40px;
              display: inline-block;
              color: $text-primary;
              font-weight: 500;
              font-size: 14px;
              line-height: 21px;
              font-family: $font-family-base;
              margin: 0;
              vertical-align: middle;
              padding: 0;
            }

            &::before {
              content: '';
              width: 30px;
              height: 20px;
              background: #f2f2f2;
              border: 1px solid rgb(75 101 132 / 30%);
              display: inline-block;
              margin-right: 8px;
              cursor: pointer;
            }
          }

          input[type='checkbox']:checked ~ label::before {
            background: $success no-repeat center;
            background-size: 12px 12px;
            cursor: pointer;
            background-image: url('data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTYuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4PSIwcHgiIHk9IjBweCIgd2lkdGg9IjE2cHgiIGhlaWdodD0iMTZweCIgdmlld0JveD0iMCAwIDQ1LjcwMSA0NS43IiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCA0NS43MDEgNDUuNzsiIHhtbDpzcGFjZT0icHJlc2VydmUiPgo8Zz4KCTxnPgoJCTxwYXRoIGQ9Ik0yMC42ODcsMzguMzMyYy0yLjA3MiwyLjA3Mi01LjQzNCwyLjA3Mi03LjUwNSwwTDEuNTU0LDI2LjcwNGMtMi4wNzItMi4wNzEtMi4wNzItNS40MzMsMC03LjUwNCAgICBjMi4wNzEtMi4wNzIsNS40MzMtMi4wNzIsNy41MDUsMGw2LjkyOCw2LjkyN2MwLjUyMywwLjUyMiwxLjM3MiwwLjUyMiwxLjg5NiwwTDM2LjY0Miw3LjM2OGMyLjA3MS0yLjA3Miw1LjQzMy0yLjA3Miw3LjUwNSwwICAgIGMwLjk5NSwwLjk5NSwxLjU1NCwyLjM0NSwxLjU1NCwzLjc1MmMwLDEuNDA3LTAuNTU5LDIuNzU3LTEuNTU0LDMuNzUyTDIwLjY4NywzOC4zMzJ6IiBmaWxsPSIjRkZGRkZGIi8+Cgk8L2c+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPC9zdmc+Cg==');
          }

          input[type='checkbox']:focus ~ label {
            color: $success $success $success;
          }
        }
      }

      .denote-fund {
        align-items: center;
        background-color: $success;
        border-radius: 50px;
        padding: 13px 26px;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: $white;
        height: 50px;
        border: 0;
        display: flex;
        justify-content: center;
        transition: all 0.5s;
        margin: 48px auto 0;
        width: 220px;

        &:hover {
          background-color: $title;
          box-shadow: 0 2px 8px rgb(0 0 0 / 15%);
          color: $white;
          outline: none;
          transition: all 0.5s;
        }
      }
    }
  }

  .stepFour {
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    left: 50%;
    width: 100%;
    padding: 0 60px;

    @include media-breakpoint-down(md) {
      padding: 0;
    }

    .congrats {
      font-weight: 600;
      font-size: 24px;
      line-height: 31px;
      color: $title;
      margin-bottom: 8px;
      font-family: $font-family-base;
      text-align: center;

      @include media-breakpoint-down(md) {
        font-size: 20px;
        padding-left: 20px;
        padding-right: 20px;
      }
    }

    p {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: $title;
      font-family: $font-family-base;
      margin-bottom: 40px;
      text-align: center;

      @include media-breakpoint-down(md) {
        font-size: 14px;
        padding-left: 20px;
        padding-right: 20px;
      }
    }

    .viewall {
      align-items: center;
      background-color: $success;
      border-radius: 50px;
      padding: 13px 26px;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: $white;
      height: 50px;
      border: 0;
      display: inline-flex;
      justify-content: center;
      transition: all 0.5s;

      &:hover {
        background-color: $title;
        box-shadow: 0 2px 8px rgb(0 0 0 / 15%);
        color: $white;
        outline: none;
        transition: all 0.5s;
      }
    }
  }

  .form-select::input-placeholder {
    /* Chrome/Opera/Safari */
    color: $grey-1;
  }

  .form-select::placeholder {
    /* Firefox 19+ */
    color: $grey-1;
  }

  .form-select:input-placeholder {
    /* IE 10+ */
    color: $grey-1;
  }

  .form-select:placeholder {
    /* Firefox 18- */
    color: $grey-1;
  }
}

select::-ms-expand {
  display: none !important;
}

body.fundraiser .Cropper {
  z-index: 999999999 !important;
}

body.fundraiser:has(.Cropper) .modal-backdrop {
  z-index: 999999999 !important;
}
