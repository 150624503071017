@import 'bootstrap/scss/bootstrap';
@import 'bootstrap/scss/mixins';
@import '../../../Assets/Styles/typography';
@import '../../../Assets/Styles/colors';
@import '../../../Assets/Styles/mixins';

$mapColor: ('green', #66bb6a, #fff), ('red', #ef5350, #fff), ('blue', #5c6bc0, #fff), ('orange', #ffa726, #fff);

.SharePopup.modal.show {
  .modal-dialog {
    max-width: 470px;
    width: 100%;

    @include media-breakpoint-down(md) {
      width: unset;
    }

    .modal-content {
      height: 100%;
      border-radius: 10px;
      border: none;
      min-height: 303px;

      .modal-header {
        border-bottom: none;
      }

      .modal-body {
        text-align: center;

        h4 {
          font: {
            family: $font-family-base;
            weight: $font-weight-medium;
            size: 16px;
          }

          color: $title;

          span {
            font-weight: $font-weight-regular;
          }
        }

        .Icons {
          display: flex;
          justify-content: space-between;
          align-items: center;
          max-width: 287px;

          //margin: 28px auto 48px;
          margin: 28px auto;

          i {
            color: $success;
            cursor: pointer;

            &:hover {
              color: $hover-button;
            }

            &.fa-butterflylink {
              font-size: 19px;
            }

            &.fa-butterflyemail2 {
              font-size: 19px;
            }

            &.fa-butterflymessage {
              font-size: 22px;
            }

            &.fa-butterflyfacebook {
              font-size: 19px;
            }

            &.fa-twitter {
              font-size: 17px;
            }

            &.fa-butterflylinkedin {
              font-size: 18px;
            }
          }

          span.iconWrapper {
            width: 42px;
            height: 42px;
            border: 2px solid $bg;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;

            a {
              line-height: 1;
            }
          }
        }

        .ShareLink {
          color: $title;
          text-align: center;
          padding: 28px 0;

          p {
            font: {
              size: 14px;
              family: $font-family-base;
              weight: $font-weight-regular;
            }

            span {
              display: block;
              color: $success;
              font: {
                size: 16px;
                family: $font-family-base;
                weight: $font-weight-semibold;
              }
            }

            margin-bottom: 28px;
          }

          .InputHolder {
            select {
              border: 1px solid $grey-1;
              height: 30px;
              width: 55px;

              &:focus,
              &:hover {
                outline: none;
                border: 1px solid $grey-1;
              }

              color: #121212;
              border-radius: 40px;
              font: {
                size: 14px;
                family: $font-family-dada-grotesk;
                weight: $font-weight-medium;
              }

              margin-right: 5px;
            }

            input {
              border: 1px solid $grey-1;
              width: 197px;
              height: 30px;
              border-radius: 40px;
              color: #121212;
              padding: 0 15px;

              &:focus,
              &:hover {
                outline: none;
                border: 1px solid $grey-1;
              }
              font: {
                size: 14px;
                family: $font-family-dada-grotesk;
                weight: $font-weight-medium;
              }
            }
          }

          button {
            @include button-success;

            margin-top: 20px;
            height: 40px;
          }
        }

        .InviteEmail {
          textarea {
            border: none;
            resize: none;
            border-bottom: 1px solid $title;
            border-radius: 0;
            font: {
              family: $font-family-dada-grotesk;
              weight: $font-weight-regular;
              size: 14px;
            }

            &:focus {
              outline: none;
              box-shadow: none;
            }
          }

          .react-multi-email {
            margin-bottom: 20px;
            max-height: 90px;
            overflow: scroll;
          }

          .checkboxField {
            /* Hide elements visually, but not for screenreaders by clipping it */

            .vh {
              position: absolute !important;
              clip: rect(1px, 1px, 1px, 1px);
              padding: 0 !important;
              border: 0 !important;
              height: 1px !important;
              width: 1px !important;
              overflow: hidden;
            }

            label::before {
              content: '';
              width: 20px;
              height: 20px;
              background: #f2f2f2;
              border: 1px solid rgb(75 101 132 / 30%);
              display: inline-block;
              margin-right: 8px;
              cursor: pointer;
            }

            label {
              font: {
                family: $font-family-dada-grotesk;
                weight: $font-weight-regular;
                size: 14px;
              }

              color: $text-primary;
            }

            input[type='checkbox']:checked ~ label::before {
              background: $success no-repeat center;
              background-size: 12px 12px;
              cursor: pointer;
              background-image: url("data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTYuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4PSIwcHgiIHk9IjBweCIgd2lkdGg9IjE2cHgiIGhlaWdodD0iMTZweCIgdmlld0JveD0iMCAwIDQ1LjcwMSA0NS43IiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCA0NS43MDEgNDUuNzsiIHhtbDpzcGFjZT0icHJlc2VydmUiPgo8Zz4KCTxnPgoJCTxwYXRoIGQ9Ik0yMC42ODcsMzguMzMyYy0yLjA3MiwyLjA3Mi01LjQzNCwyLjA3Mi03LjUwNSwwTDEuNTU0LDI2LjcwNGMtMi4wNzItMi4wNzEtMi4wNzItNS40MzMsMC03LjUwNCAgICBjMi4wNzEtMi4wNzIsNS40MzMtMi4wNzIsNy41MDUsMGw2LjkyOCw2LjkyN2MwLjUyMywwLjUyMiwxLjM3MiwwLjUyMiwxLjg5NiwwTDM2LjY0Miw3LjM2OGMyLjA3MS0yLjA3Miw1LjQzMy0yLjA3Miw3LjUwNSwwICAgIGMwLjk5NSwwLjk5NSwxLjU1NCwyLjM0NSwxLjU1NCwzLjc1MmMwLDEuNDA3LTAuNTU5LDIuNzU3LTEuNTU0LDMuNzUyTDIwLjY4NywzOC4zMzJ6IiBmaWxsPSIjRkZGRkZGIi8+Cgk8L2c+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPC9zdmc+Cg==");
            }

            input[type='checkbox']:focus ~ label {
              color: $success $success $success;
            }
          }

          label {
            width: 100%;
            text-align: left;
            display: inline-flex;
            font: {
              family: $font-family-dada-grotesk;
              weight: $font-weight-regular;
              size: 14px;
            }

            color: $text-primary;

            input[type='checkbox'] {
              width: 20px;
              height: 20px;
              margin-right: 10px;

              &::before {
                background-color: $success;
              }
            }
          }

          .Ctas {
            margin-top: 30px;

            .cancel {
              width: 100px;
              height: 40px;
              border: 1px solid $grey-1;
              border-radius: 50px;
              background-color: $white;
              background-size: cover;
              font: {
                family: $font-family-dada-grotesk;
                weight: $font-weight-medium;
                size: 14px;
              }

              @include media-breakpoint-down(md) {
                font-size: 16px;
              }

              color: #777;
              text-align: center;

              &:hover {
                background-color: $title;
                color: $white;
              }
            }

            .sendInvite {
              @include button-success;

              height: 40px;
              margin-left: 15px;
            }
          }
        }

        .invite-label {
          display: flex;
          justify-content: space-between;

          h5 {
            cursor: pointer;
            font: {
              family: $font-family-base;
              weight: $font-weight-medium;
              size: 14px;
            }

            color: $success;
          }
        }

        .blocked-members-label {
          text-align: left;
        }

        .blocked-members-list > input {
          display: none !important;
        }
      }
    }
  }

  @include media-breakpoint-down(md) {
    z-index: 9999999999 !important;
  }
}

body:has(.SharePopup.modal.show) .modal-backdrop.show {
  @include media-breakpoint-down(md) {
    z-index: 9999999999 !important;
  }
}
