@import 'bootstrap/scss/bootstrap';
@import 'bootstrap/scss/mixins';
@import '../../Assets/Styles/typography';
@import '../../Assets/Styles/colors';
@import '../../Assets/Styles/mixins';

.MobileFeatureNav {
  background-color: $white;
  position: fixed;
  height: 80px;
  width: 100vw;
  bottom: 0;
  z-index: 999999;
  box-shadow: 0 30px 100px rgb(0 0 0 / 70%);
  padding: 16px 10px;

  @include media-breakpoint-between(md, xl) {
    display: block !important;
  }

  button {
    width: 180px;
    height: 50px;
    border: 2px solid $white;
    border-radius: 70px;
    background-color: $success;
    background-size: cover;
    font-family: Poppins;
    font-size: 16px;
    color: $white;
    text-decoration: none solid rgb(255 255 255);
    text-align: center;
    box-shadow: 0 1px 20px rgb(0 0 0 / 20%);
    position: absolute;
    bottom: 100px;
    transform: translateX(-50%);
    left: 50%;
  }

  @include media-breakpoint-up(xl) {
    display: none;
  }

  .EachNav {
    text-align: center;

    i {
      font-size: 28px;
    }

    &.active {
      i {
        color: $success;
      }
    }

    span {
      font: {
        family: $font-family-base;
        weight: $font-weight-medium;
        size: 11px;
      }

      color: #000;
      display: block;
    }
  }

  #FeaturesNav {
    display: flex;
    align-items: center;
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 5px;

    li {
      &.d-none {
        display: flex !important;
      }

      //----------------Uncomment the below code when we show fundraiser ---------------
      width: 16%;

      &:nth-last-child(1) {
        width: 20%;
      }

      &:nth-last-child(2) {
        width: 20%;
      }

      color: #000;
      text-align: center;
      flex-wrap: wrap;
      padding: 0;
      margin-bottom: 0;

      &:hover,
      &:focus {
        background-color: transparent;
        color: #000;
      }

      i {
        font-size: 28px;
        flex: 0 1 100%;
        max-width: 100%;
        color: #000;
      }

      &:hover,
      &:focus {
        i {
          color: $success;
        }
      }

      &:hover,
      &:focus {
        i {
          @include media-breakpoint-down(xl) {
            color: #000;
          }
        }
      }

      label {
        flex: 0 1 100%;
        max-width: 100%;
        font: {
          family: $font-family-base;
          weight: $font-weight-medium;
          size: 11px;
        }

        color: #000;
        display: block;
      }

      span {
        display: none;
      }

      &.active {
        i {
          color: $success !important;
        }
      }
    }
  }
}

body.question-page.modal-open {
  .MobileFeatureNav {
    #FeaturesNav {
      li {
        pointer-events: none;
        cursor: none;

        &:hover {
        }
      }
    }
  }
}
