@import 'bootstrap/scss/bootstrap';
@import 'bootstrap/scss/mixins';
@import '../../Assets/Styles/typography';
@import '../../Assets/Styles/colors';
@import '../../Assets/Styles/mixins';

.GlobalLoader {
  background: rgb(232 232 237 / 40%);
  backdrop-filter: blur(3px);
  position: fixed;
  inset: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  transition:
    opacity 0.32s cubic-bezier(0.4, 0, 0.6, 1) 80ms,
    visibility 0.32s step-end 80ms;

  & > div {
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .lottie-loader {
    width: 55px;
    height: 55px;

    svg {
      box-shadow: none;
    }
  }

  .progress {
    max-width: 150px;
    height: 10px;
    box-shadow: 0 0 4px rgb(0 0 0 / 15%);
    border: 1px solid $white;

    .progress-bar {
      background-color: $success;
    }

    z-index: 999999999999 !important;
  }

  .UploadCount {
    background-color: rgb(0 0 0 / 40%);
    color: $white;
    height: 18px;
    border-radius: 40px;
    text-align: center;
    width: max-content;
    padding: 0 10px;
    display: flex;
    align-items: center;
    margin-top: 20px;
    z-index: 999999999999 !important;
    font: {
      size: 9px;
      family: $font-family-base;
      weight: $font-weight-semibold;
    }
  }
}
