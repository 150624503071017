@import 'bootstrap/scss/bootstrap';
@import 'bootstrap/scss/mixins';
@import '../../../Assets/Styles/typography';
@import '../../../Assets/Styles/colors';
@import '../../../Assets/Styles/mixins';

.AudioViewModal {
  .modal-dialog {
    .modal-content {
      padding: 0 15px;

      .modal-header {
        justify-content: center;
        text-align: center;
        position: relative;
        border-bottom: none;
        align-items: flex-start;
        height: 100px;
        padding: 15px 0;

        i {
          position: absolute;
          font-size: 42px;
          color: $success;
          top: 27px;
        }
      }

      .modal-body {
        .CurrentUser {
          max-width: 427px;
          width: 100%;
          margin: auto;

          .row {
            align-items: center;
          }

          .Profile {
            width: 65px;
            text-align: center;
            flex: 0 1 65px;
            overflow: hidden;
            padding: 0;

            img {
              max-width: 100%;
              width: 100%;
              border-radius: 20px;
            }

            span {
              color: $title;
              display: block;
              line-height: 1.5;
              text-transform: capitalize;
              font: {
                size: 12px;
                family: $font-family-base;
                weight: $font-weight-regular;
              }

              &.Alpha {
                text-transform: capitalize;
                height: 65px;
                width: 65px;
                border-radius: 5px;
                background-color: rgba(29, 129, 123, 0.169);
                color: #1d817b;
                font-size: 26px;
                display: flex;
                align-items: center;
                justify-content: center;
              }
            }

            .imgWrapper {
              border-radius: 5px;
              background-color: rgba(29, 129, 123, 0.169);
              color: #1d817b;

              .new {
                font: {
                  size: 8px;
                  family: $font-family-base;
                  weight: $font-weight-medium;
                }

                background-color: $success;
                color: $white;
                position: absolute;
                left: 0;
                top: 0;
                z-index: 9;
                height: 13px;
                width: 30px;
                text-align: center;
                padding: 1px 0;
                border-bottom-right-radius: 5px;
                border-bottom-left-radius: 5px;
                border-top-right-radius: 5px;
              }
            }
          }

          .AudioWaves {
            flex: 0 1 calc(100% - 130px);
          }

          .ControllerIcon {
            width: 58px;
            height: 58px;
            flex: 0 1 58px;
            padding: 0;

            i {
              font-size: 58px;
              color: $success;
            }
          }

          .delete {
            margin-top: 24px;

            i {
              font-size: 24px;
              color: $title;
              cursor: pointer;
            }

            .deleteConfirmation {
              display: flex;
              flex-wrap: wrap;
              text-align: left;
              align-items: center;

              span {
                color: #333;
                margin: 15px auto auto;
                font : {
                  size: 14px;
                  family: $font-family-base;
                  weight: $font-weight-medium;
                }

                button {
                  margin-left: 10px;

                  @include button-success;

                  width: 130px;
                  height: 40px;
                  background-color: $white;
                  border: 1px solid #fc394d;
                  color: #fc394d;
                  font-size: 14px;

                  &:hover,
                  &:focus {
                    background-color: #fc394d !important;
                    border: 1px solid #fc394d;
                    color: $white;
                  }

                  &.NoDelete {
                    font-size: 14px;
                    background-color: $success;
                    border: none;
                    height: 40px;
                    color: $white;
                    border-radius: 50px;
                    padding: 16px 18px;

                    &:hover {
                      background-color: $title !important;
                      box-shadow: 0 2px 8px rgb(0 0 0 / 15%);
                      color: $white;
                      outline: none;
                    }
                  }

                  // @include media-breakpoint-down()
                }
              }
            }
          }
        }
      }

      .modal-footer {
        .OtherUsers {
          width: 100%;

          .Profile {
            width: 65px;
            text-align: center;
            flex: 0 1 65px;
            overflow: hidden;
            padding: 0;
            margin: 5px;

            .imgWrapper {
              position: relative;
              width: 65px;
              height: 65px;
              cursor: pointer;

              img {
                max-width: 100%;
                width: 100%;
                border-radius: 20px;
              }

              i {
                font-size: 18px;
                color: $white;
                position: absolute;
                z-index: 999;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
              }

              &:hover {
                i {
                  color: $success;
                }
              }

              border-radius: 5px;
              background-color: rgba(29, 129, 123, 0.169);
                color: #1d817b;

              .new {
                font: {
                  size: 8px;
                  family: $font-family-base;
                  weight: $font-weight-medium;
                }

                background-color: $success;
                color: $white;
                position: absolute;
                left: 0;
                top: 0;
                z-index: 9;
                height: 13px;
                width: 30px;
                text-align: center;
                padding: 1px 0;
                border-bottom-right-radius: 5px;
                border-bottom-left-radius: 5px;
                border-top-right-radius: 5px;
              }
            }

            span {
              color: $title;
              display: block;
              line-height: 1.5;
              font: {
                size: 12px;
                family: $font-family-base;
                weight: $font-weight-regular;
              }

              &.Alpha {
                text-transform: capitalize;
                height: 65px;
                width: 65px;
                background: #f3f0fe;
                border-radius: 5px;
                color: #9b7bf6;
                font-size: 36px;
                font-weight: $font-weight-semibold;
                display: flex;
                align-items: center;
                justify-content: center;
              }
            }
          }
        }
      }
    }
  }
}

.AudioViewMobile {
  .headerMobile {
    display: flex;
    position: relative;
    align-items: flex-start;
    height: 100px;
    padding: 15px 0;
    border-bottom: none !important;
    justify-content: center !important;
    text-align: center;

    i {
      position: absolute;
      font-size: 42px;
      color: $success;
      top: 27px;
    }
  }

  .CurrentUser {
    max-width: 427px;
    width: 100%;
    margin: auto;

    .row {
      align-items: center;
    }

    .Profile {
      width: 65px;
      text-align: center;
      flex: 0 1 65px;
      overflow: hidden;
      padding: 0;

      img {
        max-width: 100%;
        width: 100%;
        border-radius: 20px;
      }

      span {
        color: $title;
        display: block;
        line-height: 1.5;
        text-transform: capitalize;
        font: {
          size: 12px;
          family: $font-family-base;
          weight: $font-weight-regular;
        }

        &.Alpha {
          text-transform: capitalize;
          height: 65px;
          width: 65px;
          background: #f3f0fe;
          border-radius: 5px;
          color: #9b7bf6;
          font-size: 26px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }

      .imgWrapper {
        background: #f3f0fe;
        border-radius: 5px;
        color: #9b7bf6;

        .new {
          font: {
            size: 8px;
            family: $font-family-base;
            weight: $font-weight-medium;
          }

          background-color: $success;
          color: $white;
          position: absolute;
          left: 0;
          top: 0;
          z-index: 9;
          height: 13px;
          width: 30px;
          text-align: center;
          padding: 1px 0;
          border-bottom-right-radius: 5px;
          border-bottom-left-radius: 5px;
          border-top-right-radius: 5px;
        }
      }
    }

    .AudioWaves {
      flex: 0 1 calc(100% - 130px);
    }

    .ControllerIcon {
      width: 58px;
      height: 58px;
      flex: 0 1 58px;
      padding: 0;

      i {
        font-size: 58px;
        color: $success;
      }
    }

    .delete {
      margin-top: 24px;

      i {
        font-size: 24px;
        color: $title;
        cursor: pointer;
      }

      .deleteConfirmation {
        display: flex;
        flex-wrap: wrap;
        text-align: center;
        align-items: center;

        //margin-top: 15px;
        span {
          color: #333;
          margin: auto;
          margin-top: 20px;
          font : {
            size: 14px;
            family: $font-family-base;
            weight: $font-weight-medium;
          }

          button {
            margin-left: 10px;

            @include button-success;

            width: 130px;
            height: 40px;
            background-color: $white;
            border: 1px solid #fc394d;
            color: #fc394d;
            font-size: 14px;

            &:hover,
            &:focus {
              background-color: #fc394d !important;
              border: 1px solid #fc394d;
              color: $white;
            }

            &.NoDelete {
              font-size: 14px;
              background-color: $success;
              border: none;
              color: $white;
              border-radius: 50px;
              padding: 16px 18px;
              height: 40px;

              &:hover,
              &:focus {
                background-color: $success !important;
                border: 1px solid $success;
                color: $white;
              }
            }
          }
        }
      }
    }
  }

  .thumbnailWrapper {
    padding: 8px 0;
    display: flex;
    flex-wrap: nowrap;
    overflow-x: scroll;
    max-width: 100%;
    justify-content: flex-start;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none;

    .eachThumbnail {
      width: 65px;
      height: 65px;
      border-radius: 5px;
      overflow: hidden;
      flex: 1 0 65px;
      margin-right: 10px;
      position: relative;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #f3f0fe;
      color: #9b7bf6;
      max-width: 65px;
      font: {
        size: 48px;
        family: $font-family-base;
        weight: $font-weight-medium;
      }

      img {
        width: 100%;
        max-width: 100%;
      }

      .new {
        font: {
          size: 8px;
          family: $font-family-base;
          weight: $font-weight-medium;
        }

        background-color: $success;
        color: $white;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 9;
        height: 13px;
        width: 30px;
        text-align: center;
        padding: 1px 0;
        border-bottom-right-radius: 5px;
        border-bottom-left-radius: 5px;
        border-top-right-radius: 5px;
      }

      i {
        font-size: 18px;
        color: $white;
        position: absolute;
        z-index: 999;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }

      &:hover {
        i {
          color: $success;
        }
      }
    }

    .NoAudio {
      color: #777;
      text-align: center;
      min-height: 81px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      margin: auto;
      font: {
        size: 15px;
        family: $font-family-base;
        weight: $font-weight-regular;
      }
    }
  }

  .OtherUsers {
    width: 100%;
    padding-top: 20px;
    border-top: 1px solid $bg;
    margin-top: 20px;

    .Profile {
      width: 65px;
      text-align: center;
      flex: 0 1 65px;
      overflow: hidden;
      padding: 0;
      margin: 5px;

      .imgWrapper {
        position: relative;
        width: 65px;
        height: 65px;
        cursor: pointer;

        img {
          max-width: 100%;
          width: 100%;
          border-radius: 20px;
        }

        i {
          font-size: 18px;
          color: $white;
          position: absolute;
          z-index: 999;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
        }

        &:hover {
          i {
            color: $success;
          }
        }

        background: #f3f0fe;
        border-radius: 5px;
        color: #9b7bf6;

        .new {
          font: {
            size: 8px;
            family: $font-family-base;
            weight: $font-weight-medium;
          }

          background-color: $success;
          color: $white;
          position: absolute;
          left: 0;
          top: 0;
          z-index: 9;
          height: 13px;
          width: 30px;
          text-align: center;
          padding: 1px 0;
          border-bottom-right-radius: 5px;
          border-bottom-left-radius: 5px;
          border-top-right-radius: 5px;
        }
      }

      span {
        color: $title;
        display: block;
        line-height: 1.5;
        font: {
          size: 12px;
          family: $font-family-base;
          weight: $font-weight-regular;
        }

        &.Alpha {
          text-transform: capitalize;
          height: 65px;
          width: 65px;
          background: #f3f0fe;
          border-radius: 5px;
          color: #9b7bf6;
          font-size: 36px;
          font-weight: $font-weight-semibold;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }
}
