@import 'bootstrap/scss/bootstrap';
@import 'bootstrap/scss/mixins';
@import '../../Assets/Styles/typography';
@import '../../Assets/Styles/colors';
@import '../../Assets/Styles/mixins';
@import 'https://fonts.googleapis.com/css2?family=Roboto:wght@500&display=swap';

#AuthPage {
  height: 100%;
  width: 100%;
  overflow-x: hidden;
  overflow: overlay;

  @include media-breakpoint-down(xl) {
    overflow: unset;
  }

  .container {
    padding: 0;
    background-color: $white;
    min-height: 100vh;
    height: max-content;
    max-height: 1200px;
    overflow-x: hidden;

    @media (width <= 1440px) {
      max-width: 100%;
      width: 100%;
      overflow: hidden;
    }

    .imgHolder {
      background-repeat: no-repeat;
      background-image: url('https://butterflyv2-production-assets.nyc3.digitaloceanspaces.com/image/81774a7b-0596-4dd7-aaf0-cd9ae5dbf709-Sign-in.jpg');
      background-size: cover;
      background-position: center;
      width: 100%;
      min-height: 100vh;
      height: 100%;
      position: relative;

      .overlay {
        position: absolute;
        background: rgb(0 0 0);
        background: linear-gradient(0deg, rgb(0 0 0 / 60%) 0%, rgb(0 0 0 / 0%) 100%);
        width: 100%;
        height: 100%;
      }

      .logo {
        position: absolute;
        margin: 60px 0 0 60px;
        width: 215px;
        height: 66px;

        @include media-breakpoint-between(md, xl) {
          margin: 40px 0 0 40px;
        }

        img {
          width: 100%;
        }
      }

      .title {
        font: {
          size: 80px;
          family: $font-family-base;
          weight: $font-weight-bold;
        }

        color: $white;
        position: absolute;
        bottom: 64px;
        left: 60px;
        line-height: 1;

        span {
          font: {
            size: 26px;
            family: $font-family-base;
            weight: $font-weight-medium;
          }

          line-height: 1.3;
          padding-top: 16px;
          display: block;
        }

        @include media-breakpoint-between(md, xl) {
          font-size: 60px;
          left: 40px;
        }
      }
    }

    .m-logo {
      margin: 43px auto 0;
      text-align: center;

      img {
        max-width: 152px;
      }

      .title {
        font: {
          size: 20px;
          family: $font-family-base;
          weight: $font-weight-semibold;
        }
      }
    }

    .lhs {
      position: relative;

      .formHolder {
        padding: 56px 80px 56px 74px;
        margin-bottom: 10px;

        @include media-breakpoint-between(md, xl) {
          padding: 20px 20px 20px 0;
        }

        @include media-breakpoint-down(md) {
          padding: 33px;
          margin-bottom: 40px;
        }

        ul {
          align-items: center;
          justify-content: center;
          border: none;
          margin-bottom: 43px;

          &.nav-tabs {
            li {
              border: none;
              width: calc(100% / 2);

              button {
                border: none;
                padding: 20px 0;
                width: 100%;

                font: {
                  size: 24px;
                  family: $font-family-base;
                  weight: $font-weight-semibold;
                }

                color: #8c8c8c;
                border-bottom: 4px solid $bg;

                &.active {
                  color: $title;
                  border-bottom: 4px solid $success;

                  &.signin {
                    color: $title;
                  }
                }
              }

              &:first-of-type {
                button.active {
                  color: $title;
                }
              }
            }
          }
        }

        .tab-content {
          text-align: center;

          .tab-pane {
            &.signIn {
              h2 {
                font: {
                  size: 24px;
                  family: $font-family-base;
                  weight: $font-weight-medium;
                  letter-spacing: 1px;
                }

                color: $title;
                text-align: center;
                margin-bottom: 28px;
              }

              button.googleCta,
              .googleCta {
                max-width: 306px;
                width: 100%;
                height: 50px;
                padding: 0;
                border: 1px solid $grey-1;
                border-radius: 50px;
                background-color: $white;

                font: {
                  size: 14px;
                  family: 'Roboto', sans-serif;
                }

                color: #1d113d;
                text-decoration: none;
                text-align: center;

                &:hover,
                &:focus {
                  color: #4285f4 !important;
                  transition: background-color 0.218s;
                  background-color: transparent !important;
                  box-shadow: none;
                }

                span {
                  margin-right: 10px;
                  padding: 5px 7px;
                  background: $white;
                  border-radius: 50%;
                }
              }

              span.or {
                display: block;
                margin: 10px;

                font: {
                  size: 14px;
                  family: $font-family-dada-grotesk;
                  weight: $font-weight-regular;
                }

                color: $title;
                text-align: center;
              }

              span.fp {
                display: block;
                margin: 22px auto 29px;
                cursor: pointer;

                font: {
                  size: 14px;
                  family: $font-family-dada-grotesk;
                  weight: $font-weight-regular;
                }

                color: $success;
                text-align: center;
                max-width: max-content;

                &:hover {
                  text-decoration: underline;
                }
              }

              input[type='text'],
              input[type='email'],
              input[type='password'] {
                max-width: 306px;
                width: 100%;
                height: 50px;
                padding: 0 8px 0 50px;
                border: 1px solid $grey-1;
                border-radius: 50px;
                background-color: $white;
                background-size: cover;

                font: {
                  size: 14px;
                  family: $font-family-dada-grotesk;
                  weight: $font-weight-regular;
                }

                color: #121212;
                text-decoration: none;
                transition: 0.3s;

                &.is-invalid {
                  border-color: #dc3545;
                  padding-right: calc(1.5em + 0.75rem);
                  background-repeat: no-repeat;
                  background-position: right calc(0.375em + 0.1875rem) center;
                  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) !important;
                  transition: none;
                }

                &.is-valid {
                  padding-right: calc(1.5em + 0.75rem);
                  background-repeat: no-repeat;
                  background-position: right calc(0.375em + 0.1875rem) center;
                  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) !important;
                  transition: none;
                }

                &:focus {
                  box-shadow: none;
                }
              }

              input::placeholder {
                font: {
                  size: 14px;
                  family: $font-family-dada-grotesk;
                  weight: $font-weight-regular;
                }
              }

              .inputWithIcon {
                position: relative;
                max-width: 306px;
                margin: auto;
                margin-bottom: 10px;
                text-align: left;
              }

              .inputWithIcon i {
                position: absolute;
                left: 11px;
                color: #000;
                top: 8px;
                padding: 9px 8px;
                transition: 0.3s;
                font-size: 15px;
                z-index: 9;

                &.fa-butterflylock,
                &.fa-butterflymyaccount {
                  font-size: 20px;
                  top: 5px;
                }

                &.icon {
                  left: unset;
                  right: 20px;
                  cursor: pointer;
                }
              }

              button {
                @include button-success;

                width: 105px;
              }
            }

            &.register {
              button.googleCta {
                max-width: 306px;
                width: 100%;
                height: 50px;
                padding: 0;
                border: 1px solid $grey-1;
                border-radius: 50px;
                background-color: $white;

                font: {
                  size: 14px;
                  family: 'Roboto', sans-serif;
                }

                color: #1d113d;
                text-decoration: none;
                text-align: center;

                &:hover,
                &:focus {
                  color: #4285f4;
                  transition: color 0.218s;
                  background-color: transparent !important;
                  box-shadow: none;
                }

                span {
                  margin-right: 10px;
                  padding: 5px 7px;
                  background: $white;
                  border-radius: 50%;
                }
              }

              span.or {
                display: block;
                margin: 10px;

                font: {
                  size: 14px;
                  family: $font-family-dada-grotesk;
                  weight: $font-weight-regular;
                }

                color: $title;
                text-align: center;
              }

              span.note {
                display: block;
                margin: 0 auto 14px;

                font: {
                  size: 12px;
                  family: $font-family-dada-grotesk;
                  weight: $font-weight-regular;
                }

                color: $title;
                text-align: left;
                max-width: 306px;

                a {
                  color: $success;
                  font-weight: $font-weight-medium;
                }
              }

              input[type='text'],
              input[type='email'],
              input[type='password'] {
                max-width: 306px;
                width: 100%;
                height: 50px;
                padding: 0 8px 0 50px;
                border: 1px solid $grey-1;
                border-radius: 50px;
                background-color: $white;
                background-size: cover;

                font: {
                  size: 14px;
                  family: $font-family-dada-grotesk;
                  weight: $font-weight-regular;
                }

                color: #121212;
                text-decoration: none;
                transition: 0.3s;

                &:focus {
                  box-shadow: none;
                }

                &.is-invalid {
                  border-color: #dc3545;
                  padding-right: calc(1.5em + 0.75rem);
                  background-repeat: no-repeat;
                  background-position: right calc(0.375em + 0.1875rem) center;
                  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
                  transition: none;
                }

                &.is-valid {
                  padding-right: calc(1.5em + 0.75rem);
                  background-repeat: no-repeat;
                  background-position: right calc(0.375em + 0.1875rem) center;
                  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) !important;
                  transition: none;
                }
              }

              input[type='password'] {
                padding: 0 50px !important;
              }

              input::placeholder {
                font: {
                  size: 14px;
                  family: $font-family-dada-grotesk;
                  weight: $font-weight-regular;
                }
              }

              .inputWithIcon {
                position: relative;
                max-width: 306px;
                margin: auto;
                margin-bottom: 10px;
                text-align: left;
              }

              .inputWithIcon i {
                position: absolute;
                left: 11px;
                color: #000;
                top: 8px;
                padding: 9px 8px;
                transition: 0.3s;
                font-size: 15px;
                z-index: 9;

                &.fa-butterflylock,
                &.fa-butterflymyaccount {
                  font-size: 20px;
                  top: 5px;
                }

                &.icon {
                  left: unset;
                  right: 20px;
                  cursor: pointer;
                }
              }

              button {
                @include button-success;

                width: 105px;
              }
            }

            .invalid-feedback {
              padding-left: 20px;
            }
          }
        }

        &.forgotPassword {
          align-items: center;
          display: flex;
          height: calc(100vh - 52px);
          justify-content: center;
          flex-direction: row;

          @include media-breakpoint-down(md) {
            height: calc(100vh - 122px);
            padding-top: 0;
            margin-bottom: 0;
          }

          .tab-content {
            width: 306px;

            @include media-breakpoint-down(md) {
              width: 100%;
              max-width: 306px;
            }

            h2 {
              font: {
                size: 24px;
                family: $font-family-base;
                weight: $font-weight-medium;
              }

              color: $title;
              text-align: center;
              margin-bottom: 28px;
            }

            p {
              font: {
                size: 16px;
                family: $font-family-base;
                weight: $font-weight-regular;
              }

              span {
                display: block;
                font-weight: $font-weight-medium;
                color: $success;
              }

              color: $title;
            }

            input[type='text'],
            input[type='email'],
            input[type='password'] {
              max-width: 306px;
              width: 100%;
              height: 50px;
              padding: 0 8px 0 50px;
              border: 1px solid $grey-1;
              border-radius: 50px;
              background-color: $white;
              background-size: cover;

              font: {
                size: 14px;
                family: $font-family-base;
                weight: $font-weight-regular;
              }

              color: #121212;
              text-decoration: none;
              transition: 0.3s;

              &.is-invalid {
                border-color: #dc3545;
                padding-right: calc(1.5em + 0.75rem);
                background-repeat: no-repeat;
                background-position: right calc(0.375em + 0.1875rem) center;
                background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) !important;
                transition: none;
              }

              &.is-valid {
                padding-right: calc(1.5em + 0.75rem);
                background-repeat: no-repeat;
                background-position: right calc(0.375em + 0.1875rem) center;
                background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) !important;
                transition: none;
              }

              &:focus {
                box-shadow: none;
              }
            }

            input::placeholder {
              font: {
                size: 14px;
                family: $font-family-base;
                weight: $font-weight-regular;
              }
            }

            .inputWithIcon {
              position: relative;
              max-width: 306px;
              margin: auto;
              margin-bottom: 10px;
              text-align: left;
            }

            .inputWithIcon i {
              position: absolute;
              left: 11px;
              color: #000;
              top: 8px;
              padding: 9px 8px;
              transition: 0.3s;
              font-size: 15px;
              z-index: 9;

              &.fa-butterflylock,
              &.fa-butterflymyaccount {
                font-size: 20px;
                top: 5px;
              }

              &.icon {
                left: unset;
                right: 20px;
                cursor: pointer;
              }
            }

            span.note {
              display: block;
              margin: 0 auto 14px;

              font: {
                size: 12px;
                family: $font-family-base;
                weight: $font-weight-light;
              }

              color: $title;
              text-align: left;
              max-width: 306px;

              a {
                color: $success;
                font-weight: $font-weight-medium;
              }
            }

            button {
              margin-top: 10px;

              @include button-success;

              width: 100%;
            }

            span.DE {
              display: inline-block;
              margin: 33px 0 29px;
              font: {
                size: 14px;
                family: $font-family-base;
                weight: $font-weight-regular;
              }

              .note {
                font-size: 12px;
              }
            }

            span.Bs {
              display: inline-block;
              cursor: pointer;
              color: $success;

              &:hover {
                text-decoration: underline;
              }

              font: {
                size: 14px;
                family: $font-family-base;
                weight: $font-weight-regular;
              }

              text-align: center;
            }
          }

          &.verifyMail {
            .tab-content {
              p {
                margin-bottom: 55px;
              }

              input::placeholder {
                font: {
                  size: 14px;
                  family: $font-family-base;
                  weight: $font-weight-regular;
                }
              }

              .inputWithIcon i {
                font-size: 22px;
                padding: 6px;
                color: $title;
                z-index: 9;
              }
            }
          }
        }
      }

      .footer {
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        color: #777;

        p {
          font: {
            size: 14px;
            family: $font-family-dada-grotesk;
            weight: $font-weight-regular;
          }

          margin-bottom: 30px;
        }
      }
    }
  }
}
