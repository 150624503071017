@import 'bootstrap/scss/bootstrap';
@import 'bootstrap/scss/mixins';
@import '../Assets/Styles/typography';
@import '../Assets/Styles/colors';
@import '../Assets/Styles/mixins';

#Layout {
  &.blocked-layout,
  &.scroll-not-allowd {
    pointer-events: none;
    overflow: hidden;

    .CenterSection {
      overflow: hidden;
      pointer-events: none;
    }
  }

  width: 100%;
  height: 100%;
  background-color: $bg; // ---------------- Added due to left sticky nav bar
  // overflow-x: hidden;
  // position: relative;
  @include media-breakpoint-up(md) {
    overflow-x: hidden;
    position: relative;
  }

  @include media-breakpoint-down(md) {
    height: unset;
  }

  .layoutwrapper {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: relative;

    @include media-breakpoint-between(md, xl) {
      height: calc(100% - 80px);
    }

    @include media-breakpoint-down(md) {
      overflow: scroll;
    }

    aside {
      max-width: 70px; // ---------------- Commented due to left sticky nav bar
      flex: 0 1 70px; // ---------------- Commented due to left sticky nav bar
      background-color: $title;
      align-items: center;

      //position: relative; // ---------------- Commented due to left sticky nav bar
      position: fixed; // ---------------- Added due to left sticky nav bar
      left: 0;
      height: 100%;
      width: 70px;

      @include media-breakpoint-between(md, xl) {
        height: calc(100% - 70px);
      }

      @include media-breakpoint-down(md) {
        display: none;
      }

      .logo {
        width: 50px;
        margin: auto;
        padding-top: 20px;
        margin-bottom: 55px;

        img {
          cursor: pointer;
          max-width: 100%;
        }
      }

      nav {
        position: relative;

        ul {
          list-style: none;
          padding: 0;
          text-align: center;

          li {
            margin-bottom: 10px;
            height: 57px;
            cursor: pointer;

            i {
              color: $grey-1;
              font-size: 24px;
              transition: 0.4s;
              position: relative;

              span {
                position: absolute;
                color: $white;
                opacity: 1;
                top: -10px;
                right: -10px;
                width: 18px;
                height: 18px;
                background: $success;
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
              }
            }

            span {
              display: block;
              opacity: 0;
              transition: 0.4s;
              line-height: 1;
              font: {
                size: 12px;
                family: $font-family-dada-grotesk;
                weight: $font-weight-medium;
              }

              color: $bg;
            }

            &.active {
              i {
                background-color: $hover-button;
                padding: 8px;
                color: $white;
                border-radius: 10px;
              }

              &:hover,
              &:focus {
                cursor: pointer;

                i {
                  color: $white;
                  transition: 0.4s;
                }

                span {
                  opacity: 0;
                }
              }
            }

            &:hover,
            &:focus {
              cursor: pointer;

              i {
                color: $success;
                transition: 0.4s;
              }

              span {
                opacity: 1;
              }
            }
          }
        }

        &.signout {
          width: 100%;
          position: absolute;
          bottom: 0;

          @include media-breakpoint-between(md, xl) {
            bottom: 10px;
          }

          .CreatePage {
            i {
              background: $success;
              padding: 10px;
              border-radius: 50%;
              color: $white;
              margin-bottom: 4px;
            }

            &:hover {
              i {
                color: $white;
              }
            }
          }
        }
      }
    }

    main {
      // max-width: calc(100% - 70px);// ---------------- Commented due to left sticky nav bar
      // flex: calc(100% - 70px);// ---------------- Commented due to left sticky nav bar
      max-width: 100%; // ---------------- Added due to left sticky nav bar
      flex: 100%; // ---------------- Added due to left sticky nav bar
      background-color: $bg;
      height: 100%;

      @include media-breakpoint-down(md) {
        max-width: 100%;
        flex: 100%;
      }

      @media (width <= 1440px) {
        padding-left: 70px;
      }

      @include media-breakpoint-down(md) {
        padding-left: unset;

        //overflow: scroll;
      }

      .MainSection {
        display: flex;
        width: 100%;
        flex-wrap: nowrap;
        height: 100%;

        @include media-breakpoint-between(md, xl) {
        }
      }
    }

    &:has(.active.panel-wrap) {
      .CreatePostFixed {
        display: none;
      }
    }
  }
}

body:has(.MobileFeatureNav.d-none) #Layout .layoutwrapper {
  @include media-breakpoint-between(md, xl) {
    height: 100% !important;
  }
}

body {
  &.message-board,
  &.gallery-add-page.modal-open,
  &.personal-message,
  &.audio-message,
  &.notification-page,
  &.search-page,
  &.settings-page,
  &.event-page:has(.active.panel-wrap .CreateEvent),
  &.gallery-add-page.modal-open,
  &.fundraiser:has(.active.panel-wrap .fundraiser-popup) {
    @include media-breakpoint-down(md) {
      overflow: hidden;
    }

    .MobileFeatureNav #FeaturesNav li {
      opacity: 0;
    }
  }

  &.legacy.modal-open #Layout .layoutwrapper,
  &.legacy.modal-open #Layout .layoutwrapper .LeftSection {
    overflow: unset !important;
  }

  &.legacy.modal-open {
    .card-footer .legacy-highlight.publishButton {
      &::before {
        content: '1';
        border: 3px solid $success;
        left: -40px;
        position: absolute;
        background: white;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $success;
        font: {
          size: 16px;
          family: $font-family-base;
          weight: $font-weight-bold;
        }
      }
    }

    #FeaturesNav li {
      &.legacy-highlight {
        position: relative;
        z-index: 99999;

        &::before {
          border: 3px solid $success;
          left: -40px;
          position: absolute;
          background: white;
          width: 30px;
          height: 30px;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          color: $success;
          font: {
            size: 16px;
            family: $font-family-base;
            weight: $font-weight-bold;
          }

          @include media-breakpoint-down(xl) {
            content: none !important;
          }
        }

        &.DesktopQuestion {
          &::before {
            content: '2';

            @include media-breakpoint-down(xl) {
              content: none !important;
            }
          }
        }

        &.EventsModule {
          &::before {
            content: '3';

            @include media-breakpoint-down(xl) {
              content: none !important;
            }
          }
        }

        &.DesktopFundraiser {
          &::before {
            content: '4';

            @include media-breakpoint-down(xl) {
              content: none !important;
            }
          }
        }
      }
    }
  }

  &.PageMemberOnboarding.modal-open #Layout .layoutwrapper,
  &.PageMemberOnboarding.modal-open #Layout .layoutwrapper .LeftSection {
    overflow: unset !important;
  }

  &.PageMemberOnboarding.modal-open {
    .card-footer .PageMemberOnboarding-highlight.shareButton {
      &::before {
        content: '2';
        border: 3px solid $success;
        left: -40px;
        position: absolute;
        background: white;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $success;
        font: {
          size: 16px;
          family: $font-family-base;
          weight: $font-weight-bold;
        }
      }
    }

    .CenterSection .PageMemberOnboarding-highlight.CreatePostBtn {
      &::before {
        content: '1';
        border: 3px solid $success;
        left: -40px;
        position: absolute;
        background: white;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $success;
        font: {
          size: 16px;
          family: $font-family-base;
          weight: $font-weight-bold;
        }
      }
    }

    #FeaturesNav li {
      &.PageMemberOnboarding-highlight {
        position: relative;
        z-index: 99999;

        &::before {
          border: 3px solid $success;
          left: -40px;
          position: absolute;
          background: white;
          width: 30px;
          height: 30px;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          color: $success;
          font: {
            size: 16px;
            family: $font-family-base;
            weight: $font-weight-bold;
          }

          @include media-breakpoint-down(xl) {
            content: none !important;
          }
        }

        &.DesktopQuestion {
          &::before {
            content: '3';

            @include media-breakpoint-down(xl) {
              content: none !important;
            }
          }
        }

        &.EventsModule {
          &::before {
            content: '4';

            @include media-breakpoint-down(xl) {
              content: none !important;
            }
          }
        }

        &.DesktopGallery {
          &::before {
            content: '5';

            @include media-breakpoint-down(xl) {
              content: none !important;
            }
          }
        }
      }
    }
  }
}

body.event-page,
.gallery-add-page,
.fundraiser,
.member-page {
  @include media-breakpoint-down(md) {
    overflow: hidden;
  }
}
