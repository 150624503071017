@import 'bootstrap/scss/bootstrap';
@import 'bootstrap/scss/mixins';
@import '../../Assets/Styles/typography';
@import '../../Assets/Styles/colors';
@import '../../Assets/Styles/mixins';

// Google Material Colors
$bluegrey100: #cfd8dc;
$bluegrey300: #90a4ae;
$bluegrey500: #607d8b;
$bluegrey900: #263238;
$blueA400: #2979ff;

// Timing Functions
$easeInQuart: cubic-bezier(0.895, 0.03, 0.685, 0.22);
$easeOutQuart: cubic-bezier(0.165, 0.84, 0.44, 1);
$easeInOutQuart: cubic-bezier(0.77, 0, 0.175, 1);
$easeIn: $easeInQuart;
$easeOut: $easeOutQuart;
$easeInOut: $easeInOutQuart;

.panel.EventPage {
  .MoveTop {
    box-shadow: 0 0 10px silver;
    position: fixed;
    right: 10px;
    width: 40px;
    height: 40px;
    background-color: $success;
    border-radius: 50%;
    border: none;
    bottom: 10px;
    z-index: 99;
    cursor: pointer;

    i {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: $white;
    }
  }

  max-width: 760px;

  @include media-breakpoint-down(md) {
    height: 100%;
    overflow: auto;

    .MoveTop {
      bottom: 100px;
    }
  }

  .header {
    &:has(.view-all-events) {
      .icons {
        span {
          padding-bottom: 0;

          &:hover {
            color: $success;
          }
        }
      }
    }
  }

  .CreateEventEmpty {
    p {
      max-width: 300px;
      text-align: center;
      font: {
        size: 14px;
        family: $font-family-dada-grotesk;
        weight: $font-weight-regular;
      }

      color: #777;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      margin: 0;

      span {
        color: #333;
        font: {
          size: 20px;
          family: $font-family-dada-grotesk;
          weight: $font-weight-medium;
        }
      }

      @include media-breakpoint-down(md) {
        width: 100%;
      }
    }

    button {
      @include button-success;

      position: absolute;
      bottom: 20px;
      left: 50%;
      transform: translate(-50%, -20px);

      i {
        &.fa-eventsicon {
          font-size: 20px;
          margin-right: 8px;
        }
      }

      @include media-breakpoint-down(xl) {
        position: fixed;
        bottom: 100px;
        border: 2px solid $white;
      }

      height: 50px;
      border: 2px solid $white;
      box-shadow: 0 1px 20px rgb(0 0 0 / 20%);
      width: max-content;
    }
  }

  .CreateEvent {
    .ActionCta button {
      @include button-success;

      i {
        margin-right: 5px;
        font-size: 22px;
      }

      &.cancelCta {
        background-color: $white;
        border: 1px solid $success;
        color: $success;

        &:focus,
        &:hover {
          color: $white;
          background-color: $success;
        }

        width: 100px;

        @include media-breakpoint-up(md) {
          margin-right: 30px;
        }
      }
    }

    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    .ProfilePic {
      flex: 0 1 170px;
      max-width: 170px;

      @include media-breakpoint-down(md) {
        flex: 0 1 100%;
        max-width: 100%;
      }

      .ImgCroper {
        width: 143px;
        height: 143px;
        border-radius: 50%;
        border: 1px dashed #777;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        position: relative;

        .ImgWrapper {
          width: 143px;
          height: 143px;
          border-radius: 50%;
          overflow: hidden;
          display: flex;
          justify-content: center;
        }

        span {
          i {
            color: $success;
            display: block;
            font-size: 34px;
            margin-bottom: 10px;
          }

          color: $title;
          display: block;
          font: {
            size: 12px;
            family: $font-family-base;
            weight: $font-weight-regular;
          }

          &.cropImg {
            border: 1px solid $bg;
            padding: 8px;
            border-radius: 50%;
            width: 40px;
            height: 40px;
            position: absolute;
            background-color: $white;
            bottom: 0;
            right: 0;

            i {
              color: $success;
              font-size: 20px;
            }
          }
        }

        img {
          width: auto;
          height: 143px;
        }

        &:hover {
          span i {
            &.noImg {
              color: $title;
            }

            color: $success;
          }

          span.cropImg {
            i {
              color: $title;
            }
          }
        }

        p {
          &.text.text-danger.errorMessage {
            position: absolute;
            bottom: -60px;
            font: {
              size: 14px;
              family: $font-family-base;
              weight: $font-weight-regular;
            }

            width: 140px !important;
            height: 40px !important;

            @include media-breakpoint-down(md) {
              font-size: 12px;
              line-height: 1.1;
              bottom: 20px;
              right: -100%;
            }
          }
        }
      }
    }

    .FormSection {
      flex: 0 1 calc(100% - 170px);
      max-width: calc(100% - 170px);

      @include media-breakpoint-down(md) {
        flex: 0 1 100%;
        max-width: 100%;
        margin-top: 20px;
      }

      label {
        color: $title;
        font: {
          size: 14px;
          family: $font-family-dada-grotesk;
          weight: $font-weight-medium;
        }

        sup {
          font-size: 15px;
          top: 0;
        }
      }

      .input-group {
        margin-bottom: 24px;

        input {
          height: 40px;
          border-radius: 10px;
          border: 1px solid $grey-1;
          color: #121212;
          outline: none;
          font: {
            size: 16px;
            family: $font-family-dada-grotesk;
            weight: $font-weight-regular;
          }
        }

        textarea {
          border-radius: 10px !important;
          border: 1px solid $grey-1;
          color: #121212;
          height: 70px;
          resize: none;
          width: 100% !important;
          border-top-right-radius: 10px;
          border-bottom-right-radius: 10px;
          outline: none;
          font: {
            size: 16px;
            family: $font-family-dada-grotesk;
            weight: $font-weight-regular;
          }

          &:focus,
          &:hover {
            box-shadow: none;
            outline: none;
          }
        }

        span.text-count {
          width: 100%;
          text-align: right;
          position: absolute;
          bottom: -20px;
        }

        .address-autocomplete {
          width: 100%;
        }
      }

      .MemberSelect {
        i {
          font-size: 21px;
          margin-right: 5px;
          color: $success;
        }

        display: inline-flex;
        color: $title;
        cursor: pointer;

        &:hover {
          color: $success;
        }
        font: {
          size: 14px;
          family: $font-family-dada-grotesk;
          weight: $font-weight-medium;
        }

        margin-bottom: 50px;
      }

      .react-datepicker-wrapper {
        @include media-breakpoint-down(md) {
          padding-right: 0 !important;

          .mb-4.row {
            padding-right: 0 !important;
          }
        }

        input {
          &.form-control {
            height: 40px;
            border-radius: 10px;
            border: 1px solid $grey-1;
            color: #121212;
            font: {
              size: 16px;
              family: $font-family-dada-grotesk;
              weight: $font-weight-regular;
            }

            &:focus {
              outline: none;
              box-shadow: none;
            }
          }

          &:read-only {
            outline: none;
            box-shadow: none;
          }
        }
      }

      .react-datepicker {
        button {
          padding: unset;
          background-color: transparent;
          height: unset;
          margin-top: 8px;

          &:hover,
          &:active {
            box-shadow: none;
          }
        }
      }

      .react-datepicker__header {
        background-color: $success;
      }

      .react-datepicker__day--selected {
        background-color: $success;
        color: $white;
      }

      .react-datepicker__time-container
        .react-datepicker__time
        .react-datepicker__time-box
        ul.react-datepicker__time-list {
        overflow-y: overlay;
      }

      .react-datepicker__time-container
        .react-datepicker__time
        .react-datepicker__time-box
        ul.react-datepicker__time-list
        li.react-datepicker__time-list-item--selected {
        background-color: $success;
        color: $white;
      }

      .react-datepicker__header {
        background-color: $success;
        color: $white;
      }

      .react-datepicker__day-name,
      .react-datepicker__current-month,
      .react-datepicker-time__header,
      .react-datepicker-year-header {
        color: $white;
      }

      .react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle::before,
      .react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle::after {
        border-bottom-color: $success;
      }

      .mobile {
        display: none;

        @include media-breakpoint-down(md) {
          display: flex;

          & > .row {
            padding-right: 0;
          }
        }
      }

      .notMobile {
        display: flex;

        @include media-breakpoint-down(md) {
          display: none;
        }
      }
    }
  }

  .MemberHeader {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 8px 11px 24px;

    .MembersCount {
      font: {
        family: $font-family-dada-grotesk;
        weight: $font-weight-medium;
        size: 16px;
      }

      color: $title;
    }

    .MembersSelectAll {
      color: $success;
      cursor: pointer;

      .fa {
        margin-right: 8px;
      }
    }
  }

  .MemberBody {
    width: 100%;

    button {
      @include button-success;

      i {
        margin-right: 5px;
      }

      @include media-breakpoint-down(md) {
        font-size: 16px;
        margin-top: 15px;
      }
    }

    .cancelCta {
      margin-left: 20px;

      @include button-outline;

      @include media-breakpoint-down(md) {
        font-size: 16px;
      }
    }

    ul {
      padding: 0;
      display: flex;
      flex-wrap: wrap;
      column-gap: 2.5rem;

      li {
        list-style: none;
        padding: 10px;
        flex: 0 1 calc(50% - 1.25rem);
        max-width: calc(50% - 1.25rem);

        @include media-breakpoint-down(md) {
          flex: 0 1 calc(100% - 1.25rem);
          max-width: calc(100% - 1.25rem);
        }

        &.EachMember {
          display: flex;
          flex-wrap: nowrap;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          border-bottom: 1px solid $bg;

          .MemberDetail {
            .owner {
              @include ProfilePic;

              .ownerPic {
                max-width: 60px;

                img,
                .ownerAlpha {
                  width: 60px;
                  height: 60px;
                  font-size: 25px;
                }
              }

              .ownerName {
                max-width: calc(100% - 60px);
                flex: calc(100% - 60px);
                color: #333;
                font: {
                  family: $font-family-dada-grotesk;
                  weight: $font-weight-medium;
                  size: 16px;
                }

                padding-left: 10px;
                line-height: 1.2;

                span {
                  display: block;
                  font: {
                    family: $font-family-dada-grotesk;
                    weight: $font-weight-regular;
                    size: 12px;
                  }

                  color: #777;
                }
              }
            }
          }

          .IconsList {
            display: inline-flex;
            align-items: center;

            .unblock-icon {
              cursor: pointer;

              i {
                color: $success;
              }

              span {
                margin-left: 5px;
                font-size: 14px;
                color: $title;
              }

              &:hover {
                i {
                  color: #777;
                }

                span {
                  color: $success;
                }
              }
            }

            i {
              cursor: pointer;
              color: $success;
              margin-left: 16px;

              &:hover {
                color: $hover-button;
              }
            }

            .checkbox {
              display: inline-block;

              .majesticCheckbox {
                $boxSize: 16px * 1.25;
                $checkThickness: 2px;

                cursor: pointer;
                display: flex;
                min-height: $boxSize;
                align-items: center;
                line-height: 1;
                user-select: none;
                color: $success;
                font-weight: normal;

                input {
                  display: none;
                }

                span {
                  position: relative;
                  display: flex;
                  width: 25px;
                  height: 25px;
                  border: 1px solid $success;
                  box-sizing: border-box;
                  overflow: hidden;
                  margin-right: 16px / 2;
                  border-radius: 8px;
                  box-shadow: inset 0 1px 2px rgb(0 0 0 / 20%);

                  i {
                    position: relative;
                    width: 20px;
                    height: 20px;
                    margin-left: 0;
                    top: 50%;
                    left: 50%;
                    transform: rotate(-45deg) translateX(0%) translateY(-60%) scale(0.8);
                    transform-origin: center left;

                    &::before,
                    &::after {
                      content: '';
                      position: absolute;
                      background-color: $white;
                      border-radius: $boxSize;
                    }

                    &::before {
                      height: 0%;
                      top: 50%;
                      left: 0;
                      width: $checkThickness;
                    }

                    &::after {
                      width: 0%;
                      bottom: 0;
                      left: 0;
                      height: $checkThickness;
                    }
                  }
                } // Box

                // If selected
                input:checked ~ span {
                  border: none;
                  background-color: $success;
                  box-shadow: none;

                  &.disabled {
                    background-color: #e9ecef;

                    i::before,
                    i::after {
                      background-color: #333;
                    }
                  }

                  i::before {
                    height: 50%;
                    transition: height 300ms $easeIn;
                  }

                  i::after {
                    width: 100%;
                    transition: width 300ms 300ms $easeOut;
                  }
                }
              }
            }
          }
        }
      }
    }

    .no-record {
      padding: 2rem 0;
      text-align: center;
      color: $title;
      font-size: 16px;
    }

    .mt-5 {
      @include media-breakpoint-down(md) {
        margin-top: 0 !important;
      }
    }
  }

  .EventDetails {
    text-align: center;
    max-width: 620px;
    margin: auto;

    .EventImg {
      width: 150px;
      height: 150px;
      overflow: hidden;
      border-radius: 50%;
      margin: auto;
      margin-bottom: 15px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;

      img {
        height: 100%;
      }

      .empty-event-image {
        width: 100%;
        height: 100%;
        background: url('https://butterflyv2-production-assets.nyc3.cdn.digitaloceanspaces.com/image/white_mark.png'),
          #1d817b;
        background-repeat: no-repeat;
        background-position: center;
        position: absolute;
        overflow: hidden;
        display: flex;
        background-size: 40%;
        justify-content: space-between;
        top: 0;
      }

      .errorMessage {
        font: {
          size: 14px;
          family: $font-family-dada-grotesk;
          weight: $font-weight-regular;
        }
      }
    }

    .EventName {
      font-size: 16px;
      margin-bottom: 16px;

      h4 {
        word-wrap: break-word;
        color: $title;
        font: {
          size: 20px;
          family: $font-family-base;
          weight: $font-weight-medium;
        }
      }

      p {
        color: $text-primary;
        font: {
          size: 14px;
          family: $font-family-dada-grotesk;
          weight: $font-weight-regular;
        }

        span {
          color: $success;
          font: {
            size: 14px;
            family: $font-family-dada-grotesk;
            weight: $font-weight-medium;
          }
        }
      }
    }

    .EventDescp {
      margin-bottom: 30px;

      p {
        color: $text-primary;
        word-wrap: break-word;
        font: {
          size: 16px;
          family: $font-family-dada-grotesk;
          weight: $font-weight-regular;
        }
      }
    }

    .VenueDetails {
      border-top: 1px dashed $grey-1;
      padding: 16px 20px;
      text-align: left;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-bottom: 20px;

      .lhs {
        flex: 0 1 calc(50% - 30px);
        max-width: 50%;

        @include media-breakpoint-down(md) {
          flex: 0 1 100%;
          max-width: 100%;
        }

        p {
          font: {
            size: 16px;
            family: $font-family-dada-grotesk;
            weight: $font-weight-regular;
          }

          color: $text-primary;
          margin-bottom: 30px;

          &:last-child {
            margin-bottom: 0;
          }

          span {
            color: $success;
            display: block;
            font: {
              size: 12px;
              family: $font-family-dada-grotesk;
              weight: $font-weight-medium;
            }
          }
        }
      }

      .rhs {
        flex: 0 1 calc(50% - 30px);
        max-width: 50%;
        background-color: silver;

        @include media-breakpoint-down(md) {
          flex: 0 1 100%;
          max-width: 100%;
          height: 90vw;
          margin-top: 20px;
        }

        .google-map-wrapper {
          position: absolute;
          background-color: transparent;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          cursor: pointer;
        }

        .no-address {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 100%;
          text-align: center;
          font-size: 15px;
          font-family: Poppins, sans-serif;
          font-weight: 400;
          color: $white;
          padding: 15px;
        }
      }
    }

    button {
      @include button-outline;

      margin-right: 20px;

      &:nth-of-type(2) {
        margin-right: 0;
      }

      &.event-action {
        margin-right: 20px;
        margin-bottom: 20px;

        &.active {
          background-color: $success;
          color: $white;

          &:focus {
            background-color: $success !important;
          }
        }
      }
    }
  }

  .EventList {
    padding: 15px;
    text-align: center;
    margin-bottom: 100px;

    @include media-breakpoint-down(md) {
      padding: 15px 0;
    }

    .card {
      box-shadow: 0 0 30px rgb(0 0 0 / 20%);
      overflow: hidden;
      border: none;

      .ImageWrapper {
        text-align: center;
        height: auto;
        width: 100%;
        min-height: 210px;
        background-color: #dadada;
        position: relative;

        img {
          height: auto;
          width: 100%;
        }

        .empty-event-image {
          width: 100%;
          height: 100%;
          background: url('https://butterflyv2-production-assets.nyc3.cdn.digitaloceanspaces.com/image/white_mark.png'),
            #1d817b;
          background-repeat: no-repeat;
          background-position: center;
          position: absolute;
          overflow: hidden;
          display: flex;
          background-size: 20%;
          justify-content: space-between;
          top: 0;
        }
      }

      .card-body {
        text-align: left;

        .card-title {
          color: $title;
          min-height: 50px;
          font: {
            size: 20px;
            family: $font-family-base;
            weight: $font-weight-medium;
          }
          letter-spacing: 0.5px;

          @include media-breakpoint-down(md) {
            min-height: unset;
          }
        }

        .card-text {
          & > p {
            min-height: 68px;

            &.venue {
              min-height: auto;
            }
          }
        }

        p {
          color: $text-primary;
          margin-bottom: 8px;
          font: {
            size: 15px;
            family: $font-family-dada-grotesk;
            weight: $font-weight-regular;
          }

          span {
            display: block;
            color: $success;
            font: {
              size: 15px;
              family: $font-family-dada-grotesk;
              weight: $font-weight-medium;
            }
          }
        }
      }

      .card-footer {
        background-color: $white;
        border: none;
        padding-top: 0;
        padding-bottom: 15px;
        display: flex;
        justify-content: space-between;

        & > div {
          text-align: left;
        }

        span {
          font: {
            size: 12px;
            family: $font-family-dada-grotesk;
            weight: $font-weight-regular;
          }

          color: $text-primary;

          &.count {
            color: $success;
            font: {
              size: 12px;
              family: $font-family-dada-grotesk;
              weight: $font-weight-medium;
            }
          }
        }

        button {
          @include button-outline;

          height: 40px;
          margin-top: 0;
          font: {
            size: 12px;
            family: $font-family-dada-grotesk;
            weight: $font-weight-medium;
          }
        }
      }
    }

    button {
      @include button-success;

      margin-top: 70px;

      .fa-eventsicon {
        font-size: 20px;
        margin-right: 8px;
      }

      &.cancelCta {
        @include button-outline;

        margin-left: 15px;

        @include media-breakpoint-down(md) {
          width: 100px;
          font-size: 16px;
        }
      }

      &.CreateEventCTA {
        @include media-breakpoint-up(xl) {
          position: fixed;
          bottom: 20px;
          right: 193px;
          transform: translateX(-50%);
        }

        @include media-breakpoint-between(md, xl) {
          position: fixed;
          bottom: 100px;
          right: 193px;
          transform: translateX(-50%);
        }

        @include media-breakpoint-down(md) {
          position: fixed;
          bottom: 100px;
          left: 50%;
          transform: translateX(-50%);
          height: 50px;
          border: 2px solid $white;
          box-shadow: 0 1px 20px rgb(0 0 0 / 20%);
          width: max-content;
        }
      }
    }
  }

  .EventEdit {
    text-align: center;
    max-width: 620px;
    margin: auto;

    .EventImg {
      width: 150px;
      height: 150px;
      margin: auto;
      margin-bottom: 15px;
      position: relative;

      .ImgWrapper {
        width: 150px;
        height: 150px;
        overflow: hidden;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        position: relative;

        .empty-event-image {
          width: 100%;
          height: 100%;
          background: url('https://butterflyv2-production-assets.nyc3.cdn.digitaloceanspaces.com/image/white_mark.png'),
            #1d817b;
          background-repeat: no-repeat;
          background-position: center;
          position: absolute;
          overflow: hidden;
          display: flex;
          background-size: 20%;
          justify-content: space-between;
          top: 0;
        }
      }

      img {
        //width: 100%;
        height: 100%;
      }

      .cropImg {
        display: flex;
        position: absolute;
        background: $white;
        padding: 9px;
        border-radius: 50%;
        height: 34px;
        width: 35px;
        bottom: 6px;
        right: 3px;
        border: 1px solid silver;
        cursor: pointer;
      }

      .errorMessage {
        font: {
          size: 12px;
          family: $font-family-base;
          weight: $font-weight-regular;
        }
      }
    }

    .FormSection {
      text-align: left;
      margin-bottom: 50px;

      .mobile {
        display: none;

        @include media-breakpoint-down(md) {
          display: flex;
        }
      }

      .notMobile {
        display: flex;

        @include media-breakpoint-down(md) {
          display: none;
        }
      }

      label {
        color: $title;
        font: {
          size: 14px;
          family: $font-family-base;
          weight: $font-weight-medium;
        }

        sup {
          font-size: 15px;
          top: 0;
        }
      }

      .input-group {
        margin-bottom: 24px;

        input {
          height: 40px;
          border-radius: 10px;
          border: 1px solid $grey-1;
          color: #121212;
          font: {
            size: 16px;
            family: $font-family-base;
            weight: $font-weight-regular;
          }

          &:focus,
          &:hover {
            box-shadow: none;
            outline: none;
          }
        }

        textarea {
          border-radius: 10px !important;
          border: 1px solid $grey-1;
          color: #121212;
          height: 70px;
          resize: none;
          border-top-right-radius: 15px;
          border-bottom-right-radius: 15px;
          width: 100% !important;
          font: {
            size: 16px;
            family: $font-family-base;
            weight: $font-weight-regular;
          }

          &:focus,
          &:hover {
            box-shadow: none;
            outline: none;
          }
        }

        .address-autocomplete {
          width: 100%;
        }
      }

      .MemberSelect {
        i {
          font-size: 21px;
          margin-right: 5px;
          color: $success;
        }

        display: inline-flex;
        color: $title;
        font: {
          size: 14px;
          family: $font-family-base;
          weight: $font-weight-medium;
        }

        margin-bottom: 50px;
      }

      .react-datepicker-wrapper {
        input {
          &.form-control {
            height: 40px;
            border-radius: 10px;
            border: 1px solid $grey-1;
            color: #121212;
            font: {
              size: 16px;
              family: $font-family-base;
              weight: $font-weight-regular;
            }
          }
        }
      }

      .react-datepicker {
        button {
          padding: unset;
          background-color: transparent;
          height: unset;
          margin-top: 8px;

          &:hover,
          &:active {
            box-shadow: none;
          }
        }
      }

      .react-datepicker__day--selected {
        background-color: $success;
        color: $white;
      }

      .react-datepicker__time-container
        .react-datepicker__time
        .react-datepicker__time-box
        ul.react-datepicker__time-list {
        overflow-y: overlay;
      }

      .react-datepicker__time-container
        .react-datepicker__time
        .react-datepicker__time-box
        ul.react-datepicker__time-list
        li.react-datepicker__time-list-item--selected {
        background-color: $success;
        color: $white;
      }

      .react-datepicker__header {
        background-color: $success;
        color: $white;
      }

      .react-datepicker__day-name,
      .react-datepicker__current-month,
      .react-datepicker-time__header,
      .react-datepicker-year-header {
        color: $white;
      }

      .react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle::before,
      .react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle::after {
        border-bottom-color: $success;
      }

      .guest-label {
        width: 100%;

        .add-guest-section {
          display: flex;
          justify-content: space-between;

          .show-all-invites {
            cursor: pointer;
          }
        }
      }

      .invited-guests-list > input {
        display: none !important;
      }

      .invited-guests-list [data-tag] {
        background-color: #e9ecef;
        color: #333;
      }
    }

    button {
      @include button-success;

      &.cancelCta {
        @include button-outline;

        @include media-breakpoint-up(md) {
          margin-right: 30px;
        }
      }
    }
  }

  .guest-multi-email.react-multi-email {
    margin-bottom: 20px;
    border-radius: 10px;
    border: 1px solid $grey-1;
    min-height: 70px;
    font-size: 16px;
    font-family: Poppins, sans-serif;
    font-weight: 400;

    & > input {
      max-width: 95%;
    }
  }

  .guest-multi-email.react-multi-email.react-multi-email.empty.empty > span[data-placeholder] {
    display: inline;
    color: #6c757d;
    width: max-content;
  }

  .guest-multi-email.react-multi-email [data-tag] {
    background-color: #25b183;
    color: $white;
  }

  img.marker-icon {
    width: 20px;
  }

  span.text-count {
    font-size: 12px;
    margin-top: 5px;
    padding-left: 8px;
  }
}
