@import 'bootstrap/scss/bootstrap';
@import 'bootstrap/scss/mixins';
@import '../../../Assets/Styles/typography';
@import '../../../Assets/Styles/colors';
@import '../../../Assets/Styles/mixins';

.Information {
  .modal-dialog {
    width: 405px;
    max-width: 405px;
    border-radius: 10px;
    fill: $white;
    border: none;

    @include media-breakpoint-down(md) {
      max-width: 100%;
      width: auto;
    }

    .modal-header {
      border: none;
      padding-bottom: 0;

      .btn-close {
        color: $title;
        opacity: 1;

        &:focus {
          box-shadow: none;
        }
      }

      .modal-title {
        text-align: center;
      }
    }

    .modal-body {
      padding-top: 0;
      color: #777;
      text-align: center;
      font: {
        size: 16px;
        family: $font-family-base;
        weight: $font-weight-regular;
      }

      span {
        display: block;

        i {
          &.fa-trash {
            color: #fc394d;
            font-size: 34px;
          }

          &.fa-tick,
          &.fa-information-outline {
            color: $success;
            font-size: 34px;
          }

          &.fa-error-outline {
            color: #fc394d;
            font-size: 34px;
          }
        }

        padding-bottom: 10px;
      }
    }

    .modal-footer {
      border: none;
      padding-top: 0;
      justify-content: center;

      button.delete {
        @include button-outline;

        height: 40px;
        color: #fc394d;
        border: 1px solid #fc394d;
        font: {
          size: 14px;
          family: $font-family-base;
          weight: $font-weight-medium;
        }

        &:hover {
          background-color: #fc394d !important;
          color: $white;
          border: 1px solid #fc394d;
        }
      }

      button.success {
        @include button-success;

        height: 40px;
        font: {
          size: 14px;
          family: $font-family-base;
          weight: $font-weight-medium;
        }
      }
    }
  }
}
