@import 'bootstrap/scss/bootstrap';
@import 'bootstrap/scss/mixins';
@import '../../../Assets/Styles/typography';
@import '../../../Assets/Styles/colors';
@import '../../../Assets/Styles/mixins';

.modal.GalleryModal {
  z-index: 9999;

  .modal-dialog {
    width: 625px;
    max-width: 100%;

    @include media-breakpoint-down(md) {
      width: unset;
    }

    .modal-content {
      padding: 15px;

      .modal-header {
        padding: 0 0 10px;

        .btn-close {
          opacity: 1;
          border: none;
          outline: none;

          &:hover,
          &:focus {
            border: none;
            outline: none;
            box-shadow: none;
          }
        }

        h4.modal-title {
          font: {
            size: 18px;
            family: $font-family-base;
            weight: $font-weight-semibold;
          }

          color: $success;
        }
      }

      .modal-body {
        padding: 10px 0;
        max-height: 365px;
        overflow-y: overlay;

        .album {
          display: flex;
          flex-wrap: wrap;
          width: 100%;

          .eachTilesWrapper {
            margin-bottom: 5px;
            display: flex;
            width: 148px;
            flex-wrap: wrap;

            .eachTiles {
              width: 130px;
              height: 130px;
              border-radius: 5px;
              overflow: hidden;
              flex: 0 1 130px;
              max-width: 130px;
              margin: 7px 9px;
              position: relative;
              cursor: pointer;
              display: flex;
              align-items: center;
              background-color: #e8e8e8;

              img {
                width: 100%;
              }

              &.firstTiles {
                border: 1px dashed #8c8c8c;
                text-align: center;
                display: inline-flex;
                justify-content: center;
                align-items: center;

                button {
                  background-color: transparent;
                  border: none;
                  color: $success;
                  font: {
                    size: 12px;
                    family: $font-family-base;
                    weight: $font-weight-regular;
                  }

                  i {
                    font-size: 44px;
                    color: $title;
                  }

                  span {
                    display: block;
                    padding-top: 10px;
                  }
                }
              }

              .no-album-image {
               background: url('https://butterflyv2-production-assets.nyc3.cdn.digitaloceanspaces.com/image/white_mark.png'),
          #1d817b;
                background-repeat: no-repeat;
                background-position: center;
                width: 100%;
                height: 100%;
              }

              .overlay {
                display: none;
              }

              &:hover {
                .overlay {
                  position: absolute;
                  z-index: 9999;
                  top: 0;
                  right: 0;
                  width: 100%;
                  height: 100%;
                  background: rgb(0 0 0 / 50%);
                  display: inline-flex;
                  align-items: center;
                  justify-content: center;

                  i {
                    font-size: 44px;
                    color: $white;
                  }
                }
              }
            }

            .albumDetails {
              padding: 0 9px;

              .albumName {
                font: {
                  family: $font-family-base;
                  weight: $font-weight-medium;
                  size: 14px;
                }

                color: $text-primary;
                line-height: 1;
              }

              .count {
                font: {
                  family: $font-family-base;
                  weight: $font-weight-regular;
                  size: 12px;
                }

                color: $text-primary;
              }
            }
          }
        }
      }
    }
  }
}

.AlbumRename {
  .modal-header {
    border-bottom: none;
    padding-bottom: 0;
  }

  .modal-body {
    text-align: center;

    i {
      font-size: 36px;
      color: $success;
      margin-bottom: 30px;
    }

    input {
      min-width: 237px;
      height: 40px;
      display: block;
      text-align: center;
      border: none;
      margin: auto;
      border-bottom: 1px solid $title;
      color: $title;
      border-radius: 0;
      outline: none;
      font: {
        size: 14px;
        family: $font-family-dada-grotesk;
        weight: $font-weight-regular;
      }

      &::placeholder {
        text-align: center;
      }

      &:focus {
        outline: none;
        border: none;
      }
    }
  }

  .modal-footer {
    text-align: center;
    border-top: none;
    justify-content: center;
    padding-bottom: 23px;

    button {
      @include button-success;

      width: 80px;
      height: 40px;
    }
  }
}
