@import 'bootstrap/scss/bootstrap';
@import 'bootstrap/scss/mixins';
@import '../../../Assets/Styles/typography';
@import '../../../Assets/Styles/colors';
@import '../../../Assets/Styles/mixins';

.ReportPopup {
  z-index: 9999999 !important;

  .modal-dialog {
    max-width: 405px;
    width: 100%;
  }

  .modal-header {
    border-bottom: none;
    padding-bottom: 0;
  }

  .modal-body {
    text-align: center;
    padding: 0 25px;

    i {
      font-size: 36px;
      color: $success;
      margin-bottom: 30px;

      &.media {
        color: #fc394d;
      }
    }

    textarea {
      border: none;
      resize: none;
      width: 100%;
      height: 80px;
      border-bottom: 1px solid $title;
      margin-bottom: 20px;
      border-radius: 0;
      font: {
        family: $font-family-base;
        weight: $font-weight-regular;
        size: 16px;
      }

      color: $title;
    }

    &:focus,
    &:hover {
      box-shadow: none;
      outline: none;
    }
  }

  .modal-footer {
    text-align: center;
    border-top: none;
    justify-content: center;
    padding-bottom: 23px;

    button {
      @include button-success;

      width: 145px;
      height: 40px;

      &.mediabutton {
        background-color: $white;
        border: 1px solid #fc394d;
        color: #fc394d;

        &:hover,
        &:focus {
          background-color: #fc394d !important;
          border: 1px solid #fc394d;
          color: $white;
        }
      }
    }
  }
}

body:has(.DeletePopup.show) .modal-backdrop {
  z-index: 9999999 !important;
}
