@import 'bootstrap/scss/bootstrap';
@import 'bootstrap/scss/mixins';
@import '../../Assets/Styles/typography';
@import '../../Assets/Styles/colors';
@import '../../Assets/Styles/mixins';

// Google Material Colors
$bluegrey100: #cfd8dc;
$bluegrey300: #90a4ae;
$bluegrey500: #607d8b;
$bluegrey900: #263238;
$blueA400: #2979ff;

// Timing Functions
$easeInQuart: cubic-bezier(0.895, 0.03, 0.685, 0.22);
$easeOutQuart: cubic-bezier(0.165, 0.84, 0.44, 1);
$easeInOutQuart: cubic-bezier(0.77, 0, 0.175, 1);
$easeIn: $easeInQuart;
$easeOut: $easeOutQuart;
$easeInOut: $easeInOutQuart;

.panel.Members {
  .MemberHeader {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 8px 0 24px 10px;

    @include media-breakpoint-down(md) {
      flex-wrap: wrap;
      padding-top: 75px;
      padding-bottom: 0;
    }

    .MembersCount {
      font: {
        family: $font-family-base;
        weight: $font-weight-semibold;
        size: 16px;
      }

      color: $title;

      @include media-breakpoint-down(md) {
        //margin-bottom: 15px;
      }
    }

    .MembersSearch {
      position: relative;
      color: #aaa;
      font-size: 16px;
      display: inline-block;

      input {
        width: 180px;
        height: 40px;
        padding: 0 35px 0 14px;
        border: 1px solid $grey-1;
        border-radius: 50px;
        background-color: $white;
        background-size: cover;
        font: {
          family: $font-family-base;
          weight: $font-weight-regular;
          size: 12px;
        }

        color: #121212;
        text-decoration: none solid rgb(18 18 18);

        &::placeholder {
          font: {
            family: $font-family-base;
            weight: $font-weight-regular;
            size: 14px;
          }
        }

        &:focus-visible {
          outline: none;
        }
      }

      i {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: auto;
        right: 15px;
        color: $success;
        font-weight: bolder;
      }
    }
  }

  .MemberBody {
    width: 100%;

    @include media-breakpoint-down(md) {
      max-height: unset !important;
    }

    ul {
      padding: 0;

      li {
        list-style: none;
        padding: 10px;

        &.EachMember {
          display: flex;
          flex-wrap: nowrap;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          border-bottom: 1px solid $bg;

          .MemberDetail {
            .owner {
              @include ProfilePic;

              .ownerPic {
                max-width: 60px;

                img,
                .ownerAlpha {
                  width: 60px;
                  height: 60px;
                  font-size: 25px;
                }
              }

              .ownerName {
                max-width: calc(100% - 60px);
                flex: calc(100% - 60px);
                color: #333;
                font: {
                  family: $font-family-base;
                  weight: $font-weight-medium;
                  size: 16px;
                }

                padding-left: 10px;
                line-height: 1.2;

                span {
                  display: block;
                  font: {
                    family: $font-family-base;
                    weight: $font-weight-regular;
                    size: 12px;
                  }

                  color: #777;
                }
              }
            }
          }

          .IconsList {
            display: inline-flex;
            align-items: center;

            .unblock-icon {
              cursor: pointer;

              i {
                color: $success;
              }

              span {
                margin-left: 5px;
                font-size: 14px;
                color: $title;
              }

              &:hover {
                i {
                  color: $hover-button;
                }

                span {
                  color: $hover-button;
                }
              }
            }

            i {
              cursor: pointer;
              color: #777;
              margin-left: 16px;

              &.fa-butterflyemail {
                &:hover {
                  color: $hover-button;
                }

                &.active {
                  color: $hover-button;
                }
              }

              &.fa-butterflyblock.unblock {
                color: $error;

                &:hover {
                  color: $error;
                }

                &.active {
                  color: $error;
                }
              }
            }

            .checkbox {
              display: inline-block;

              .majesticCheckbox {
                $boxSize: 16px * 1.25;
                $checkThickness: 2px;

                cursor: pointer;
                display: flex;
                min-height: $boxSize;
                align-items: center;
                line-height: 1;
                user-select: none;
                color: $success;
                font-weight: normal;

                input {
                  display: none;
                }

                span {
                  position: relative;
                  display: flex;
                  width: 25px;
                  height: 25px;
                  border: 1px solid $success;
                  box-sizing: border-box;
                  overflow: hidden;
                  margin-right: 16px / 2;
                  border-radius: 8px;
                  box-shadow: inset 0 1px 2px rgb(0 0 0 / 20%);

                  i {
                    position: relative;
                    width: 20px;
                    height: 20px;
                    margin-left: 0;
                    top: 50%;
                    left: 50%;
                    transform: rotate(-45deg) translateX(0%) translateY(-60%) scale(0.8);
                    transform-origin: center left;

                    &::before,
                    &::after {
                      content: '';
                      position: absolute;
                      background-color: $white;
                      border-radius: $boxSize;
                    }

                    &::before {
                      height: 0%;
                      top: 50%;
                      left: 0;
                      width: $checkThickness;
                    }

                    &::after {
                      width: 0%;
                      bottom: 0;
                      left: 0;
                      height: $checkThickness;
                    }
                  }
                } // Box

                // If selected
                input:checked ~ span {
                  border: none;
                  background-color: $success;
                  box-shadow: none;

                  i::before {
                    height: 50%;
                    transition: height 300ms $easeIn;
                  }

                  i::after {
                    width: 100%;
                    transition: width 300ms 300ms $easeOut;
                  }
                }
              }
            }
          }
        }
      }
    }

    .no-record {
      padding: 2rem 0;
      text-align: center;
      color: $title;
      font-size: 16px;
    }

    .SharePopup {
      h4 {
        text-align: center;
        font: {
          family: $font-family-base;
          weight: $font-weight-medium;
          size: 16px;
        }

        color: $title;

        span {
          font-weight: $font-weight-regular;
        }
      }

      .Icons {
        display: flex;
        justify-content: space-between;
        align-items: center;
        max-width: 287px;
        margin: 28px auto;

        i {
          color: $success;
          cursor: pointer;

          &:hover {
            color: $hover-button;
          }

          &.fa-butterflylink {
            font-size: 19px;
          }

          &.fa-butterflyemail2 {
            font-size: 20px;
          }

          &.fa-sms {
            font-size: 24px;
          }

          &.fa-butterflyfacebook {
            font-size: 19px;
          }

          &.fa-twitter {
            font-size: 17px;
          }

          &.fa-butterflylinkedin {
            font-size: 18px;
          }

          &.fa-butterflymessage {
            font-size: 22px;
          }
        }

        span.iconWrapper {
          width: 42px;
          height: 42px;
          border: 2px solid $bg;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;

          a {
            line-height: 1;
          }
        }
      }

      .ShareLink {
        color: $title;
        text-align: center;
        padding: 28px 0;

        p {
          font: {
            size: 14px;
            family: $font-family-base;
            weight: $font-weight-regular;
          }

          span {
            display: block;
            color: $success;
            font: {
              size: 16px;
              family: $font-family-base;
              weight: $font-weight-semibold;
            }
          }

          margin-bottom: 28px;
        }

        .InputHolder {
          select {
            border: 1px solid $grey-1;
            height: 30px;
            width: 55px;

            &:focus,
            &:hover {
              outline: none;
              border: 1px solid $grey-1;
            }

            color: #121212;
            border-radius: 40px;
            font: {
              size: 14px;
              family: $font-family-base;
              weight: $font-weight-medium;
            }

            margin-right: 5px;
          }

          input {
            border: 1px solid $grey-1;
            width: 197px;
            height: 30px;
            border-radius: 40px;
            color: #121212;
            padding: 0 15px;

            &:focus,
            &:hover {
              outline: none;
              border: 1px solid $grey-1;
            }
            font: {
              size: 14px;
              family: $font-family-base;
              weight: $font-weight-medium;
            }
          }
        }

        button {
          @include button-success;

          margin-top: 20px;
          height: 40px;
        }
      }

      .InviteEmail {
        max-width: 100%;
        margin: 0;

        textarea {
          border: none;
          resize: none;
          border-bottom: 1px solid $title;
          border-radius: 0;
          font: {
            family: $font-family-base;
            weight: $font-weight-regular;
            size: 14px;
          }

          &:focus,
          &:hover {
            box-shadow: none;
            outline: none;
          }
        }

        .react-multi-email {
          margin-bottom: 20px;
          max-height: 90px;
          overflow: scroll;
        }

        .checkboxField {
          /* Hide elements visually, but not for screenreaders by clipping it */

          .vh {
            position: absolute !important;
            clip: rect(1px, 1px, 1px, 1px);
            padding: 0 !important;
            border: 0 !important;
            height: 1px !important;
            width: 1px !important;
            overflow: hidden;
          }

          label::before {
            content: '';
            width: 20px;
            height: 20px;
            background: #f2f2f2;
            border: 1px solid rgb(75 101 132 / 30%);
            display: inline-block;
            margin-right: 8px;
            cursor: pointer;
          }

          label {
            font: {
              family: $font-family-base;
              weight: $font-weight-regular;
              size: 14px;
            }

            color: $text-primary;
          }

          input[type='checkbox']:checked ~ label::before {
            background: $success no-repeat center;
            background-size: 12px 12px;
            cursor: pointer;
            background-image: url("data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTYuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4PSIwcHgiIHk9IjBweCIgd2lkdGg9IjE2cHgiIGhlaWdodD0iMTZweCIgdmlld0JveD0iMCAwIDQ1LjcwMSA0NS43IiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCA0NS43MDEgNDUuNzsiIHhtbDpzcGFjZT0icHJlc2VydmUiPgo8Zz4KCTxnPgoJCTxwYXRoIGQ9Ik0yMC42ODcsMzguMzMyYy0yLjA3MiwyLjA3Mi01LjQzNCwyLjA3Mi03LjUwNSwwTDEuNTU0LDI2LjcwNGMtMi4wNzItMi4wNzEtMi4wNzItNS40MzMsMC03LjUwNCAgICBjMi4wNzEtMi4wNzIsNS40MzMtMi4wNzIsNy41MDUsMGw2LjkyOCw2LjkyN2MwLjUyMywwLjUyMiwxLjM3MiwwLjUyMiwxLjg5NiwwTDM2LjY0Miw3LjM2OGMyLjA3MS0yLjA3Miw1LjQzMy0yLjA3Miw3LjUwNSwwICAgIGMwLjk5NSwwLjk5NSwxLjU1NCwyLjM0NSwxLjU1NCwzLjc1MmMwLDEuNDA3LTAuNTU5LDIuNzU3LTEuNTU0LDMuNzUyTDIwLjY4NywzOC4zMzJ6IiBmaWxsPSIjRkZGRkZGIi8+Cgk8L2c+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPC9zdmc+Cg==");
          }

          input[type='checkbox']:focus ~ label {
            color: $success $success $success;
          }
        }

        label {
          width: 100%;
          text-align: left;
          display: inline-flex;
          font: {
            family: $font-family-base;
            weight: $font-weight-regular;
            size: 14px;
          }

          color: $text-primary;

          input[type='checkbox'] {
            width: 20px;
            height: 20px;
            margin-right: 10px;

            &::before {
              background-color: $success;
            }
          }
        }

        .Ctas {
          margin-top: 30px;

          .cancel {
            width: 100px;
            height: 40px;
            border: 1px solid $grey-1;
            border-radius: 50px;
            background-color: $white;
            background-size: cover;
            font: {
              family: $font-family-base;
              weight: $font-weight-medium;
              size: 14px;
            }

            @include media-breakpoint-down(md) {
              font-size: 16px;
            }

            color: #777;
            text-align: center;

            &:hover {
              background-color: $title;
              color: $white;
            }
          }

          .sendInvite {
            @include button-success;

            height: 40px;
            margin-left: 15px;
          }
        }
      }

      .invite-label {
        display: flex;
        justify-content: space-between;

        h5 {
          cursor: pointer;
          font: {
            family: $font-family-base;
            weight: $font-weight-medium;
            size: 14px;
          }

          color: $success;
        }
      }

      .blocked-members-label {
        text-align: left;
      }

      .blocked-members-list > input {
        display: none !important;
      }
    }
  }

  .MemberFooter {
    padding: 21px 0 6px;

    span {
      color: $success;
    }

    .CtaSetOne {
      display: flex;
      justify-content: space-between;

      @include media-breakpoint-down(md) {
        flex-wrap: wrap;
      }

      .BlockUserCta {
        background-color: transparent;
        border: none;
        padding: 10px;
        font: {
          family: $font-family-base;
          weight: $font-weight-medium;
          size: 14px;
        }

        color: $title;

        i {
          color: $success;
          font-size: 20px;
          margin-right: 5px;
        }

        &:hover {
          color: $success;

          i {
            color: $title;
          }
        }
      }

      .OutlineBtn {
        font-family: $font-family-work-san;
        font-weight: $font-weight-semibold;
        font-size: 14px;
        background-color: transparent;
        border: 1px solid $success;
        color: $success;
        border-radius: 50px;
        opacity: 1;
        outline: none;
        box-shadow: none;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        min-width: 90px;
        padding: 8px 12px;

        i {
          margin-right: 5px;
        }
        font: {
          family: $font-family-base;
          weight: $font-weight-medium;
          size: 14px;
        }

        &:hover {
          box-shadow: none;
          outline: none;
          background-color: $success !important;
          color: $white;
          border-color: $success;

          i {
            color: $white;
          }
        }
      }
    }

    .CtaSetTwo {
      padding-top: 18px;
      text-align: center;

      .text-left {
        text-align: left;
      }

      h4 {
        font: {
          family: $font-family-base;
          weight: $font-weight-semibold;
          size: 16px;
        }

        color: $title;
        margin-bottom: 10px;
      }

      p {
        font: {
          family: $font-family-base;
          weight: $font-weight-medium;
          size: 14px;
        }

        color: $title;

        span {
          font: {
            family: $font-family-base;
            weight: $font-weight-semibold;
            size: 14px;
          }

          color: $success;
        }
      }

      textarea {
        border: none;
        resize: none;
        width: 100%;
        height: 80px;
        border-bottom: 1px solid $title;
        margin-bottom: 20px;
        border-radius: 0;
        font: {
          family: $font-family-base;
          weight: $font-weight-regular;
          size: 16px;
        }

        color: $title;

        &:focus-visible {
          outline: none;
        }

        &:focus,
        &:hover {
          box-shadow: none;
          outline: none;
        }
      }

      .send {
        @include button-success;

        padding: 8px 18px;
        height: 40px;
        margin-left: 15px;
      }

      .OutlineBtn {
        font-family: $font-family-work-san;
        font-weight: $font-weight-semibold;
        font-size: 14px;
        background-color: transparent;
        border: 1px solid $success;
        color: $success;
        border-radius: 50px;
        opacity: 1;
        outline: none;
        box-shadow: none;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        min-width: 90px;
        padding: 8px 12px;

        i {
          margin-right: 5px;
        }
        font: {
          family: $font-family-base;
          weight: $font-weight-medium;
          size: 14px;
        }

        &:hover {
          box-shadow: none;
          outline: none;
          background-color: $success !important;
          color: $white;
          border-color: $success;

          i {
            color: $white;
          }

          @include media-breakpoint-down(lg) {
            background-color: transparent !important;
            color: $success;
          }
        }
      }
    }
  }
}
