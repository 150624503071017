@import 'bootstrap/scss/bootstrap';
@import 'bootstrap/scss/mixins';
@import '../../Assets/Styles/typography';
@import '../../Assets/Styles/colors';
@import '../../Assets/Styles/mixins';

.PageCard {
  border-radius: 5px !important;
  border: none !important;

  .ImgHolder {
    width: 100%;
    height: 216px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    background: url('https://butterflyv2-production-assets.nyc3.cdn.digitaloceanspaces.com/image/white_mark.png'),
      #1d817b;
    background-repeat: no-repeat;
    background-position: center;
    position: relative;
    overflow: hidden;
    display: flex;
    justify-content: space-between;

    .card-img-top {
      position: absolute;
      height: 100%;
      width: auto;
      left: 50%;
      transform: translateX(-50%);

      @include media-breakpoint-down(md) {
        width: 100%;
        height: auto;
      }
    }

    .ImgWrapper {
      background: silver;
      width: 100%;
      position: relative;

      .overlay {
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 100%;
        background: rgb(255 249 249);
        background: linear-gradient(180deg, rgb(255 249 249 / 1.03%) 41%, rgb(0 0 0 / 52.6%) 97%);
      }
    }

    i {
      &.fa-uploadpic {
        color: #1d817b2b;
        padding: 12px 18px;
        font-size: 26px;
        right: 0;
        cursor: pointer;
      }

      position: absolute;
      z-index: 1;
    }

    p {
      max-width: calc(100% - 40px);
      flex: 0 1 calc(100% - 40px);
      padding: 15px;
      margin: 0;
      line-height: 1;
      bottom: 0;
      text-transform: capitalize;
      font: {
        size: 24px;
        family: $font-family-base;
        weight: $font-weight-semibold;
      }

      color: $white;
      position: absolute;
    }

    span {
      position: absolute;
      bottom: 14px;
      right: 15px;
      width: 25px;
      display: block;
      height: 25px;
      font-size: 24px;
      color: $white;
    }

    span.subscribe {
      position: absolute;
      bottom: 19px;
      display: block;
      right: 18px;

      i {
        color: $white;
        position: relative;
        font-size: 24px;
      }
    }
  }

  .card-body {
    padding: 13px;

    .card-text {
      word-wrap: break-word;
      font: {
        size: 15px;
        family: $font-family-dada-grotesk;
        weight: $font-weight-regular;
      }

      color: $text-primary;
      line-height: 1.6;

      p {
        font: {
          size: 16px;
          family: $font-family-dada-grotesk;
          weight: $font-weight-regular;
        }
        span {
          cursor: pointer;
          text-decoration: underline;
        }
      }

      span {
        cursor: pointer;
        text-decoration: underline;
      }
    }

    .owner {
      @include ProfilePic;

      .ownerName {
        font: {
          size: 18px;
          family: $font-family-base;
          weight: $font-weight-medium;
        }
        letter-spacing: 0.5px;
        color: #333;
      }

      .circle {
        border-radius: 500% !important;
        overflow: hidden;
        width: 40px;
        height: 40px;
        border: none;
        position: relative;
        top: 0;
        background-color: #f3f0fe;
        color: #9b7bf6;
        display: flex;

        span {
          display: block;
          font: {
            size: 20px;
            family: $font-family-dada-grotesk;
            weight: $font-weight-medium;
          }

          position: absolute;
          top: 50%;
          left: 50%;
          line-height: 1;
          transform: translate(-50%, -50%);
        }

        img {
          max-width: 100%;
          height: auto;

          &.profile-pic {
            width: 100%;
            display: inline-block;
          }
        }
      }
    }
  }

  hr {
    margin: 0 10px;
    border-color: $bg;
    opacity: 0.7;
  }

  .card-footer {
    background-color: $white;
    border: none;
    padding: 8px;
    text-align: right;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    &.memberView {
      display: flex;
      justify-content: space-between;

      .subscribe-badge {
        color: #777;
        display: flex;
        align-items: center;

        .subscribe-button {
          height: 17px;
          display: flex;
          align-items: center;
          cursor: pointer;

          &:hover {
            color: $success;
          }
        }

        i {
          font-size: 17px;
        }

        i.subscribed {
          color: $success;
          cursor: pointer;
        }

        span {
          font-size: 12px;
          margin-left: 4px;
          font-weight: 500;
        }
      }
    }

    button {
      height: 30px;
      background: transparent;
      border: 1px solid $bg;
      padding: 3px 10px;
      border-radius: 50px;
      display: inline-flex;
      align-items: center;
      font: {
        size: 12px;
        family: $font-family-dada-grotesk;
        weight: $font-weight-medium;
      }

      color: #777;

      &.highlightGreen {
        color: $white;
        background: $success;
      }

      i {
        font-size: 14px;
        margin-right: 4px;

        &.fa-butterflypublish {
          font-size: 14px;
          margin-right: 6px;
        }

        &.fa-share {
          font-size: 14px;
        }
      }

      &:hover {
        border: 1px solid $hover-button;
        background-color: $hover-button;
        color: $white;
      }

      &:focus-visible {
        outline: none;
      }

      &:first-child {
        margin-right: 10px;
      }

      &:disabled {
        background-color: $disabled-button;
        border: none;
        outline: none;
        color: $text-primary !important;

        &:hover,
        &:focus,
        &:active {
          background-color: $disabled-button !important;
          border: none;
          outline: none;
          box-shadow: none;
          color: $white;
        }
      }

      &.legacy-highlight {
        position: relative;
        z-index: 99999;
        background: white;
        pointer-events: none;

        &:hover {
          border: 1px solid $success;
          background-color: $success;
          color: $white;
        }
      }

      &.PageMemberOnboarding-highlight {
        position: relative;
        z-index: 99999;
        background: white;
        pointer-events: none;

        &:hover {
          border: 1px solid $success;
          background-color: $success;
          color: $white;
        }
      }
    }
  }
}
