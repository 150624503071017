@import 'bootstrap/scss/bootstrap';
@import 'bootstrap/scss/mixins';
@import '../../../Assets/Styles/typography';
@import '../../../Assets/Styles/colors';
@import '../../../Assets/Styles/mixins';

.FundriserPreview {
  @include media-breakpoint-down(lg) {
    padding: 0 !important;
  }

  @include media-breakpoint-down(md) {
    z-index: 999999 !important;
  }

  .modal-dialog {
    max-width: 60%;
    width: 100%;

    @include media-breakpoint-down(lg) {
      max-width: 90%;
    }

    @include media-breakpoint-down(md) {
      max-width: 100%;
      margin: 0;
      padding: 0;
    }

    @include media-breakpoint-between(md, xl) {
      max-width: 80%;
    }

    .modal-content {
      @include media-breakpoint-down(md) {
        height: 100vh;
      }
    }

    .PreviewWrapper {
      display: flex;
      flex-wrap: nowrap;

      @include media-breakpoint-down(xl) {
        position: relative;
        flex-wrap: wrap;

        //padding-bottom: 80px;
        //max-height: 99vh;
        //overflow: hidden;
      }

      .ImageSection {
        @include media-breakpoint-between(md, xl) {
          display: none;
        }

        @include media-breakpoint-down(md) {
          display: none;
        }

        border-top-left-radius: 5px;
        flex: 0 1 calc(100% - 360px);
        max-width: calc(100% - 360px);
        padding: 0;

        //height: 635px;
        background-color: #f8f7f7;
        max-height: 90vh;

        //min-height: 90vh;

        @include media-breakpoint-down(xl) {
          flex-basis: 100%;
          max-width: 100%;
          max-height: 60vh;
          min-height: 60vh;
        }

        .modal-header {
          display: none;

          @include media-breakpoint-down(xl) {
            display: block;
            position: absolute;
            z-index: 9;
            right: 0;

            button {
              &.btn-close {
                background-color: $white;
                border-radius: 50%;
              }
            }
          }
        }

        .Img {
          display: flex;
          height: 100%;
          align-items: center;
          justify-content: center;

          .card-img {
            display: flex;
            justify-content: center;
            position: relative;
            height: 100%;
            background-color: rgb(0 0 0 / 10%);

            .carousel.slide.carousel-fade {
              width: 100%;

              .carousel-control-next {
                right: 0;
                height: 32px;
                width: 32px;
                margin-right: 30px;
                top: 50%;
                transform: translateY(-50%);
              }

              .carousel-control-prev {
                left: 0;
                height: 32px;
                width: 32px;
                margin-left: 30px;
                top: 50%;
                transform: translateY(-50%);
              }

              .carousel-inner {
                .carousel-item {
                  display: flex;

                  .no-fundraiser-image {
                    width: 100%;
                    height: 100%;
                    background: url('https://butterflyv2-production-assets.nyc3.cdn.digitaloceanspaces.com/image/white_mark.png'),
          #1d817b;
                    background-repeat: no-repeat;
                    background-position: center;
                    position: absolute;
                    overflow: hidden;
                    display: flex;
                    background-size: 20%;
                    justify-content: space-between;
                    top: 0;
                  }
                }
              }
            }
          }
        }
      }

      .Commentsection {
        max-width: 360px;
        flex: 0 1 360px;
        border-left: 1px solid $bg;

        @include media-breakpoint-down(xl) {
          max-width: 100%;
          width: 100%;
          flex: 100%;

          // position: absolute;
          // z-index: 1;
          background: $white;
          height: 100%;

          //top: calc(100vh - 80px);
          &.slideIn {
            animation: slideIn 0.5s forwards;
          }

          &.slideOut {
            animation: slideOut 0.5s forwards;
          }

          @keyframes slideIn {
            100% {
              top: 0;
            }
          }

          @keyframes slideIn {
            100% {
              top: 0;
            }
          }

          @keyframes slideOut {
            100% {
              top: 100vh;
            }
          }

          @keyframes slideOut {
            100% {
              top: 100vh;
            }
          }
        }

        .ImgMobile {
          display: none;

          @include media-breakpoint-between(md, xl) {
            display: block;
          }

          @include media-breakpoint-down(md) {
            display: block;
          }

          .carousel-inner {
            .carousel-item {
              @include media-breakpoint-between(md, xl) {
                margin-bottom: 10px;
              }

              @include media-breakpoint-down(md) {
                margin-bottom: 10px;
              }

              img {
                @include media-breakpoint-between(md, xl) {
                  width: 100%;
                  height: 400px;
                  object-fit: cover;
                }

                @include media-breakpoint-down(md) {
                  width: 100%;
                  height: 200px;
                  object-fit: cover;
                }
              }
            }
          }
        }

        .modal-header {
          border: none;
          padding: 16px;

          .owner {
            @include ProfilePic;

            .ownerName {
              color: $text-primary;
              font-size: 14px;
              font-weight: 400;

              .created_at {
                font-size: 11px;
              }
            }

            .ownerPic {
              max-width: 70px;

              img {
                width: 45px;
                height: 45px;
              }
            }
          }

          .btn-close {
            opacity: 0.9;
            padding-top: 0;
            margin-top: -31px;

            &:focus {
              outline: none;
              border: none;
              box-shadow: none;
            }

            @include media-breakpoint-between(md, xl) {
              display: block;
            }

            @include media-breakpoint-down(md) {
              display: block;
            }
          }
        }

        .fundraiserPre-ownerdetails {
          .owner {
            @include ProfilePic;

            padding: 5px 16px;

            .ownerName {
              color: $text-primary;
              font-size: 11px;
              font-weight: 500;
              line-height: 1.5;

              .created_at {
                color: #777;
                font-weight: 100;
                font-size: 10px;
              }
            }

            .ownerPic {
              img {
                width: 30px;
                height: 30px;
              }
            }
          }
        }

        .fundraiserPre-contents {
          padding: 0 16px;

          .fundraiserPre-heading {
            color: $text-primary;
            max-height: 96px;
            overflow: auto;
            margin-bottom: 5px;
            word-break: break-word;

            h6 {
              font-size: 13px;
              font-weight: 600;
              border: none;
              margin-bottom: 5px;
              word-break: break-word;
            }

            p {
              font-size: 12px;
              margin-bottom: 5px;
              word-break: break-word;
            }
          }
        }

        .fundraiserPre-reactionWrapper {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 0 16px;

          .addreaction {
            background-color: $white;
            border-radius: 20px;
            cursor: pointer;
            margin-right: 10px;
            padding: 1px 5px;
            border: 1px solid $bg;
          }
        }

        .contents {
          margin: 0 15px 10px;
          max-height: 225px;
          overflow: auto;

          .QuestionAnswers {
            word-wrap: break-word;

            p {
              margin-bottom: 10px;
              line-height: 1.5;
              word-wrap: break-word;
              color: $title;

              &.Question {
                font: {
                  size: 16px;
                  family: $font-family-base;
                  weight: $font-weight-semibold;
                }

                word-wrap: break-word;
              }

              &.Answer {
                word-wrap: break-word;

                font: {
                  size: 15px;
                  family: $font-family-base;
                  weight: $font-weight-regular;
                }
              }
            }
          }

          .description {
            margin-bottom: 10px;
            line-height: 1.5;
            word-wrap: break-word;

            font: {
              size: 15px;
              family: $font-family-base;
              weight: $font-weight-regular;
            }
          }

          .Descriptions {
            word-wrap: break-word;

            p {
              word-wrap: break-word;

              &.description {
                margin-bottom: 10px;
                line-height: 1.5;
                word-wrap: break-word;

                font: {
                  size: 15px;
                  family: $font-family-base;
                  weight: $font-weight-regular;
                }
              }
            }
          }
        }

        .Reactions {
          margin: 10px;
          display: flex;

          span {
            color: #777;
            display: inline-flex;
            align-items: center;
            line-height: 1;
            border-radius: 30px;
            border: 1px solid #e8e8e8;
            padding: 0 8px;
            height: 22px;

            font: {
              size: 13px;
              family: $font-family-base;
              weight: $font-weight-regular;
            }

            i {
              margin-right: 5px;
              cursor: pointer;

              &.disabled {
                pointer-events: none;
              }
            }

            &:nth-of-type(1) {
              margin-right: 5px;

              i {
                font-size: 14px;
                color: $success;
              }
            }

            &:nth-of-type(2) {
              i {
                color: #fc394d;
                font-size: 14px;
              }
            }
          }
        }

        .MessageBoard {
          background-color: $white;
          border-radius: 0;
          padding: 0;

          @include media-breakpoint-down(md) {
            //max-height: calc(100vh - 210px) !important;
            max-height: unset !important;
            height: 100% !important;
            min-height: 100px;
            padding-bottom: 75px;
            z-index: 999999;
          }

          width: 100%;
          position: relative;
          flex: 3;
          display: flex;
          flex-direction: column;
          transition: 0.2s;
          border-top: 1px solid rgb(102 102 102 / 60%);
          height: calc(100vh - 272px);

          @include media-breakpoint-up(xl) {
            height: calc(100vh - 314px) !important;
            max-height: calc(100vh - 314px) !important;
          }

          @include media-breakpoint-down(md) {
            height: max-content !important;
          }

          &-header {
            position: sticky;
            top: 0;
            border-bottom: 1px solid $grey-1;
            padding: 12px 4px 9px;
            margin: 0 14px;

            font: {
              size: 16px;
              family: $font-family-base;
              weight: $font-weight-medium;
            }

            span {
              font: {
                size: 12px;
                family: $font-family-base;
                weight: $font-weight-regular;
              }
            }
          }

          .commentsDisabled {
            padding: 12px;
            color: #777;
            font-size: 14px;
          }

          .chat-wrapper {
            overflow-y: overlay;
            overflow-x: hidden;
            padding: 10px;
            position: relative;
            height: 100%;
            scroll-behavior: smooth;

            @include media-breakpoint-down(md) {
              margin-bottom: 57px;
            }

            @include media-breakpoint-between(md, lg) {
              padding: 8px 0;
            }

            @include media-breakpoint-down(md) {
              padding: 5px 0;
            }

            .no-comments {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              text-align: center;
            }

            &.EmptyComments {
              @include media-breakpoint-down(md) {
                margin-bottom: 0;
                height: 50px;
                width: 100%;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                text-align: center;
              }
            }

            .comment-loader {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -10%);
              max-width: 50px;
              text-align: center;
              height: 100%;
            }
          }

          &:has(.no-comments) {
            @include media-breakpoint-down(md) {
              max-height: 100% !important;
              height: 40vh !important;
            }
          }

          .chat-input {
            border: none;
            outline: none;
            height: 42px;
            flex: 1;
            margin-right: 4px;
            background-color: var(--chat-input);
            color: var(--text-dark);
            padding-top: 12px;

            &:placeholder {
              color: var(--text-light);
              font-size: 12px;
              padding-top: 12px;
            }

            &-wrapper {
              margin-top: auto;
              padding: 10px 12px;
              background-color: $white;
              border-top: 1px solid $grey-1;

              @include media-breakpoint-down(md) {
                position: fixed;
                width: 100%;
                bottom: 0;
              }

              position: relative;

              .reaction-emoji-msgbord {
                position: absolute;
                bottom: 58px;
                left: 0;
                width: 100%;

                & > p {
                  position: absolute;
                  z-index: 9;
                  right: 12px;
                  cursor: pointer;
                }

                aside {
                  max-width: 100%;
                  width: 100% !important;
                  background-color: $white;
                  max-height: 350px;

                  &.EmojiPickerReact {
                    .epr-header {
                      width: 100%;

                      .epr-header-overlay {
                        padding-bottom: 0;
                      }
                    }

                    .epr-body {
                      width: 100%;
                      padding: 0 10px;

                      .epr-emoji-list {
                        li {
                          &.epr-emoji-category > .epr-emoji-category-label {
                            font: {
                              size: 14px;
                              family: $font-family-base;
                              weight: $font-weight-semibold;
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }

              .chatImageHolder {
                margin-top: 5px;

                .chatImageWrapper {
                  height: 100px;
                  position: relative;
                  width: max-content;

                  img {
                    height: 100%;
                    width: auto;
                  }

                  .closeBtn {
                    cursor: pointer;
                    position: absolute;
                    width: 22px;
                    height: 22px;
                    border-radius: 50%;
                    background-color: rgb(0 0 0 / 20%);
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    top: 0;
                    right: 0;

                    i {
                      color: $white;
                    }

                    &:hover {
                      background-color: rgb(0 0 0 / 100%);
                    }
                  }
                }
              }
            }
          }

          .input-wrapper {
            border: none;
            border-radius: 4px;
            font-size: 12px;
            display: flex;
            flex: 1;
            overflow: hidden;
            justify-content: space-between;
            background-color: $white;
            align-items: center;
            flex-wrap: wrap;
            align-content: center;

            .chat-input {
              resize: none;
              width: calc(100% - 100px);

              font: {
                size: 14px;
                family: $font-family-base;
                weight: $font-weight-medium;
              }
            }
          }

          .emoji-btn {
            border: none;
            background-color: transparent;
            padding: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0 4px 0 0;
            color: $grey-1;
            width: 28px;
            height: 28px;

            i {
              font-size: 18px;
              color: $text-primary;
            }

            &:hover {
              i {
                color: orange;
              }
            }
          }

          .chat-send-btn {
            color: $success;
            height: 18px;

            i {
              font-size: 18px;
            }
          }

          .chat-send-btn {
            color: $grey-1;
            border: none;
            background-color: transparent;
            padding: 0;
            cursor: pointer;
            width: 28px;
            height: 28px;

            i {
              font-size: 18px;
            }

            &:disabled {
              color: $grey-1;
            }

            &.can-send {
              color: $success;
            }
          }

          .chat-attachment-btn {
            border: none;
            padding: 0;
            background-color: transparent;
            color: var(--text-light);
            display: flex;
            align-items: center;
            opacity: 0.7;
          }

          .message-wrapper {
            display: flex;
            align-items: flex-start;
            padding-bottom: 20px;
            flex-wrap: wrap;
            width: 100%;

            .message-pp {
              width: 30px;
              height: 30px;
              border-radius: 50%;
              object-fit: cover;
              flex-shrink: 0;
              flex: 0 0 30px;
              max-width: 30px;
            }

            .ownerAlpha {
              background-color: #f3f0fe;
              color: #9b7bf6;
              width: 30px;
              height: 30px;
              line-height: 1;
              padding: 7px;
              position: relative;
              border-radius: 50%;

              font: {
                size: 18px;
                family: $font-family-work-san;
                weight: $font-weight-semibold;
              }

              span {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
              }
            }

            .d-flex {
              flex-wrap: wrap;
            }

            .message-box {
              border-radius: 5px;
              width: auto;
              max-width: 100%;
              word-wrap: break-word;

              // max-height: 100px;
              overflow: auto;

              font: {
                size: 14px;
                family: $font-family-base;
                weight: $font-weight-regular;
              }

              line-height: 1.4;
              color: $text-primary;

              .read-or-hide {
                cursor: pointer;
                text-decoration: underline;
                color: rgb(25 135 84);
              }

              &-wrapper {
                margin: 0 0 0 12px;
                padding: 10px;
                background-color: #f2f4f5;
                border-radius: 5px;
                flex: 0 0 calc(100% - 50px);
                max-width: calc(100% - 50px);

                .title-box {
                  display: flex;
                  width: 100%;
                  justify-content: space-between;
                  margin-bottom: 4px;

                  .name {
                    font: {
                      size: 12px;
                      family: $font-family-base;
                      weight: $font-weight-medium;
                    }

                    color: $text-primary;
                  }

                  .time {
                    position: relative;
                    width: 75px;
                    height: 25px;
                    background: rgb(0 0 0 / 0%);
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    font: {
                      size: 11px;
                      family: $font-family-base;
                      weight: $font-weight-regular;
                    }

                    color: $text-primary;

                    i {
                      font-size: 4px;
                      color: #8b79b8;
                      cursor: pointer;
                    }

                    .ActionMenu {
                      color: #777;
                      box-shadow: 0 0 4px rgb(0 0 0 / 10%);
                      padding: 11px;
                      right: 0;
                      z-index: 9;
                      background: $white;
                      position: absolute;
                      border-radius: 5px;

                      font: {
                        size: 11px;
                        family: $font-family-base;
                        weight: $font-weight-semibold;
                      }

                      ul {
                        list-style: none;
                        padding-left: 0;
                        margin-bottom: 0;

                        li {
                          display: flex;
                          align-items: center;
                          justify-content: flex-start;
                          padding-bottom: 17px;
                          cursor: pointer;

                          &:last-child {
                            padding-bottom: 0;
                          }
                        }
                      }

                      i {
                        color: #777;
                        font-size: 18px;
                        padding-right: 5px;

                        &.fa-edit {
                          font-size: 18px;
                        }

                        &.fa-butterflydelete {
                          font-size: 16px;
                        }

                        &.fa-turnoff {
                          font-size: 17px;
                        }
                      }
                    }
                  }
                }

                .footer {
                  display: flex;
                  align-items: center;
                  padding-top: 5px;
                  justify-content: space-between;

                  .reply {
                    font: {
                      size: 11px;
                      family: $font-family-base;
                      weight: $font-weight-semibold;
                    }

                    color: $text-primary;
                    margin-right: 10px;
                  }

                  .addreaction {
                    background-color: $white;
                    padding: 3px 9px;
                    border-radius: 20px;
                    margin-right: 10px;
                    border: 1px solid #ebebeb;
                    cursor: pointer;

                    i {
                      font-size: 17px;
                    }
                  }

                  .reply {
                    cursor: pointer;
                  }

                  .ctas {
                    span {
                      color: #777;
                      display: inline-flex;
                      align-items: center;
                      line-height: 1;
                      border-radius: 30px;
                      border: 1px solid #e8e8e8;
                      padding: 5px 8px;
                      background-color: $white;
                      cursor: pointer;

                      font: {
                        size: 13px;
                        family: $font-family-base;
                        weight: $font-weight-regular;
                      }

                      img {
                        margin-right: 5px;
                      }

                      em.reaction-wrapper {
                        font-style: normal;
                        font-size: 16px;
                        letter-spacing: 6px;
                      }
                    }
                  }
                }

                &.Edit {
                  background-color: $white;
                  border-radius: 5px;
                  border: 1px solid #dadada;

                  textarea {
                    width: 100%;
                    border: none;
                    resize: none;

                    font: {
                      size: 14px;
                      family: $font-family-base;
                      weight: $font-weight-regular;
                    }

                    &:focus {
                      border: none;
                      outline: none;
                    }
                  }

                  .footer {
                    justify-content: space-between;

                    .ctas {
                      color: $title;
                      display: inline-flex;
                      align-items: center;

                      button {
                        background: transparent;
                        border: none;
                        display: inline-flex;
                        align-items: center;
                      }

                      font: {
                        size: 14px;
                        family: $font-family-base;
                        weight: $font-weight-medium;
                      }

                      .cancel {
                        i {
                          color: #fc394d;
                          font-size: 20px;
                        }
                      }

                      .save {
                        i {
                          color: #2ecc71;
                          font-size: 20px;
                        }

                        &:disabled {
                          i {
                            color: #b7b7b7;
                          }
                        }
                      }
                    }
                  }
                }
              }

              .chatImageWrapper {
                margin: 8px 0;
                height: 100px;
                position: relative;
                width: max-content;

                img {
                  height: 100%;
                  width: auto;
                }
              }
            }

            &-reply {
              display: flex;
              align-items: flex-start;

              //padding-bottom: 20px;
              flex-wrap: wrap;
              width: calc(100% - 42px);
              margin: 10px 0 0 42px;

              .message-box-wrapper {
                background-color: transparent !important;
                border: 1px solid #ebebeb;
              }
            }

            .reaction-emoji-msgbord {
              & > p {
                position: absolute;
                z-index: 9;
                right: 22px;
                cursor: pointer;
              }

              aside {
                max-width: 100%;
                width: 100% !important;
                background-color: $white;
                margin-top: 10px;

                &.EmojiPickerReact {
                  .epr-header {
                    width: 100%;

                    .epr-header-overlay {
                      padding-bottom: 0;
                    }
                  }

                  .epr-body {
                    width: 100%;
                    padding: 0 10px;

                    .epr-emoji-list {
                      li {
                        &.epr-emoji-category > .epr-emoji-category-label {
                          font: {
                            size: 14px;
                            family: $font-family-base;
                            weight: $font-weight-semibold;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }

          .reply-box {
            position: absolute;
            height: calc(100% - 102px);
            background: rgb(255 255 255);
            background: linear-gradient(180deg, rgb(255 255 255 / 0%) 5%, rgb(0 0 0 / 49.2%) 100%);
            width: 100%;
            top: 38px;

            .reply-wrapper {
              padding: 10px 15px;
              background-color: $white;
              width: 100%;
              bottom: 0;
              position: absolute;

              .header {
                display: flex;
                justify-content: space-between;
                align-items: center;

                .title {
                  font: {
                    size: 11px;
                    family: $font-family-base;
                    weight: $font-weight-medium;
                  }

                  color: $success;
                }

                .close-btn {
                  cursor: pointer;

                  i {
                    font-size: 18px;
                    color: #777;
                  }
                }
              }

              .message {
                font: {
                  size: 12px;
                  family: $font-family-base;
                  weight: $font-weight-regular;
                }

                color: #333;
              }
            }
          }
        }
      }
    }
  }
}
