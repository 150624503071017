@import 'bootstrap/scss/bootstrap';
@import 'bootstrap/scss/mixins';
@import '../../Assets/Styles/typography';
@import '../../Assets/Styles/colors';
@import '../../Assets/Styles/mixins';

.Notification {
  max-width: 570px;
  width: 100%;

  .MoveTop {
    box-shadow: 0 0 10px silver;
    position: fixed;
    right: 10px;
    width: 40px;
    height: 40px;
    background-color: $success;
    border-radius: 50%;
    border: none;
    bottom: 10px;
    z-index: 99;
    cursor: pointer;

    i {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: $white;
    }
  }

  .NotificationWrapper {
    .emptyMessage {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      text-align: center;
      width: 100%;
    }

    .EachNotificationWrapper {
      border-bottom: 1px dashed $grey-1;
      padding: 18px 0;

      .dashed-border-top {
        border-bottom: 1px dashed $grey-1;
      }

      .EachNotification {
        display: flex;
        flex-wrap: nowrap;
        align-items: flex-start;
        padding: 0 18px;

        @include media-breakpoint-down(lg) {
          padding: 0;
        }

        .ProfileImg {
          width: 50px;
          height: 50px;
          flex: 0 1 50px;
          max-width: 50px;
          border-radius: 50%;
          background-color: #1d817b2b;
          color: #1d817b;
          display: flex;
          align-items: center;
          justify-content: center;

          span {
            font-family: $font-family-dada-grotesk;
            font-size: 20px;
            font-weight: 600;
          }

          img {
            width: 50px;
            height: 50px;
            border-radius: 50%;
          }
        }

        .NotificationText {
          max-width: calc(100% - 50px);
          flex: 0 1 calc(100% - 50px);
          padding-left: 12px;
          word-break: break-word;
          font: {
            size: 16px;
            family: $font-family-dada-grotesk;
            weight: $font-weight-regular;
          }

          line-height: 1.2;

          strong,
          span {
            font: {
              size: 16px;
              family: $font-family-dada-grotesk;
              weight: $font-weight-medium;
            }
          }

          strong {
            color: $title;
          }

          span {
            color: $success;
          }

          .time {
            color: #777;
            margin-top: 5px;
            font: {
              size: 12px;
              family: $font-family-base;
              weight: $font-weight-regular;
            }
          }

          a {
            color: $success;
          }
        }
      }

      .time {
        color: #777;
        margin-top: 5px;
        font: {
          size: 12px;
          family: $font-family-base;
          weight: $font-weight-regular;
        }

        padding-left: 0;
      }

      .row {
        padding-left: 90px;
        padding-right: 15px;
        padding-top: 16px;
        align-items: center;

        @include media-breakpoint-down(xl) {
          padding-left: 75px;
        }

        .description {
          max-width: 100%;
          flex: 0 1 100%;
          color: $text-primary;
          padding-left: 0;
          padding-right: 0;
          line-height: 1.2;
          word-wrap: break-word;
          font: {
            size: 14px;
            family: $font-family-base;
            weight: $font-weight-regular;
          }

          .ActionCTA {
            padding: 20px 0 0;
          }
        }

        .ActionCTA {
          display: flex;
          align-items: center;

          span {
            display: flex;
            align-items: center;
            color: $title;

            font: {
              size: 14px;
              family: $font-family-base;
              weight: $font-weight-medium;
            }

            &.accept {
              cursor: pointer;

              i {
                color: $success;
                font-size: 24px;
              }

              margin-right: 30px;
            }

            &.reject {
              cursor: pointer;

              i {
                color: $error;
                font-size: 24px;
              }
            }
          }
        }

        .EachActionItem {
          display: flex;
          align-items: center;
          justify-content: space-between;
          border-top: 1px dashed $grey-1;
          padding: 12px 0;

          &.no-border {
            border: none;

            .ActionCTA {
              padding: 20px 0 0;
            }
          }

          .media {
            width: 115px;
            height: 115px;
            background-color: $bg;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;

            img {
              max-height: 100%;
              width: auto;
              max-width: 100%;
              height: auto;
            }

            &.video-player {
              height: auto;
              width: 50%;

              @include media-breakpoint-down(md) {
                width: 100%;
              }
            }

            video {
              max-width: 100%;
              cursor: pointer;
            }

            .video-thumb {
              position: absolute;
              left: 50%;
              transform: translateX(-50%);

              span {
                i {
                  color: $white;
                  cursor: pointer;
                  font-size: 30px;
                }
              }
            }
          }

          .content {
            flex: 0 1 calc(100% - 210px);
            max-width: calc(100% - 210px);
            color: $text-primary;
            padding: 0 10px;
            line-height: 1.2;
            word-wrap: break-word;
            font: {
              size: 14px;
              family: $font-family-base;
              weight: $font-weight-regular;
            }
          }

          .Ctas {
            flex: 0 1 100px;
            max-width: 100px;

            span {
              display: flex;
              align-items: center;
              flex-wrap: nowrap;
              color: $title;
              cursor: pointer;
              font: {
                size: 14px;
                family: $font-family-base;
                weight: $font-weight-medium;
              }

              &.accept {
                &.disabled {
                  color: $success;
                  cursor: none;
                }

                i {
                  color: $success;
                  font-size: 24px;
                }

                &:hover {
                  color: $success;
                }

                margin-right: 30px;
              }

              &.reject {
                &.disabled {
                  color: $error;
                  cursor: none;
                }

                i {
                  color: $error;
                  font-size: 24px;
                }

                &:hover {
                  color: $error;
                }
              }
            }
          }

          &.video-wrapper {
            flex-wrap: wrap;

            .content {
              margin-top: 15px;
              flex: 0 1 100%;
              max-width: 100%;
              order: 3;
              padding: 0;
            }
          }

          @include media-breakpoint-down(md) {
            flex-wrap: wrap;

            .media {
              margin-bottom: 15px;
            }

            .content {
              flex: 0 1 100%;
              max-width: 100%;
              margin-bottom: 15px;
              padding: 0;
            }

            .Ctas {
              flex: 0 1 100%;
              max-width: 100%;
              display: flex;

              br {
                display: none;
              }
            }
          }

          &.noapprove {
            align-items: flex-start;

            .content {
              flex: 0 1 calc(100% - 130px);
              max-width: calc(100% - 130px);
            }

            @include media-breakpoint-down(md) {
              flex-wrap: wrap;

              .media {
                margin-bottom: 15px;
              }

              .content {
                flex: 0 1 100%;
                max-width: 100%;
              }

              .Ctas {
                flex: 0 1 100%;
                max-width: 100%;
              }
            }
          }
        }

        &:has(.AudioWaves) {
          align-items: center;
        }

        .AudioWaves {
          flex: 0 1 calc(100% - 70px);

          .audioWrapper {
            width: 100%;

            .waves {
              width: 100%;

              wave {
                wave {
                  z-index: 2;
                }
              }
            }
          }
        }

        .ControllerIcon {
          width: 58px;
          height: 58px;
          flex: 0 1 58px;
          padding: 0;
          cursor: pointer;

          i {
            font-size: 58px;
            color: $success;
          }
        }

        span.personal-audio-msg {
          width: 65px;
          height: 65px;
          padding: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          background: #1d817b;
          border-radius: 5px;

          i {
            color: $white;
            font-size: 23px;
          }
        }
      }
    }
  }
}
