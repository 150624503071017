@import 'bootstrap/scss/bootstrap';
@import 'bootstrap/scss/mixins';
@import '../../Assets/Styles/typography';
@import '../../Assets/Styles/colors';
@import '../../Assets/Styles/mixins';

.panel-wrap .panel.SearchPages {
  padding: 0;

  .header {
    margin: 0 24px;
  }

  .search-text {
    padding: 20px 32px;
    color: $text-primary;
  }

  .searchInput {
    position: relative;
    color: #aaa;
    font-size: 16px;
    display: inline-block;
    padding: 0 32px 30px;
    max-width: 100%;
    width: 100%;

    label {
      color: $text-primary;
      font: {
        family: $font-family-base;
        weight: $font-weight-regular;
        size: 14px;
      }

      margin-bottom: 5px;
    }

    input {
      width: 100%;
      height: 50px;
      text-indent: 12px;
      background: $white;
      border: 1px solid $grey-1;
      border-radius: 10px;
      padding-left: 10px;
      color: $title;
      padding-right: 40px;
      font: {
        family: $font-family-base;
        weight: $font-weight-regular;
        size: 16px;
      }

      &:focus {
        outline: none;
        border: 1px solid $grey-1;
      }
    }

    i {
      position: absolute;
      top: 56px;
      transform: translateY(-50%);
      right: 45px;
      color: $success;
      font-size: 18px;
      font-weight: bold;
      cursor: pointer;

      @include media-breakpoint-down(md) {
        top: auto;
        bottom: 37px;
      }
    }
  }

  .wrapper {
    display: grid;
    gap: 25px;
    grid-template-columns: 1fr;
    justify-content: flex-start;
    margin-bottom: 25px;
    overflow-y: overlay;
    padding: 0 32px 50px;
    max-height: calc(100vh - 295px);

    //min-height: calc(100vh - 74px);

    @include media-breakpoint-down(md) {
      max-height: unset;
    }
  }

  .card {
    border-radius: 5px;
    min-width: calc(100% / 3);
    display: flex;
    border: 1px solid $bg;

    &:hover {
      box-shadow: 0 0 34px -7px rgb(0 0 0 / 45%);
      cursor: pointer;
      transition: ease 0.2s;
    }

    .ImgHolder {
      width: 100%;
      height: auto;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      background: url('https://butterflyv2-production-assets.nyc3.cdn.digitaloceanspaces.com/image/white_mark.png'),
        #1d817b;
      background-repeat: no-repeat;
      background-position: center;
      position: relative;
      overflow: hidden;
      display: flex;
      justify-content: space-between;

      .card-img-top {
        // position: absolute;
        // height: 100%;
        width: 100%;

        // left: 50%;
        // transform: translateX(-50%);
      }

      .ImgWrapper {
        width: 100%;
        position: relative;
        height: 172px;

        .overlay {
          position: absolute;
          bottom: 0;
          width: 100%;
          height: 100%;
          background: rgb(255 249 249);
          background: linear-gradient(180deg, rgb(255 249 249 / 1.03%) 41%, rgb(0 0 0 / 52.6%) 97%);
        }

        &:has(.card-img-top) {
          background: silver;
          height: unset;
        }

        .BottomSection {
          position: absolute;
          bottom: 0;
          padding: 8px 10px;
          max-width: 100%;

          p {
            margin: 0;
            line-height: 1;
            font: {
              size: 20px;
              family: $font-family-base;
              weight: $font-weight-semibold;
            }

            color: $white;
          }

          .BottomIcons {
            color: $white;
            display: inline-flex;
            align-items: center;
            margin-top: 4px;
            width: 100%;

            .privacy {
              width: 100px;
            }

            .members {
              width: 140px;
            }

            .subscribe {
              width: 70px;
              text-align: right;

              @include media-breakpoint-down(md) {
                width: 54px;
              }
            }

            span {
              display: inline-flex;
              align-items: center;
              padding-right: 15px;
              font: {
                size: 14px;
                family: $font-family-base;
                weight: $font-weight-regular;
              }

              &:last-child {
                padding-right: 0;
              }
            }

            i {
              color: $white;
              position: relative;
              font-size: 24px;
              padding-right: 3px;

              &.fa-membersicon {
                font-size: 18px;
              }
            }
          }
        }
      }
    }

    &__img {
      width: 100%;
      height: auto;
      object-fit: cover;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
    }

    &__body {
      margin: 1rem;
      flex-grow: 0;

      .owner {
        @include ProfilePic;

        color: $text-primary;
        min-height: 52px;

        .ownerName {
          color: $text-primary;
        }
      }

      p {
        font: {
          family: $font-family-base;
          weight: $font-weight-regular;
          size: 15px;
        }

        color: $text-primary;
        min-height: 90px;
        word-wrap: break-word;
        max-height: 90px;
        overflow: hidden;

        @include media-breakpoint-down(md) {
          min-height: unset;
        }
      }
    }

    &__footer {
      margin: 10px;
      display: flex;
      justify-content: space-between;
      padding: 10px 0 0;
      border-top: 1px solid $bg;
      align-items: center;
    }

    &__btn {
      @include button-success;

      height: 40px;
      font-size: 14px;
    }

    .subscribe-button {
      color: #777;
      display: inline-flex;
      align-items: center;
      font: {
        family: $font-family-base;
        weight: $font-weight-medium;
        size: 12px;
      }

      span {
        padding-top: 0;
        line-height: 1;
      }

      i {
        font-size: 18px;
        padding-right: 5px;
        color: $success;
      }

      &.active {
        i {
          color: $success;
        }
      }

      .subscribed {
        color: $success;
      }

      .peding {
        color: #777;
      }
    }
  }

  @include media-breakpoint-between(md, lg) {
    .wrapper {
      grid-template-columns: repeat(auto-fit, minmax(240px, 260px));
    }
  }

  @include media-breakpoint-up(lg) {
    .wrapper {
      grid-template-columns: repeat(auto-fit, minmax(240px, 260px));
    }

    .card {
      flex-direction: column;

      //min-width: calc(100% / 3);

      &__img {
        width: 100%;
        height: auto;
      }
    }
  }

  .no-result {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    text-align: center;
    font-size: 15px;
    font-family: Poppins, sans-serif;
    font-weight: 400;
    color: #777;
  }
}
