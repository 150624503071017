.PhoneInput {
  max-width: 250px;
  margin: 0 auto;

  .PhoneInputCountry {
    border: 1px solid #c6c8ca;
    padding: 0 10px;
    border-radius: 40px;
  }
}
