@import 'bootstrap/scss/bootstrap';
@import 'bootstrap/scss/mixins';
@import '../../Assets/Styles/typography';
@import '../../Assets/Styles/colors';
@import '../../Assets/Styles/mixins';

$container-width: 500px;
$container-height: 35px;
$container-padding-left: 10px;
$drop-list-max-height: 300px;
$list-item-height: 45px;
$ease-timing: 0.2s;

.MySubscribedPages {
  padding: 66px 80px 0;
  width: 100%;
  max-height: 100vh;
  overflow: scroll;

  @include media-breakpoint-down(md) {
    padding: 120px 15px 15px;
    max-height: none;
    overflow: unset;
  }

  @include media-breakpoint-between(md, xl) {
    padding: 66px 60px 0;
  }

  .TopBar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    padding-left: 20px;
    padding-right: 20px;

    @include media-breakpoint-down(md) {
      flex-wrap: wrap;
      padding: 0;
      margin-bottom: 0;
    }

    h2 {
      margin-bottom: 0;
      font: {
        family: $font-family-base;
        weight: $font-weight-bold;
        size: 32px;
      }

      display: inline-flex;
      align-items: center;
      color: $title;

      span {
        margin-left: 10px;
        min-width: 38px;
        width: max-content;
        padding: 0 10px;
        height: 38px;
        background-color: $white;
        border-radius: 50px;
        display: inline-flex;
        color: $success;
        justify-content: center;
        align-items: center;
        font: {
          family: $font-family-base;
          weight: $font-weight-medium;
          size: 16px;
        }
      }

      @include media-breakpoint-down(md) {
        padding-bottom: 22px;
        border-bottom: 1px solid silver;

        //margin-bottom: 20px;
        width: 100%;
        padding-left: 5px;
        font-size: 24px;
      }
    }

    &__right {
      font: {
        family: $font-family-base;
        weight: $font-weight-semibold;
        size: 16px;
      }

      display: inline-flex;
      align-items: center;
      margin-left: 5px;

      @include SelectDropdown;

      @include media-breakpoint-down(md) {
        //padding-left: 25px;
        //margin-top: 20px;
        .butterfly-dropdown-select {
          width: 180px;
        }
      }

      span {
        display: inline-flex;
        align-items: center;

        @include media-breakpoint-down(md) {
          padding-top: 20px;
          width: 100%;
        }
      }

      button.Cta {
        @include button-success;

        height: 40px;
        margin-left: 20px;

        i {
          margin-right: 8px;
          font-size: 16px;
        }

        margin-bottom: 0;

        @include media-breakpoint-down(lg) {
          display: none;
        }
      }
    }
  }

  .wrapper {
    display: grid;
    gap: 33px;
    grid-template-columns: 1fr;
    justify-content: flex-start;
    margin-bottom: 0;
    overflow-y: auto;
    max-height: calc(100vh - 243px);
    min-height: calc(100vh - 243px);
    scroll-behavior: smooth;
    padding: 20px;
    padding-bottom: 100px;

    @include media-breakpoint-down(xl) {
      max-height: unset;
      padding: 20px 0;
      padding-top: 25px;
    }
  }

  .card {
    border-radius: 5px;

    //min-width: calc(100% / 3);
    display: flex;
    border: 1px solid $bg;
    height: max-content;

    @include media-breakpoint-up(xl) {
      &:hover {
        box-shadow: 0 0 34px -7px rgb(0 0 0 / 45%);
        cursor: pointer;
        transition: ease 0.2s;
      }
    }

    .ImgHolder {
      width: 100%;
      height: auto;
      border-radius: 5px;
      background: url('https://butterflyv2-production-assets.nyc3.cdn.digitaloceanspaces.com/image/white_mark.png'),
        #1d817b;
      background-repeat: no-repeat;
      background-position: center;
      position: relative;
      overflow: hidden;
      display: flex;
      justify-content: space-between;

      .card-img-top {
        // position: absolute;
        // height: 100%;
        width: 100%;

        // left: 50%;
        // transform: translateX(-50%);
      }

      .ImgWrapper {
        width: 100%;
        position: relative;
        height: 215px;

        @include media-breakpoint-between(md, xl) {
          height: 165px;
        }

        .overlay {
          position: absolute;
          bottom: 0;
          width: 100%;
          height: 100%;
          background: rgb(255 249 249);
          background: linear-gradient(
            180deg,
            rgb(255 249 249 / 1.03%) 41%,
            rgb(0 0 0 / 52.6%) 97%
          );
        }

        .BottomSection {
          position: absolute;
          bottom: 0;
          padding: 8px 10px;
          max-width: 100%;
          width: 100%;

          p {
            margin: 0;
            line-height: 1;
            font: {
              size: 20px;
              family: $font-family-base;
              weight: $font-weight-semibold;
            }

            color: $white;
          }

          .BottomIcons {
            color: $white;
            display: inline-flex;
            align-items: center;
            margin-top: 4px;
            width: 100%;
            justify-content: flex-start;

            .privacy {
              width: 100px;
            }

            .members {
              width: 140px;

              @include media-breakpoint-between(md, xl) {
                width: 280px;
              }
            }

            .subscribe {
              width: 70px;
              text-align: right;
              display: inline-block;

              @include media-breakpoint-down(md) {
                width: 54px;
              }
            }

            span {
              display: inline-flex;
              align-items: center;
              padding-right: 15px;
              font: {
                size: 14px;
                family: $font-family-base;
                weight: $font-weight-regular;
              }

              &:last-child {
                padding-right: 0;
              }
            }

            i {
              color: $white;
              position: relative;
              font-size: 24px;
              padding-right: 3px;

              &.fa-membersicon {
                font-size: 18px;
              }
            }

            @include media-breakpoint-down(xl) {
              justify-content: space-between;
            }
          }
        }
      }
    }

    &__img {
      width: 100%;
      height: auto;
      object-fit: cover;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
    }

    &__body {
      margin: 1rem;
      flex-grow: 0;

      .owner {
        @include ProfilePic;

        color: $text-primary;

        .ownerName {
          color: $text-primary;
        }
      }

      p {
        font: {
          family: $font-family-base;
          weight: $font-weight-regular;
          size: 15px;
        }

        color: $text-primary;
        min-height: 90px;
        word-wrap: break-word;
        max-height: 90px;
        overflow: hidden;

        @include media-breakpoint-down(md) {
          min-height: unset;
        }
      }
    }

    &__footer {
      margin: 10px;
      display: flex;
      justify-content: center;
      padding: 10px 0 0;
      border-top: 1px solid $bg;
      align-items: center;
    }

    &__btn {
      @include button-success;

      height: 40px;
      font-size: 14px;
    }

    .subscribe-button {
      color: #777;
      display: inline-flex;
      align-items: center;
      font: {
        family: $font-family-base;
        weight: $font-weight-medium;
        size: 12px;
      }

      span {
        padding-top: 0;
        line-height: 1;
      }

      i {
        font-size: 18px;
        padding-right: 5px;
        color: $success;
      }

      &.active {
        i {
          color: $success;
        }
      }

      .subscribed {
        color: $success;
      }

      .peding {
        color: #777;
      }
    }
  }

  @include media-breakpoint-between(md, lg) {
    .wrapper {
      grid-template-columns: repeat(auto-fit, minmax(240px, 270px));
    }
  }

  @include media-breakpoint-up(lg) {
    .wrapper {
      grid-template-columns: repeat(auto-fit, minmax(260px, 325px));
    }

    .card {
      flex-direction: column;

      //min-width: calc(100% / 3);

      &__img {
        width: 100%;
        height: auto;
      }
    }
  }

  #Footer {
    .Emptydiv {
      display: none;
    }

    @include media-breakpoint-down(md) {
      .Emptydiv {
        display: block;
        height: 150px;
      }
    }

    text-align: center;
    position: relative;

    &::before {
      content: '';
      position: absolute;
      width: 100%;
      background: linear-gradient(180deg, hsl(0deg 0% 100% / 0%) 0%, $bg 97%);
      bottom: 43px;
      height: 50px;
      left: 0;

      @include media-breakpoint-down(md) {
        display: none;
      }
    }

    button {
      @include button-success;

      height: 60px;

      i {
        margin-right: 8px;
      }

      margin-bottom: 35px;
    }

    p {
      font: {
        size: 12px;
        family: $font-family-base;
        weight: $font-weight-regular;
      }

      color: #777;
    }
  }
}
