@import 'bootstrap/scss/bootstrap';
@import 'bootstrap/scss/mixins';
@import '../../Assets/Styles/typography';
@import '../../Assets/Styles/colors';
@import '../../Assets/Styles/mixins';

#CreatePage {
  height: 100%;
  width: 100%;
  overflow-x: hidden;

  .container {
    padding: 0;
    background-color: $white;
    min-height: 100vh;
    height: max-content;
    max-height: 1200px;
    overflow-x: hidden;

    @media (width <= 1440px) {
      max-width: 100%;
      width: 100%;
      overflow: hidden;
      margin-left: 0;
    }

    .m-logo {
      margin: 43px auto 0;
      text-align: center;

      img {
        max-width: 152px;
      }

      .title {
        font: {
          size: 20px;
          family: $font-family-base;
          weight: $font-weight-semibold;
        }
      }
    }

    .imgHolder {
      width: 45%;
      height: auto;
      position: relative;

      .bgImg {
        position: absolute;
        width: 100%;
        height: auto;
        object-fit: cover;
      }

      .contentWrapper {
        position: absolute;
        min-height: 100vh;
        height: 100%;
        width: 100%;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;

        .overlay {
          position: absolute;
          bottom: 0;
          width: 100%;
          height: 100%;
          z-index: 0;
          background: linear-gradient(180deg, rgb(255 249 249 / 1.03%) 41%, rgb(0 0 0 / 52.6%) 97%);
        }

        &.stepOneBg {
          background-image: url('https://butterflyv2-production-assets.nyc3.digitaloceanspaces.com/image/b770ec88-e105-45c5-87cf-d1299c74dcc6-Page-Details.jpg');
        }

        &.stepTwoBg {
          background-image: url('https://butterflyv2-production-assets.nyc3.digitaloceanspaces.com/image/d6ad382c-dc67-41f9-9df1-ca940ddf3737-Privacy-Settings.jpg');
        }

        &.stepThreeBg {
          background-image: url('https://butterflyv2-production-assets.nyc3.digitaloceanspaces.com/image/a4b5cff7-58a5-4561-a831-5a1a089d6a3b-e-Approval-Settings.jpg');
        }

        .sideBar {
          width: 70px;
          height: 100vh;
          background-color: rgb(0 0 0 / 60%);
          text-align: center;
          position: fixed;
          z-index: 1;
          left: 0;

          .logo {
            padding: 21px 15px;

            img {
              max-width: 38px;
              cursor: pointer;
            }
          }

          ul.Back {
            list-style: none;
            padding: 0;
            text-align: center;

            li {
              margin-bottom: 10px;
              height: 57px;
              cursor: pointer;

              i {
                background: $success;
                padding: 10px;
                border-radius: 50%;
                color: $white;
                display: flex;
                justify-content: center;
                align-items: center;
                margin: auto;
                width: 44px;
                height: 44px;
                margin-bottom: 5px;
                transition: 0.4s;
              }

              &:hover {
                i {
                  color: $success;
                  background-color: $white;
                  transition: 0.4s;
                }

                span {
                  opacity: 1;
                }
              }

              span {
                display: block;
                opacity: 0;
                transition: 0.4s;
                line-height: 1;
                font: {
                  size: 10px;
                  family: $font-family-base;
                  weight: $font-weight-medium;
                }

                color: $bg;
              }
            }
          }

          ul.Back {
            list-style: none;
            padding: 0;
            text-align: center;

            li {
              margin-bottom: 10px;
              height: 57px;
              cursor: pointer;

              i {
                background: $success;
                padding: 10px;
                border-radius: 50%;
                color: $white;
                display: flex;
                justify-content: center;
                align-items: center;
                margin: auto;
                width: 44px;
                height: 44px;
                margin-bottom: 5px;
                transition: 0.4s;
              }

              &:hover {
                i {
                  color: $success;
                  background-color: $white;
                  transition: 0.4s;
                }

                span {
                  opacity: 1;
                }
              }

              span {
                display: block;
                opacity: 0;
                transition: 0.4s;
                line-height: 1;
                font: {
                  size: 10px;
                  family: $font-family-base;
                  weight: $font-weight-medium;
                }

                color: $bg;
              }
            }
          }
        }

        .title {
          font: {
            size: 36px;
            family: $font-family-base;
            weight: $font-weight-bold;
          }

          color: $white;
          position: absolute;
          bottom: 60px;
          line-height: 1.3;
          padding: 0 85px;

          @include media-breakpoint-between(md, xl) {
            left: 80px;
            line-height: 1.3;
            padding: 0 20px 0 0;
          }
        }
      }
    }

    .formHolder {
      position: relative;
      width: 55%;
      height: 100vh;
      padding: 90px 114px 20px;
      max-height: 100vh;
      overflow-y: overlay;
      scroll-behavior: smooth;

      @media only screen and (width <= 1366px) and (width >= 1200px) {
        height: 110vh;
        max-height: 110vh;
      }

      @include media-breakpoint-down(md) {
        width: 100%;
        padding: 0 38px 38px;
        height: max-content;
        max-height: max-content;
        position: relative;
      }

      @include media-breakpoint-between(md, xl) {
        padding: 90px 40px 20px;
      }

      .steps {
        &.stepOne {
          margin-top: 80px;

          @include media-breakpoint-down(md) {
            margin: 50px 0;
          }

          h3 {
            font: {
              size: 18px;
              family: $font-family-base;
              weight: $font-weight-medium;
            }

            color: $title;
            margin-top: 5px;

            @include media-breakpoint-down(md) {
              text-align: center;
            }
          }

          form {
            label {
              font: {
                size: 14px;
                family: $font-family-base;
                weight: $font-weight-regular;
              }

              color: $title;
              margin-bottom: 5px;
            }

            input {
              height: 40px;
              padding: 0 0 0 10px;
              border: 1px solid $grey-1;
              border-radius: 10px;
              background-color: $white;
              background-size: cover;
              font: {
                size: 14px;
                family: $font-family-base;
                weight: $font-weight-regular;
              }

              color: $title;
              text-decoration: none solid rgb(44 62 80);

              &:focus,
              &:hover {
                box-shadow: none;
                outline: none;
              }
            }

            textarea {
              height: 80px;
              padding: 8px 5px 5px 10px;
              border: 1px solid $grey-1;
              border-radius: 10px;
              background-color: $white;
              background-size: cover;
              resize: none;
              font: {
                size: 14px;
                family: $font-family-base;
                weight: $font-weight-regular;
              }

              color: $title;
              text-decoration: none solid rgb(44 62 80);

              &:focus,
              &:hover {
                box-shadow: none;
                outline: none;
              }
            }

            span.textcount {
              color: #777;
              font: {
                size: 12px;
                family: $font-family-base;
                weight: $font-weight-medium;
              }
            }

            @include media-breakpoint-down(md) {
              .mobile-alignment {
                text-align: center !important;
                width: 100%;
                justify-content: center;
                margin: 0;
              }
            }
          }
        }

        &.stepTwo {
          margin: 50px 0;

          .privacy {
            padding: 47px 0 0;

            @include media-breakpoint-down(md) {
              padding: 36px 0 0;
            }

            .card {
              flex-wrap: nowrap;
              width: 100%;
              border: none;
              margin-bottom: 46px;

              .card-body {
                align-items: center;

                @include media-breakpoint-down(md) {
                  align-items: flex-start;
                }

                .lhs {
                  flex: 0 1 calc(100% - 75px);
                  max-width: calc(100% - 75px);
                  padding-right: 10px;

                  .title {
                    font: {
                      size: 16px;
                      family: $font-family-base;
                      weight: $font-weight-medium;
                    }

                    word-wrap: break-word;
                    color: $title;
                    padding-bottom: 3px;
                    display: flex;
                    align-items: center;

                    i {
                      color: $success;
                      font-size: 24px;
                      font-weight: 100;
                      padding-right: 8px;
                    }

                    @include media-breakpoint-down(md) {
                      align-items: flex-start;
                    }
                  }

                  .subTitle {
                    color: #777;
                    font: {
                      size: 15px;
                      family: $font-family-base;
                      weight: $font-weight-regular;
                    }

                    margin-bottom: 0;
                    text-align: left;
                    word-wrap: break-word;
                  }
                }

                .rhs {
                  flex: 0 1 75px;
                  max-width: 75px;
                }
              }
            }
          }
        }

        &.stepThree {
          margin-top: 50px;

          @include media-breakpoint-down(md) {
            .justify-content-between.d-flex {
              flex-wrap: wrap;
              justify-content: center !important;

              button {
                &:first-of-type {
                  margin-bottom: 20px;
                }
              }
            }
          }
        }

        h2 {
          font: {
            size: 24px;
            family: $font-family-base;
            weight: $font-weight-semibold;
          }

          color: $title;
          margin-bottom: 0;

          @include media-breakpoint-down(md) {
            text-align: center;
            font-size: 20px;
            width: 100%;
          }

          word-wrap: break-word;
        }

        p {
          word-wrap: break-word;
          font: {
            size: 16px;
            family: $font-family-base;
            weight: $font-weight-regular;
          }

          color: $title;

          span {
            color: $success;
            display: block;
          }

          @include media-breakpoint-down(md) {
            text-align: center;
          }
        }

        form {
          button {
            @include button-success;

            min-width: 120px;
            width: auto;
            line-height: 1;

            @include media-breakpoint-down(md) {
              margin: 15px;
            }

            @include media-breakpoint-between(md, xl) {
              margin-right: 10px;
            }

            &.prev {
              border: 1px solid $grey-1;
              border-radius: 50px;
              background-color: $white;
              background-size: cover;
              font-family: Poppins;
              font-size: 16px;
              color: #777;
              text-decoration: none solid rgb(119 119 119);
              text-align: center;

              &:hover,
              &:focus,
              &:active {
                background-color: $title !important;
                color: $white !important;
              }
            }

            &:focus,
            &:hover {
              box-shadow: none;
              outline: none;
            }
          }

          .switch input {
            display: none;
          }

          .switch {
            display: inline-block;
            width: 75px; /* =w */
            height: 40px; /* =h */
            margin: 4px;
            position: relative;
          }

          .slider {
            position: absolute;
            inset: 0;
            border-radius: 30px;
            box-shadow:
              0 0 0 1px $grey-1,
              0 0 0 $grey-1;
            cursor: pointer;
            border: 4px solid transparent;
            overflow: hidden;
            transition: 0.2s;
          }

          .slider::before {
            position: absolute;
            content: '';
            width: 100%;
            height: 100%;
            background-color: $grey-1;
            border-radius: 30px;
            transform: translateX(-30px);
            transition: 0.2s;
          }

          input:checked + .slider::before {
            transform: translateX(35px); /* translateX(w-h) */
            background-color: $success;
          }

          input:checked + .slider {
            box-shadow:
              0 0 0 2px $success,
              0 0 8px $success;
          }

          .switch200 .slider::before {
            width: 200%;
            transform: translateX(-102px);
          }

          .switch200 input:checked + .slider::before {
            background-color: $success;
          }

          .switch200 input:checked + .slider {
            box-shadow:
              0 0 0 1px #6f7072,
              0 0 0 #6f7072;
          }
        }
      }

      .RSPBprogressBar {
        height: 0;
        line-height: 1;
        border: 1px dashed rgb(211 211 211 / 60%);
        border-radius: 10px;
        position: relative;
        background-color: transparent;
        width: 100px;

        @include media-breakpoint-down(md) {
          margin: auto;
          margin-top: 30px;
        }

        .RSPBprogression {
          border: none;
          background: transparent;
        }

        .RSPBstep {
          .steps {
            width: 12px;
            height: 12px;
            background-color: $bg;
            border-radius: 50%;

            &.completed {
              background-color: $success;
            }
          }
        }
      }

      .position-absolute {
        bottom: 10px;
        height: 30px;
        left: 50%;
        transform: translateX(-50%);
        width: 227px;

        @media (width >= 768px) and (width <= 980px) and (orientation: landscape) {
          position: relative !important;
          top: 50px;
        }
      }

      .CopyRight {
        font: {
          size: 14px;
          family: $font-family-base;
          weight: $font-weight-regular;
        }

        color: #777;
      }
    }
  }
}
