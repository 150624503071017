@import 'bootstrap/scss/bootstrap';
@import 'bootstrap/scss/mixins';
@import '../../Assets/Styles/typography';
@import '../../Assets/Styles/colors';

#Footer {
  text-align: center;
  font: {
    size: 12px;
    family: $font-family-dada-grotesk;
    weight: $font-weight-regular;
  }

  margin-top: 8px;
  color: $text-primary;

  a {
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
}

.footer {
  text-align: left !important;
}
