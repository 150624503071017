@import 'bootstrap/scss/bootstrap';
@import 'bootstrap/scss/mixins';
@import '../../Assets/Styles/typography';
@import '../../Assets/Styles/colors';
@import '../../Assets/Styles/mixins';

.MobileNav {
  display: none;

  @include media-breakpoint-down(md) {
    display: block;
    z-index: 99999999999;
    position: fixed;
  }

  header {
    display: flex;
    position: fixed;
    height: 70px;
    background: $white;
    color: #d1d1d1;
    justify-content: space-between;
    align-items: flex-end;
    -webkit-tap-highlight-color: rgb(0 0 0 / 0%);
    width: 100vw;
    padding: 12px 17px;
    box-shadow: 0 0 30px rgb(0 0 0 / 10%);

    .logo {
      width: 48px;

      img {
        width: 100%;
      }
    }

    .Cta {
      width: 120px;
      display: flex;
      justify-content: space-between;
      align-items: flex-end;

      span.Icon {
        display: block;
        color: #000;
        padding-bottom: 3px;

        i {
          font-size: 28px;
          color: #777;
        }
      }

      &.EndAlign {
        justify-content: flex-end;
        padding-right: 10px;
      }

      .button {
        .NotificationDot {
          background: #25b082;
          width: 12px;
          height: 12px;
          border: 2px solid $white;
          border-radius: 50%;
          right: -2px;
          position: absolute;
          bottom: 23px;
        }
      }
    }

    &.have-personal-message {
      .Cta {
        width: 170px;
      }
    }
  }

  #nav-container {
    position: fixed;
    height: 100%;
    width: 100%;
    pointer-events: none;
    display: flex;
    justify-content: flex-end;

    //background: rgba(0, 0, 0, 0.5);
  }

  #nav-container .bg {
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
    visibility: hidden;
    opacity: 0;
    transition: 0.3s;
    background: #000;
  }

  #nav-container .bg.Active {
    visibility: visible;
    opacity: 0.6;
    cursor: none;
  }

  #nav-container * {
    visibility: visible;
  }

  .button {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    z-index: 1;
    appearance: none;
    border: 0;
    background: transparent;
    border-radius: 0;
    height: 70px;
    width: 30px;
    cursor: pointer;
    pointer-events: auto;
    margin-right: 0;
    padding-bottom: 10px;
    touch-action: manipulation;
    -webkit-tap-highlight-color: rgb(0 0 0 / 0%);
  }

  .icon-bar {
    display: block;
    width: 100%;
    height: 3px;
    background: #333;
    transition: 0.3s;
  }

  .icon-bar + .icon-bar {
    margin-top: 5px;
  }

  .NavWrapper {
    overflow: scroll;
    max-height: 100%;

    &::-webkit-scrollbar {
      display: block;
    }
  }

  #nav-content {
    margin-top: 0;
    padding: 37px;
    width: 90%;
    max-width: 300px;
    position: absolute;
    top: 0;
    left: 0;
    border-right: 1px solid #d1d1d1;
    height: 100%;
    background: $white;
    pointer-events: auto;
    -webkit-tap-highlight-color: rgb(0 0 0 / 0%);
    transform: translateX(-100%);
    transition: transform 0.3s;
    will-change: transform;
    contain: paint;

    &.is-not-detail-page {
      height: 100%;

      .signout {
        padding-top: 50px;
      }
    }

    .logoText {
      height: 43px;
      width: 100%;
      display: inline-flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 20px;

      span {
        &:first-child {
          max-width: 150px;

          img {
            width: 100%;
          }
        }

        i {
          &.fa-approvalrequired {
            font-size: 20px;
          }
        }
      }
    }

    nav {
      padding-top: 0;
      margin-top: 20px;

      &.signout {
        padding-top: 10px;

        ul {
          li {
            &:first-child {
              order: 2;
            }

            &.CreatePage {
              @include button-success;

              width: 180px;
            }
          }
        }
      }

      ul {
        list-style: none;
        padding-left: 0;

        li {
          color: $text-primary;
          padding: 25px 0;
          display: flex;
          align-items: center;

          i {
            margin-right: 16px;
            font-size: 25px;
          }
          font: {
            size: 14px;
            family: $font-family-base;
            weight: $font-weight-medium;
          }

          span {
            &.mobileCount {
              @include media-breakpoint-up(md) {
                display: none;
              }

              width: 25px;
              height: 25px;
              display: inline-flex;
              justify-content: center;
              align-items: center;
              background: $success;
              color: $white;
              border-radius: 50%;
              font: {
                size: 12px;
                family: $font-family-base;
                weight: $font-weight-medium;
              }
            }

            &.desktopCount {
              @include media-breakpoint-down(md) {
                display: none;
              }
            }
          }
        }
      }
    }
  }

  #nav-content ul {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  #nav-content li a {
    padding: 10px 5px;
    display: block;
    text-transform: uppercase;
    transition: color 0.1s;
    cursor: pointer;
  }

  #nav-content li a:hover {
    color: #bf7497;
  }

  #nav-content li:not(.small) + .small {
    margin-top: auto;
  }

  .small {
    display: flex;
    align-self: center;
  }

  .small a {
    font-size: 12px;
    font-weight: 400;
    color: #888;
  }

  .small a + a {
    margin-left: 15px;
  }

  #nav-content.Active {
    transform: none;
  }
}

body:has(.MobileNav #nav-content.Active) .MobileFeatureNav {
  z-index: 999999;
}

body:has(.CreatePost.show, .active.panel-wrap, .PostPopup.show) .MobileNav {
  z-index: auto;
}

body.fundraiser .MobileNav {
  z-index: auto;
}
