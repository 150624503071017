@import 'bootstrap/scss/bootstrap';
@import 'bootstrap/scss/mixins';
@import '../../Assets/Styles/typography';
@import '../../Assets/Styles/colors';
@import '../../Assets/Styles/mixins';

.PostCardWrapper {
  max-height: calc(100vh - 85px);
  overflow-y: auto;
  padding-bottom: 40px;

  @include media-breakpoint-down(xl) {
    // max-height: calc(100vh - 175px);
    max-height: unset;
  }

  .CardWrapper {
    //margin-top: 15px;
    height: 100%;
    margin-bottom: 15px;

    @include media-breakpoint-down(md) {
      //padding-bottom: 90px;
    }

    h4 {
      color: #44565e;
      margin: 10px 0;
      font: {
        size: 14px;
        family: $font-family-base;
        weight: $font-weight-medium;
      }

      span {
        font: {
          size: 12px;
          family: $font-family-base;
          weight: $font-weight-regular;
        }
      }
    }

    .PostCard.card {
      padding: 13px;
      border-radius: 5px;
      border: none;
      margin-top: 15px;

      &:first-of-type {
        margin-top: 0;
      }

      .Header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: nowrap;
        width: 100%;
        padding-bottom: 8px;

        //border-bottom: 1px solid $bg;
        .Profile {
          max-width: calc(100% - 100px);
          flex: 0 1 calc(100% - 100px);

          .owner {
            @include ProfilePic;
          }
        }

        .actions {
          display: inline-block;
          position: relative;

          span {
            display: inline-flex;
            align-items: center;
            padding: 5px;

            span {
              &.dots {
                width: 33px;
                height: 33px;
                cursor: pointer;

                &:hover {
                  width: 33px;
                  height: 33px;
                  background: #1d817b57;
                  border-radius: 50%;
                }
              }
            }

            i {
              vertical-align: middle;
              cursor: pointer;

              &.fa-meatballs {
                font-size: 5px;
                color: $success;
                cursor: pointer;
              }

              &.fa-butterflypin {
                font-size: 18px;
                color: $success;
                margin-right: 16px;
              }

              &.fa-butterflypinned {
                font-size: 18px;
                color: $success;
                margin-right: 16px;
              }
            }
          }

          .ActionMenu {
            color: #777;
            box-shadow: 0 0 4px rgb(0 0 0 / 10%);
            padding: 11px;
            right: 0;
            z-index: 9;
            background: $white;
            position: absolute;
            border-radius: 5px;
            font: {
              size: 11px;
              family: $font-family-base;
              weight: $font-weight-semibold;
            }

            ul {
              list-style: none;
              padding-left: 0;
              margin-bottom: 0;

              li {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                padding-bottom: 17px;
                cursor: pointer;
                font-family: $font-family-dada-grotesk;
                font-size: 14px;
                &:last-child {
                  padding-bottom: 0;
                }

                @include media-breakpoint-down(md) {
                  font-size: 16px;
                }
              }
            }

            i {
              color: #777;
              font-size: 18px;
              padding-right: 5px;

              &.fa-edit {
                font-size: 18px;
              }

              &.fa-butterflydelete {
                font-size: 16px;
              }

              &.fa-turnoff {
                font-size: 17px;
              }

              @include media-breakpoint-down(xl) {
                &.fa-edit {
                  font-size: 24px;
                }

                &.fa-butterflydelete {
                  font-size: 20px;
                }

                &.fa-turnoff {
                  font-size: 24px;
                }
              }
            }
          }
        }
      }

      .Body {
        padding-top: 8px;

        .Question {
          font: {
            size: 16px;
            family: $font-family-base;
            weight: $font-weight-semibold;
          }

          margin-bottom: 5px;
          color: $text-primary;
        }

        .Answer {
          font: {
            size: 15px;
            family: $font-family-base;
            weight: $font-weight-regular;
          }

          margin-bottom: 15px;
          color: $text-primary;
        }

        .Description {
          white-space: pre-wrap;
          font: {
            size: 16px;
            family: $font-family-dada-grotesk;
            weight: $font-weight-regular;
          }

          color: $text-primary;

          a {
            color: $success;
          }
        }

        .video-post {
          position: relative;
          cursor: pointer;

          video.video-player {
            width: 100%;
            cursor: pointer;
          }

          .video-thumb {
            width: 100%;
            height: 100%;

            //background: url("https://butterflyv2-production-assets.nyc3.digitaloceanspaces.com/image/69136eb9-d427-403a-9edf-d0314ce8c762-butterfly-purple.svg"), #1d817b;
            background: url('https://butterflyv2-production-assets.nyc3.cdn.digitaloceanspaces.com/image/white_mark.png'),
              #1d817b;
            background-repeat: no-repeat;
            background-position: center;
            position: absolute;
            overflow: hidden;
            display: flex;
            justify-content: space-between;
            top: 0;
            background-size: cover;

            span {
              position: absolute;
              left: 50%;
              top: 50%;
              transform: translate(-50%, -50%);
              font-size: 40px;
              cursor: pointer;
              color: $white;
              width: 40px;
              height: 40px;
              display: flex;

              i {
                box-shadow: 0 0 10px #d1d1d1;
                font-size: 40px;
                border-radius: 50%;
                background-color: rgb(0 0 0 / 20%);
              }
            }

            &.no-video-thumb {
              background-color: #1d817b;

              .spinner-border.text-light {
                position: absolute;
                bottom: 10px;
                left: 10px;
                width: 20px;
                height: 20px;
              }
            }
          }
        }

        .video-post {
          max-height: 350px;
          display: flex;
          cursor: pointer;
          justify-content: center;

          video.video-player {
          }

          .video-thumb {
            background-size: contain;
            background-color: #e5e5e5;

            .overlay {
              background-color: rgb(0 0 0 / 20%);
              height: 100%;
              width: 100%;
              position: absolute;
            }
          }
        }

        .ImageGrid {
          max-height: none;
          cursor: pointer;

          img {
            object-fit: cover;
          }

          .multiple-images {
            display: flex;
            flex-wrap: wrap;
            margin-bottom: 13px;
            gap: 12px;

            .eachThumbnail {
              width: calc(50% - 7px);
              height: 240px;
              background-position: center;
              background-size: cover;
              background-repeat: no-repeat;
              margin-bottom: 0;
              cursor: pointer;

              &:nth-of-type(odd) {
                margin-right: 0;
              }

              .videoPlayIcon {
                max-width: 40px;
              }

              img {
                max-width: 100%;
              }

              @include media-breakpoint-down(md) {
                width: calc(50% - 6px);
                height: calc(55vw - 80px);
                flex: 0 0 calc(50% - 6px);
                max-width: calc(50% - 6px);
              }

              @include media-breakpoint-only(md) {
                height: 173px;
              }

              .more-images {
                position: absolute;
                height: 100%;
                width: 100%;
                background-color: rgb(0 0 0 / 70%);
                color: $white;
                font-size: 40px;
                font-weight: 700;
                text-align: center;
                top: 0;

                span {
                  font: {
                    size: $h3-font-size;
                    family: $font-family-base;
                    weight: $font-weight-semibold;
                  }

                  position: absolute;
                  top: 40%;
                  transform: translate(-50%);
                  left: 50%;
                }
              }

              &.video-type-post,
              &.audio-type-post {
                background-color: #1d817b;

                .overlay {
                  background-color: rgb(0 0 0 / 20%);
                  height: 100%;
                  width: 100%;
                  position: absolute;
                }

                i {
                  width: 100%;
                  height: 100%;
                  font-size: 60px;
                  align-items: center;
                  color: $white;
                  display: flex;
                  justify-content: center;
                  position: relative;
                }
              }

              &.video-type-post {
                background-color: transparent;

                i {
                  font-size: 30px;
                }

                &.no-video-thumb {
                  background: url('https://butterflyv2-production-assets.nyc3.cdn.digitaloceanspaces.com/image/white_mark.png'),
                    #1d817b;
                  background-repeat: no-repeat;
                  background-position: center;
                  background-size: 30%;
                  position: relative;

                  i {
                    position: absolute;
                  }

                  .spinner-border.text-light {
                    position: absolute;
                    bottom: 10px;
                    left: 10px;
                    width: 20px;
                    height: 20px;
                  }
                }
              }

              &.audio-type-post {
              }
            }
          }

          .single-image {
            .eachThumbnail {
              position: relative;
              border-radius: 0%;
              margin-bottom: 26px;
              width: 100%;
              cursor: pointer;
              background-repeat: no-repeat;
              background-size: contain;
              background-position: center;
              height: 394px;

              &::before {
                content: '';
                background: rgb(0 0 0 / 10%);
                width: 100%;
                height: 100%;
                position: absolute;
              }

              img {
                max-width: 100%;
                width: 100%;
              }

              .videoPlayIcon {
                position: absolute;
                right: 16px;
                top: 16px;
                width: 40px;
              }
            }
          }

          .eachThumbnail {
            border-radius: 0%;

            //margin-bottom: 26px;
            width: 100%;
            cursor: pointer;
            position: relative;
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
            height: 394px;

            @include media-breakpoint-down(md) {
              height: 180px;
            }

            &.video-type-post,
            &.audio-type-post {
              background-color: #1d817b;

              .overlay {
                background-color: rgb(0 0 0 / 20%);
                height: 100%;
                width: 100%;
                position: absolute;
              }

              i {
                width: 100%;
                height: 100%;
                font-size: 60px;
                align-items: center;
                color: $white;
                display: flex;
                justify-content: center;
                position: relative;
              }
            }

            &.video-type-post {
              background-color: transparent;

              i {
                font-size: 30px;
              }

              &.no-video-thumb {
                background: url('https://butterflyv2-production-assets.nyc3.cdn.digitaloceanspaces.com/image/white_mark.png'),
                  #1d817b;
                background-repeat: no-repeat;
                background-position: center;
                background-size: 30%;
                position: relative;

                i {
                  position: absolute;
                }

                .spinner-border.text-light {
                  position: absolute;
                  bottom: 10px;
                  left: 10px;
                  width: 20px;
                  height: 20px;
                }
              }
            }

            &::before {
              content: '';
              background: rgb(0 0 0 / 10%);
              width: 100%;
              height: 100%;
              position: absolute;
            }

            img {
              max-width: 100%;
              width: 100%;
            }

            .videoPlayIcon {
              position: absolute;
              right: 16px;
              top: 16px;
              width: 40px;
            }
          }

          .grid-container {
            align-items: flex-start;
            justify-content: flex-start;
            width: 100%;
            display: flex;

            &.two-image-grid-post-list {
              flex-wrap: nowrap;

              .eachThumbnail-post-1 {
                border-right: 1px solid white;
              }
            }

            &.three-image-grid-post-list {
              flex-wrap: wrap;

              .eachThumbnail-post-1 {
                flex: 0 1 100%;
                max-width: 100%;
                height: 240px;
                border-bottom: 1px solid $white;

                @include media-breakpoint-down(md) {
                  height: 180px;
                }
              }

              .eachThumbnail-post-2 {
                border-right: 1px solid $white;
              }

              .eachThumbnail-post-2,
              .eachThumbnail-post-3 {
                flex: 0 1 50%;
                max-width: 50%;
                height: 240px;

                @include media-breakpoint-down(md) {
                  height: 180px;
                }
              }
            }

            &.four-image-grid-post-list {
              flex-wrap: wrap;

              .eachThumbnail-post-1 {
                flex: 0 1 100%;
                max-width: 100%;
                height: 240px;
                border-bottom: 1px solid $white;

                @include media-breakpoint-down(md) {
                  height: 180px;
                }
              }

              .eachThumbnail-post-3 {
                border-left: 1px solid $white;
                border-right: 1px solid $white;
              }

              .eachThumbnail-post-2,
              .eachThumbnail-post-3,
              .eachThumbnail-post-4 {
                flex: 0 1 33.33%;
                max-width: 33.33%;
                height: 240px;

                @include media-breakpoint-down(md) {
                  height: 180px;
                }
              }
            }

            &.five-image-grid-post-list,
            &.more-image-grid-post-list {
              flex-wrap: wrap;

              .eachThumbnail-post-1,
              .eachThumbnail-post-2 {
                flex: 0 1 50%;
                max-width: 50%;
                height: 240px;
                border-bottom: 1px solid $white;

                @include media-breakpoint-down(md) {
                  height: 180px;
                }
              }

              .eachThumbnail-post-1 {
                border-right: 1px solid $white;
              }

              .eachThumbnail-post-4 {
                border-left: 1px solid $white;
                border-right: 1px solid $white;
              }

              .eachThumbnail-post-3,
              .eachThumbnail-post-4,
              .eachThumbnail-post-5 {
                flex: 0 1 33.33%;
                max-width: 33.33%;
                height: 240px;

                @include media-breakpoint-down(md) {
                  height: 180px;
                }
              }

              .eachThumbnail-post-5 {
                position: relative;

                .more-images {
                  position: absolute;
                  height: 100%;
                  width: 100%;
                  background-color: rgb(0 0 0 / 70%);
                  color: $white;
                  font-size: 40px;
                  font-weight: 700;
                  text-align: center;
                  top: 0;

                  span {
                    font: {
                      size: 40px;
                      family: $font-family-base;
                      weight: $font-weight-semibold;
                    }

                    position: absolute;
                    top: 40%;
                    transform: translate(-50%);
                    left: 50%;
                  }
                }
              }
            }
          }
        }

        .audio-post {
          .row {
            align-items: center;
          }

          .AudioWaves {
            flex: 0 1 calc(100% - 70px);

            .audioWrapper {
              width: 100%;

              .waves {
                width: 100%;

                wave {
                  wave {
                    z-index: 2;
                  }
                }
              }
            }
          }

          .ControllerIcon {
            width: 58px;
            height: 58px;
            flex: 0 1 58px;
            padding: 0;
            cursor: pointer;

            i {
              font-size: 58px;
              color: $success;
            }
          }
        }
      }

      .Footer {
        display: flex;
        justify-content: space-between;
        flex-wrap: nowrap;
        padding-top: 12px;
        align-items: center;

        .FooterText {
          p {
            margin-bottom: 0;
            font: {
              size: 12px;
              family: $font-family-dada-grotesk;
              weight: $font-weight-regular;
            }

            color: $text-primary;
          }
        }

        .FooterButton {
          display: flex;
          align-items: center;

          span {
            color: #777;
            display: inline-flex;
            align-items: center;
            line-height: 1;
            justify-content: flex-end;
            font: {
              size: 14px;
              family: $font-family-dada-grotesk;
              weight: $font-weight-regular;
            }

            i {
              margin-left: 2px;
              cursor: pointer;
            }

            &:nth-of-type(1) {
              margin-right: 5px;

              i {
                font-size: 19px;
                color: $success;
              }
            }

            &:nth-of-type(2) {
              i {
                color: #fc394d;
                font-size: 18px;

                &.disabled {
                  pointer-events: none;
                }
              }

              width: 40px;
            }
          }
        }
      }
    }

    &.Pin {
      .PostCard.card {
        &:last-of-type {
          margin-bottom: 40px;
        }

        .Body .ImageGrid .multiple-images {
          gap: inherit !important;
        }
      }
    }

    .post-pagination-loader {
      height: 50px;
      text-align: center;
      width: 50px;
      margin: 30px auto 0;

      @include media-breakpoint-down(md) {
        margin-bottom: 0;
        margin-top: 10px;
      }

      .lottie-loader svg {
        width: 50px !important;
        height: 50px !important;
        margin: auto;
      }
    }
  }

  .PinCardWrapper {
    //max-width: 450px;
    overflow: visible;
    margin-bottom: 15px;

    h4 {
      color: #44565e;
      margin: 10px 0;
      font: {
        size: 14px;
        family: $font-family-base;
        weight: $font-weight-medium;
      }

      span {
        font: {
          size: 12px;
          family: $font-family-base;
          weight: $font-weight-regular;
        }
      }
    }

    .react-multi-carousel-list {
      .react-multiple-carousel__arrow {
        z-index: 999;
        opacity: 0;
      }

      &:hover {
        .react-multiple-carousel__arrow {
          z-index: 999;
          opacity: 1;
        }
      }

      ul {
        &.react-multi-carousel-track {
          gap: 15px;

          li {
            &.react-multi-carousel-item {
              max-width: 418px;
              cursor: grab;

              //width: 300px !important;
              //flex: 1 1 300px !important;
              .PostCard {
                height: 320px;

                &.card {
                  .Body {
                    .GridWrapper {
                      width: 100%;
                      background-color: $bg;

                      .ImageGrid {
                        max-height: 110px;
                        height: 110px;
                        min-width: 28%;
                        margin: auto;

                        img {
                          object-fit: cover;
                        }

                        .multiple-images {
                          display: flex;
                          flex-wrap: wrap;
                          margin-bottom: 13px;
                          gap: 12px;

                          .eachThumbnail {
                            //width: 240px;
                            height: 110px;
                            background-position: center;
                            background-size: contain;
                            background-repeat: no-repeat;
                            margin-bottom: 0;
                            flex-basis: calc(25% - 9px);
                            cursor: pointer;

                            &.two {
                              flex-basis: calc(50% - 9px);
                            }

                            &.three {
                              flex-basis: calc(33.33% - 9px);
                            }

                            &.four {
                              flex-basis: calc(25% - 9px);
                            }

                            &:nth-of-type(odd) {
                              margin-right: 0;
                            }

                            .videoPlayIcon {
                              max-width: 40px;
                            }

                            img {
                              max-width: 100%;
                            }

                            @include media-breakpoint-down(md) {
                              width: 21%;
                              flex: 0 0 21%;
                              max-width: 21%;
                            }

                            @include media-breakpoint-only(md) {
                              width: 287px;
                              height: 287px;
                            }

                            .more-images {
                              position: relative;
                              height: 100%;
                              width: 100%;
                              background-color: rgb(0 0 0 / 70%);
                              color: $white;
                              text-align: center;

                              span {
                                font: {
                                  size: 20px;
                                  family: $font-family-base;
                                  weight: $font-weight-semibold;
                                }

                                position: absolute;
                                top: 40%;
                                transform: translate(-50%);
                                left: 50%;
                                display: block;
                                width: 90%;
                              }
                            }
                          }
                        }

                        .single-image {
                          .eachThumbnail {
                            position: relative;
                            border-radius: 0%;
                            margin-bottom: 26px;
                            width: 100%;
                            cursor: pointer;
                            background-repeat: no-repeat;
                            background-size: contain;
                            background-position: center;
                            height: 110px;

                            &::before {
                              content: '';
                              background: rgb(0 0 0 / 10%);
                              width: 100%;
                              height: 100%;
                              position: absolute;
                            }

                            img {
                              max-width: 100%;
                              width: 100%;
                            }

                            .videoPlayIcon {
                              position: absolute;
                              right: 16px;
                              top: 16px;
                              width: 40px;
                            }
                          }
                        }

                        .eachThumbnail {
                          border-radius: 0%;
                          margin-bottom: 26px;
                          width: 100%;
                          cursor: pointer;
                          position: relative;
                          background-repeat: no-repeat;
                          background-size: contain;
                          background-position: center;
                          height: 394px;

                          &::before {
                            content: '';
                            background: rgb(0 0 0 / 10%);
                            width: 100%;
                            height: 100%;
                            position: absolute;
                          }

                          img {
                            max-width: 100%;
                            width: 100%;
                          }

                          .videoPlayIcon {
                            position: absolute;
                            right: 16px;
                            top: 16px;
                            width: 40px;
                          }
                        }
                      }
                    }

                    .audio-post {
                      .row {
                        align-items: center;
                      }

                      .AudioWaves {
                        flex: 0 1 calc(100% - 70px);

                        .audioWrapper {
                          width: 100%;

                          .waves {
                            width: 100%;
                          }
                        }
                      }

                      .ControllerIcon {
                        width: 58px;
                        height: 58px;
                        flex: 0 1 58px;
                        padding: 0;
                        cursor: pointer;

                        i {
                          font-size: 58px;
                          color: $success;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }

        &:only-child {
          transform: translate3d(0, 0, 0) !important;
          width: 100%;

          li {
            width: 100% !important;
            max-width: 100% !important;
          }
        }
      }
    }

    .postCard {
      border: 5px solid transparent;
      min-height: 340px;

      @include media-breakpoint-up(md) {
        width: 350px !important;
        height: 340px;
      }
    }

    .CarouselWrapper {
      position: relative;

      .control {
        transform: translateY(-50%);
        background: rgb(0 0 0 / 20%);
        border-radius: 50%;
        border: none;
        width: 40px;
        height: 40px;
        color: $white;

        &.prev {
          left: 15px !important;
          padding: 0;
        }

        &.next {
          right: 15px !important;
          padding: 0;
        }

        i {
          font-size: 40px;
        }

        &:disabled {
          display: none;
        }
      }

      .PostCard.card {
        padding: 13px;
        border-radius: 5px;
        border: none;
        margin-top: 15px;
        min-height: 325px;

        &:first-of-type {
          margin-top: 0;
        }

        &:first-of-type {
          margin-top: 0;
        }

        .Header {
          display: flex;
          justify-content: space-between;
          align-items: center;
          flex-wrap: nowrap;
          width: 100%;
          padding-bottom: 8px;

          //border-bottom: 1px solid $bg;
          .Profile {
            max-width: calc(100% - 100px);
            flex: 0 1 calc(100% - 100px);

            .owner {
              @include ProfilePic;
            }
          }

          .actions {
            display: inline-block;
            position: relative;

            span {
              display: inline-flex;
              align-items: center;
              padding: 5px;

              span {
                &.dots {
                  width: 33px;
                  height: 33px;
                  cursor: pointer;

                  &:hover {
                    width: 33px;
                    height: 33px;
                    background: #1d817b57;
                    border-radius: 50%;
                  }
                }
              }

              i {
                vertical-align: middle;
                cursor: pointer;

                &.fa-meatballs {
                  font-size: 5px;
                  color: #1f927d;
                }

                &.fa-butterflypin {
                  font-size: 18px;
                  color: $success;
                  margin-right: 16px;
                }

                &.fa-butterflypinned {
                  font-size: 18px;
                  color: $success;
                  margin-right: 16px;
                }
              }
            }

            .ActionMenu {
              color: #777;
              box-shadow: 0 0 4px rgb(0 0 0 / 10%);
              padding: 11px;
              right: 0;
              z-index: 9;
              background: $white;
              position: absolute;
              border-radius: 5px;
              font: {
                size: 11px;
                family: $font-family-base;
                weight: $font-weight-semibold;
              }

              ul {
                list-style: none;
                padding-left: 0;
                margin-bottom: 0;

                li {
                  display: flex;
                  align-items: center;
                  justify-content: flex-start;
                  padding-bottom: 17px;
                  cursor: pointer;

                  &:last-child {
                    padding-bottom: 0;
                  }
                }
              }

              i {
                color: #777;
                font-size: 18px;
                padding-right: 5px;

                &.fa-edit {
                  font-size: 18px;
                }

                &.fa-butterflydelete {
                  font-size: 16px;
                }

                &.fa-turnoff {
                  font-size: 17px;
                }
              }
            }
          }
        }

        .Body {
          padding-top: 8px;
          min-height: 208px;
          height: 220px;
          overflow: hidden;

          @include media-breakpoint-between(md, lg) {
            min-height: 210px;
          }

          .wrapper {
            min-height: 100px;
            max-height: 100px;
            text-overflow: ellipsis;
          }

          .no-media-post {
            &.wrapper {
              min-height: unset;
              max-height: unset;
              text-overflow: unset;

              .Description {
                height: 210px;
                -webkit-line-clamp: 10;
                -webkit-box-orient: vertical;
              }

              &.Qa {
                .Question {
                  line-height: 1.3;
                }

                .Description {
                  //height: 168px;
                  height: 190px;
                }
              }
            }
          }

          &.withoutdesc {
            display: flex;
            width: 100%;
            flex-wrap: nowrap;
            align-items: center;
            height: 218px;

            .GridWrapper {
              width: 100%;
            }
          }

          .Question {
            font: {
              size: 16px;
              family: $font-family-base;
              weight: $font-weight-semibold;
            }

            margin-bottom: 0;
            color: $text-primary;
            max-height: 50px;
            text-overflow: ellipsis;
            overflow: hidden;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            height: 45px;
            display: -webkit-box;
          }

          .Answer {
            font: {
              size: 15px;
              family: $font-family-base;
              weight: $font-weight-regular;
            }

            margin-bottom: 15px;
            color: $text-primary;
          }

          .Description {
            font: {
              size: 15px;
              family: $font-family-dada-grotesk;
              weight: $font-weight-regular;
            }

            color: $text-primary;
            display: block;
            display: -webkit-box;
            max-width: 100%;
            height: 43px;
            margin: 0 auto;
            font-size: 14px;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;

            a {
              color: $success;

              &:hover {
                // color: ;
              }
            }
          }

          .video-post {
            position: relative;
            width: 50%;
            margin: auto;
            height: 110px;

            video.video-player {
              width: 100%;
              height: 110px;
            }

            .video-thumb {
              width: 100%;
              height: 100%;
              background: url('https://butterflyv2-production-assets.nyc3.cdn.digitaloceanspaces.com/image/white_mark.png'),
                #1d817b;
              background-repeat: no-repeat;
              background-position: center;
              position: absolute;
              overflow: hidden;
              display: flex;
              justify-content: space-between;
              top: 0;
              background-size: cover;

              span {
                position: absolute;
                right: unset;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                font-size: 30px;
                cursor: pointer;
                color: $white;

                i {
                  box-shadow: 0 0 10px #d1d1d1;
                  font-size: 40px;
                  border-radius: 50%;
                  background-color: rgb(0 0 0 / 20%);
                }
              }

              &.no-video-thumb {
                .spinner-border.text-light {
                  position: absolute;
                  bottom: 10px;
                  left: 10px;
                  width: 20px;
                  height: 20px;
                }
              }
            }
          }

          .video-post {
            &:only-child {
              .video-thumb {
                width: 100%;
                height: 100%;
              }
            }
          }

          .ImageGrid {
            max-height: none;
            cursor: pointer;

            .grid_wrapper {
              text-align: center;
              background-color: $bg;
              align-items: center;

              img {
                width: auto;
                height: 100%;
                max-height: 100%;
                max-width: 100%;
                margin: auto;
              }
            }
          }

          .ImageGrid {
            max-height: 110px;
            height: 110px;
            min-width: 28%;
            margin: auto;

            img {
              object-fit: cover;
            }

            .multiple-images {
              display: flex;
              flex-wrap: wrap;
              margin-bottom: 13px;
              gap: 12px;

              .eachThumbnail {
                //width: 240px;
                height: 110px;
                background-position: center;
                background-size: contain;
                background-repeat: no-repeat;
                margin-bottom: 0;
                flex-basis: calc(25% - 9px);
                cursor: pointer;

                &.two {
                  flex-basis: calc(50% - 9px);
                }

                &.three {
                  flex-basis: calc(33.33% - 9px);
                }

                &.four {
                  flex-basis: calc(25% - 9px);
                }

                &:nth-of-type(odd) {
                  margin-right: 0;
                }

                .videoPlayIcon {
                  max-width: 40px;
                }

                img {
                  max-width: 100%;
                }

                @include media-breakpoint-down(md) {
                  width: 21%;
                  flex: 0 0 21%;
                  max-width: 21%;
                }

                .more-images {
                  position: relative;
                  height: 100%;
                  width: 100%;
                  background-color: rgb(0 0 0 / 70%);
                  color: $white;
                  text-align: center;

                  span {
                    font: {
                      size: 20px;
                      family: $font-family-base;
                      weight: $font-weight-semibold;
                    }

                    position: absolute;
                    top: 40%;
                    transform: translate(-50%);
                    left: 50%;
                    display: block;
                    width: 90%;
                  }
                }

                i {
                  position: absolute;
                  box-shadow: 0 0 10px #d1d1d1;
                  font-size: 25px;
                  color: $white;
                  border-radius: 50%;
                  background-color: rgb(0 0 0 / 20%);
                  top: 50%;
                  left: 50%;
                  transform: translate(-50%, -50%);
                }

                &.audio-type-post {
                  &::before {
                    background-color: #1d817b;
                  }

                  i {
                    background-color: transparent;
                    border-radius: 0;
                    box-shadow: none;
                  }
                }

                &.video-type-post.no-video-thumb {
                  &::before {
                    background-color: #1d817b;
                  }

                  .spinner-border.text-light {
                    position: absolute;
                    bottom: 10px;
                    left: 10px;
                    width: 20px;
                    height: 20px;
                  }
                }
              }
            }

            .single-image {
              .eachThumbnail {
                position: relative;
                border-radius: 0%;
                margin-bottom: 26px;
                width: 100%;
                cursor: pointer;
                background-repeat: no-repeat;
                background-size: contain;
                background-position: center;
                height: 110px;

                &::before {
                  content: '';

                  //background: rgba(0, 0, 0, 0.1);
                  background: transparent;
                  width: 100%;
                  height: 100%;
                  position: absolute;
                }

                img {
                  max-width: 100%;
                  width: 100%;
                }

                .videoPlayIcon {
                  position: absolute;
                  right: 16px;
                  top: 16px;
                  width: 40px;
                }
              }
            }

            .eachThumbnail {
              border-radius: 0%;
              margin-bottom: 26px;
              width: 100%;
              cursor: pointer;
              position: relative;
              background-repeat: no-repeat;
              background-size: contain;
              background-position: center;
              height: 394px;

              &::before {
                content: '';
                background: rgb(0 0 0 / 10%);
                width: 100%;
                height: 100%;
                position: absolute;
              }

              img {
                max-width: 100%;
                width: 100%;
              }

              .videoPlayIcon {
                position: absolute;
                right: 16px;
                top: 16px;
                width: 40px;
              }
            }
          }

          .audio-post {
            .row {
              align-items: center;
            }

            .AudioWaves {
              flex: 0 1 calc(100% - 70px);
              width: 290px;

              .audioWrapper {
                width: 100%;

                .waves {
                  width: 100%;

                  wave {
                    height: 113px !important;
                  }
                }
              }
            }

            .ControllerIcon {
              width: 58px;
              height: 58px;
              flex: 0 1 58px;
              padding: 0;
              cursor: pointer;

              i {
                font-size: 58px;
                color: $success;
              }
            }
          }
        }

        .Footer {
          display: flex;
          justify-content: space-between;
          flex-wrap: nowrap;
          padding-top: 12px;
          align-items: center;

          .FooterText {
            p {
              margin-bottom: 0;
              font: {
                size: 12px;
                family: $font-family-dada-grotesk;
                weight: $font-weight-regular;
              }

              color: $text-primary;
            }
          }

          .FooterButton {
            display: flex;
            align-items: center;

            span {
              color: #777;
              display: inline-flex;
              align-items: center;
              line-height: 1;
              justify-content: flex-end;
              cursor: pointer;
              font: {
                size: 14px;
                family: $font-family-base;
                weight: $font-weight-regular;
              }

              i {
                margin-left: 2px;
              }

              &:nth-of-type(1) {
                margin-right: 5px;

                i {
                  font-size: 19px;
                  color: $success;
                }
              }

              &:nth-of-type(2) {
                i {
                  color: #fc394d;
                  font-size: 18px;
                }

                width: 40px;
              }
            }
          }
        }
      }
    }
  }
}
