@import 'bootstrap/scss/bootstrap';
@import 'bootstrap/scss/mixins';
@import '../../../Assets/Styles/typography';
@import '../../../Assets/Styles/colors';
@import '../../../Assets/Styles/mixins';

@include media-breakpoint-down(md) {
  body:has(.PostPopup.show) .MobileFeatureNav {
    display: none;
  }
}

@include media-breakpoint-between(md, xl) {
  .modal-backdrop.show,
  .PostPopup.modal.show {
    z-index: 999999 !important;
  }
}

.PostPopup.modal.show {
  overflow: overlay;

  @include media-breakpoint-down(md) {
    padding: 0 !important;
    z-index: 99999999 !important;
  }

  .modal-dialog {
    max-width: 60%;
    width: 100%;

    @include media-breakpoint-down(md) {
      max-height: 100vh;
      margin: 0;
      max-width: 100vh;
    }

    &:has(.without-image-preview) {
      max-width: 500px;

      .Commentsection {
        width: 100%;
        max-width: 100%;
        flex: 0 1 100%;
      }
    }

    .modal-content {
      border-radius: 0;
      border: none;

      @include media-breakpoint-down(md) {
        max-height: 100vh;
        height: 100vh;
      }

      .modal-header {
        border-bottom: none;
      }

      .modal-body {
        padding: 0;
      }
    }
  }
}
