@import 'bootstrap/scss/bootstrap';
@import 'bootstrap/scss/mixins';
@import '../../Assets/Styles/typography';
@import '../../Assets/Styles/colors';
@import '../../Assets/Styles/mixins';

.link-expired-content {
  max-width: 700px;
  width: 100%;
  margin: auto;
  text-align: left;

  h3 {
    font: {
      size: 44px;
      family: $font-family-base;
      weight: $font-weight-bold;
    }
  }

  button {
    @include button-success;

    height: 40px;
    margin-top: 10px;
  }

  @include media-breakpoint-down(md) {
    width: 100%;
    padding: 15px;

    h3 {
      font: {
        size: 34px;
        family: $font-family-base;
        weight: $font-weight-semibold;
      }
    }
  }
}

body:has(.link-expired-content) .MobileFeatureNav {
  display: none;
}
