@import 'bootstrap/scss/bootstrap';
@import 'bootstrap/scss/mixins';
@import '../../Assets/Styles/typography';
@import '../../Assets/Styles/colors';
@import '../../Assets/Styles/mixins';

#FeaturesNav {
  list-style: none;
  margin-top: 16px;
  padding: 0;

  li {
    padding: 10px 8px 10px 14px;
    background-color: $white;
    border-radius: 50px;
    cursor: pointer;

    &.DesktopQuestion {
      display: inline-flex;

      @include media-breakpoint-down(xl) {
        display: none;
      }
    }

    &.legacy-highlight {
      position: relative;
      z-index: 99999;
      pointer-events: none;
    }

    &.PageMemberOnboarding-highlight {
      position: relative;
      z-index: 99999;
      pointer-events: none;
    }

    &.MobileQuestion {
      display: none;

      @include media-breakpoint-down(xl) {
        display: flex;
      }
    }
    font: {
      size: 16px;
      family: $font-family-base;
      weight: $font-weight-medium;
    }

    &:hover {
      cursor: pointer;
      background-color: $success;
      color: $white;

      i {
        color: $white;
      }

      span {
        background-color: rgb(228 229 230 / 20%);
        color: $white;
      }
    }

    color: $title;
    display: inline-flex;
    width: 100%;
    align-items: center;
    margin-bottom: 10px;

    i {
      color: $success;
      font-size: 24px;
      flex: 0 1 35px;
      max-width: 35px;
    }

    label {
      flex: 0 1 calc(100% - 70px);
      max-width: calc(100% - 70px);
      cursor: pointer;
    }

    span {
      flex: 0 1 30px;
      max-width: 30px;
      width: 30px;
      height: 30px;
      background-color: $bg;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      font: {
        size: 12px;
        family: $font-family-base;
        weight: $font-weight-medium;
      }

      color: $title;
    }
  }
}
