:root {
  --color-primary: #333;
  --color-secondary: #d00;
  --color-additional: #ffce00;
}

$white: #fff;
$text-primary: #53555f;
$title: #004c54;
$bg: #f9f4f1;
$grey-1: #c6c8ca;
$success: #1f927d;
$error: #fc394d;
$Nuetral-900: $gray-900;
$primary-button: #1c2a51;
$secondary-button: $success;
$success-button: $success;
$hover-button: #c8742a;
$cta-focus: #1f9971;
$google-btn: $success;
$disabled-button: $gray-200;
$paragraph-2: #3e4462;
$heading: #18214d;
$lp-cta-bg: #29cc97;
$dashboardbg: #f5f5f5;
$error-color: #f49f9f;
$textarea-color: #f9f7ff;
$comment-line: #ddd;
$color-green: #24b183;
