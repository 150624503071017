@import 'bootstrap/scss/bootstrap';
@import 'bootstrap/scss/mixins';
@import '../../Assets/Styles/typography';
@import '../../Assets/Styles/colors';
@import '../../Assets/Styles/mixins';

.LeftSection {
  flex: 0 1 326px;
  max-width: 326px;
  padding: 9px 13px;
  overflow-y: overlay;
  max-height: 100vh;

  @include media-breakpoint-down(xl) {
    display: none;
  }
}
