@import 'bootstrap/scss/bootstrap';
@import 'bootstrap/scss/mixins';
@import 'https://fonts.googleapis.com/css2?family=Inter:wght@400;700&family=Poppins:wght@400;500;600&family=Work+Sans:wght@400;500;600&display=swap';

html,
body,
#root {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  background-color: $white;
}

html {
  height: 100%;
}

body {
  margin: 0;
  font-family: Poppins, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  overflow: hidden;
  -webkit-overflow-scrolling: touch;

  @include media-breakpoint-down(md) {
    overflow: scroll;
  }

  &:has(#HPLandingpage) {
    overflow: scroll;
  }

  .container {
    padding: 0;

    //background-color: $white; // ---------------- Commented due to left sticky nav bar
    background-color: #e4e5e6; // ---------------- Added due to left sticky nav bar
    min-height: 100vh;
    height: 100%;
    max-height: 1200px;
    position: relative;

    @media (width <= 1440px) {
      max-width: 100%;
      width: 100%;
      height: 100%;
    }

    @include media-breakpoint-down(xl) {
      min-height: 100%;
      -webkit-overflow-scrolling: touch;
      max-height: none;
    }
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

#turn {
  display: none;

  p {
    font-size: 0;
  }
}

.introjs-helperLayer {
  width: 0 !important;
  height: 0 !important;
  display: none;
}

.introjs-disableInteraction {
  opacity: 1 !important;
  background-color: transparent !important;
  box-shadow:
    rgb(33 33 33 / 80%) 0 0 0 0,
    rgb(33 33 33 / 50%) 0 0 0 5000px !important;
}

@media screen and (width >= 0) and (width <= 768px) and (orientation: landscape) {
  #turn {
    display: block;
    position: fixed;
    width: 100vw;
    height: 100%;
    z-index: 999999999999;
    top: 0;
    background-color: rgb(0 0 0 / 90%);
    color: $white;

    p {
      color: $white;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      text-align: center;
      font-size: 16px;

      span {
        width: 100px;
        height: 100px;
        display: block;
        margin: auto;
        margin-bottom: 10px;
      }

      img {
        width: 100px;
        height: 100px;
      }
    }
  }
}
