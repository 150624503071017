@import 'bootstrap/scss/bootstrap';
@import 'bootstrap/scss/mixins';
@import '../../Assets/Styles/typography';
@import '../../Assets/Styles/colors';
@import '../../Assets/Styles/mixins';

#AudioMessage {
  background-color: $white;
  padding: 10px;
  border-radius: 5px;
  margin-top: 13px;

  .header {
    color: $title;
    border-bottom: 1px solid $grey-1;
    padding-bottom: 10px;
    letter-spacing: 0.5px;
    font: {
      size: 14px;
      family: $font-family-base;
      weight: $font-weight-medium;
    }

    span {
      font: {
        size: 12px;
        family: $font-family-base;
        weight: $font-weight-regular;
      }
    }
  }

  .thumbnailWrapper {
    padding: 8px 0;
    display: flex;
    flex-wrap: nowrap;
    overflow-x: scroll;
    max-width: 100%;
    justify-content: flex-start;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none;

    &::-webkit-scrollbar {
      height: 4px;
    }

    .eachThumbnail {
      width: 65px;
      height: 65px;
      border-radius: 5px;
      overflow: hidden;
      flex: 1 0 65px;
      margin-right: 10px;
      position: relative;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: rgba(29, 129, 123, 0.169);
      color: #1d817b;
      max-width: 65px;
      font: {
        size: 48px;
        family: $font-family-dada-grotesk;
        weight: $font-weight-medium;
      }

      img {
        width: 100%;
        max-width: 100%;
      }

      .new {
        font: {
          size: 8px;
          family: $font-family-dada-grotesk;
          weight: $font-weight-medium;
        }

        background-color: $success;
        color: $white;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 9;
        height: 13px;
        width: 30px;
        text-align: center;
        padding: 1px 0;
        border-bottom-right-radius: 5px;
        border-bottom-left-radius: 5px;
        border-top-right-radius: 5px;
      }

      i {
        font-size: 18px;
        color: $white;
        position: absolute;
        z-index: 999;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }

      &:hover {
        i {
          color: $success;
        }
      }
    }

    .NoAudio {
      color: #777;
      text-align: center;
      min-height: 81px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      margin: auto;
      font: {
        size: 14px;
        family: $font-family-dada-grotesk;
        weight: $font-weight-regular;
      }
    }
  }

  &.MemberView {
    background-color: $success;
    height: 130px;
    width: 100%;
    padding: 16px;
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    cursor: pointer;
    margin-bottom: 35px;

    .AudioIcon {
      color: $white;
      flex: 0 1 45px;
      max-width: 45px;

      i {
        font-size: 45px;
      }
    }

    .AudioText {
      color: $white;
      flex: 0 1 calc(100% - 45px);
      max-width: calc(100% - 45px);
      padding-left: 20px;

      h6 {
        font: {
          size: 16px;
          family: $font-family-dada-grotesk;
          weight: $font-weight-medium;
        }
      }

      p {
        margin-bottom: 0;
        color: $white;
        margin-top: 5px;
        font: {
          size: 14px;
          family: $font-family-dada-grotesk;
          weight: $font-weight-regular;
        }
      }
    }
  }
}
