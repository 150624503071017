@import 'bootstrap/scss/bootstrap';
@import 'bootstrap/scss/mixins';
@import '../../../Assets/Styles/typography';
@import '../../../Assets/Styles/colors';
@import '../../../Assets/Styles/mixins';

.ReactionPopup {
  .modal-dialog {
    max-width: 300px;
    margin: auto;

    .modal-content {
      padding: 15px;

      .modal-header {
        flex-wrap: wrap;
        padding: 0;
        padding-top: 10px;
        border: none;

        ul {
          flex: 100%;
          border: none;
          margin-top: 10px;
          border-bottom: 1px solid #dee2e6;
          flex-wrap: nowrap !important;
          overflow-x: scroll;
          overflow-y: hidden;
          scrollbar-width: 6px;
          scrollbar-color: rgb(127 127 127 / 50%);

          /* Chrome, Edge, and Safari */
          &::-webkit-scrollbar {
            height: 4px;
            src: 3px;
          }

          &:hover {
            &::-webkit-scrollbar {
              width: 6px;
            }
          }

          &::-webkit-scrollbar-track {
            background: transparent;
          }

          &::-webkit-scrollbar-thumb {
            background-color: rgb(127 127 127 / 50%);
            border-radius: 10px;
            border: none;
            opacity: 0.5;
          }

          li {
            .nav-link {
              padding: 5px;
              border: none;
              padding-bottom: 2px;
              display: flex;
              align-items: center;
              font: {
                size: 16px;
                family: $font-family-base;
                weight: $font-weight-semibold;
              }

              color: $success;

              span.reaction-count {
                font-size: 14px;
                margin-left: 1px;
                color: #777;
              }

              &.active {
                color: $hover-button;
                border-bottom: 2px solid $hover-button;
              }
            }

            margin-right: 12px;
          }
        }

        .tab-content {
          height: 190px;
          overflow-y: overlay;
          width: 100%;

          .tab-pane {
            .ReactorWrpper {
              display: flex;
              flex-wrap: wrap;
              align-items: center;
              justify-content: flex-start;
              margin-bottom: 6px;
              padding: 4px 0;
              pointer-events: none;

              &.ownReaction {
                cursor: pointer;
                pointer-events: unset;

                &:hover {
                  background-color: rgb(242 244 245);
                }
              }

              .ReactorImg {
                flex: 0 1 35px;
                max-width: 35px;
                height: 35px;
                position: relative;

                .Reactions {
                  background-color: #f2f2f2;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  padding: 3px;
                  position: absolute;
                  border-radius: 50%;
                  bottom: 0;
                  right: -10px;

                  img {
                    max-width: 14px;
                  }
                }

                .ImgsHolder {
                  width: 100%;
                  height: 35px;
                  border-radius: 50%;
                  overflow: hidden;

                  img {
                    width: 100%;
                  }

                  .ownerAlpha {
                    background-color: #f3f0fe;
                    color: #9b7bf6;
                    width: 35px;
                    height: 35px;
                    line-height: 1;
                    padding: 7px;
                    position: relative;
                    border-radius: 50%;
                    font: {
                      size: 18px;
                      family: $font-family-work-san;
                      weight: $font-weight-semibold;
                    }

                    span {
                      position: absolute;
                      top: 50%;
                      left: 50%;
                      transform: translate(-50%, -50%);
                    }
                  }
                }
              }

              .ReactorName {
                margin-left: 20px;
                font: {
                  size: 14px;
                  family: $font-family-base;
                  weight: $font-weight-semibold;
                }

                color: $title;

                span.remove {
                  display: block;
                  font-size: 10px;
                  font-weight: 500;
                  color: #777;
                }
              }
            }
          }
        }

        .btn-close {
          top: 15px;
          position: absolute;
          right: 15px;
        }
      }
    }
  }

  @include media-breakpoint-down(md) {
    z-index: 9999999 !important;
  }
}

body:has(.ReactionPopup) .modal-backdrop {
  @include media-breakpoint-down(md) {
    z-index: 9999999 !important;
  }
}

body.settings-page.modal-open .modal-backdrop {
  @include media-breakpoint-down(md) {
    z-index: 9999999 !important;
  }
}

body.settings-page.modal-open .Information {
  z-index: 999999999 !important;
}
