@import 'bootstrap/scss/bootstrap';
@import 'bootstrap/scss/mixins';
@import '../../../Assets/Styles/typography';
@import '../../../Assets/Styles/colors';
@import '../../../Assets/Styles/mixins';

.Cropper {
  z-index: 9999999 !important;

  button {
    &.buttonCropper {
      @include button-success;

      height: 40px;
    }
  }

  .modal-header {
    border-bottom: 0;
  }

  .modal-body {
    padding-top: 0;
  }

  p {
    font: {
      size: 16px;
      family: $font-family-base;
      weight: $font-weight-regular;
    }

    strong {
      font: {
        size: 16px;
        family: $font-family-base;
        weight: $font-weight-semibold;
      }
    }
  }
}

body:has(.Cropper) .modal-backdrop {
  z-index: 9999999 !important;
}
