@import '~slick-carousel/slick/slick.css';
@import '~slick-carousel/slick/slick-theme.css';
@import 'bootstrap/scss/bootstrap';
@import 'bootstrap/scss/mixins';
@import '../../../Assets/Styles/typography';
@import '../../../Assets/Styles/colors';
@import '../../../Assets/Styles/mixins';

.AddDeleteQuestion {
  overflow-y: overlay;
  scroll-behavior: smooth;

  .questionHolder {
    padding-top: 20px;

    ul {
      padding-left: 0;

      //max-height: 50vh;
      overflow: scroll;
      scroll-behavior: smooth;
    }

    .questionContainer {
    }
  }

  .modal-dialog {
    max-width: 540px;
    width: 100%;

    .modal-content {
      border: none;
      background: $white;
      padding: 15px;

      @include media-breakpoint-down(md) {
        padding-bottom: 90px;
      }

      .modal-header {
        padding: 9px 0;

        .modal-title {
          align-items: center;
          font: {
            size: 20px;
            family: $font-family-base;
            weight: $font-weight-semibold;
          }

          color: $success;

          .owner {
            margin-right: 6px;

            @include ProfilePic;
          }
        }

        button.btn-close {
          color: $text-primary;
          opacity: 1;
          padding: 0;
          background-size: 14px;
          padding-right: 8px;
          background: none;
          font-family: Hmmngbrd;
          display: inline-block;
          font-size: 24px;
          font-weight: normal;
          font-style: normal;
          line-height: 1;
          text-rendering: auto;

          &::before {
            content: '\f06c';
          }

          &:focus {
            outline: none;
            border: none;
          }
        }
      }
    }

    .modal-footer,
    .modal-body {
      padding: 0;
      border: 0;

      ul {
        padding-left: 0;
      }
    }

    button.btn-close {
      right: 0 !important;
      font-size: 12px;
      top: 9px !important;

      &:focus {
        outline: none;
        box-shadow: none;
      }
    }
  }

  .questionWrap {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    cursor: grab;
    border: 1px solid silver;
    border-radius: 8px;
    padding: 12px;
    transition: all 0.8s ease-in-out;
    margin-bottom: 24px;
    background-color: $white;

    .circular-shape {
      margin-right: 12px;
      transition: all 0.8s ease-in-out;

      i {
        font-size: 32px;
        color: #d0c0fb;
      }
    }

    .question-title {
      width: 500px;
      max-width: 500px;
      padding-right: 12px;
      font-size: $subtitle;
      transition: all 0.7s ease-in-out;
      font-weight: $font-weight-medium;
      font-family: $font-family-base;
      color: $gray-800;
      line-height: 27px;
      word-break: break-word;

      @include media-breakpoint-down(sm) {
        width: 100%;
        max-width: 100%;
      }
    }
  }

  .question-expand {
    border-radius: 8px;
    padding: 12px;
    margin-bottom: 24px;
    border: 1px solid silver;

    .title {
      font-size: $subtitle;
      font-weight: $font-weight-medium;
      font-family: $font-family-base;
      color: $gray-800;
      line-height: 27px;
      margin-bottom: 12px;
    }

    p {
      font-size: 14px;
      font-weight: $font-weight-regular;
      font-family: $font-family-base;
      color: $gray-500;
      margin-bottom: 0;
      line-height: 150%;
    }

    .inner-holder {
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
      align-items: center;
      padding-top: 12px;

      button {
        background: none;
        border: 0;
        font-size: $subtext;
        font-weight: $font-weight-medium;
        font-family: $font-family-base;
        text-decoration: underline;

        &.no {
          color: $success;
          padding: 0;
        }

        &.yes {
          color: $error;
          padding: 0;
        }
      }
    }
  }

  .addQuestions {
    button {
      background: transparent;
      border: none;
      padding: 0;
      border-radius: 50%;
      outline: none;
      display: block;
      text-align: center;
      margin: 0 auto 24px;

      i {
        color: silver;
        font-size: $h1-font-size + 8;
      }

      &:disabled {
        i {
          color: $gray-200;
        }
      }
    }

    .answers-wrap {
      position: relative;
      border: 1px solid silver;
      border-radius: 8px;
      padding: 12px;
      margin-bottom: 24px;

      textarea {
        width: 100%;
        margin-bottom: 12px;
        border: none;
        font-size: 14px;
        font-weight: $font-weight-regular;
        font-family: $font-family-base;
        height: 94px;
        resize: none;
        color: $title;

        &:focus {
          outline: none;
        }
      }

      .ans-holder {
        position: absolute;
        bottom: 10px;
        left: 50%;
        transform: translateX(-50%);
        display: inline-flex;
        align-items: center;

        button {
          background: none;
          border: 0;
          padding: 0;
          display: inline-flex;
          align-items: center;
          margin: 0;

          &.save {
            color: $title;
            font-size: 14px;
            font-weight: $font-weight-medium;
            font-family: $font-family-base;

            //text-decoration: underline 1px;
            padding-right: 22px;

            //text-underline-offset: 2px;
            i {
              color: $success;
              font-size: 14px;
              padding-right: 5px;
            }
          }

          &.cancel {
            color: $error;
            font-size: $subtext;
            font-weight: $font-weight-medium;
            font-family: $font-family-base;

            //text-decoration: underline 1px;
            //text-underline-offset: 2px;
            i {
              color: $error;
              font-size: 22px;
              padding-left: 5px;
            }
          }
        }
      }
    }

    .ctaWrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;

      .lhs {
        button {
          display: inline-flex;
          align-items: center;

          i {
            color: $success;
            font-size: 18px;
            margin-right: 8px;
          }
          font: {
            size: 14px;
            family: $font-family-base;
            weight: $font-weight-medium;
          }

          color: $title;

          &:hover {
            color: $success;
          }
        }
      }

      .rhs {
        display: flex;
        align-items: center;

        .cancelCta {
          width: 80px;
          height: 40px;
          border: 1px solid $grey-1;
          border-radius: 50px;
          background-color: $white;
          background-size: cover;
          font-family: $font-family-base;
          font-weight: $font-weight-medium;
          font-size: 14px;
          color: $success;
          text-decoration: none solid rgb(36 177 131);
          text-align: center;
          display: inline-flex;
          justify-content: center;
          align-items: center;
          margin-right: 10px;

          &:hover {
            background-color: $success;
            color: $white;
            border: 1px solid $success;
          }
        }

        .saveCta {
          @include button-success;

          height: 40px;
          display: inline-flex;
          justify-content: center;
          align-items: center;
          font-family: $font-family-base;
          font-weight: $font-weight-medium;
          font-size: 14px;
        }
      }
    }
  }

  button.delete {
    background: none;
    border: 0;
    padding: 0;

    &:active {
      background: none;
    }

    i {
      font-size: 24px;
      color: $title;
    }

    &:hover {
      i {
        color: $error;
      }
    }
  }

  @include media-breakpoint-down(xl) {
    z-index: 999999 !important;
  }
}

body:has(.AddDeleteQuestion.show) .modal-backdrop {
  @include media-breakpoint-down(md) {
    z-index: 999999 !important;
  }
}
